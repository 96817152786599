import { serverTimestamp } from 'firebase/database';
import {
    ChatboardPermissionType,
    ChatboardPermission,
    SpacePermissionType,
    SpacePermission,
} from '@/utils/PowerchatClient/models/_interfaces';

// NOTIFICATION
export type RdbLastChatboardAddNotification = {
    spaceName: string;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    initialChatboardMemberships:
        | {
              id: string;
              userId: string;
              chatboardPermission: ChatboardPermissionType;
              toUserUniqueName: string;
              toUserDisplayName: string | undefined;
              spacePermission: SpacePermissionType;
          }[]
        | undefined;
};
export type RdbLastChatboardModifyNotification = {
    spaceName: string;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
};

// TYPE
export type RdbLastChatboard = {
    action: 'add' | 'modify';
    id: string;
    createdAt: number;
    updatedAt: number | undefined;
    uniqueName: string;
    closedAt: number | undefined;
    // for notification
    addNotification: RdbLastChatboardAddNotification | undefined;
    modifyNotification: RdbLastChatboardModifyNotification | undefined;
};

// INPUT
export type RdbLastChatboardInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    uniqueName: string;
    closedAt: Date | 'serverTimestamp' | undefined;
    // for notification
    addNotification: RdbLastChatboardAddNotification | undefined;
    modifyNotification: RdbLastChatboardModifyNotification | undefined;
};
export type AddRdbLastChatboardInput = {
    id: string;
    uniqueName: string;
    // for notification
    addNotification: RdbLastChatboardAddNotification;
};
export type ModifyRdbLastChatboardInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    uniqueName: string;
    closedAt: Date | 'serverTimestamp' | undefined;
    // for notification
    modifyNotification: RdbLastChatboardModifyNotification;
};

export const getRdbLastChatboardInput = ({
    id,
    createdAt,
    updatedAt,
    uniqueName,
    closedAt,
    addNotification,
    modifyNotification,
}: RdbLastChatboardInput): {
    id: string;
    createdAt: number | ReturnType<typeof serverTimestamp>;
    updatedAt: number | ReturnType<typeof serverTimestamp> | null;
    uniqueName: string;
    closedAt: number | ReturnType<typeof serverTimestamp> | null;
    // for notification
    addNotification: {
        spaceName: string;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        initialChatboardMemberships:
            | {
                  id: string;
                  userId: string;
                  chatboardPermission: ChatboardPermissionType;
                  toUserUniqueName: string;
                  toUserDisplayName: string | null;
                  spacePermission: SpacePermissionType;
              }[]
            | null;
    } | null;
    modifyNotification: {
        spaceName: string;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
    } | null;
} => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        updatedAt: updatedAt === 'serverTimestamp' ? serverTimestamp() : updatedAt ? updatedAt.getTime() : null,
        uniqueName,
        closedAt: closedAt === 'serverTimestamp' ? serverTimestamp() : closedAt ? closedAt.getTime() : null,
        addNotification: (() => {
            if (addNotification) {
                const { spaceName, fromUserUniqueName, fromUserDisplayName, initialChatboardMemberships } =
                    addNotification;
                return {
                    spaceName,
                    fromUserUniqueName,
                    fromUserDisplayName: fromUserDisplayName || null,
                    initialChatboardMemberships:
                        initialChatboardMemberships?.map(
                            ({
                                id,
                                userId,
                                chatboardPermission,
                                toUserUniqueName,
                                toUserDisplayName,
                                spacePermission,
                            }) => ({
                                id,
                                userId,
                                chatboardPermission: new ChatboardPermission(chatboardPermission),
                                toUserUniqueName,
                                toUserDisplayName: toUserDisplayName || null,
                                spacePermission: new SpacePermission(spacePermission),
                            })
                        ) || null,
                };
            }
            return null;
        })(),
        modifyNotification: (() => {
            if (modifyNotification) {
                const { spaceName, fromUserUniqueName, fromUserDisplayName } = modifyNotification;
                return {
                    spaceName,
                    fromUserUniqueName,
                    fromUserDisplayName: fromUserDisplayName || null,
                };
            }
            return null;
        })(),
    };
};
