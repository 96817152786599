import { ref, set } from 'firebase/database';
import { collection, doc } from 'firebase/firestore';
import { getFirebaseRealtimeDatabase, getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import {
    LobbyMasterMessage,
    LobbyReplyMessage,
    ChatboardMasterMessage,
    ChatboardReplyMessage,
    Message,
} from '@/utils/PowerchatClient/models';
import { getRdbLastMessageInput, AddRdbLastMessageInput } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbMessage = ({ spaceId, newMessage }: { spaceId: string; newMessage: AddRdbLastMessageInput }) => {
    const firestoreDb = getFirebaseFirestore();
    const { id } = newMessage.chatboardId
        ? doc(collection(firestoreDb, `Space/${spaceId}/Chatboard/${newMessage.chatboardId}/Message`))
        : doc(collection(firestoreDb, `Space/${spaceId}/Message`));
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastMessageRef = ref(realtimeDb, `lastMessages/${spaceId}`);
    const mentionToUserIds = newMessage.addNotification.mentions?.map(({ id }) => id) || [];

    set(lastMessageRef, {
        action: 'add',
        ...getRdbLastMessageInput({
            ...newMessage,
            id,
            createdAt: 'serverTimestamp',
            updatedAt: undefined,
            canceledAt: undefined,
            mentionToUserIds,
            modifyNotification: undefined,
        }),
    });
    return {
        newMessage: (() => {
            if (newMessage.chatboardId && newMessage.replyToMessageId) {
                return new ChatboardReplyMessage({
                    id,
                    createdAt: new Date(),
                    updatedAt: undefined,
                    serialNumber: undefined,
                    userId: newMessage.userId,
                    spaceId,
                    chatboardId: newMessage.chatboardId,
                    body: newMessage.body,
                    replyToMessageId: newMessage.replyToMessageId,
                    mentionToUserIds: mentionToUserIds || [],
                    canceledAt: undefined,
                });
            }
            if (newMessage.chatboardId) {
                return new ChatboardMasterMessage({
                    id,
                    createdAt: new Date(),
                    updatedAt: undefined,
                    serialNumber: undefined,
                    userId: newMessage.userId,
                    spaceId,
                    chatboardId: newMessage.chatboardId,
                    body: newMessage.body,
                    mentionToUserIds: mentionToUserIds || [],
                    canceledAt: undefined,
                });
            }
            if (newMessage.replyToMessageId) {
                return new LobbyReplyMessage({
                    id,
                    createdAt: new Date(),
                    updatedAt: undefined,
                    serialNumber: undefined,
                    userId: newMessage.userId,
                    spaceId,
                    body: newMessage.body,
                    replyToMessageId: newMessage.replyToMessageId,
                    mentionToUserIds: mentionToUserIds || [],
                    canceledAt: undefined,
                });
            }
            return new LobbyMasterMessage({
                id,
                createdAt: new Date(),
                updatedAt: undefined,
                serialNumber: undefined,
                userId: newMessage.userId,
                spaceId,
                body: newMessage.body,
                mentionToUserIds: mentionToUserIds || [],
                canceledAt: undefined,
            });
        })() as Message,
    };
};
