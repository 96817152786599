import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useUserCtx, useHealthCtx, useErrorCtx } from '@/utils/ctxs';
import {
    HealthProvider,
    MutationsProvider,
    ErrorProvider,
    UserProvider,
    ConnectionProvider,
    RealtimeDatabaseProvider,
    CloudMessagingProvider,
    SpacesProvider,
} from '@/providers';
import { GoogleTagManagerBody, getGoogleTagManagerHead } from '@/components/0_atom/GoogleTagManager';
import { NetworkIndicator } from '@/components/2_org/NetworkIndicator';
import { ConsoleTemplate } from '@/components/3_template/ConsoleTemplate';
import { PreConsoleTemplate } from '@/components/3_template/PreConsoleTemplate';

import { muiTheme } from '@/styles/muiTheme';

const Handler: FC = () => {
    // HOOKS
    const { isFetching: isFetchingUser, userClient, isNeedToGrantNotification } = useUserCtx();
    const { fetchStatus } = useHealthCtx();
    const { is404, is500 } = useErrorCtx();

    // RETURN
    if (isFetchingUser || fetchStatus === 'fetching' || is404 || is500 || isNeedToGrantNotification) {
        return <PreConsoleTemplate />;
    }
    if (userClient) {
        return (
            <RealtimeDatabaseProvider>
                <CloudMessagingProvider>
                    <SpacesProvider>
                        <ConsoleTemplate />
                    </SpacesProvider>
                </CloudMessagingProvider>
            </RealtimeDatabaseProvider>
        );
    }
    return <PreConsoleTemplate />;
};

export const AppRoot: FC = () => {
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' });
        }
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                {/* {(() => {
                    const g = getGoogleTagManagerHead();
                    return g || <></>;
                })()} */}
                {getGoogleTagManagerHead()}
                <link rel={'preconnect'} href={'https://fonts.googleapis.com'} />
                <link rel={'preconnect'} href={'https://fonts.gstatic.com'} crossOrigin={'crossOrigin'} />
                <link
                    href={'https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap'}
                    rel={'stylesheet'}
                />
            </Helmet>
            <>
                <GoogleTagManagerBody />
                <ConnectionProvider>
                    <ErrorProvider>
                        <HealthProvider>
                            <MutationsProvider>
                                <UserProvider>
                                    <BrowserRouter>
                                        <ThemeProvider theme={muiTheme}>
                                            <CssBaseline />
                                            <NetworkIndicator />
                                            <Handler />
                                        </ThemeProvider>
                                    </BrowserRouter>
                                </UserProvider>
                            </MutationsProvider>
                        </HealthProvider>
                    </ErrorProvider>
                </ConnectionProvider>
            </>
        </HelmetProvider>
    );
};
AppRoot.displayName = 'AppRoot';
