import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    getRdbLastChatboardMembershipInput,
    ModifyRdbLastChatboardMembershipInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const modifyRdbChatboardMembership = ({
    spaceId,
    chatboardMembership,
}: {
    spaceId: string;
    chatboardMembership: ModifyRdbLastChatboardMembershipInput;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastChatboardMembershipRef = ref(db, `lastChatboardMemberships/${spaceId}`);
    set(lastChatboardMembershipRef, {
        action: 'modify',
        ...getRdbLastChatboardMembershipInput({
            ...chatboardMembership,
            updatedAt: 'serverTimestamp',
            removedAt: undefined,
            addNotification: undefined,
            removeNotification: undefined,
        }),
    });
};
