import { GqlLobbyReplyMessage } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import { LobbyMasterMessageType, LobbyMasterMessage } from './LobbyMasterMessage';

export type LobbyReplyMessageType = LobbyMasterMessageType & {
    replyToMessageId: string;
};

export class LobbyReplyMessage extends LobbyMasterMessage implements LobbyReplyMessageType {
    readonly replyToMessageId: string;

    constructor({ replyToMessageId, ...input }: LobbyReplyMessageType) {
        super(input);
        this.replyToMessageId = replyToMessageId;
    }
}

export const getLobbyReplyMessageFromGql = ({
    id,
    createdAt,
    updatedAt,
    serialNumber,
    userId,
    spaceId,
    body,
    replyToMessageId,
    mentionToUserIds,
    canceledAt,
}: GqlLobbyReplyMessage) => {
    return new LobbyReplyMessage({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        serialNumber,
        spaceId,
        userId,
        body,
        replyToMessageId,
        mentionToUserIds,
        canceledAt: isoToDateOptionally(canceledAt),
    });
};
