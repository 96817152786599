import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { Thread, ChatboardApi } from '@/utils/PowerchatClient';
import { useSpaceCtxAbsolutely, useSpaceMembersCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { getOptionalUserName } from '@/utils/utilFunctions';
import { Window } from '@/components/0_atom/Window';
import { ChatBox } from '@/components/0_atom/ChatBox';
import { AddMessageInput } from '@/components/2_org/AddMessageInput';
import { ThreadViewContent } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/ThreadViewContent';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 180,
        // flexShrink: 0,
        // '&:not(:last-child)': {
        //     marginRight: 5,
        // },
    },
    chatbox: {},
    input: {
        marginLeft: 25,
    },
}));

export const ThreadView: FC<{
    chatboardApi: ChatboardApi;
    thread: Thread;
}> = ({ chatboardApi, thread }) => {
    // STYLE
    const c = useStyles(useTheme());

    // CTX
    const {
        current: { spaceMembershipApi },
    } = useSpaceCtxAbsolutely();
    const { getSpaceMember } = useSpaceMembersCtxAbsolutely();

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const chatboxElmId = `power-chatboard-chatbox-${chatboardApi.id}-${thread.master.messageApi.id}`;
    const { master } = thread;
    const masterMessageAuthor = getSpaceMember(master.messageApi.userId);
    const { uniqueName: toUserUniqueName, displayName: toUserDisplayName } = getOptionalUserName({
        spaceMembershipApi: masterMessageAuthor?.spaceMembershipApi,
        txt,
    });

    return (
        <>
            <ChatBox
                id={chatboxElmId}
                className={c.chatbox}
                initialChildren={undefined}
                upstreamChildren={undefined}
                downstreamChildren={{
                    component: ThreadViewContent,
                    props: { thread },
                }}
                otherChildren={undefined}
            />
            <AddMessageInput
                className={c.input}
                handleSendMessage={async ({ body }) => {
                    chatboardApi.createMessage({
                        body,
                        replyTo: {
                            messageId: master.messageApi.id,
                            user: {
                                id: master.messageApi.userId,
                                toUserUniqueName,
                                toUserDisplayName,
                            },
                        },
                        mentions: [],
                        isMarkUnconfirmed: false,
                        isObserveReply: false,
                    });
                }}
                isPermitted={spaceMembershipApi.permission.lobby.write}
                placeholder={txt({
                    en: 'Reply to this thread',
                    ja: 'このスレッドへ',
                })}
            />
        </>
    );
};
ThreadView.displayName = 'Chatboard/ThreadView';
