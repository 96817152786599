import { useCallback, useEffect } from 'react';

import { RdbUser, ChatboardMembershipApi, SpaceMembershipApi, ChatboardApi } from '@/utils/PowerchatClient';
import { useLastChatboardMembershipCtx, useSpaceMembersCtx, useHotMembersCtxAbsolutely } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks/useItems';

export type ChatboardMemberItem = {
    id: string;
    // rdbUser: RdbUser | undefined;
    // spaceMembershipApi: SpaceMembershipApi;
    chatboardMembershipApi: ChatboardMembershipApi;
};

// later: SpaceMembershipの更新を反映できていない (useLastSpaceMembershipCtxでいいか？)->いや、むしろspaceMembershipApiを手放し、身軽になった方がいいかも

export const useChatboardMembers = ({ chatboardApi }: { chatboardApi: ChatboardApi }) => {
    // CTX
    const { userIds, getSpaceMemberAbsolutely } = useSpaceMembersCtx();
    // const { userIds: hotUserIds, getHotRdbUser } = useHotMembersCtxAbsolutely();
    const { lastChatboardMembershipLog } = useLastChatboardMembershipCtx();

    // HOOKS
    const { ids, itemDatas, getItem, getItemAbsolutely, initialize, addItem, updateItem, removeItem } =
        useLazyItems<ChatboardMemberItem>(undefined);
    console.log({ ids, itemDatas });
    // CALLBACKS
    const fetchChatboardMembers = useCallback(async () => {
        if (userIds) {
            const fetchedItems = await chatboardApi.getChatboardMembershipApis({
                spaceMembershipApis: userIds.map((userId) => getSpaceMemberAbsolutely(userId).spaceMembershipApi),
            });
            console.log({ fetchedItems });
            initialize(
                fetchedItems.map((chatboardMembershipApi) => {
                    // const { rdbUser, spaceMembershipApi } = getSpaceMemberAbsolutely(chatboardMembershipApi.userId);
                    return {
                        id: chatboardMembershipApi.userId,
                        // rdbUser,
                        // spaceMembershipApi,
                        chatboardMembershipApi,
                    };
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatboardApi.id, userIds]);

    // USEEFFECT
    useEffect(() => {
        fetchChatboardMembers();
    }, [fetchChatboardMembers]);
    useEffect(() => {
        return () => {
            initialize(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatboardApi.id]);

    // CALLBACKS
    const addChatboardMember = useCallback(
        (chatboardMembershipApi: ChatboardMembershipApi) => {
            // const { rdbUser, spaceMembershipApi } = getSpaceMemberAbsolutely(chatboardMembershipApi.userId);
            addItem({
                newItem: {
                    id: chatboardMembershipApi.userId,
                    // rdbUser,
                    // spaceMembershipApi,
                    chatboardMembershipApi,
                },
                isIgnoreDuplicationError: true,
            });
        },
        [addItem]
    );
    const updateChatboardMembershipApi = useCallback(
        (updatedChatboardMembershipApi: ChatboardMembershipApi) => {
            updateItem({
                id: updatedChatboardMembershipApi.userId,
                updator: (prevItem) => ({
                    ...prevItem,
                    chatboardMembershipApi: updatedChatboardMembershipApi,
                }),
                isIgnoreMissingError: true,
            });
        },
        [updateItem]
    );
    const removeChatboardMember = useCallback(
        (userId: string) => {
            removeItem({
                id: userId,
                isIgnoreMissingError: true,
            });
        },
        [removeItem]
    );

    // LISTER
    useEffect(() => {
        if (lastChatboardMembershipLog?.action === 'add') {
            addChatboardMember(lastChatboardMembershipLog.chatboardMembershipApi);
        }
        if (lastChatboardMembershipLog?.action === 'modify') {
            updateChatboardMembershipApi(lastChatboardMembershipLog.chatboardMembershipApi);
        }
        if (lastChatboardMembershipLog?.action === 'remove') {
            removeChatboardMember(lastChatboardMembershipLog.chatboardMembershipApi.userId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastChatboardMembershipLog]);

    return {
        userIds: ids,
        getChatboardMember: getItem,
        getChatboardMemberAbsolutely: getItemAbsolutely,
        // addChatboardMember,
        // updateChatboardMembershipApi,
        // removeChatboardMember,
        // initializeChatboardMembers: initialize,
    };
};
