import { useState, useReducer, useEffect, useCallback } from 'react';

const initItems = <T>(items?: Record<string, T>) => items;

export const useLazyAssociativeItems = <T>(items?: Record<string, T>) => {
    // HOOKS
    const [ids, setIds] = useState<string[] | undefined>(() => (items ? Object.keys(items) : undefined));
    const [itemDatas, dispatchItemDatas] = useReducer<
        (
            state: Record<string, T> | undefined,
            input: {
                add?: {
                    id: string;
                    newItem: T;
                    isIgnoreDuplicationError?: boolean;
                };
                remove?: {
                    id: string;
                    isIgnoreMissingError?: boolean;
                };
                update?: {
                    id: string;
                    updator: (prevItem: T) => T;
                    isIgnoreMissingError?: boolean;
                };
                reset?: { items?: Record<string, T> };
            }
        ) => Record<string, T> | undefined,
        Record<string, T> | undefined
    >(
        (state, { add, remove, update, reset }) => {
            if (add) {
                if (state) {
                    const sameKeyItem = state[add.id];
                    if (sameKeyItem) {
                        if (add.isIgnoreDuplicationError) {
                            return state;
                        }
                        throw new Error('useLazyItem.addItem: !!sameIdItem.');
                    }
                    return {
                        ...state,
                        [add.id]: add.newItem,
                    };
                }
                return {
                    [add.id]: add.newItem,
                };
            }
            if (remove) {
                if (state) {
                    if (!remove.isIgnoreMissingError && !state[remove.id]) {
                        throw new Error('useLazyItem.removeItem: missing id.');
                    }
                    const newState = state;
                    delete newState[remove.id];
                    return newState;
                }
                if (remove.isIgnoreMissingError) {
                    return state;
                }
                throw new Error('useLazyItem.removeItem: missing id.');
            }
            if (update) {
                const { id, updator, isIgnoreMissingError } = update;
                const prevItem = state ? state[id] : undefined;
                if (prevItem) {
                    const newItem = updator(prevItem);
                    return {
                        ...state,
                        [id]: newItem,
                    };
                }
                if (isIgnoreMissingError) {
                    return state;
                }
                throw new Error('useLazyItems.updator: !prevItem.');
            }
            if (reset) {
                return initItems(reset.items);
            }
            throw new Error('never');
        },
        items,
        initItems
    );

    // USEEFFECT
    useEffect(() => {
        // when added or initialized
        if ((itemDatas ? Object.keys(itemDatas).length : undefined) !== ids?.length) {
            setIds(items ? Object.keys(items) : undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemDatas]);

    // HANDLER (CALLBACKS)
    const getItemData = useCallback(
        (id: string) => {
            if (!itemDatas) {
                return undefined;
            }
            return itemDatas[id];
        },
        [itemDatas]
    );

    const getItemDataAbsolutely = useCallback(
        (id: string) => {
            const target = getItemData(id);
            if (target) {
                return target;
            }
            console.error('useLazyItems.getItemDataAbsolutely: item for id not found!', {
                givenId: id,
                ids,
                itemDatas,
            });
            throw new Error('useLazyItems.getItemDataAbsolutely: item for id not found!');
        },
        [ids, itemDatas, getItemData]
    );

    const updateItem = useCallback(
        ({
            id,
            updator,
            isIgnoreMissingError,
        }: {
            id: string;
            updator: (prevItem: T) => T;
            isIgnoreMissingError?: boolean;
        }) => {
            dispatchItemDatas({
                update: {
                    id,
                    updator,
                    isIgnoreMissingError,
                },
            });
        },
        []
    );
    const removeItem = useCallback(
        ({ id, isIgnoreMissingError }: { id: string; isIgnoreMissingError?: boolean }) => {
            setIds(ids?.filter((item) => item !== id));
            dispatchItemDatas({ remove: { id, isIgnoreMissingError } });
        },
        [ids]
    );
    const addItem = useCallback(
        ({ id, newItem, isIgnoreDuplicationError }: { id: string; newItem: T; isIgnoreDuplicationError?: boolean }) => {
            dispatchItemDatas({ add: { id, newItem, isIgnoreDuplicationError } });
        },
        []
    );
    const initialize = useCallback((items?: Record<string, T>) => {
        setIds(undefined);
        dispatchItemDatas({ reset: { items } });
    }, []);

    return {
        ids,
        itemDatas,
        getItem: getItemData,
        getItemAbsolutely: getItemDataAbsolutely,
        updateItem,
        removeItem,
        addItem,
        initialize,
    };
};

export const useAssociativeItems = <T>(items: Record<string, T>) => {
    // HOOKS
    const { ids, itemDatas, getItem, getItemAbsolutely, updateItem, removeItem, addItem, initialize } =
        useLazyAssociativeItems(items);

    return {
        ids: ids || [],
        itemDatas: itemDatas || {},
        getItem,
        getItemAbsolutely,
        updateItem,
        removeItem,
        addItem,
        initialize: (items: Record<string, T>) => initialize(items),
    };
};
