import { useEffect } from 'react';

import { ReactionApi } from '@/utils/PowerchatClient';
import { useLastReactionCtx } from '@/utils/ctxs';
import { useItems } from '@/utils/customHooks';

export const useReactions = ({
    messageId,
    initialReactionApis,
}: {
    messageId: string;
    initialReactionApis: ReactionApi[];
}) => {
    // HOOKS
    const { lastReactionLog } = useLastReactionCtx();
    const { ids, itemDatas, getItemAbsolutely, addItem, removeItem } = useItems<ReactionApi>(initialReactionApis);

    // USEEFFECT
    useEffect(() => {
        if (lastReactionLog?.reactionApi.messageId === messageId) {
            const { reactionApi } = lastReactionLog;
            if (lastReactionLog.action === 'add') {
                addItem({
                    newItem: reactionApi,
                    isIgnoreDuplicationError: true,
                });
            } else if (lastReactionLog.action === 'remove') {
                console.log('remove reaction: ', reactionApi.id);
                removeItem({ id: reactionApi.id });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastReactionLog, messageId]);

    return {
        reactionIds: ids,
        reactionApis: itemDatas,
        getReactionItemAbsolutely: getItemAbsolutely,
    };
};
