import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { ChatboardApi, ChatboardMembershipApi } from '@/utils/PowerchatClient';
import { useSpaceCtxAbsolutely } from '@/utils/ctxs';
import { useChatboardMasterMessages } from '@/utils/customHooks';
import { ChatBox } from '@/components/0_atom/ChatBox';
import { AddMessageInput } from '@/components/2_org/AddMessageInput';
import { ChatboardViewLocalContent } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/ChatboardViewLocalContent';
import { ChatboardViewPastContent } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/ChatboardViewPastContent';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    chatbox: {},
}));

export const ChatboardView: FC<{
    chatboardApi: ChatboardApi;
    chatboardMembershipApi: ChatboardMembershipApi;
}> = ({ chatboardApi, chatboardMembershipApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // CTXS
    const {
        current: { spaceMembershipApi, spaceApi },
    } = useSpaceCtxAbsolutely();

    // HOOKS
    const { messageIds, getMessageItemAbsolutely, messageItems } = useChatboardMasterMessages({
        chatboardId: chatboardApi.id,
    });

    // DATA
    const chatboxElmId = `power-chatboard-chatbox-${chatboardApi.id}-main`;

    return (
        <div className={c.root}>
            <ChatBox
                id={chatboxElmId}
                className={c.chatbox}
                initialChildren={undefined}
                upstreamChildren={{
                    component: ChatboardViewPastContent,
                    props: {
                        chatboardApi,
                    },
                }}
                downstreamChildren={{
                    component: ChatboardViewLocalContent,
                    props: {
                        chatboardApi,
                        messageIds,
                        getMessageItemAbsolutely,
                        messageItems,
                    },
                }}
                otherChildren={undefined}
            />
            <AddMessageInput
                handleSendMessage={async ({ body }) => {
                    // later: mentions
                    chatboardApi.createMessage({
                        body,
                        replyTo: undefined,
                        mentions: [],
                        isMarkUnconfirmed: false,
                        isObserveReply: false,
                    });
                }}
                isPermitted={chatboardMembershipApi.permission.write}
                placeholder={chatboardApi.uniqueName}
            />
        </div>
    );
};
ChatboardView.displayName = 'ChatboardView';
