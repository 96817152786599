class RgbColor {
    r: number;

    g: number;

    b: number;

    constructor([r, g, b]: [r: number, g: number, b: number]) {
        this.r = r;
        this.g = g;
        this.b = b;
    }

    getCssValue({ opacity }: { opacity: number }) {
        if (opacity === 1) {
            return `rgb(${this.r},${this.g},${this.b})`;
        }
        return `rgba(${this.r},${this.g},${this.b},${opacity})`;
    }
}

const rgbColors: RgbColor[] = [
    new RgbColor([251, 133, 133]),
    new RgbColor([110, 59, 220]),
    new RgbColor([220, 184, 59]),
    new RgbColor([245, 120, 49]),
    new RgbColor([103, 218, 33]),
    new RgbColor([11, 123, 226]),
    new RgbColor([234, 78, 218]),
    new RgbColor([37, 192, 202]),
    new RgbColor([70, 106, 234]),
    new RgbColor([4, 174, 144]),
];

export const generateRgbColorForText = ({ text }: { text: string }) => {
    const parsedNumber = parseInt(text, 36);
    const index = (() => {
        if (parsedNumber <= rgbColors.length) {
            return parsedNumber - 1;
        }
        return (parsedNumber % rgbColors.length) - 1;
    })();
    const rgbColor: RgbColor = rgbColors[index] || new RgbColor([251, 133, 133]);
    return {
        rgbColor,
    };
};
