import { FC, ReactNode, MouseEventHandler } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
// import MuiListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SvgChevronUp from '@/svgs/fa-chevron-up-solid';
import SvgCheck from '@/svgs/fa-check-regular';

import { Button, ButtonRole } from '@/components/0_atom/Button';

const useStyles = makeStyles((theme) => ({
    rootWithMarginBottom: {
        marginBottom: 21,
    },
    title: {
        padding: '8px 14px',
        fontWeight: 600,
    },
    items: {
        border: 'solid 1px rgba(0,0,0,.03)',
        borderRadius: 10,
        padding: 0,
        overflow: 'hidden',
        '& .MuiListItem-container:not(:last-child), .MuiListItem-root:not(:last-child)': {
            borderBottom: 'solid 1px rgba(0,0,0,.03)',
        },
        '& .MuiListItem-container .MuiListItem-root': {
            borderBottom: 'none !important',
        },
    },
    item: {
        background: '#fff',
        padding: '12px 16px',
        display: 'block',
    },
    itemUpper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    itemLower: {
        fontSize: 12,
        color: theme.palette.text.disabled,
        marginTop: 7,
    },
    label: {
        fontSize: 14,
    },
    itemValueWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    value: {
        fontSize: 14,
        color: theme.palette.text.disabled,
    },
    actionButton: {
        fontSize: 12,
        padding: '6px 12px',
    },
    chevronUp: {
        width: 10,
        height: 10,
        transform: 'rotate(90deg)',
        marginLeft: 14,
    },
    check: {
        width: 10,
        height: 10,
        marginLeft: 14,
    },
    isDisabled: {
        color: theme.palette.text.disabled,
    },
    dangerZone: {
        color: theme.palette.error.main,
    },
}));

export type ConfigBlockItem = {
    label: string | ReactNode;
    value?: string | ReactNode;
    action?: {
        label: string;
        onClick?: () => void;
        role: ButtonRole;
    };
    onClick?: MouseEventHandler<HTMLDivElement>;
    isDisabled?: boolean;
    isDanger?: boolean;
    descrition?: string;
    actionIconKind?: 'check' | 'none';
};

const ConfiguredListItem: FC<{
    isButton?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    className?: string;
    children: ReactNode;
}> = ({ isButton, onClick, ...otherProps }) => {
    return isButton ? <MuiListItem {...otherProps} button onClick={onClick} /> : <MuiListItem {...otherProps} />;
};
ConfiguredListItem.displayName = 'ConfiguredListItem';

export const ConfigBlock: FC<{
    className?: string;
    title: string | undefined;
    items: ConfigBlockItem[];
    itemsPlaceholder?: string | ReactNode;
    isKeepMarginBottom?: boolean;
}> = ({ className, title, items, itemsPlaceholder, isKeepMarginBottom }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div
            className={clsx(className, {
                [c.rootWithMarginBottom]: isKeepMarginBottom,
            })}
        >
            {!!title && <MuiTypography className={c.title}>{title}</MuiTypography>}
            {(items.length > 0 || itemsPlaceholder) && (
                <MuiList className={c.items}>
                    {items.length > 0 ? (
                        items.map(
                            (
                                { label, value, action, onClick, actionIconKind, isDisabled, isDanger, descrition },
                                i
                            ) => (
                                <ConfiguredListItem
                                    key={`ConfigBlock-item-${i}`}
                                    onClick={onClick}
                                    className={c.item}
                                    isButton={!!onClick}
                                >
                                    <div
                                        className={clsx(c.itemUpper, {
                                            [c.isDisabled]: isDisabled,
                                            [c.dangerZone]: isDanger,
                                        })}
                                    >
                                        {typeof label === 'string' ? (
                                            <MuiTypography className={c.label}>{label}</MuiTypography>
                                        ) : (
                                            label
                                        )}
                                        <div className={c.itemValueWrapper}>
                                            {typeof value === 'string' ? (
                                                <MuiTypography className={c.value}>{value}</MuiTypography>
                                            ) : (
                                                value
                                            )}
                                            {!!action && (
                                                <Button
                                                    onClick={action.onClick}
                                                    role={action.role}
                                                    className={c.actionButton}
                                                >
                                                    {action.label}
                                                </Button>
                                            )}
                                            {!!onClick && actionIconKind !== 'none' && (
                                                <>
                                                    {actionIconKind === 'check' ? (
                                                        <SvgCheck className={c.check} />
                                                    ) : (
                                                        <SvgChevronUp className={c.chevronUp} />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {!!descrition && <div className={c.itemLower}>{descrition}</div>}
                                </ConfiguredListItem>
                            )
                        )
                    ) : itemsPlaceholder ? (
                        <MuiListItem className={clsx(c.item, c.isDisabled)}>
                            {typeof itemsPlaceholder === 'string' ? (
                                <MuiTypography className={c.label}>{itemsPlaceholder}</MuiTypography>
                            ) : (
                                itemsPlaceholder
                            )}
                        </MuiListItem>
                    ) : (
                        <></>
                    )}
                </MuiList>
            )}
        </div>
    );
};
ConfigBlock.displayName = 'ConfigBlock';
