import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GqlDateTime: string;
  GqlMessageBody: string;
  GqlNull: null | undefined;
  GqlSingleLetter: string;
};

export type GqlCancelChatboardMessageInput = {
  chatboardId: Scalars['ID'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlCancelChatboardMessagePayload = {
  message: GqlChatboardMessage;
};

export type GqlCancelLobbyMessageInput = {
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlCancelLobbyMessagePayload = {
  message: GqlLobbyMessage;
};

export type GqlCancelUserPayload = {
  canceledUser: GqlUser;
};

export type GqlChatboard = {
  closedAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  uniqueName: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
};

export type GqlChatboardMasterMessage = {
  body: Scalars['GqlMessageBody'];
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  chatboardId: Scalars['ID'];
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlChatboardMasterMessageWithMark = {
  message: GqlChatboardMasterMessage;
  reactions: Array<GqlReaction>;
  replyObservers: Array<GqlChatboardReplyObserver>;
  unconfirmedMark?: Maybe<GqlChatboardUnconfirmedMark>;
};

export type GqlChatboardMembership = {
  chatboardId: Scalars['ID'];
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  permission: GqlChatboardPermission;
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlChatboardMembershipWithUser = {
  chatboardMembership: GqlChatboardMembership;
  user: GqlUser;
};

export type GqlChatboardMessage = {
  body: Scalars['GqlMessageBody'];
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  chatboardId: Scalars['ID'];
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  replyToMessageId?: Maybe<Scalars['ID']>;
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlChatboardMessageWithMark = {
  message: GqlChatboardMessage;
  reactions: Array<GqlReaction>;
  replyObservers: Array<GqlChatboardReplyObserver>;
  unconfirmedMark?: Maybe<GqlChatboardUnconfirmedMark>;
};

export type GqlChatboardPermission = {
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type GqlChatboardPermissionInput = {
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type GqlChatboardReplyMessage = {
  body: Scalars['GqlMessageBody'];
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  chatboardId: Scalars['ID'];
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  replyToMessageId: Scalars['ID'];
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlChatboardReplyMessageWithMark = {
  message: GqlChatboardReplyMessage;
  reactions: Array<GqlReaction>;
  unconfirmedMark?: Maybe<GqlChatboardUnconfirmedMark>;
};

export type GqlChatboardReplyObserver = {
  chatboardId: Scalars['ID'];
  createdAt: Scalars['GqlDateTime'];
  fromUserId: Scalars['ID'];
  id: Scalars['ID'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlChatboardThread = {
  master: GqlChatboardMasterMessageWithMark;
  replies: Array<GqlChatboardReplyMessageWithMark>;
};

export type GqlChatboardUnconfirmedMark = {
  chatboardId: Scalars['ID'];
  createdAt: Scalars['GqlDateTime'];
  fromUserId: Scalars['ID'];
  id: Scalars['ID'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlChatboardWithMembership = {
  chatboard: GqlChatboard;
  chatboardMembership: GqlChatboardMembership;
};

export type GqlCheckin = {
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  leftAt?: Maybe<Scalars['GqlDateTime']>;
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlCloseChatboardInput = {
  chatboardId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlCloseChatboardPayload = {
  closedChatboard: GqlChatboard;
};

export type GqlCloseSpaceInput = {
  spaceId: Scalars['ID'];
};

export type GqlCloseSpacePayload = {
  closedSpace: GqlSpace;
};

export type GqlCreateChatboardInput = {
  spaceId: Scalars['ID'];
  uniqueName: Scalars['ID'];
  users: Array<GqlCreateChatboardUserInput>;
};

export type GqlCreateChatboardMembershipInput = {
  chatboardId: Scalars['ID'];
  permission: GqlChatboardPermissionInput;
  spaceId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type GqlCreateChatboardMembershipPayload = {
  chatboardMembership: GqlChatboardMembership;
};

export type GqlCreateChatboardMessageInput = {
  body: Scalars['GqlMessageBody'];
  chatboardId: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  preparedId: Scalars['String'];
  replyObserverToUsers: Array<GqlPreparedIdForUser>;
  replyToMessageId?: InputMaybe<Scalars['ID']>;
  spaceId: Scalars['ID'];
  unconfirmedMarkToUsers: Array<GqlPreparedIdForUser>;
};

export type GqlCreateChatboardMessagePayload = {
  message: GqlChatboardMessage;
};

export type GqlCreateChatboardPayload = {
  chatboard: GqlChatboard;
  chatboardMemberships: Array<GqlChatboardMembership>;
};

export type GqlCreateChatboardReplyObserverInput = {
  chatboardId: Scalars['ID'];
  messageId: Scalars['ID'];
  preparedId: Scalars['String'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlCreateChatboardReplyObserverPayload = {
  replyObserver: GqlChatboardReplyObserver;
};

export type GqlCreateChatboardUnconfirmedMarkInput = {
  chatboardId: Scalars['ID'];
  messageId: Scalars['ID'];
  preparedId: Scalars['String'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlCreateChatboardUnconfirmedMarkPayload = {
  unconfirmedMark: GqlChatboardUnconfirmedMark;
};

export type GqlCreateChatboardUserInput = {
  permission: GqlChatboardPermissionInput;
  userId: Scalars['ID'];
};

export type GqlCreateLobbyMessageInput = {
  body: Scalars['GqlMessageBody'];
  mentionToUserIds: Array<Scalars['ID']>;
  preparedId: Scalars['String'];
  replyObserverToUsers: Array<GqlPreparedIdForUser>;
  replyToMessageId?: InputMaybe<Scalars['ID']>;
  spaceId: Scalars['ID'];
  unconfirmedMarkToUsers: Array<GqlPreparedIdForUser>;
};

export type GqlCreateLobbyMessagePayload = {
  message: GqlLobbyMessage;
};

export type GqlCreateLobbyReplyObserverInput = {
  messageId: Scalars['ID'];
  preparedId: Scalars['String'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlCreateLobbyReplyObserverPayload = {
  replyObserver: GqlLobbyReplyObserver;
};

export type GqlCreateLobbyUnconfirmedMarkInput = {
  messageId: Scalars['ID'];
  preparedId: Scalars['String'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlCreateLobbyUnconfirmedMarkPayload = {
  unconfirmedMark: GqlLobbyUnconfirmedMark;
};

export type GqlCreateReactionInput = {
  chatboardId: Scalars['ID'];
  letter: Scalars['GqlSingleLetter'];
  messageId: Scalars['ID'];
  preparedId: Scalars['String'];
  spaceId: Scalars['ID'];
};

export type GqlCreateReactionPayload = {
  reaction: GqlReaction;
};

export type GqlCreateSpaceInput = {
  name: Scalars['String'];
  ownerDisplayName?: InputMaybe<Scalars['String']>;
  ownerUniqueName: Scalars['String'];
  users: Array<GqlCreateSpaceUserInput>;
};

export type GqlCreateSpaceMembershipInput = {
  membershipDisplayName?: InputMaybe<Scalars['String']>;
  membershipUniqueName?: InputMaybe<Scalars['ID']>;
  permission: GqlSpacePermissionInput;
  spaceId: Scalars['ID'];
  userUniqueCode: Scalars['ID'];
};

export type GqlCreateSpaceMembershipPayload = {
  spaceMembership: GqlSpaceMembership;
  user: GqlUser;
};

export type GqlCreateSpaceMembershipsInput = {
  spaceId: Scalars['ID'];
  users: Array<GqlCreateSpaceMembershipsUserInput>;
};

export type GqlCreateSpaceMembershipsPayload = {
  members: Array<GqlCreateSpaceMembershipsUser>;
};

export type GqlCreateSpaceMembershipsUser = {
  spaceMembership: GqlSpaceMembership;
  user: GqlUser;
};

export type GqlCreateSpaceMembershipsUserInput = {
  membershipDisplayName?: InputMaybe<Scalars['String']>;
  membershipUniqueName?: InputMaybe<Scalars['ID']>;
  permission: GqlSpacePermissionInput;
  userUniqueCode: Scalars['ID'];
};

export type GqlCreateSpacePayload = {
  otherMemberships: Array<GqlOtherSpaceMembership>;
  ownerSpaceMembership: GqlSpaceMembership;
  space: GqlSpace;
};

export type GqlCreateSpaceUserInput = {
  membershipDisplayName?: InputMaybe<Scalars['String']>;
  membershipUniqueName?: InputMaybe<Scalars['ID']>;
  permission: GqlSpacePermissionInput;
  userUniqueCode: Scalars['ID'];
};

export type GqlCreateUserInput = {
  displayName?: InputMaybe<Scalars['String']>;
  uniqueName: Scalars['ID'];
};

export type GqlCreateUserPayload = {
  user: GqlUser;
};

export type GqlGetAllMarkedMessagesInput = {
  spaceId: Scalars['ID'];
};

export type GqlGetAllMarkedMessagesPayload = {
  chatboard: Array<GqlChatboardMessageWithMark>;
  lobby: Array<GqlLobbyMessageWithMark>;
};

export type GqlGetChatboardMembershipWithUsersInput = {
  chatboardId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlGetChatboardMembershipWithUsersPayload = {
  chatboardMembershipWithUsers: Array<GqlChatboardMembershipWithUser>;
};

export type GqlGetChatboardMembershipsInput = {
  chatboardId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlGetChatboardMembershipsPayload = {
  chatboardMemberships: Array<GqlChatboardMembership>;
};

export type GqlGetChatboardMessageWithMarkInput = {
  chatboardId: Scalars['ID'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlGetChatboardMessageWithMarkPayload = {
  messageWithMark: GqlChatboardMessageWithMark;
};

export type GqlGetChatboardMessageWithMarksFilter = {
  replyToMessageId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetChatboardMessageWithMarksInput = {
  chatboardId: Scalars['ID'];
  filter?: InputMaybe<GqlGetChatboardMessageWithMarksFilter>;
  limitation?: InputMaybe<GqlGetChatboardMessageWithMarksLimitation>;
  spaceId: Scalars['ID'];
};

export type GqlGetChatboardMessageWithMarksLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
};

export type GqlGetChatboardMessageWithMarksPayload = {
  messageWithMarks: Array<GqlChatboardMessageWithMark>;
};

export type GqlGetChatboardThreadInput = {
  chatboardId: Scalars['ID'];
  limitation?: InputMaybe<GqlGetChatboardThreadLimitation>;
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlGetChatboardThreadLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
};

export type GqlGetChatboardThreadPayload = {
  thread: GqlChatboardThread;
};

export type GqlGetChatboardThreadsFilter = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetChatboardThreadsInput = {
  chatboardId: Scalars['ID'];
  filter?: InputMaybe<GqlGetChatboardThreadsFilter>;
  limitation?: InputMaybe<GqlGetChatboardThreadsLimitation>;
  spaceId: Scalars['ID'];
};

export type GqlGetChatboardThreadsLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
};

export type GqlGetChatboardThreadsPayload = {
  threads: Array<GqlChatboardThread>;
};

export type GqlGetChatboardWithMembershipsForUserInput = {
  spaceId: Scalars['ID'];
};

export type GqlGetChatboardWithMembershipsForUserPayload = {
  chatboardWithMemberships: Array<GqlChatboardWithMembership>;
};

export type GqlGetChatboardsInput = {
  spaceId: Scalars['ID'];
};

export type GqlGetChatboardsPayload = {
  chatboards: Array<GqlChatboard>;
};

export type GqlGetCheckinsFilter = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetCheckinsInput = {
  filter?: InputMaybe<GqlGetCheckinsFilter>;
  limitation?: InputMaybe<GqlGetCheckinsLimitation>;
  spaceId: Scalars['ID'];
};

export type GqlGetCheckinsLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
};

export type GqlGetCheckinsPayload = {
  checkins: Array<GqlCheckin>;
};

export type GqlGetIsUserUniqueNameAvailableInput = {
  userUniqueName: Scalars['String'];
};

export type GqlGetIsUserUniqueNameAvailablePayload = {
  isAvailable: Scalars['Boolean'];
  userUniqueName: Scalars['String'];
};

export type GqlGetLobbyMessageWithMarkInput = {
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlGetLobbyMessageWithMarkPayload = {
  messageWithMark: GqlLobbyMessageWithMark;
};

export type GqlGetLobbyMessageWithMarksFilter = {
  replyToMessageId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetLobbyMessageWithMarksInput = {
  filter?: InputMaybe<GqlGetLobbyMessageWithMarksFilter>;
  limitation?: InputMaybe<GqlGetLobbyMessageWithMarksLimitation>;
  spaceId: Scalars['ID'];
};

export type GqlGetLobbyMessageWithMarksLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
};

export type GqlGetLobbyMessageWithMarksPayload = {
  messageWithMarks: Array<GqlLobbyMessageWithMark>;
};

export type GqlGetLobbyThreadInput = {
  limitation?: InputMaybe<GqlGetLobbyThreadLimitation>;
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlGetLobbyThreadLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
};

export type GqlGetLobbyThreadPayload = {
  thread: GqlLobbyThread;
};

export type GqlGetLobbyThreadsFilter = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type GqlGetLobbyThreadsInput = {
  filter?: InputMaybe<GqlGetLobbyThreadsFilter>;
  limitation?: InputMaybe<GqlGetLobbyThreadsLimitation>;
  spaceId: Scalars['ID'];
};

export type GqlGetLobbyThreadsLimitation = {
  endBeforeCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  startAfterCreatedAt?: InputMaybe<Scalars['GqlDateTime']>;
};

export type GqlGetLobbyThreadsPayload = {
  threads: Array<GqlLobbyThread>;
};

export type GqlGetPrivacyPolicyPayload = {
  privacyPolicy: Scalars['String'];
};

export type GqlGetServiceHealthPayload = {
  serviceHealth: GqlServiceHealth;
};

export type GqlGetSpaceMembershipWithUsersInput = {
  spaceId: Scalars['ID'];
};

export type GqlGetSpaceMembershipWithUsersPayload = {
  spaceMembershipWithUsers: Array<GqlSpaceMembershipWithUser>;
};

export type GqlGetSpaceMembershipsInput = {
  spaceId: Scalars['ID'];
};

export type GqlGetSpaceMembershipsPayload = {
  spaceMemberships: Array<GqlSpaceMembership>;
};

export type GqlGetSpaceWithMembershipsForUserPayload = {
  spaceWithMemberships: Array<GqlSpaceWithMembership>;
};

export type GqlGetTosPayload = {
  tos: Scalars['String'];
};

export type GqlGetUserByCodeInput = {
  userCode: Scalars['ID'];
};

export type GqlGetUserByCodePayload = {
  user: GqlUser;
};

export type GqlGetUserPayload = {
  user?: Maybe<GqlUser>;
};

export type GqlLobbyMasterMessage = {
  body: Scalars['GqlMessageBody'];
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlLobbyMasterMessageWithMark = {
  message: GqlLobbyMasterMessage;
  replyObservers: Array<GqlLobbyReplyObserver>;
  unconfirmedMark?: Maybe<GqlLobbyUnconfirmedMark>;
};

export type GqlLobbyMessage = {
  body: Scalars['GqlMessageBody'];
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  replyToMessageId?: Maybe<Scalars['ID']>;
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlLobbyMessageWithMark = {
  message: GqlLobbyMessage;
  replyObservers: Array<GqlLobbyReplyObserver>;
  unconfirmedMark?: Maybe<GqlLobbyUnconfirmedMark>;
};

export type GqlLobbyPermission = {
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type GqlLobbyPermissionInput = {
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type GqlLobbyReplyMessage = {
  body: Scalars['GqlMessageBody'];
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  replyToMessageId: Scalars['ID'];
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlLobbyReplyMessageWithMark = {
  message: GqlLobbyReplyMessage;
  unconfirmedMark?: Maybe<GqlLobbyUnconfirmedMark>;
};

export type GqlLobbyReplyObserver = {
  createdAt: Scalars['GqlDateTime'];
  fromUserId: Scalars['ID'];
  id: Scalars['ID'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlLobbyThread = {
  master: GqlLobbyMasterMessageWithMark;
  replies: Array<GqlLobbyReplyMessageWithMark>;
};

export type GqlLobbyUnconfirmedMark = {
  createdAt: Scalars['GqlDateTime'];
  fromUserId: Scalars['ID'];
  id: Scalars['ID'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlMessage = {
  body: Scalars['GqlMessageBody'];
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  chatboardId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  mentionToUserIds: Array<Scalars['ID']>;
  replyToMessageId?: Maybe<Scalars['ID']>;
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlModifyChatboardMembershipPermissionInput = {
  chatboardId: Scalars['ID'];
  chatboardMembershipId: Scalars['ID'];
  permission: GqlChatboardPermissionInput;
  spaceId: Scalars['ID'];
};

export type GqlModifyChatboardMembershipPermissionPayload = {
  chatboardMembership: GqlChatboardMembership;
};

export type GqlModifySpaceMembershipDisplayNameInput = {
  displayName?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['ID'];
  spaceMembershipId: Scalars['ID'];
};

export type GqlModifySpaceMembershipDisplayNamePayload = {
  spaceMembership: GqlSpaceMembership;
};

export type GqlModifySpaceMembershipPermissionInput = {
  permission: GqlSpacePermissionInput;
  spaceId: Scalars['ID'];
  spaceMembershipId: Scalars['ID'];
};

export type GqlModifySpaceMembershipPermissionPayload = {
  spaceMembership: GqlSpaceMembership;
};

export type GqlModifySpaceNameInput = {
  name: Scalars['String'];
  spaceId: Scalars['ID'];
};

export type GqlModifySpaceNamePayload = {
  space: GqlSpace;
};

export type GqlModifyUserDisplayNameInput = {
  displayName?: InputMaybe<Scalars['String']>;
};

export type GqlModifyUserDisplayNamePayload = {
  user: GqlUser;
};

export type GqlOtherSpaceMembership = {
  spaceMembership: GqlSpaceMembership;
  user: GqlUser;
};

export type GqlPreparedIdForUser = {
  preparedId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlReaction = {
  chatboardId: Scalars['ID'];
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  letter: Scalars['GqlSingleLetter'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type GqlRemoveChatboardMembershipInput = {
  chatboardId: Scalars['ID'];
  chatboardMembershipId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlRemoveChatboardMembershipPayload = {
  removedChatboardMembershipId: Scalars['ID'];
};

export type GqlRemoveChatboardReplyObserverInput = {
  chatboardId: Scalars['ID'];
  replyObserverId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlRemoveChatboardReplyObserverPayload = {
  removedReplyObserverId: Scalars['ID'];
};

export type GqlRemoveChatboardUnconfirmedMarkInput = {
  chatboardId: Scalars['ID'];
  spaceId: Scalars['ID'];
  unconfirmedMarkId: Scalars['ID'];
};

export type GqlRemoveChatboardUnconfirmedMarkPayload = {
  removedUnconfirmedMarkId: Scalars['ID'];
};

export type GqlRemoveLobbyReplyObserverInput = {
  replyObserverId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlRemoveLobbyReplyObserverPayload = {
  removedReplyObserverId: Scalars['ID'];
};

export type GqlRemoveLobbyUnconfirmedMarkInput = {
  spaceId: Scalars['ID'];
  unconfirmedMarkId: Scalars['ID'];
};

export type GqlRemoveLobbyUnconfirmedMarkPayload = {
  removedUnconfirmedMarkId: Scalars['ID'];
};

export type GqlRemoveReactionInput = {
  chatboardId: Scalars['ID'];
  messageId: Scalars['ID'];
  reactionId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type GqlRemoveReactionPayload = {
  removedReactionId: Scalars['ID'];
};

export type GqlRemoveSpaceMembershipInput = {
  spaceId: Scalars['ID'];
  spaceMembershipId: Scalars['ID'];
};

export type GqlRemoveSpaceMembershipPayload = {
  removedSpaceMembershipId: Scalars['ID'];
};

export type GqlReplyObserver = {
  chatboardId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['GqlDateTime'];
  fromUserId: Scalars['ID'];
  id: Scalars['ID'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlServiceHealth = {
  code: ServiceHealthCode;
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type GqlSpace = {
  closedAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  id: Scalars['ID'];
  isEnabledActivities: Scalars['Boolean'];
  isEnabledLobbyArchive: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
};

export type GqlSpaceMembership = {
  createdAt: Scalars['GqlDateTime'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isOpenPresence: Scalars['Boolean'];
  permission: GqlSpacePermission;
  serialNumber: Scalars['Int'];
  spaceId: Scalars['ID'];
  uniqueName: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
  userId: Scalars['ID'];
};

export type GqlSpaceMembershipWithUser = {
  spaceMembership: GqlSpaceMembership;
  user: GqlUser;
};

export type GqlSpacePermission = {
  admin: Scalars['Boolean'];
  lobby: GqlLobbyPermission;
};

export type GqlSpacePermissionInput = {
  admin: Scalars['Boolean'];
  lobby: GqlLobbyPermissionInput;
};

export type GqlSpaceWithMembership = {
  space: GqlSpace;
  spaceMembership: GqlSpaceMembership;
};

export type GqlUnconfirmedMark = {
  chatboardId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['GqlDateTime'];
  fromUserId: Scalars['ID'];
  id: Scalars['ID'];
  messageId: Scalars['ID'];
  spaceId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

export type GqlUser = {
  canceledAt?: Maybe<Scalars['GqlDateTime']>;
  createdAt: Scalars['GqlDateTime'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  uniqueCode: Scalars['ID'];
  uniqueName: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GqlDateTime']>;
};

export type Mutation = {
  cancelChatboardMessage: GqlCancelChatboardMessagePayload;
  cancelLobbyMessage: GqlCancelLobbyMessagePayload;
  cancelUser: GqlCancelUserPayload;
  closeChatboard: GqlCloseChatboardPayload;
  closeSpace: GqlCloseSpacePayload;
  createChatboard: GqlCreateChatboardPayload;
  createChatboardMembership: GqlCreateChatboardMembershipPayload;
  createChatboardMessage: GqlCreateChatboardMessagePayload;
  createChatboardReplyObserver: GqlCreateChatboardReplyObserverPayload;
  createChatboardUnconfirmedMark: GqlCreateChatboardUnconfirmedMarkPayload;
  createLobbyMessage: GqlCreateLobbyMessagePayload;
  createLobbyReplyObserver: GqlCreateLobbyReplyObserverPayload;
  createLobbyUnconfirmedMark: GqlCreateLobbyUnconfirmedMarkPayload;
  createReaction: GqlCreateReactionPayload;
  createSpace: GqlCreateSpacePayload;
  createSpaceMembership: GqlCreateSpaceMembershipPayload;
  createSpaceMemberships: GqlCreateSpaceMembershipsPayload;
  createUser: GqlCreateUserPayload;
  modifyChatboardMembershipPermission: GqlModifyChatboardMembershipPermissionPayload;
  modifySpaceMembershipDisplayName: GqlModifySpaceMembershipDisplayNamePayload;
  modifySpaceMembershipPermission: GqlModifySpaceMembershipPermissionPayload;
  modifySpaceName: GqlModifySpaceNamePayload;
  modifyUserDisplayName: GqlModifyUserDisplayNamePayload;
  removeChatboardMembership: GqlRemoveChatboardMembershipPayload;
  removeChatboardReplyObserver: GqlRemoveChatboardReplyObserverPayload;
  removeChatboardUnconfirmedMark: GqlRemoveChatboardUnconfirmedMarkPayload;
  removeLobbyReplyObserver: GqlRemoveLobbyReplyObserverPayload;
  removeLobbyUnconfirmedMark: GqlRemoveLobbyUnconfirmedMarkPayload;
  removeReaction: GqlRemoveReactionPayload;
  removeSpaceMembership: GqlRemoveSpaceMembershipPayload;
};


export type MutationCancelChatboardMessageArgs = {
  input: GqlCancelChatboardMessageInput;
};


export type MutationCancelLobbyMessageArgs = {
  input: GqlCancelLobbyMessageInput;
};


export type MutationCloseChatboardArgs = {
  input: GqlCloseChatboardInput;
};


export type MutationCloseSpaceArgs = {
  input: GqlCloseSpaceInput;
};


export type MutationCreateChatboardArgs = {
  input: GqlCreateChatboardInput;
};


export type MutationCreateChatboardMembershipArgs = {
  input: GqlCreateChatboardMembershipInput;
};


export type MutationCreateChatboardMessageArgs = {
  input: GqlCreateChatboardMessageInput;
};


export type MutationCreateChatboardReplyObserverArgs = {
  input: GqlCreateChatboardReplyObserverInput;
};


export type MutationCreateChatboardUnconfirmedMarkArgs = {
  input: GqlCreateChatboardUnconfirmedMarkInput;
};


export type MutationCreateLobbyMessageArgs = {
  input: GqlCreateLobbyMessageInput;
};


export type MutationCreateLobbyReplyObserverArgs = {
  input: GqlCreateLobbyReplyObserverInput;
};


export type MutationCreateLobbyUnconfirmedMarkArgs = {
  input: GqlCreateLobbyUnconfirmedMarkInput;
};


export type MutationCreateReactionArgs = {
  input: GqlCreateReactionInput;
};


export type MutationCreateSpaceArgs = {
  input: GqlCreateSpaceInput;
};


export type MutationCreateSpaceMembershipArgs = {
  input: GqlCreateSpaceMembershipInput;
};


export type MutationCreateSpaceMembershipsArgs = {
  input: GqlCreateSpaceMembershipsInput;
};


export type MutationCreateUserArgs = {
  input: GqlCreateUserInput;
};


export type MutationModifyChatboardMembershipPermissionArgs = {
  input: GqlModifyChatboardMembershipPermissionInput;
};


export type MutationModifySpaceMembershipDisplayNameArgs = {
  input: GqlModifySpaceMembershipDisplayNameInput;
};


export type MutationModifySpaceMembershipPermissionArgs = {
  input: GqlModifySpaceMembershipPermissionInput;
};


export type MutationModifySpaceNameArgs = {
  input: GqlModifySpaceNameInput;
};


export type MutationModifyUserDisplayNameArgs = {
  input: GqlModifyUserDisplayNameInput;
};


export type MutationRemoveChatboardMembershipArgs = {
  input: GqlRemoveChatboardMembershipInput;
};


export type MutationRemoveChatboardReplyObserverArgs = {
  input: GqlRemoveChatboardReplyObserverInput;
};


export type MutationRemoveChatboardUnconfirmedMarkArgs = {
  input: GqlRemoveChatboardUnconfirmedMarkInput;
};


export type MutationRemoveLobbyReplyObserverArgs = {
  input: GqlRemoveLobbyReplyObserverInput;
};


export type MutationRemoveLobbyUnconfirmedMarkArgs = {
  input: GqlRemoveLobbyUnconfirmedMarkInput;
};


export type MutationRemoveReactionArgs = {
  input: GqlRemoveReactionInput;
};


export type MutationRemoveSpaceMembershipArgs = {
  input: GqlRemoveSpaceMembershipInput;
};

export type Query = {
  getAllMarkedMessages: GqlGetAllMarkedMessagesPayload;
  getChatboardMembershipWithUsers: GqlGetChatboardMembershipWithUsersPayload;
  getChatboardMemberships: GqlGetChatboardMembershipsPayload;
  getChatboardMessageWithMark: GqlGetChatboardMessageWithMarkPayload;
  getChatboardMessageWithMarks: GqlGetChatboardMessageWithMarksPayload;
  getChatboardThread: GqlGetChatboardThreadPayload;
  getChatboardThreads: GqlGetChatboardThreadsPayload;
  getChatboardWithMembershipsForUser: GqlGetChatboardWithMembershipsForUserPayload;
  getChatboards: GqlGetChatboardsPayload;
  getCheckins: GqlGetCheckinsPayload;
  getIsUserUniqueNameAvailable: GqlGetIsUserUniqueNameAvailablePayload;
  getLobbyMessageWithMark: GqlGetLobbyMessageWithMarkPayload;
  getLobbyMessageWithMarks: GqlGetLobbyMessageWithMarksPayload;
  getLobbyThread: GqlGetLobbyThreadPayload;
  getLobbyThreads: GqlGetLobbyThreadsPayload;
  getPrivacyPolicy: GqlGetPrivacyPolicyPayload;
  getServiceHealth: GqlGetServiceHealthPayload;
  getSpaceMembershipWithUsers: GqlGetSpaceMembershipWithUsersPayload;
  getSpaceMemberships: GqlGetSpaceMembershipsPayload;
  getSpaceWithMembershipsForUser: GqlGetSpaceWithMembershipsForUserPayload;
  getTos: GqlGetTosPayload;
  getUser: GqlGetUserPayload;
  getUserByCode: GqlGetUserByCodePayload;
};


export type QueryGetAllMarkedMessagesArgs = {
  input: GqlGetAllMarkedMessagesInput;
};


export type QueryGetChatboardMembershipWithUsersArgs = {
  input: GqlGetChatboardMembershipWithUsersInput;
};


export type QueryGetChatboardMembershipsArgs = {
  input: GqlGetChatboardMembershipsInput;
};


export type QueryGetChatboardMessageWithMarkArgs = {
  input: GqlGetChatboardMessageWithMarkInput;
};


export type QueryGetChatboardMessageWithMarksArgs = {
  input: GqlGetChatboardMessageWithMarksInput;
};


export type QueryGetChatboardThreadArgs = {
  input: GqlGetChatboardThreadInput;
};


export type QueryGetChatboardThreadsArgs = {
  input: GqlGetChatboardThreadsInput;
};


export type QueryGetChatboardWithMembershipsForUserArgs = {
  input: GqlGetChatboardWithMembershipsForUserInput;
};


export type QueryGetChatboardsArgs = {
  input: GqlGetChatboardsInput;
};


export type QueryGetCheckinsArgs = {
  input: GqlGetCheckinsInput;
};


export type QueryGetIsUserUniqueNameAvailableArgs = {
  input: GqlGetIsUserUniqueNameAvailableInput;
};


export type QueryGetLobbyMessageWithMarkArgs = {
  input: GqlGetLobbyMessageWithMarkInput;
};


export type QueryGetLobbyMessageWithMarksArgs = {
  input: GqlGetLobbyMessageWithMarksInput;
};


export type QueryGetLobbyThreadArgs = {
  input: GqlGetLobbyThreadInput;
};


export type QueryGetLobbyThreadsArgs = {
  input: GqlGetLobbyThreadsInput;
};


export type QueryGetSpaceMembershipWithUsersArgs = {
  input: GqlGetSpaceMembershipWithUsersInput;
};


export type QueryGetSpaceMembershipsArgs = {
  input: GqlGetSpaceMembershipsInput;
};


export type QueryGetUserByCodeArgs = {
  input: GqlGetUserByCodeInput;
};

export type ServiceHealthCode =
  | 'ok'
  | 'outOfService';

export type CreateUserMutationVariables = Exact<{
  input: GqlCreateUserInput;
}>;


export type CreateUserMutation = { createUser: { user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null } } };

export type CreateSpaceMutationVariables = Exact<{
  input: GqlCreateSpaceInput;
}>;


export type CreateSpaceMutation = { createSpace: { space: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isEnabledLobbyArchive: boolean, isEnabledActivities: boolean }, ownerSpaceMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } }, otherMemberships: Array<{ user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null }, spaceMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } } }> } };

export type CancelUserMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelUserMutation = { cancelUser: { canceledUser: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null } } };

export type ModifyUserDisplayNameMutationVariables = Exact<{
  input: GqlModifyUserDisplayNameInput;
}>;


export type ModifyUserDisplayNameMutation = { modifyUserDisplayName: { user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null } } };

export type CreateChatboardMutationVariables = Exact<{
  input: GqlCreateChatboardInput;
}>;


export type CreateChatboardMutation = { createChatboard: { chatboard: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, spaceId: string, uniqueName: string, closedAt?: string | null }, chatboardMemberships: Array<{ id: string, createdAt: string, updatedAt?: string | null, userId: string, spaceId: string, chatboardId: string, permission: { read: boolean, write: boolean } }> } };

export type CancelLobbyMessageMutationVariables = Exact<{
  input: GqlCancelLobbyMessageInput;
}>;


export type CancelLobbyMessageMutation = { cancelLobbyMessage: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> } } };

export type CreateLobbyMessageMutationVariables = Exact<{
  input: GqlCreateLobbyMessageInput;
}>;


export type CreateLobbyMessageMutation = { createLobbyMessage: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> } } };

export type CreateLobbyReplyObserverMutationVariables = Exact<{
  input: GqlCreateLobbyReplyObserverInput;
}>;


export type CreateLobbyReplyObserverMutation = { createLobbyReplyObserver: { replyObserver: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } } };

export type RemoveLobbyReplyObserverMutationVariables = Exact<{
  input: GqlRemoveLobbyReplyObserverInput;
}>;


export type RemoveLobbyReplyObserverMutation = { removeLobbyReplyObserver: { removedReplyObserverId: string } };

export type CloseSpaceMutationVariables = Exact<{
  input: GqlCloseSpaceInput;
}>;


export type CloseSpaceMutation = { closeSpace: { closedSpace: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isEnabledLobbyArchive: boolean, isEnabledActivities: boolean } } };

export type ModifySpaceNameMutationVariables = Exact<{
  input: GqlModifySpaceNameInput;
}>;


export type ModifySpaceNameMutation = { modifySpaceName: { space: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isEnabledLobbyArchive: boolean, isEnabledActivities: boolean } } };

export type CreateSpaceMembershipMutationVariables = Exact<{
  input: GqlCreateSpaceMembershipInput;
}>;


export type CreateSpaceMembershipMutation = { createSpaceMembership: { user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null }, spaceMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } } } };

export type CreateSpaceMembershipsMutationVariables = Exact<{
  input: GqlCreateSpaceMembershipsInput;
}>;


export type CreateSpaceMembershipsMutation = { createSpaceMemberships: { members: Array<{ user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null }, spaceMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } } }> } };

export type ModifySpaceMembershipDisplayNameMutationVariables = Exact<{
  input: GqlModifySpaceMembershipDisplayNameInput;
}>;


export type ModifySpaceMembershipDisplayNameMutation = { modifySpaceMembershipDisplayName: { spaceMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } } } };

export type ModifySpaceMembershipPermissionMutationVariables = Exact<{
  input: GqlModifySpaceMembershipPermissionInput;
}>;


export type ModifySpaceMembershipPermissionMutation = { modifySpaceMembershipPermission: { spaceMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } } } };

export type RemoveSpaceMembershipMutationVariables = Exact<{
  input: GqlRemoveSpaceMembershipInput;
}>;


export type RemoveSpaceMembershipMutation = { removeSpaceMembership: { removedSpaceMembershipId: string } };

export type CreateLobbyUnconfirmedMarkMutationVariables = Exact<{
  input: GqlCreateLobbyUnconfirmedMarkInput;
}>;


export type CreateLobbyUnconfirmedMarkMutation = { createLobbyUnconfirmedMark: { unconfirmedMark: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } } };

export type RemoveLobbyUnconfirmedMarkMutationVariables = Exact<{
  input: GqlRemoveLobbyUnconfirmedMarkInput;
}>;


export type RemoveLobbyUnconfirmedMarkMutation = { removeLobbyUnconfirmedMark: { removedUnconfirmedMarkId: string } };

export type CloseChatboardMutationVariables = Exact<{
  input: GqlCloseChatboardInput;
}>;


export type CloseChatboardMutation = { closeChatboard: { closedChatboard: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, spaceId: string, uniqueName: string, closedAt?: string | null } } };

export type CreateChatboardMembershipMutationVariables = Exact<{
  input: GqlCreateChatboardMembershipInput;
}>;


export type CreateChatboardMembershipMutation = { createChatboardMembership: { chatboardMembership: { id: string, createdAt: string, updatedAt?: string | null, userId: string, spaceId: string, chatboardId: string, permission: { read: boolean, write: boolean } } } };

export type ModifyChatboardMembershipPermissionMutationVariables = Exact<{
  input: GqlModifyChatboardMembershipPermissionInput;
}>;


export type ModifyChatboardMembershipPermissionMutation = { modifyChatboardMembershipPermission: { chatboardMembership: { id: string, createdAt: string, updatedAt?: string | null, userId: string, spaceId: string, chatboardId: string, permission: { read: boolean, write: boolean } } } };

export type RemoveChatboardMembershipMutationVariables = Exact<{
  input: GqlRemoveChatboardMembershipInput;
}>;


export type RemoveChatboardMembershipMutation = { removeChatboardMembership: { removedChatboardMembershipId: string } };

export type CancelChatboardMessageMutationVariables = Exact<{
  input: GqlCancelChatboardMessageInput;
}>;


export type CancelChatboardMessageMutation = { cancelChatboardMessage: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> } } };

export type CreateChatboardMessageMutationVariables = Exact<{
  input: GqlCreateChatboardMessageInput;
}>;


export type CreateChatboardMessageMutation = { createChatboardMessage: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> } } };

export type CreateReactionMutationVariables = Exact<{
  input: GqlCreateReactionInput;
}>;


export type CreateReactionMutation = { createReaction: { reaction: { id: string, createdAt: string, userId: string, spaceId: string, chatboardId: string, messageId: string, letter: string } } };

export type RemoveReactionMutationVariables = Exact<{
  input: GqlRemoveReactionInput;
}>;


export type RemoveReactionMutation = { removeReaction: { removedReactionId: string } };

export type CreateChatboardReplyObserverMutationVariables = Exact<{
  input: GqlCreateChatboardReplyObserverInput;
}>;


export type CreateChatboardReplyObserverMutation = { createChatboardReplyObserver: { replyObserver: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } } };

export type RemoveChatboardReplyObserverMutationVariables = Exact<{
  input: GqlRemoveChatboardReplyObserverInput;
}>;


export type RemoveChatboardReplyObserverMutation = { removeChatboardReplyObserver: { removedReplyObserverId: string } };

export type CreateChatboardUnconfirmedMarkMutationVariables = Exact<{
  input: GqlCreateChatboardUnconfirmedMarkInput;
}>;


export type CreateChatboardUnconfirmedMarkMutation = { createChatboardUnconfirmedMark: { unconfirmedMark: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } } };

export type RemoveChatboardUnconfirmedMarkMutationVariables = Exact<{
  input: GqlRemoveChatboardUnconfirmedMarkInput;
}>;


export type RemoveChatboardUnconfirmedMarkMutation = { removeChatboardUnconfirmedMark: { removedUnconfirmedMarkId: string } };

export type GetPrivacyPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrivacyPolicyQuery = { getPrivacyPolicy: { privacyPolicy: string } };

export type GetServiceHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServiceHealthQuery = { getServiceHealth: { serviceHealth: { code: ServiceHealthCode, message?: string | null, url?: string | null } } };

export type GetTosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTosQuery = { getTos: { tos: string } };

export type GetIsUserUniqueNameAvailableQueryVariables = Exact<{
  input: GqlGetIsUserUniqueNameAvailableInput;
}>;


export type GetIsUserUniqueNameAvailableQuery = { getIsUserUniqueNameAvailable: { userUniqueName: string, isAvailable: boolean } };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { getUser: { user?: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null } | null } };

export type GetSpaceWithMembershipsForUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpaceWithMembershipsForUserQuery = { getSpaceWithMembershipsForUser: { spaceWithMemberships: Array<{ space: { id: string, createdAt: string, updatedAt?: string | null, name: string, closedAt?: string | null, isEnabledLobbyArchive: boolean, isEnabledActivities: boolean }, spaceMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } } }> } };

export type GetUserByCodeQueryVariables = Exact<{
  input: GqlGetUserByCodeInput;
}>;


export type GetUserByCodeQuery = { getUserByCode: { user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null } } };

export type GetAllMarkedMessagesQueryVariables = Exact<{
  input: GqlGetAllMarkedMessagesInput;
}>;


export type GetAllMarkedMessagesQuery = { getAllMarkedMessages: { lobby: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string }> }>, chatboard: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, spaceId: string, chatboardId: string, messageId: string, letter: string }>, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string }> }> } };

export type GetChatboardWithMembershipsForUserQueryVariables = Exact<{
  input: GqlGetChatboardWithMembershipsForUserInput;
}>;


export type GetChatboardWithMembershipsForUserQuery = { getChatboardWithMembershipsForUser: { chatboardWithMemberships: Array<{ chatboard: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, spaceId: string, uniqueName: string, closedAt?: string | null }, chatboardMembership: { id: string, createdAt: string, updatedAt?: string | null, userId: string, spaceId: string, chatboardId: string, permission: { read: boolean, write: boolean } } }> } };

export type GetChatboardsQueryVariables = Exact<{
  input: GqlGetChatboardsInput;
}>;


export type GetChatboardsQuery = { getChatboards: { chatboards: Array<{ id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, spaceId: string, uniqueName: string, closedAt?: string | null }> } };

export type GetCheckinsQueryVariables = Exact<{
  input: GqlGetCheckinsInput;
}>;


export type GetCheckinsQuery = { getCheckins: { checkins: Array<{ id: string, createdAt: string, updatedAt?: string | null, userId: string, spaceId: string, leftAt?: string | null }> } };

export type GetLobbyMessageWithMarkQueryVariables = Exact<{
  input: GqlGetLobbyMessageWithMarkInput;
}>;


export type GetLobbyMessageWithMarkQuery = { getLobbyMessageWithMark: { messageWithMark: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string }> } } };

export type GetLobbyMessageWithMarksQueryVariables = Exact<{
  input: GqlGetLobbyMessageWithMarksInput;
}>;


export type GetLobbyMessageWithMarksQuery = { getLobbyMessageWithMarks: { messageWithMarks: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string }> }> } };

export type GetLobbyThreadQueryVariables = Exact<{
  input: GqlGetLobbyThreadInput;
}>;


export type GetLobbyThreadQuery = { getLobbyThread: { thread: { master: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, canceledAt?: string | null, mentionToUserIds: Array<string> }, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string }> }, replies: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, canceledAt?: string | null, mentionToUserIds: Array<string> }, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } | null }> } } };

export type GetLobbyThreadsQueryVariables = Exact<{
  input: GqlGetLobbyThreadsInput;
}>;


export type GetLobbyThreadsQuery = { getLobbyThreads: { threads: Array<{ master: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, canceledAt?: string | null, mentionToUserIds: Array<string> }, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string }> }, replies: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, body: string, canceledAt?: string | null, mentionToUserIds: Array<string> }, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, messageId: string, fromUserId: string, toUserId: string } | null }> }> } };

export type GetSpaceMembershipWithUsersQueryVariables = Exact<{
  input: GqlGetSpaceMembershipWithUsersInput;
}>;


export type GetSpaceMembershipWithUsersQuery = { getSpaceMembershipWithUsers: { spaceMembershipWithUsers: Array<{ spaceMembership: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } }, user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null } }> } };

export type GetSpaceMembershipsQueryVariables = Exact<{
  input: GqlGetSpaceMembershipsInput;
}>;


export type GetSpaceMembershipsQuery = { getSpaceMemberships: { spaceMemberships: Array<{ id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, uniqueName: string, displayName?: string | null, isOpenPresence: boolean, permission: { admin: boolean, lobby: { read: boolean, write: boolean } } }> } };

export type GetChatboardMembershipWithUsersQueryVariables = Exact<{
  input: GqlGetChatboardMembershipWithUsersInput;
}>;


export type GetChatboardMembershipWithUsersQuery = { getChatboardMembershipWithUsers: { chatboardMembershipWithUsers: Array<{ chatboardMembership: { id: string, createdAt: string, updatedAt?: string | null, userId: string, spaceId: string, chatboardId: string, permission: { read: boolean, write: boolean } }, user: { id: string, createdAt: string, updatedAt?: string | null, uniqueName: string, uniqueCode: string, displayName?: string | null, canceledAt?: string | null } }> } };

export type GetChatboardMembershipsQueryVariables = Exact<{
  input: GqlGetChatboardMembershipsInput;
}>;


export type GetChatboardMembershipsQuery = { getChatboardMemberships: { chatboardMemberships: Array<{ id: string, createdAt: string, updatedAt?: string | null, userId: string, spaceId: string, chatboardId: string, permission: { read: boolean, write: boolean } }> } };

export type GetChatboardMessageWithMarkQueryVariables = Exact<{
  input: GqlGetChatboardMessageWithMarkInput;
}>;


export type GetChatboardMessageWithMarkQuery = { getChatboardMessageWithMark: { messageWithMark: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, spaceId: string, chatboardId: string, messageId: string, letter: string }>, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string }> } } };

export type GetChatboardMessageWithMarksQueryVariables = Exact<{
  input: GqlGetChatboardMessageWithMarksInput;
}>;


export type GetChatboardMessageWithMarksQuery = { getChatboardMessageWithMarks: { messageWithMarks: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, replyToMessageId?: string | null, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, spaceId: string, chatboardId: string, messageId: string, letter: string }>, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string }> }> } };

export type GetChatboardThreadQueryVariables = Exact<{
  input: GqlGetChatboardThreadInput;
}>;


export type GetChatboardThreadQuery = { getChatboardThread: { thread: { master: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, spaceId: string, chatboardId: string, messageId: string, letter: string }>, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string }> }, replies: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, replyToMessageId: string, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, spaceId: string, chatboardId: string, messageId: string, letter: string }>, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } | null }> } } };

export type GetChatboardThreadsQueryVariables = Exact<{
  input: GqlGetChatboardThreadsInput;
}>;


export type GetChatboardThreadsQuery = { getChatboardThreads: { threads: Array<{ master: { message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, spaceId: string, chatboardId: string, messageId: string, letter: string }>, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } | null, replyObservers: Array<{ id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string }> }, replies: Array<{ message: { id: string, createdAt: string, updatedAt?: string | null, serialNumber: number, userId: string, spaceId: string, chatboardId: string, body: string, replyToMessageId: string, canceledAt?: string | null, mentionToUserIds: Array<string> }, reactions: Array<{ id: string, createdAt: string, userId: string, spaceId: string, chatboardId: string, messageId: string, letter: string }>, unconfirmedMark?: { id: string, createdAt: string, spaceId: string, chatboardId: string, messageId: string, fromUserId: string, toUserId: string } | null }> }> } };


export const CreateUserDocument = gql`
    mutation createUser($input: GqlCreateUserInput!) {
  createUser(input: $input) {
    user {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      displayName
      canceledAt
    }
  }
}
    `;
export const CreateSpaceDocument = gql`
    mutation createSpace($input: GqlCreateSpaceInput!) {
  createSpace(input: $input) {
    space {
      id
      createdAt
      updatedAt
      name
      closedAt
      isEnabledLobbyArchive
      isEnabledActivities
    }
    ownerSpaceMembership {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      uniqueName
      permission {
        admin
        lobby {
          read
          write
        }
      }
      displayName
      isOpenPresence
    }
    otherMemberships {
      user {
        id
        createdAt
        updatedAt
        uniqueName
        uniqueCode
        displayName
        canceledAt
      }
      spaceMembership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        uniqueName
        permission {
          admin
          lobby {
            read
            write
          }
        }
        displayName
        isOpenPresence
      }
    }
  }
}
    `;
export const CancelUserDocument = gql`
    mutation cancelUser {
  cancelUser {
    canceledUser {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      displayName
      canceledAt
    }
  }
}
    `;
export const ModifyUserDisplayNameDocument = gql`
    mutation modifyUserDisplayName($input: GqlModifyUserDisplayNameInput!) {
  modifyUserDisplayName(input: $input) {
    user {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      displayName
      canceledAt
    }
  }
}
    `;
export const CreateChatboardDocument = gql`
    mutation createChatboard($input: GqlCreateChatboardInput!) {
  createChatboard(input: $input) {
    chatboard {
      id
      createdAt
      updatedAt
      serialNumber
      spaceId
      uniqueName
      closedAt
    }
    chatboardMemberships {
      id
      createdAt
      updatedAt
      userId
      spaceId
      chatboardId
      permission {
        read
        write
      }
    }
  }
}
    `;
export const CancelLobbyMessageDocument = gql`
    mutation cancelLobbyMessage($input: GqlCancelLobbyMessageInput!) {
  cancelLobbyMessage(input: $input) {
    message {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      body
      replyToMessageId
      canceledAt
      mentionToUserIds
    }
  }
}
    `;
export const CreateLobbyMessageDocument = gql`
    mutation createLobbyMessage($input: GqlCreateLobbyMessageInput!) {
  createLobbyMessage(input: $input) {
    message {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      body
      replyToMessageId
      canceledAt
      mentionToUserIds
    }
  }
}
    `;
export const CreateLobbyReplyObserverDocument = gql`
    mutation createLobbyReplyObserver($input: GqlCreateLobbyReplyObserverInput!) {
  createLobbyReplyObserver(input: $input) {
    replyObserver {
      id
      createdAt
      spaceId
      messageId
      fromUserId
      toUserId
    }
  }
}
    `;
export const RemoveLobbyReplyObserverDocument = gql`
    mutation removeLobbyReplyObserver($input: GqlRemoveLobbyReplyObserverInput!) {
  removeLobbyReplyObserver(input: $input) {
    removedReplyObserverId
  }
}
    `;
export const CloseSpaceDocument = gql`
    mutation closeSpace($input: GqlCloseSpaceInput!) {
  closeSpace(input: $input) {
    closedSpace {
      id
      createdAt
      updatedAt
      name
      closedAt
      isEnabledLobbyArchive
      isEnabledActivities
    }
  }
}
    `;
export const ModifySpaceNameDocument = gql`
    mutation modifySpaceName($input: GqlModifySpaceNameInput!) {
  modifySpaceName(input: $input) {
    space {
      id
      createdAt
      updatedAt
      name
      closedAt
      isEnabledLobbyArchive
      isEnabledActivities
    }
  }
}
    `;
export const CreateSpaceMembershipDocument = gql`
    mutation createSpaceMembership($input: GqlCreateSpaceMembershipInput!) {
  createSpaceMembership(input: $input) {
    user {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      displayName
      canceledAt
    }
    spaceMembership {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      uniqueName
      permission {
        admin
        lobby {
          read
          write
        }
      }
      displayName
      isOpenPresence
    }
  }
}
    `;
export const CreateSpaceMembershipsDocument = gql`
    mutation createSpaceMemberships($input: GqlCreateSpaceMembershipsInput!) {
  createSpaceMemberships(input: $input) {
    members {
      user {
        id
        createdAt
        updatedAt
        uniqueName
        uniqueCode
        displayName
        canceledAt
      }
      spaceMembership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        uniqueName
        permission {
          admin
          lobby {
            read
            write
          }
        }
        displayName
        isOpenPresence
      }
    }
  }
}
    `;
export const ModifySpaceMembershipDisplayNameDocument = gql`
    mutation modifySpaceMembershipDisplayName($input: GqlModifySpaceMembershipDisplayNameInput!) {
  modifySpaceMembershipDisplayName(input: $input) {
    spaceMembership {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      uniqueName
      permission {
        admin
        lobby {
          read
          write
        }
      }
      displayName
      isOpenPresence
    }
  }
}
    `;
export const ModifySpaceMembershipPermissionDocument = gql`
    mutation modifySpaceMembershipPermission($input: GqlModifySpaceMembershipPermissionInput!) {
  modifySpaceMembershipPermission(input: $input) {
    spaceMembership {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      uniqueName
      permission {
        admin
        lobby {
          read
          write
        }
      }
      displayName
      isOpenPresence
    }
  }
}
    `;
export const RemoveSpaceMembershipDocument = gql`
    mutation removeSpaceMembership($input: GqlRemoveSpaceMembershipInput!) {
  removeSpaceMembership(input: $input) {
    removedSpaceMembershipId
  }
}
    `;
export const CreateLobbyUnconfirmedMarkDocument = gql`
    mutation createLobbyUnconfirmedMark($input: GqlCreateLobbyUnconfirmedMarkInput!) {
  createLobbyUnconfirmedMark(input: $input) {
    unconfirmedMark {
      id
      createdAt
      spaceId
      messageId
      fromUserId
      toUserId
    }
  }
}
    `;
export const RemoveLobbyUnconfirmedMarkDocument = gql`
    mutation removeLobbyUnconfirmedMark($input: GqlRemoveLobbyUnconfirmedMarkInput!) {
  removeLobbyUnconfirmedMark(input: $input) {
    removedUnconfirmedMarkId
  }
}
    `;
export const CloseChatboardDocument = gql`
    mutation closeChatboard($input: GqlCloseChatboardInput!) {
  closeChatboard(input: $input) {
    closedChatboard {
      id
      createdAt
      updatedAt
      serialNumber
      spaceId
      uniqueName
      closedAt
    }
  }
}
    `;
export const CreateChatboardMembershipDocument = gql`
    mutation createChatboardMembership($input: GqlCreateChatboardMembershipInput!) {
  createChatboardMembership(input: $input) {
    chatboardMembership {
      id
      createdAt
      updatedAt
      userId
      spaceId
      chatboardId
      permission {
        read
        write
      }
    }
  }
}
    `;
export const ModifyChatboardMembershipPermissionDocument = gql`
    mutation modifyChatboardMembershipPermission($input: GqlModifyChatboardMembershipPermissionInput!) {
  modifyChatboardMembershipPermission(input: $input) {
    chatboardMembership {
      id
      createdAt
      updatedAt
      userId
      spaceId
      chatboardId
      permission {
        read
        write
      }
    }
  }
}
    `;
export const RemoveChatboardMembershipDocument = gql`
    mutation removeChatboardMembership($input: GqlRemoveChatboardMembershipInput!) {
  removeChatboardMembership(input: $input) {
    removedChatboardMembershipId
  }
}
    `;
export const CancelChatboardMessageDocument = gql`
    mutation cancelChatboardMessage($input: GqlCancelChatboardMessageInput!) {
  cancelChatboardMessage(input: $input) {
    message {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      chatboardId
      body
      replyToMessageId
      canceledAt
      mentionToUserIds
    }
  }
}
    `;
export const CreateChatboardMessageDocument = gql`
    mutation createChatboardMessage($input: GqlCreateChatboardMessageInput!) {
  createChatboardMessage(input: $input) {
    message {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      chatboardId
      body
      replyToMessageId
      canceledAt
      mentionToUserIds
    }
  }
}
    `;
export const CreateReactionDocument = gql`
    mutation createReaction($input: GqlCreateReactionInput!) {
  createReaction(input: $input) {
    reaction {
      id
      createdAt
      userId
      spaceId
      chatboardId
      messageId
      letter
    }
  }
}
    `;
export const RemoveReactionDocument = gql`
    mutation removeReaction($input: GqlRemoveReactionInput!) {
  removeReaction(input: $input) {
    removedReactionId
  }
}
    `;
export const CreateChatboardReplyObserverDocument = gql`
    mutation createChatboardReplyObserver($input: GqlCreateChatboardReplyObserverInput!) {
  createChatboardReplyObserver(input: $input) {
    replyObserver {
      id
      createdAt
      spaceId
      chatboardId
      messageId
      fromUserId
      toUserId
    }
  }
}
    `;
export const RemoveChatboardReplyObserverDocument = gql`
    mutation removeChatboardReplyObserver($input: GqlRemoveChatboardReplyObserverInput!) {
  removeChatboardReplyObserver(input: $input) {
    removedReplyObserverId
  }
}
    `;
export const CreateChatboardUnconfirmedMarkDocument = gql`
    mutation createChatboardUnconfirmedMark($input: GqlCreateChatboardUnconfirmedMarkInput!) {
  createChatboardUnconfirmedMark(input: $input) {
    unconfirmedMark {
      id
      createdAt
      spaceId
      chatboardId
      messageId
      fromUserId
      toUserId
    }
  }
}
    `;
export const RemoveChatboardUnconfirmedMarkDocument = gql`
    mutation removeChatboardUnconfirmedMark($input: GqlRemoveChatboardUnconfirmedMarkInput!) {
  removeChatboardUnconfirmedMark(input: $input) {
    removedUnconfirmedMarkId
  }
}
    `;
export const GetPrivacyPolicyDocument = gql`
    query getPrivacyPolicy {
  getPrivacyPolicy {
    privacyPolicy
  }
}
    `;
export const GetServiceHealthDocument = gql`
    query getServiceHealth {
  getServiceHealth {
    serviceHealth {
      code
      message
      url
    }
  }
}
    `;
export const GetTosDocument = gql`
    query getTos {
  getTos {
    tos
  }
}
    `;
export const GetIsUserUniqueNameAvailableDocument = gql`
    query getIsUserUniqueNameAvailable($input: GqlGetIsUserUniqueNameAvailableInput!) {
  getIsUserUniqueNameAvailable(input: $input) {
    userUniqueName
    isAvailable
  }
}
    `;
export const GetUserDocument = gql`
    query getUser {
  getUser {
    user {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      displayName
      canceledAt
    }
  }
}
    `;
export const GetSpaceWithMembershipsForUserDocument = gql`
    query getSpaceWithMembershipsForUser {
  getSpaceWithMembershipsForUser {
    spaceWithMemberships {
      space {
        id
        createdAt
        updatedAt
        name
        closedAt
        isEnabledLobbyArchive
        isEnabledActivities
      }
      spaceMembership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        uniqueName
        permission {
          admin
          lobby {
            read
            write
          }
        }
        displayName
        isOpenPresence
      }
    }
  }
}
    `;
export const GetUserByCodeDocument = gql`
    query getUserByCode($input: GqlGetUserByCodeInput!) {
  getUserByCode(input: $input) {
    user {
      id
      createdAt
      updatedAt
      uniqueName
      uniqueCode
      displayName
      canceledAt
    }
  }
}
    `;
export const GetAllMarkedMessagesDocument = gql`
    query getAllMarkedMessages($input: GqlGetAllMarkedMessagesInput!) {
  getAllMarkedMessages(input: $input) {
    lobby {
      message {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        body
        replyToMessageId
        canceledAt
        mentionToUserIds
      }
      unconfirmedMark {
        id
        createdAt
        spaceId
        messageId
        fromUserId
        toUserId
      }
      replyObservers {
        id
        createdAt
        spaceId
        messageId
        fromUserId
        toUserId
      }
    }
    chatboard {
      message {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        chatboardId
        body
        replyToMessageId
        canceledAt
        mentionToUserIds
      }
      reactions {
        id
        createdAt
        userId
        spaceId
        chatboardId
        messageId
        letter
      }
      unconfirmedMark {
        id
        createdAt
        spaceId
        chatboardId
        messageId
        fromUserId
        toUserId
      }
      replyObservers {
        id
        createdAt
        spaceId
        chatboardId
        messageId
        fromUserId
        toUserId
      }
    }
  }
}
    `;
export const GetChatboardWithMembershipsForUserDocument = gql`
    query getChatboardWithMembershipsForUser($input: GqlGetChatboardWithMembershipsForUserInput!) {
  getChatboardWithMembershipsForUser(input: $input) {
    chatboardWithMemberships {
      chatboard {
        id
        createdAt
        updatedAt
        serialNumber
        spaceId
        uniqueName
        closedAt
      }
      chatboardMembership {
        id
        createdAt
        updatedAt
        userId
        spaceId
        chatboardId
        permission {
          read
          write
        }
      }
    }
  }
}
    `;
export const GetChatboardsDocument = gql`
    query getChatboards($input: GqlGetChatboardsInput!) {
  getChatboards(input: $input) {
    chatboards {
      id
      createdAt
      updatedAt
      serialNumber
      spaceId
      uniqueName
      closedAt
    }
  }
}
    `;
export const GetCheckinsDocument = gql`
    query getCheckins($input: GqlGetCheckinsInput!) {
  getCheckins(input: $input) {
    checkins {
      id
      createdAt
      updatedAt
      userId
      spaceId
      leftAt
    }
  }
}
    `;
export const GetLobbyMessageWithMarkDocument = gql`
    query getLobbyMessageWithMark($input: GqlGetLobbyMessageWithMarkInput!) {
  getLobbyMessageWithMark(input: $input) {
    messageWithMark {
      message {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        body
        replyToMessageId
        canceledAt
        mentionToUserIds
      }
      unconfirmedMark {
        id
        createdAt
        spaceId
        messageId
        fromUserId
        toUserId
      }
      replyObservers {
        id
        createdAt
        spaceId
        messageId
        fromUserId
        toUserId
      }
    }
  }
}
    `;
export const GetLobbyMessageWithMarksDocument = gql`
    query getLobbyMessageWithMarks($input: GqlGetLobbyMessageWithMarksInput!) {
  getLobbyMessageWithMarks(input: $input) {
    messageWithMarks {
      message {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        body
        replyToMessageId
        canceledAt
        mentionToUserIds
      }
      unconfirmedMark {
        id
        createdAt
        spaceId
        messageId
        fromUserId
        toUserId
      }
      replyObservers {
        id
        createdAt
        spaceId
        messageId
        fromUserId
        toUserId
      }
    }
  }
}
    `;
export const GetLobbyThreadDocument = gql`
    query getLobbyThread($input: GqlGetLobbyThreadInput!) {
  getLobbyThread(input: $input) {
    thread {
      master {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          spaceId
          body
          canceledAt
          mentionToUserIds
        }
        unconfirmedMark {
          id
          createdAt
          spaceId
          messageId
          fromUserId
          toUserId
        }
        replyObservers {
          id
          createdAt
          spaceId
          messageId
          fromUserId
          toUserId
        }
      }
      replies {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          spaceId
          body
          canceledAt
          mentionToUserIds
        }
        unconfirmedMark {
          id
          createdAt
          spaceId
          messageId
          fromUserId
          toUserId
        }
      }
    }
  }
}
    `;
export const GetLobbyThreadsDocument = gql`
    query getLobbyThreads($input: GqlGetLobbyThreadsInput!) {
  getLobbyThreads(input: $input) {
    threads {
      master {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          spaceId
          body
          canceledAt
          mentionToUserIds
        }
        unconfirmedMark {
          id
          createdAt
          spaceId
          messageId
          fromUserId
          toUserId
        }
        replyObservers {
          id
          createdAt
          spaceId
          messageId
          fromUserId
          toUserId
        }
      }
      replies {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          spaceId
          body
          canceledAt
          mentionToUserIds
        }
        unconfirmedMark {
          id
          createdAt
          spaceId
          messageId
          fromUserId
          toUserId
        }
      }
    }
  }
}
    `;
export const GetSpaceMembershipWithUsersDocument = gql`
    query getSpaceMembershipWithUsers($input: GqlGetSpaceMembershipWithUsersInput!) {
  getSpaceMembershipWithUsers(input: $input) {
    spaceMembershipWithUsers {
      spaceMembership {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        uniqueName
        permission {
          admin
          lobby {
            read
            write
          }
        }
        displayName
        isOpenPresence
      }
      user {
        id
        createdAt
        updatedAt
        uniqueName
        uniqueCode
        displayName
        canceledAt
      }
    }
  }
}
    `;
export const GetSpaceMembershipsDocument = gql`
    query getSpaceMemberships($input: GqlGetSpaceMembershipsInput!) {
  getSpaceMemberships(input: $input) {
    spaceMemberships {
      id
      createdAt
      updatedAt
      serialNumber
      userId
      spaceId
      uniqueName
      permission {
        admin
        lobby {
          read
          write
        }
      }
      displayName
      isOpenPresence
    }
  }
}
    `;
export const GetChatboardMembershipWithUsersDocument = gql`
    query getChatboardMembershipWithUsers($input: GqlGetChatboardMembershipWithUsersInput!) {
  getChatboardMembershipWithUsers(input: $input) {
    chatboardMembershipWithUsers {
      chatboardMembership {
        id
        createdAt
        updatedAt
        userId
        spaceId
        chatboardId
        permission {
          read
          write
        }
      }
      user {
        id
        createdAt
        updatedAt
        uniqueName
        uniqueCode
        displayName
        canceledAt
      }
    }
  }
}
    `;
export const GetChatboardMembershipsDocument = gql`
    query getChatboardMemberships($input: GqlGetChatboardMembershipsInput!) {
  getChatboardMemberships(input: $input) {
    chatboardMemberships {
      id
      createdAt
      updatedAt
      userId
      spaceId
      chatboardId
      permission {
        read
        write
      }
    }
  }
}
    `;
export const GetChatboardMessageWithMarkDocument = gql`
    query getChatboardMessageWithMark($input: GqlGetChatboardMessageWithMarkInput!) {
  getChatboardMessageWithMark(input: $input) {
    messageWithMark {
      message {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        chatboardId
        body
        replyToMessageId
        canceledAt
        mentionToUserIds
      }
      reactions {
        id
        createdAt
        userId
        spaceId
        chatboardId
        messageId
        letter
      }
      unconfirmedMark {
        id
        createdAt
        spaceId
        chatboardId
        messageId
        fromUserId
        toUserId
      }
      replyObservers {
        id
        createdAt
        spaceId
        chatboardId
        messageId
        fromUserId
        toUserId
      }
    }
  }
}
    `;
export const GetChatboardMessageWithMarksDocument = gql`
    query getChatboardMessageWithMarks($input: GqlGetChatboardMessageWithMarksInput!) {
  getChatboardMessageWithMarks(input: $input) {
    messageWithMarks {
      message {
        id
        createdAt
        updatedAt
        serialNumber
        userId
        spaceId
        chatboardId
        body
        replyToMessageId
        canceledAt
        mentionToUserIds
      }
      reactions {
        id
        createdAt
        userId
        spaceId
        chatboardId
        messageId
        letter
      }
      unconfirmedMark {
        id
        createdAt
        spaceId
        chatboardId
        messageId
        fromUserId
        toUserId
      }
      replyObservers {
        id
        createdAt
        spaceId
        chatboardId
        messageId
        fromUserId
        toUserId
      }
    }
  }
}
    `;
export const GetChatboardThreadDocument = gql`
    query getChatboardThread($input: GqlGetChatboardThreadInput!) {
  getChatboardThread(input: $input) {
    thread {
      master {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          spaceId
          chatboardId
          body
          canceledAt
          mentionToUserIds
        }
        reactions {
          id
          createdAt
          userId
          spaceId
          chatboardId
          messageId
          letter
        }
        unconfirmedMark {
          id
          createdAt
          spaceId
          chatboardId
          messageId
          fromUserId
          toUserId
        }
        replyObservers {
          id
          createdAt
          spaceId
          chatboardId
          messageId
          fromUserId
          toUserId
        }
      }
      replies {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          spaceId
          chatboardId
          body
          replyToMessageId
          canceledAt
          mentionToUserIds
        }
        reactions {
          id
          createdAt
          userId
          spaceId
          chatboardId
          messageId
          letter
        }
        unconfirmedMark {
          id
          createdAt
          spaceId
          chatboardId
          messageId
          fromUserId
          toUserId
        }
      }
    }
  }
}
    `;
export const GetChatboardThreadsDocument = gql`
    query getChatboardThreads($input: GqlGetChatboardThreadsInput!) {
  getChatboardThreads(input: $input) {
    threads {
      master {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          spaceId
          chatboardId
          body
          canceledAt
          mentionToUserIds
        }
        reactions {
          id
          createdAt
          userId
          spaceId
          chatboardId
          messageId
          letter
        }
        unconfirmedMark {
          id
          createdAt
          spaceId
          chatboardId
          messageId
          fromUserId
          toUserId
        }
        replyObservers {
          id
          createdAt
          spaceId
          chatboardId
          messageId
          fromUserId
          toUserId
        }
      }
      replies {
        message {
          id
          createdAt
          updatedAt
          serialNumber
          userId
          spaceId
          chatboardId
          body
          replyToMessageId
          canceledAt
          mentionToUserIds
        }
        reactions {
          id
          createdAt
          userId
          spaceId
          chatboardId
          messageId
          letter
        }
        unconfirmedMark {
          id
          createdAt
          spaceId
          chatboardId
          messageId
          fromUserId
          toUserId
        }
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createUser(variables: CreateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserMutation>(CreateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUser');
    },
    createSpace(variables: CreateSpaceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateSpaceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSpaceMutation>(CreateSpaceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createSpace');
    },
    cancelUser(variables?: CancelUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelUserMutation>(CancelUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelUser');
    },
    modifyUserDisplayName(variables: ModifyUserDisplayNameMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyUserDisplayNameMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ModifyUserDisplayNameMutation>(ModifyUserDisplayNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'modifyUserDisplayName');
    },
    createChatboard(variables: CreateChatboardMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateChatboardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateChatboardMutation>(CreateChatboardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createChatboard');
    },
    cancelLobbyMessage(variables: CancelLobbyMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelLobbyMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelLobbyMessageMutation>(CancelLobbyMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelLobbyMessage');
    },
    createLobbyMessage(variables: CreateLobbyMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateLobbyMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateLobbyMessageMutation>(CreateLobbyMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createLobbyMessage');
    },
    createLobbyReplyObserver(variables: CreateLobbyReplyObserverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateLobbyReplyObserverMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateLobbyReplyObserverMutation>(CreateLobbyReplyObserverDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createLobbyReplyObserver');
    },
    removeLobbyReplyObserver(variables: RemoveLobbyReplyObserverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveLobbyReplyObserverMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveLobbyReplyObserverMutation>(RemoveLobbyReplyObserverDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeLobbyReplyObserver');
    },
    closeSpace(variables: CloseSpaceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CloseSpaceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CloseSpaceMutation>(CloseSpaceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'closeSpace');
    },
    modifySpaceName(variables: ModifySpaceNameMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifySpaceNameMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ModifySpaceNameMutation>(ModifySpaceNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'modifySpaceName');
    },
    createSpaceMembership(variables: CreateSpaceMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateSpaceMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSpaceMembershipMutation>(CreateSpaceMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createSpaceMembership');
    },
    createSpaceMemberships(variables: CreateSpaceMembershipsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateSpaceMembershipsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSpaceMembershipsMutation>(CreateSpaceMembershipsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createSpaceMemberships');
    },
    modifySpaceMembershipDisplayName(variables: ModifySpaceMembershipDisplayNameMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifySpaceMembershipDisplayNameMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ModifySpaceMembershipDisplayNameMutation>(ModifySpaceMembershipDisplayNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'modifySpaceMembershipDisplayName');
    },
    modifySpaceMembershipPermission(variables: ModifySpaceMembershipPermissionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifySpaceMembershipPermissionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ModifySpaceMembershipPermissionMutation>(ModifySpaceMembershipPermissionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'modifySpaceMembershipPermission');
    },
    removeSpaceMembership(variables: RemoveSpaceMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveSpaceMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveSpaceMembershipMutation>(RemoveSpaceMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeSpaceMembership');
    },
    createLobbyUnconfirmedMark(variables: CreateLobbyUnconfirmedMarkMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateLobbyUnconfirmedMarkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateLobbyUnconfirmedMarkMutation>(CreateLobbyUnconfirmedMarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createLobbyUnconfirmedMark');
    },
    removeLobbyUnconfirmedMark(variables: RemoveLobbyUnconfirmedMarkMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveLobbyUnconfirmedMarkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveLobbyUnconfirmedMarkMutation>(RemoveLobbyUnconfirmedMarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeLobbyUnconfirmedMark');
    },
    closeChatboard(variables: CloseChatboardMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CloseChatboardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CloseChatboardMutation>(CloseChatboardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'closeChatboard');
    },
    createChatboardMembership(variables: CreateChatboardMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateChatboardMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateChatboardMembershipMutation>(CreateChatboardMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createChatboardMembership');
    },
    modifyChatboardMembershipPermission(variables: ModifyChatboardMembershipPermissionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyChatboardMembershipPermissionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ModifyChatboardMembershipPermissionMutation>(ModifyChatboardMembershipPermissionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'modifyChatboardMembershipPermission');
    },
    removeChatboardMembership(variables: RemoveChatboardMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveChatboardMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveChatboardMembershipMutation>(RemoveChatboardMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeChatboardMembership');
    },
    cancelChatboardMessage(variables: CancelChatboardMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelChatboardMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelChatboardMessageMutation>(CancelChatboardMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelChatboardMessage');
    },
    createChatboardMessage(variables: CreateChatboardMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateChatboardMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateChatboardMessageMutation>(CreateChatboardMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createChatboardMessage');
    },
    createReaction(variables: CreateReactionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateReactionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateReactionMutation>(CreateReactionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createReaction');
    },
    removeReaction(variables: RemoveReactionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveReactionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveReactionMutation>(RemoveReactionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeReaction');
    },
    createChatboardReplyObserver(variables: CreateChatboardReplyObserverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateChatboardReplyObserverMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateChatboardReplyObserverMutation>(CreateChatboardReplyObserverDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createChatboardReplyObserver');
    },
    removeChatboardReplyObserver(variables: RemoveChatboardReplyObserverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveChatboardReplyObserverMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveChatboardReplyObserverMutation>(RemoveChatboardReplyObserverDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeChatboardReplyObserver');
    },
    createChatboardUnconfirmedMark(variables: CreateChatboardUnconfirmedMarkMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateChatboardUnconfirmedMarkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateChatboardUnconfirmedMarkMutation>(CreateChatboardUnconfirmedMarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createChatboardUnconfirmedMark');
    },
    removeChatboardUnconfirmedMark(variables: RemoveChatboardUnconfirmedMarkMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveChatboardUnconfirmedMarkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveChatboardUnconfirmedMarkMutation>(RemoveChatboardUnconfirmedMarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeChatboardUnconfirmedMark');
    },
    getPrivacyPolicy(variables?: GetPrivacyPolicyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPrivacyPolicyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPrivacyPolicyQuery>(GetPrivacyPolicyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPrivacyPolicy');
    },
    getServiceHealth(variables?: GetServiceHealthQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetServiceHealthQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetServiceHealthQuery>(GetServiceHealthDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getServiceHealth');
    },
    getTos(variables?: GetTosQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetTosQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTosQuery>(GetTosDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTos');
    },
    getIsUserUniqueNameAvailable(variables: GetIsUserUniqueNameAvailableQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetIsUserUniqueNameAvailableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetIsUserUniqueNameAvailableQuery>(GetIsUserUniqueNameAvailableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getIsUserUniqueNameAvailable');
    },
    getUser(variables?: GetUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserQuery>(GetUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUser');
    },
    getSpaceWithMembershipsForUser(variables?: GetSpaceWithMembershipsForUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSpaceWithMembershipsForUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSpaceWithMembershipsForUserQuery>(GetSpaceWithMembershipsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSpaceWithMembershipsForUser');
    },
    getUserByCode(variables: GetUserByCodeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserByCodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserByCodeQuery>(GetUserByCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserByCode');
    },
    getAllMarkedMessages(variables: GetAllMarkedMessagesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllMarkedMessagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllMarkedMessagesQuery>(GetAllMarkedMessagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllMarkedMessages');
    },
    getChatboardWithMembershipsForUser(variables: GetChatboardWithMembershipsForUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChatboardWithMembershipsForUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChatboardWithMembershipsForUserQuery>(GetChatboardWithMembershipsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChatboardWithMembershipsForUser');
    },
    getChatboards(variables: GetChatboardsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChatboardsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChatboardsQuery>(GetChatboardsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChatboards');
    },
    getCheckins(variables: GetCheckinsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCheckinsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCheckinsQuery>(GetCheckinsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCheckins');
    },
    getLobbyMessageWithMark(variables: GetLobbyMessageWithMarkQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLobbyMessageWithMarkQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLobbyMessageWithMarkQuery>(GetLobbyMessageWithMarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLobbyMessageWithMark');
    },
    getLobbyMessageWithMarks(variables: GetLobbyMessageWithMarksQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLobbyMessageWithMarksQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLobbyMessageWithMarksQuery>(GetLobbyMessageWithMarksDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLobbyMessageWithMarks');
    },
    getLobbyThread(variables: GetLobbyThreadQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLobbyThreadQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLobbyThreadQuery>(GetLobbyThreadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLobbyThread');
    },
    getLobbyThreads(variables: GetLobbyThreadsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLobbyThreadsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLobbyThreadsQuery>(GetLobbyThreadsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLobbyThreads');
    },
    getSpaceMembershipWithUsers(variables: GetSpaceMembershipWithUsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSpaceMembershipWithUsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSpaceMembershipWithUsersQuery>(GetSpaceMembershipWithUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSpaceMembershipWithUsers');
    },
    getSpaceMemberships(variables: GetSpaceMembershipsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSpaceMembershipsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSpaceMembershipsQuery>(GetSpaceMembershipsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSpaceMemberships');
    },
    getChatboardMembershipWithUsers(variables: GetChatboardMembershipWithUsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChatboardMembershipWithUsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChatboardMembershipWithUsersQuery>(GetChatboardMembershipWithUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChatboardMembershipWithUsers');
    },
    getChatboardMemberships(variables: GetChatboardMembershipsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChatboardMembershipsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChatboardMembershipsQuery>(GetChatboardMembershipsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChatboardMemberships');
    },
    getChatboardMessageWithMark(variables: GetChatboardMessageWithMarkQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChatboardMessageWithMarkQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChatboardMessageWithMarkQuery>(GetChatboardMessageWithMarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChatboardMessageWithMark');
    },
    getChatboardMessageWithMarks(variables: GetChatboardMessageWithMarksQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChatboardMessageWithMarksQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChatboardMessageWithMarksQuery>(GetChatboardMessageWithMarksDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChatboardMessageWithMarks');
    },
    getChatboardThread(variables: GetChatboardThreadQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChatboardThreadQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChatboardThreadQuery>(GetChatboardThreadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChatboardThread');
    },
    getChatboardThreads(variables: GetChatboardThreadsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChatboardThreadsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChatboardThreadsQuery>(GetChatboardThreadsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChatboardThreads');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;