import { FC, useState, useCallback, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { initPowerchatPublicClient } from '@/utils/PowerchatClient';
import { useLanguage } from '@/utils/customHooks';
import { Modal } from '@/components/0_atom/Modal';
import { MarkdownViewer } from '@/components/0_atom/MarkdownViewer';
import { FetchingText } from '@/components/0_atom/FetchingText';

const useStyles = makeStyles((theme) => ({
    tosHeading: {},
    tosText: {
        lineHeight: '1.6',
        '& h1': {
            fontSize: theme.typography.h1.fontSize,
            color: theme.palette.primary.main,
            marginBottom: 34,
            fontWeight: 600,
        },
        '& h2': {
            marginTop: '2em',
            fontSize: theme.typography.h2.fontSize,
            fontWeight: 600,
            // color: theme.palette.primary.main,
        },
        '& ol, ul': {
            paddingLeft: 15,
            '& li': {
                paddingLeft: 4,
                '&::marker': {
                    marginRight: 7,
                    fontWeight: 600,
                },
                '&:not(:last-child)': {
                    marginBottom: 7,
                },
                '& ol, ul': {
                    marginTop: 7,
                },
            },
        },
    },
}));

export const TosModal: FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [tos, setTos] = useState<string | undefined>(undefined);

    // CALLBACK
    const fetchTos = useCallback(async () => {
        const client = initPowerchatPublicClient();
        const { tos: fetchedTos } = await client.getTos();
        setTos(fetchedTos);
    }, []);

    // USEEFFECT
    useEffect(() => {
        fetchTos();
    }, [fetchTos]);

    return (
        <Modal open={isOpen} onClose={onClose}>
            {/* <MuiTypography variant={'h1'} className={c.tosHeading}>
                {txt({ en: 'Terms of service', ja: '利用規約' })}
            </MuiTypography> */}
            <div className={c.tosText}>{tos ? <MarkdownViewer textMd={tos} /> : <FetchingText />}</div>
        </Modal>
    );
};
TosModal.displayName = 'TosModal';
