import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    getRdbLastChatboardInput,
    ModifyRdbLastChatboardInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const modifyRdbChatboard = ({
    spaceId,
    chatboard,
}: {
    spaceId: string;
    chatboard: ModifyRdbLastChatboardInput;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastChatboardRef = ref(db, `lastChatboards/${spaceId}`);
    set(lastChatboardRef, {
        action: 'modify',
        ...getRdbLastChatboardInput({
            ...chatboard,
            updatedAt: 'serverTimestamp',
            addNotification: undefined,
        }),
    });
};
