import { collection, doc } from 'firebase/firestore';
import { getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import {
    GqlMessage,
    GqlReaction,
    GqlChatboardMembership,
    GqlUnconfirmedMark,
    GqlReplyObserver,
} from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { dateToIsoOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import {
    postRdbMessage,
    postRdbChatboardMembership,
    ReplyToUser,
    MentionToUser,
    modifyRdbChatboard,
} from '@/utils/PowerchatClient/RealtimeDatabase';
import { ChatboardPermissionType } from '@/utils/PowerchatClient/models/_interfaces';
import { Chatboard, ChatboardType } from '@/utils/PowerchatClient/models/Chatboard/data/Chatboard';
import { SpaceMembershipApi } from '@/utils/PowerchatClient/models/SpaceMembership';
import {
    getChatboardMembershipFromGql,
    ChatboardMembershipApi,
} from '@/utils/PowerchatClient/models/ChatboardMembership';
import { Message, MessageApi, getMessageFromGql } from '@/utils/PowerchatClient/models/Message';
import { ReactionApi, getReactionFromGql } from '@/utils/PowerchatClient/models/Reaction';
import { UnconfirmedMarkApi, getUnconfirmedMarkFromGql } from '@/utils/PowerchatClient/models/UnconfirmedMark';
import { ReplyObserverApi, getReplyObserverFromGql } from '@/utils/PowerchatClient/models/ReplyObserver';
import { User, getUserFromGql } from '@/utils/PowerchatClient/models/User';
import { getCheckinFromGql } from '@/utils/PowerchatClient/models/Checkin';
import { MessageWithMark } from '@/utils/PowerchatClient/models/MessageWithMark';
import { Thread } from '@/utils/PowerchatClient/models/Thread';

type ReplyTo = {
    messageId: string;
    user: ReplyToUser;
};

type ChatboardApiType = {
    // UPDATE SELF
    close: () => Promise<void>;

    // GET CHILDREN
    getMessageWithMark: (input: { messageId: string }) => Promise<MessageWithMark>;
    getMessageWithMarks: (input: {
        maxAmount?: number;
        startAfterCreatedAt?: Date;
        endBeforeCreatedAt?: Date;
        replyToMessageId?: string;
        markForUserId?: string;
    }) => Promise<MessageWithMark[]>;
    getThread: (input: { messageId: string }) => Promise<Thread>;
    getThreads: (input: {
        maxAmount?: number;
        startAfterCreatedAt?: Date;
        endBeforeCreatedAt?: Date;
    }) => Promise<Thread[]>;
    getChatboardMembershipApis: (input: {
        spaceMembershipApis: SpaceMembershipApi[];
    }) => Promise<ChatboardMembershipApi[]>;
    getChatboardMembershipApiWithUsers: (input: { spaceMembershipApis: SpaceMembershipApi[] }) => Promise<
        {
            chatboardMembershipApi: ChatboardMembershipApi;
            user: User;
        }[]
    >;

    // CREATE CHILDREN
    createMessage: (input: {
        body: string;
        replyTo: ReplyTo | undefined;
        mentions: MentionToUser[];
        isMarkUnconfirmed: boolean;
        isObserveReply: boolean;
    }) => Promise<{
        messageApi: MessageApi;
    }>;
    createChatboardMembership: (input: {
        spaceMembershipApi: SpaceMembershipApi;
        permission: ChatboardPermissionType;
    }) => Promise<{
        chatboardMembershipApi: ChatboardMembershipApi;
    }>;
    createChatboardMemberships: (
        input: {
            spaceMembershipApi: SpaceMembershipApi;
            permission: ChatboardPermissionType;
        }[]
    ) => Promise<ChatboardMembershipApi[]>;
};

type ConstructorInput = ChatboardType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    clientUser: User;
    clientUniqueName: string;
    clientDisplayName: string | undefined;
    spaceName: string;
};

export class ChatboardApi extends Chatboard implements ChatboardApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    protected _clientUser: User;

    protected _clientUniqueName: string;

    protected _clientDisplayName: string | undefined;

    protected _spaceName: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
        this._clientUser = input.clientUser;
        this._clientUniqueName = input.clientUniqueName;
        this._clientDisplayName = input.clientDisplayName;
        this._spaceName = input.spaceName;
    }

    // PRIVATE UTILS
    private _getMessageApiFromGql(message: GqlMessage) {
        return new MessageApi({
            graphqlSdk: this._graphqlSdk,
            currentFcmToken: this._currentFcmToken,
            clientUser: this._clientUser,
            clientUniqueName: this._clientUniqueName,
            clientDisplayName: this._clientDisplayName,
            spaceName: this._spaceName,
            chatboardUniqueName: this.uniqueName,
            ...getMessageFromGql(message),
        });
    }

    private _getMessageApi(message: Message) {
        return new MessageApi({
            graphqlSdk: this._graphqlSdk,
            currentFcmToken: this._currentFcmToken,
            clientUser: this._clientUser,
            clientUniqueName: this._clientUniqueName,
            clientDisplayName: this._clientDisplayName,
            spaceName: this._spaceName,
            chatboardUniqueName: this.uniqueName,
            ...message,
        });
    }

    private _getReactionApiFromGql(reaction: GqlReaction) {
        return new ReactionApi({
            graphqlSdk: this._graphqlSdk,
            ...getReactionFromGql(reaction),
        });
    }

    private _getUnconfirmedMarkApiFromGql(unconfirmedMark: GqlUnconfirmedMark) {
        return new UnconfirmedMarkApi({
            graphqlSdk: this._graphqlSdk,
            ...getUnconfirmedMarkFromGql(unconfirmedMark),
        });
    }

    private _getReplyObserverApiFromGql(replyObserver: GqlReplyObserver) {
        return new ReplyObserverApi({
            graphqlSdk: this._graphqlSdk,
            ...getReplyObserverFromGql(replyObserver),
        });
    }

    private _getChatboardMembershipApiFromGql({
        chatboardMembership,
        spaceMembershipApi,
    }: {
        chatboardMembership: GqlChatboardMembership;
        spaceMembershipApi: SpaceMembershipApi;
    }) {
        return new ChatboardMembershipApi({
            graphqlSdk: this._graphqlSdk,
            currentFcmToken: this._currentFcmToken,
            clientUser: this._clientUser,
            clientUniqueName: this._clientUniqueName,
            clientDisplayName: this._clientDisplayName,
            spaceName: this._spaceName,
            spaceMembershipUniqueName: spaceMembershipApi.uniqueName,
            spaceMembershipDisplayName: spaceMembershipApi.getDisplayName(),
            spaceMembershipPermission: spaceMembershipApi.permission,
            chatboardUniqueName: this.uniqueName,
            ...getChatboardMembershipFromGql(chatboardMembership),
        });
    }

    // UPDATE SELF
    async close() {
        const now = new Date();
        this._updateClosedAt({ closedAt: now, updatedAt: now });
        modifyRdbChatboard({
            spaceId: this.spaceId,
            chatboard: {
                ...this,
                modifyNotification: {
                    spaceName: this._spaceName,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                },
            },
        });
        await this._graphqlSdk.closeChatboard({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
            },
        });
    }

    // GET CHILDREN
    async getMessageWithMark({ messageId }: { messageId: string }) {
        const {
            getChatboardMessageWithMark: {
                messageWithMark: { message, reactions, unconfirmedMark, replyObservers },
            },
        } = await this._graphqlSdk.getChatboardMessageWithMark({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
                messageId,
            },
        });
        return {
            messageApi: this._getMessageApiFromGql(message),
            reactionApis: reactions.map((reaction) => this._getReactionApiFromGql(reaction)),
            unconfirmedMarkApi: unconfirmedMark ? this._getUnconfirmedMarkApiFromGql(unconfirmedMark) : undefined,
            replyObserverApis: replyObservers.map((replyObserver) => this._getReplyObserverApiFromGql(replyObserver)),
        };
    }

    async getMessageWithMarks({
        maxAmount,
        startAfterCreatedAt,
        endBeforeCreatedAt,
        replyToMessageId,
    }: {
        maxAmount?: number;
        startAfterCreatedAt?: Date;
        endBeforeCreatedAt?: Date;
        replyToMessageId?: string;
    }) {
        const {
            getChatboardMessageWithMarks: { messageWithMarks },
        } = await this._graphqlSdk.getChatboardMessageWithMarks({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
                filter: {
                    replyToMessageId,
                },
                limitation: {
                    maxAmount,
                    startAfterCreatedAt: dateToIsoOptionally(startAfterCreatedAt),
                    endBeforeCreatedAt: dateToIsoOptionally(endBeforeCreatedAt),
                },
            },
        });
        return messageWithMarks.map(({ message, reactions, unconfirmedMark, replyObservers }) => ({
            messageApi: this._getMessageApiFromGql(message),
            reactionApis: reactions.map((reaction) => this._getReactionApiFromGql(reaction)),
            unconfirmedMarkApi: unconfirmedMark ? this._getUnconfirmedMarkApiFromGql(unconfirmedMark) : undefined,
            replyObserverApis: replyObservers.map((replyObserver) => this._getReplyObserverApiFromGql(replyObserver)),
        }));
    }

    async getChatboardMembershipApis({ spaceMembershipApis }: { spaceMembershipApis: SpaceMembershipApi[] }) {
        const {
            getChatboardMemberships: { chatboardMemberships },
        } = await this._graphqlSdk.getChatboardMemberships({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
            },
        });
        return chatboardMemberships.map((chatboardMembership) => {
            const spaceMembershipApi = spaceMembershipApis.find((item) => item.userId === chatboardMembership.userId);
            if (!spaceMembershipApi) {
                throw new Error('getChatboardMembershipApis: never.');
            }
            return this._getChatboardMembershipApiFromGql({
                chatboardMembership,
                spaceMembershipApi,
            });
        });
    }

    async getChatboardMembershipApiWithUsers({ spaceMembershipApis }: { spaceMembershipApis: SpaceMembershipApi[] }) {
        const {
            getChatboardMembershipWithUsers: { chatboardMembershipWithUsers },
        } = await this._graphqlSdk.getChatboardMembershipWithUsers({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
            },
        });
        return chatboardMembershipWithUsers.map(({ chatboardMembership, user }) => {
            const spaceMembershipApi = spaceMembershipApis.find((item) => item.userId === chatboardMembership.userId);
            if (!spaceMembershipApi) {
                throw new Error('getChatboardMembershipApiWithUsers: never.');
            }
            return {
                chatboardMembershipApi: this._getChatboardMembershipApiFromGql({
                    chatboardMembership,
                    spaceMembershipApi,
                }),
                user: getUserFromGql(user),
            };
        });
    }

    async getThread({ messageId }: { messageId: string }) {
        const {
            getChatboardThread: { thread },
        } = await this._graphqlSdk.getChatboardThread({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
                messageId,
            },
        });
        return {
            master: {
                messageApi: this._getMessageApiFromGql(thread.master.message),
                reactionApis: thread.master.reactions.map((reaction) => this._getReactionApiFromGql(reaction)),
                unconfirmedMarkApi: thread.master.unconfirmedMark
                    ? this._getUnconfirmedMarkApiFromGql(thread.master.unconfirmedMark)
                    : undefined,
                replyObserverApis: thread.master.replyObservers.map((replyObserver) =>
                    this._getReplyObserverApiFromGql(replyObserver)
                ),
            },
            replies: thread.replies.map(({ message, reactions, unconfirmedMark }) => ({
                messageApi: this._getMessageApiFromGql(message),
                reactionApis: reactions.map((reaction) => this._getReactionApiFromGql(reaction)),
                unconfirmedMarkApi: unconfirmedMark ? this._getUnconfirmedMarkApiFromGql(unconfirmedMark) : undefined,
                replyObserverApis: [],
            })),
        };
    }

    async getThreads({
        maxAmount,
        startAfterCreatedAt,
        endBeforeCreatedAt,
        userId,
    }: {
        maxAmount?: number;
        startAfterCreatedAt?: Date;
        endBeforeCreatedAt?: Date;
        userId?: string;
    }) {
        const {
            getChatboardThreads: { threads },
        } = await this._graphqlSdk.getChatboardThreads({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
                filter: {
                    userId,
                },
                limitation: {
                    maxAmount,
                    startAfterCreatedAt: dateToIsoOptionally(startAfterCreatedAt),
                    endBeforeCreatedAt: dateToIsoOptionally(endBeforeCreatedAt),
                },
            },
        });
        return threads.map(({ master, replies }) => ({
            master: {
                messageApi: this._getMessageApiFromGql(master.message),
                reactionApis: master.reactions.map((reaction) => this._getReactionApiFromGql(reaction)),
                unconfirmedMarkApi: master.unconfirmedMark
                    ? this._getUnconfirmedMarkApiFromGql(master.unconfirmedMark)
                    : undefined,
                replyObserverApis: master.replyObservers.map((replyObserver) =>
                    this._getReplyObserverApiFromGql(replyObserver)
                ),
            },
            replies: replies.map(({ message, reactions, unconfirmedMark }) => ({
                messageApi: this._getMessageApiFromGql(message),
                reactionApis: reactions.map((reaction) => this._getReactionApiFromGql(reaction)),
                unconfirmedMarkApi: unconfirmedMark ? this._getUnconfirmedMarkApiFromGql(unconfirmedMark) : undefined,
                replyObserverApis: [],
            })),
        }));
    }

    // CREATE CHILDREN
    async createMessage({
        body,
        replyTo,
        mentions,
        isMarkUnconfirmed,
        isObserveReply,
    }: {
        body: string;
        replyTo: ReplyTo | undefined;
        mentions: MentionToUser[];
        isMarkUnconfirmed: boolean;
        isObserveReply: boolean;
    }) {
        const firestoreDb = getFirebaseFirestore();
        const unconfirmedMarkToUsers = (() => {
            if (isMarkUnconfirmed) {
                return mentions.map(({ id }) => ({
                    id: doc(collection(firestoreDb, `Space/${this.spaceId}/UnconfirmedMark`)).id,
                    toUserId: id,
                }));
            }
            return [];
        })();
        const replyObserverToUsers = (() => {
            if (isObserveReply) {
                return mentions.map(({ id }) => ({
                    id: doc(collection(firestoreDb, `Space/${this.spaceId}/ReplyObserver`)).id,
                    toUserId: id,
                }));
            }
            return [];
        })();
        const { newMessage } = postRdbMessage({
            spaceId: this.spaceId,
            newMessage: {
                chatboardId: this.id,
                userId: this._clientUser.id,
                body,
                replyToMessageId: replyTo?.messageId,
                addNotification: {
                    replyToUser: replyTo?.user,
                    mentions,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spaceName: this._spaceName,
                    chatboardUniqueName: this.uniqueName,
                    unconfirmedMarks: unconfirmedMarkToUsers,
                    replyObservers: replyObserverToUsers,
                },
            },
        });
        this._graphqlSdk.createChatboardMessage({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
                preparedId: newMessage.id,
                body,
                replyToMessageId: replyTo?.messageId,
                mentionToUserIds: mentions?.map(({ id }) => id) || [],
                unconfirmedMarkToUsers: unconfirmedMarkToUsers.map(({ id, toUserId }) => ({
                    preparedId: id,
                    toUserId,
                })),
                replyObserverToUsers: replyObserverToUsers.map(({ id, toUserId }) => ({
                    preparedId: id,
                    toUserId,
                })),
            },
        });
        return {
            messageApi: this._getMessageApi(newMessage),
        };
    }

    async createChatboardMembership({
        permission,
        spaceMembershipApi,
    }: {
        spaceMembershipApi: SpaceMembershipApi;
        permission: ChatboardPermissionType;
    }) {
        const {
            createChatboardMembership: { chatboardMembership },
        } = await this._graphqlSdk.createChatboardMembership({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.id,
                userId: spaceMembershipApi.userId,
                permission,
            },
        });
        postRdbChatboardMembership({
            spaceId: this.spaceId,
            newChatboardMembership: {
                id: chatboardMembership.id,
                userId: spaceMembershipApi.userId,
                chatboardId: this.id,
                permission,
                addNotification: {
                    spaceName: this._spaceName,
                    chatboardUniqueName: this.uniqueName,
                    toUserUniqueName: spaceMembershipApi.uniqueName,
                    toUserDisplayName: spaceMembershipApi.getDisplayName(),
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spacePermission: spaceMembershipApi.permission,
                },
            },
        });
        return {
            chatboardMembershipApi: this._getChatboardMembershipApiFromGql({ chatboardMembership, spaceMembershipApi }),
        };
    }

    async createChatboardMemberships(
        input: {
            spaceMembershipApi: SpaceMembershipApi;
            permission: ChatboardPermissionType;
        }[]
    ) {
        // later: 専用のエンドポイントを使う
        return Promise.all(
            input.map(async (inputItem) => (await this.createChatboardMembership(inputItem)).chatboardMembershipApi)
        );
    }
}
