import { FC, useCallback } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { useSpaceCtx } from '@/utils/ctxs';
import { useAllHotMembers } from '@/utils/customHooks';
import { SpaceProvider } from '@/providers';
import { SpaceTemplate } from '@/components/3_template/ConsoleTemplate/SpaceTemplate';
import { Sidebar } from '@/components/3_template/ConsoleTemplate/Sidebar';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
}));

export const ConsoleTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // CTX
    const { current } = useSpaceCtx();

    // HOOKS
    const { getHotUser, getHotUsersForSpace } = useAllHotMembers();

    // DATA
    const hotUsers = current ? getHotUsersForSpace(current.id) : undefined;
    const hotUserIds = hotUsers ? Object.keys(hotUsers) : undefined;

    // CALLBACK
    const getHotRdbUser = useCallback(
        (userId: string) => {
            return current ? getHotUser({ spaceId: current.id, userId }) : undefined;
        },
        [current, getHotUser]
    );

    return (
        <SpaceProvider hotUserIds={hotUserIds} hotUsers={hotUsers} getHotRdbUser={getHotRdbUser}>
            <div className={c.root}>
                <Sidebar getHotUser={getHotUser} getHotUsersForSpace={getHotUsersForSpace} />
                {current ? <SpaceTemplate /> : <></>}
            </div>
        </SpaceProvider>
    );
};
ConsoleTemplate.displayName = 'ConsoleTemplate';
