import { FC, useCallback } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import { MessageApi, ReactionApi } from '@/utils/PowerchatClient';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '6px 9px',
        minWidth: 0,
        minHeight: 0,
        borderRadius: 13.5,
        '& .MuiButton-label': {
            lineHeight: '1',
        },
        '&:not(:first-child)': {
            marginLeft: 2,
        },
    },
    isUsers: {
        background: 'rgba(255,255,255,.08)',
    },
    emoji: {
        display: 'inline-block',
    },
    count: {
        fontSize: 10,
        display: 'inline-block',
        marginLeft: 4,
    },
}));

export const ReactionButton: FC<{
    className?: string;
    messageApi: MessageApi;
    letter: string;
    reactionApis: ReactionApi[];
    userReactionApi: ReactionApi | undefined;
}> = ({ className, messageApi, letter, reactionApis, userReactionApi }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HANDLER
    const handleClick = useCallback(() => {
        if (userReactionApi) {
            // remove
            userReactionApi.remove();
            return;
        }
        // add
        messageApi.createReaction({ letter });
    }, [letter, messageApi, userReactionApi]);

    return (
        <MuiButton
            className={clsx(c.root, className, {
                [c.isUsers]: !!userReactionApi,
            })}
            onClick={handleClick}
        >
            <span className={c.emoji}>{letter}</span>
            <span className={c.count}>{reactionApis.length}</span>
        </MuiButton>
    );
};
ReactionButton.displayName = 'ChatboardTemplate/ReactionButton';
