import { FC, MouseEvent } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import { MessageApi, MessageWithMark, SpaceMembershipApi } from '@/utils/PowerchatClient';
import { useSpaceMembersCtx, useHotMembersCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { UserIcon } from '@/components/2_org/UserIcon';

import { getOptionalUserName } from '@/utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: 4,
        minWidth: 0,
        borderRadius: 10,
        marginTop: -5,
    },
    userIcons: {},
    userIcon: {
        width: 12,
        height: 12,
        fontSize: 8,
        display: 'flex',
    },
    text: {
        fontSize: 8,
        color: 'rgba(255,255,255,.57)',
    },
}));

export const RepliesView: FC<{
    className?: string;
    messageApi: MessageApi;
    replis: Omit<MessageWithMark, 'replyObserverApis'>[];
    onClick: () => void;
}> = ({ className, messageApi, replis, onClick }) => {
    // STYLE
    const c = useStyles(useTheme());

    // CTX
    const { getSpaceMember } = useSpaceMembersCtx();
    const { getHotRdbUser } = useHotMembersCtx();

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const authors = replis.reduce((acc, prev) => {
        const spaceMembershipApi = getSpaceMember(prev.messageApi.userId)?.spaceMembershipApi;
        const targetItem = acc.some((accItem) => accItem?.userId === spaceMembershipApi?.userId);
        if (targetItem) {
            return acc;
        }
        return [...acc, spaceMembershipApi];
    }, [] as (SpaceMembershipApi | undefined)[]);

    return (
        <MuiButton className={clsx(c.root, className)} onClick={onClick}>
            <div className={c.userIcons}>
                {authors.map((author) => {
                    const { uniqueName } = getOptionalUserName({ spaceMembershipApi: author, txt });
                    const rdbUser = author ? getHotRdbUser(author.userId) : undefined;
                    return (
                        <UserIcon
                            className={c.userIcon}
                            userName={uniqueName}
                            isHot={rdbUser?.currentSpace?.id === messageApi.spaceId}
                        />
                    );
                })}
            </div>
            <div className={c.text}>
                {txt({
                    en: `${replis.length} ${replis.length > 1 ? 'replies' : 'reply'}`,
                    ja: `${replis.length}件のリプライ`,
                })}
            </div>
        </MuiButton>
    );
};
RepliesView.displayName = 'ChatboardTemplate/RepliesView';
