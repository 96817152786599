import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { ChatboardMembership } from '@/utils/PowerchatClient/models';
import {
    RdbLastChatboardMembership,
    RdbLastChatboardMembershipAddNotification,
    RdbLastChatboardMembershipModifyNotification,
    RdbLastChatboardMembershipRemoveNotification,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToChatboardMemberships = ({
    spaceId,
    onAdded,
    onModified,
    onRemoved,
}: {
    spaceId: string;
    onAdded: (input: {
        newChatboardMembership: ChatboardMembership;
        addNotification: RdbLastChatboardMembershipAddNotification;
    }) => void;
    onModified: (input: {
        modifiedChatboardMembership: ChatboardMembership;
        modifyNotification: RdbLastChatboardMembershipModifyNotification;
    }) => void;
    onRemoved: (input: {
        removedChatboardMembership: ChatboardMembership;
        removeNotification: RdbLastChatboardMembershipRemoveNotification;
    }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastChatboardMembershipRef = ref(db, `lastChatboardMemberships/${spaceId}`);
    const firedAt = new Date();
    const unsubscribeListenToChatboardMemberships = onValue(lastChatboardMembershipRef, (data) => {
        if (data.exists()) {
            const {
                action,
                removedAt,
                addNotification,
                modifyNotification,
                removeNotification,
                ...chatboardMembershipData
            }: RdbLastChatboardMembership = data.val();
            const chatboardMembership = new ChatboardMembership({
                id: chatboardMembershipData.id,
                createdAt: new Date(chatboardMembershipData.createdAt),
                updatedAt: chatboardMembershipData.updatedAt ? new Date(chatboardMembershipData.updatedAt) : undefined,
                userId: chatboardMembershipData.userId,
                spaceId,
                chatboardId: chatboardMembershipData.chatboardId,
                permission: chatboardMembershipData.permission,
            });
            if (firedAt <= chatboardMembership.createdAt && action === 'add' && addNotification) {
                onAdded({
                    newChatboardMembership: chatboardMembership,
                    addNotification,
                });
            } else if (
                chatboardMembership.updatedAt &&
                firedAt <= chatboardMembership.updatedAt &&
                action === 'modify' &&
                modifyNotification
            ) {
                onModified({
                    modifyNotification,
                    modifiedChatboardMembership: chatboardMembership,
                });
            } else if (removedAt && firedAt <= new Date(removedAt) && action === 'remove' && removeNotification) {
                onRemoved({
                    removeNotification,
                    removedChatboardMembership: chatboardMembership,
                });
            }
        }
    });
    return {
        unsubscribeListenToChatboardMemberships,
    };
};
