import { createContext, useContext } from 'react';
import { ChatboardMembershipApi } from '@/utils/PowerchatClient';

export type LastChatboardMembershipLog = {
    action: 'add' | 'modify' | 'remove';
    chatboardMembershipApi: ChatboardMembershipApi;
};

type LastChatboardMembershipCtxType = {
    lastChatboardMembershipLog: LastChatboardMembershipLog | undefined;
};
export const LastChatboardMembershipCtx = createContext<LastChatboardMembershipCtxType>({
    lastChatboardMembershipLog: undefined,
});
export const useLastChatboardMembershipCtx = () => {
    return useContext(LastChatboardMembershipCtx);
};
