import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import {} from '@/utils/PowerchatClient';
import { useChatboardsCtx, useSpaceCtxAbsolutely } from '@/utils/ctxs';
import { useChatboardMasterMessages, useLanguage, usePastThreads, useAllMessageWithMarks } from '@/utils/customHooks';
import { FetchingText } from '@/components/0_atom/FetchingText';
import { ChatboardButton } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/ChatboardButton';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        zIndex: 1,
        margin: '24px 0 0 20px',
        width: 172,
    },
    hr: {},
    fetching: {
        fontSize: 10,
        color: theme.palette.text.secondary,
    },
    button: {
        marginBottom: 3,
    },
}));

export const ChatboardTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // CTXS
    const {
        current: { spaceApi },
    } = useSpaceCtxAbsolutely();
    const { chatboardIds, getChatboardWithMembershipAbsolutely } = useChatboardsCtx();

    // HOOKS
    const {
        messageIdForReplyObservers,
        messageIdForUnconfirmedMarks,
        getMessageWithMarkForReplyObserver,
        getMessageWithMarkForReplyObserverAbsolutely,
        getMessageWithMarkForUnconfirmedMark,
        getMessageWithMarkForUnconfirmedMarkAbsolutely,
    } = useAllMessageWithMarks();
    const { txt } = useLanguage();

    // DATA
    const messageForUnconfirmedMarks = messageIdForUnconfirmedMarks?.map((messageId) =>
        getMessageWithMarkForUnconfirmedMarkAbsolutely(messageId)
    );
    console.log({ chatboardIds, messageIdForReplyObservers, messageIdForUnconfirmedMarks });
    return (
        <div className={c.root}>
            {chatboardIds && messageIdForReplyObservers && messageIdForUnconfirmedMarks ? (
                <>
                    {/* <MuiButton>
                        {txt({
                            en: 'Unconfirmed',
                            ja: '要確認',
                        })}
                    </MuiButton>
                    <MuiButton>
                        {txt({
                            en: 'Awaiting replies',
                            ja: '返信待ち',
                        })}
                    </MuiButton>
                    <hr className={c.hr} /> */}
                    {chatboardIds.map((chatboardId) => {
                        const { chatboardApi, chatboardMembershipApi } =
                            getChatboardWithMembershipAbsolutely(chatboardId);
                        return (
                            <ChatboardButton
                                key={chatboardId}
                                className={c.button}
                                chatboardApi={chatboardApi}
                                chatboardMembershipApi={chatboardMembershipApi}
                                isHasUnconfirmedMark={
                                    !!messageForUnconfirmedMarks?.find((m) => m.chatboardApi?.id === chatboardApi.id)
                                }
                            />
                        );
                    })}
                </>
            ) : (
                <FetchingText
                    className={c.fetching}
                    text={txt({
                        en: 'Fetching Chatboards',
                        ja: 'チャットボードを取得中',
                    })}
                />
            )}
        </div>
    );
};
ChatboardTemplate.displayName = 'ChatboardTemplate';
