import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import {
    User,
    UserType,
    UserApi,
    getUserFromGql,
    SpaceApi,
    SpaceMembership,
    SpaceMembershipApi,
    getSpaceFromGql,
    getSpaceMembershipFromGql,
    SpacePermission,
    SpacePermissionType,
    ChatboardPermissionType,
    ChatboardApi,
    getChatboardFromGql,
    ChatboardMembershipApi,
    getChatboardMembershipFromGql,
} from '@/utils/PowerchatClient/models';
import {
    startConnectingRealtimeDatabase,
    modifyRdbUser,
    addCurrentChatboard,
    removeCurrentChatboard,
    CurrentSpace,
    CurrentChatboard,
} from '@/utils/PowerchatClient/RealtimeDatabase';
import { Language } from '@/utils/customHooks';

type PowerchatUserClientType = {
    // GET
    getSpaceWithMembershipsForUser: () => Promise<
        {
            spaceApi: SpaceApi;
            spaceMembershipApi: SpaceMembershipApi;
        }[]
    >;
    getUserByCode: (input: { userCode: string }) => Promise<{
        user: User;
    }>;

    // CREATE
    createSpace: (input: {
        spaceName: string;
        ownerUniqueName: string;
        ownerDisplayName: string | undefined;
        members: {
            userUniqueCode: string;
            membershipUniqueName: string | undefined;
            membershipDisplayName: string | undefined;
            permission: SpacePermissionType;
        }[];
    }) => Promise<{
        spaceApi: SpaceApi;
        ownerSpaceMembershipApi: SpaceMembershipApi;
        otherSpaceMembershipApis: SpaceMembershipApi[];
    }>;

    // RTC
    startConnectingRealtimeDatabase: (input: {
        initialSpace: CurrentSpace | undefined;
        initialConfigSpaceId: string | undefined;
        onConnectionChanged: (input: { isOnline: boolean }) => void;
    }) => void;
    updateCurrentSpace: (input: {
        currentSpace: CurrentSpace | undefined;
        currentConfigSpaceId: string | undefined;
    }) => void;
    addCurrentChatboard: (input: { newChatboardId: string; newChatboardUniqueName: string }) => void;
    removeCurrentChatboard: (input: { chatboardId: string }) => void;
};

type ConstructorInput = UserType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    language: Language | undefined;
};

export class PowerchatUserClient extends UserApi implements PowerchatUserClientType {
    isConnectingRealtimeDatabase: boolean;

    language: Language | undefined;

    constructor(input: ConstructorInput) {
        super(input);
        this.isConnectingRealtimeDatabase = false;
        this.language = input.language;
    }

    // UTILS
    _getDisplayName({ spaceMembership }: { spaceMembership: SpaceMembership }) {
        return spaceMembership.displayName || this.displayName;
    }

    // GET
    async getSpaceWithMembershipsForUser() {
        const {
            getSpaceWithMembershipsForUser: { spaceWithMemberships },
        } = await this._graphqlSdk.getSpaceWithMembershipsForUser();
        return spaceWithMemberships.map(({ space, spaceMembership: gqlSpaceMembership }) => {
            const spaceMembership = getSpaceMembershipFromGql(gqlSpaceMembership);
            const clientDisplayName = this._getDisplayName({ spaceMembership });
            return {
                spaceApi: new SpaceApi({
                    graphqlSdk: this._graphqlSdk,
                    currentFcmToken: this._currentFcmToken,
                    clientUser: this,
                    clientUniqueName: spaceMembership.uniqueName,
                    clientDisplayName,
                    ...getSpaceFromGql(space),
                }),
                spaceMembershipApi: new SpaceMembershipApi({
                    graphqlSdk: this._graphqlSdk,
                    clientUser: this,
                    clientUniqueName: spaceMembership.uniqueName,
                    clientDisplayName,
                    spaceName: space.name,
                    memberUserDisplayName: this.displayName,
                    currentFcmToken: this._currentFcmToken,
                    ...spaceMembership,
                }),
            };
        });
    }

    async getUserByCode({ userCode }: { userCode: string }) {
        const {
            getUserByCode: { user },
        } = await this._graphqlSdk.getUserByCode({
            input: { userCode },
        });
        return {
            user: getUserFromGql(user),
        };
    }

    // CREATE
    async createSpace({
        spaceName,
        ownerUniqueName,
        ownerDisplayName,
        members,
    }: {
        spaceName: string;
        ownerUniqueName: string;
        ownerDisplayName: string | undefined;
        members: {
            userUniqueCode: string;
            membershipUniqueName: string | undefined;
            membershipDisplayName: string | undefined;
            permission: SpacePermissionType;
        }[];
    }) {
        const {
            createSpace: { space, ownerSpaceMembership: gqlOwnerSpaceMembership, otherMemberships },
        } = await this._graphqlSdk.createSpace({
            input: {
                name: spaceName,
                ownerUniqueName,
                ownerDisplayName,
                users: members.map(({ userUniqueCode, membershipDisplayName, membershipUniqueName, permission }) => ({
                    userUniqueCode,
                    membershipDisplayName,
                    membershipUniqueName,
                    permission: new SpacePermission(permission),
                })),
            },
        });
        const ownerSpaceMembership = getSpaceMembershipFromGql(gqlOwnerSpaceMembership);
        const clientDisplayName = this._getDisplayName({ spaceMembership: ownerSpaceMembership });
        return {
            spaceApi: new SpaceApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this._currentFcmToken,
                clientUser: this,
                clientUniqueName: ownerSpaceMembership.uniqueName,
                clientDisplayName,
                ...getSpaceFromGql(space),
            }),
            ownerSpaceMembershipApi: new SpaceMembershipApi({
                graphqlSdk: this._graphqlSdk,
                clientUser: this,
                currentFcmToken: this._currentFcmToken,
                clientUniqueName: ownerSpaceMembership.uniqueName,
                clientDisplayName,
                spaceName,
                memberUserDisplayName: this.displayName,
                ...ownerSpaceMembership,
            }),
            otherSpaceMembershipApis: otherMemberships.map(
                ({ spaceMembership, user }) =>
                    new SpaceMembershipApi({
                        graphqlSdk: this._graphqlSdk,
                        clientUser: this,
                        currentFcmToken: this._currentFcmToken,
                        clientUniqueName: ownerSpaceMembership.uniqueName,
                        clientDisplayName,
                        spaceName,
                        memberUserDisplayName: user.displayName || undefined,
                        ...getSpaceMembershipFromGql(spaceMembership),
                    })
            ),
        };
    }

    // RTC
    startConnectingRealtimeDatabase({
        initialSpace,
        initialConfigSpaceId,
        onConnectionChanged,
    }: {
        initialSpace: CurrentSpace | undefined;
        initialConfigSpaceId: string | undefined;
        onConnectionChanged: (input: { isOnline: boolean }) => void;
    }) {
        if (this.isConnectingRealtimeDatabase) {
            console.error('PowerchatUserClient: already connected to RealtimeDatabase.');
            return;
        }
        startConnectingRealtimeDatabase({
            userId: this.id,
            uniqueName: this.uniqueName,
            displayName: this.displayName,
            initialSpace,
            initialConfigSpaceId,
            currentFcmToken: this._currentFcmToken,
            onChanged: ({ isOnline }) => {
                onConnectionChanged({ isOnline });
                this.isConnectingRealtimeDatabase = isOnline;
            },
            language: this.language,
        });
        this.isConnectingRealtimeDatabase = true;
    }

    updateCurrentSpace({
        currentSpace,
        currentConfigSpaceId,
    }: {
        currentSpace: CurrentSpace | undefined;
        currentConfigSpaceId: string | undefined;
    }) {
        modifyRdbUser({
            userId: this.id,
            currentSpace,
            currentConfigSpaceId,
            currentFcmToken: this._currentFcmToken,
            uniqueName: this.uniqueName,
            displayName: this.displayName,
            language: this.language,
        });
    }

    addCurrentChatboard({
        newChatboardId,
        newChatboardUniqueName,
    }: {
        newChatboardId: string;
        newChatboardUniqueName: string;
    }) {
        addCurrentChatboard({
            userId: this.id,
            newChatboardId,
            newChatboardUniqueName,
        });
    }

    removeCurrentChatboard({ chatboardId }: { chatboardId: string }) {
        removeCurrentChatboard({
            userId: this.id,
            chatboardId,
        });
    }
}
