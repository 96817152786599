import { ref, set, remove } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { getRdbUserInput, CurrentSpace } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';
import { Language } from '@/utils/customHooks/useLanguage';

export const modifyRdbUser = ({
    userId,
    currentSpace,
    currentConfigSpaceId,
    currentFcmToken,
    uniqueName,
    displayName,
    language,
}: {
    userId: string;
    currentSpace: CurrentSpace | undefined;
    currentConfigSpaceId: string | undefined;
    currentFcmToken: string;
    uniqueName: string;
    displayName: string | undefined;
    language: Language | undefined;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const userRef = ref(db, `users/${userId}`);

    set(
        userRef,
        getRdbUserInput({
            updatedAt: 'serverTimestamp',
            currentSpace,
            currentConfigSpaceId,
            isOnline: true,
            currentFcmToken,
            uniqueName,
            displayName,
            language,
        })
    );
};

export const addCurrentChatboard = ({
    userId,
    newChatboardId,
    newChatboardUniqueName,
}: {
    userId: string;
    newChatboardId: string;
    newChatboardUniqueName: string;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const currentChatboardsRef = ref(db, `users/${userId}/currentSpace/currentChatboards/${newChatboardId}`);
    set(currentChatboardsRef, {
        uniqueName: newChatboardUniqueName,
    });
};

export const removeCurrentChatboard = ({ userId, chatboardId }: { userId: string; chatboardId: string }) => {
    const db = getFirebaseRealtimeDatabase();
    const currentChatboardsRef = ref(db, `users/${userId}/currentSpace/currentChatboards/${chatboardId}`);
    remove(currentChatboardsRef);
};
