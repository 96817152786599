import { useEffect, useCallback } from 'react';

import { ChatboardApi, MessageWithMark } from '@/utils/PowerchatClient';
import { useChatboardsCtx, useSpaceCtxAbsolutely } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';

export type MessageForMark = {
    id: string; // messageId
    chatboardApi: ChatboardApi | undefined;
    messageWithMark: MessageWithMark;
};

export const useAllMessageWithMarks = () => {
    // CTXS
    const {
        current: { spaceApi },
    } = useSpaceCtxAbsolutely();
    const { chatboardIds, getChatboardWithMembershipAbsolutely } = useChatboardsCtx();

    // HOOKS
    const {
        ids: messageIdForUnconfirmedMarks,
        getItem: getMessageWithMarkForUnconfirmedMark,
        getItemAbsolutely: getMessageWithMarkForUnconfirmedMarkAbsolutely,
        initialize: initializeUnconfirmedMarks,
    } = useLazyItems<MessageForMark>(undefined);
    const {
        ids: messageIdForReplyObservers,
        getItem: getMessageWithMarkForReplyObserver,
        getItemAbsolutely: getMessageWithMarkForReplyObserverAbsolutely,
        initialize: initializeReplyObservers,
    } = useLazyItems<MessageForMark>(undefined);

    // CALLBACKS
    const fetchMessageWithMarks = useCallback(async () => {
        if (chatboardIds) {
            const { lobby, chatboard } = await spaceApi.getAllMarkedMessages({
                getChatboardApi: (id: string) => getChatboardWithMembershipAbsolutely(id).chatboardApi,
            });
            const arr: MessageForMark[] = [
                ...lobby.map((item) => ({
                    id: item.messageApi.id,
                    messageWithMark: item,
                    chatboardApi: undefined,
                })),
                ...chatboard.flatMap(({ messageWithMarks, chatboardApi }) =>
                    messageWithMarks.map((item) => ({
                        id: item.messageApi.id,
                        messageWithMark: item,
                        chatboardApi,
                    }))
                ),
            ];
            const { unconfirmedMarks, replyObservers } = arr.reduce(
                (acc, { messageWithMark, chatboardApi, id }) => {
                    const newAcc = acc;
                    if (messageWithMark.unconfirmedMarkApi) {
                        newAcc.unconfirmedMarks.push({
                            id,
                            messageWithMark,
                            chatboardApi,
                        });
                    }
                    if (messageWithMark.replyObserverApis.length > 0) {
                        newAcc.replyObservers.push({
                            id,
                            messageWithMark,
                            chatboardApi,
                        });
                    }
                    return newAcc;
                },
                {
                    unconfirmedMarks: [],
                    replyObservers: [],
                } as {
                    unconfirmedMarks: MessageForMark[];
                    replyObservers: MessageForMark[];
                }
            );
            initializeUnconfirmedMarks(unconfirmedMarks);
            initializeReplyObservers(replyObservers);
        }
    }, [
        spaceApi,
        chatboardIds,
        getChatboardWithMembershipAbsolutely,
        initializeUnconfirmedMarks,
        initializeReplyObservers,
    ]);

    useEffect(() => {
        fetchMessageWithMarks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatboardIds !== undefined]);

    return {
        messageIdForUnconfirmedMarks,
        getMessageWithMarkForUnconfirmedMark,
        getMessageWithMarkForUnconfirmedMarkAbsolutely,
        messageIdForReplyObservers,
        getMessageWithMarkForReplyObserver,
        getMessageWithMarkForReplyObserverAbsolutely,
    };
};
