import { FC, useRef } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import SvgEllipsis from '@/svgs/ellipsis';
import SvgChatboard from '@/svgs/chatboard';

import { ChatboardApi, ChatboardMembershipApi } from '@/utils/PowerchatClient';
import {
    useChatboardsCtx,
    useSpaceCtxAbsolutely,
    useUserCtxAbsolutely,
    useHotMembersCtxAbsolutely,
    useSpaceMembersCtxAbsolutely,
} from '@/utils/ctxs';
import { useChatboardMasterMessages, useLanguage, usePastThreads, useChatboardMembers } from '@/utils/customHooks';
import { Window } from '@/components/0_atom/Window';
import { UserIcon } from '@/components/2_org/UserIcon';
import { ChatboardView } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/ChatboardView';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '5px 5px 5px 21px',
        borderRadius: 14,
        justifyContent: 'space-between',
        minHeight: 28,
    },
    current: {
        background: 'rgba(0,0,0,.18)',
        color: theme.palette.text.primary,
    },
    Notcurrent: {
        color: theme.palette.text.secondary,
    },
    name: {},
    hotMembers: {},
    userIcon: {
        width: 18,
        height: 18,
        fontSize: 10,
    },
    unconfirmed: {
        width: 6,
        height: 6,
        color: '#fff',
    },
}));

export const ChatboardButton: FC<{
    className?: string;
    chatboardApi: ChatboardApi;
    chatboardMembershipApi: ChatboardMembershipApi;
    isHasUnconfirmedMark: boolean;
}> = ({ className, chatboardApi, chatboardMembershipApi, isHasUnconfirmedMark }) => {
    // STYLE
    const c = useStyles(useTheme());

    // REF
    const buttonRef = useRef<HTMLButtonElement>(null);

    // CTX
    const { userClient } = useUserCtxAbsolutely();
    const { getHotRdbUser } = useHotMembersCtxAbsolutely();
    const { getSpaceMemberAbsolutely } = useSpaceMembersCtxAbsolutely();

    // HOOKS
    const { userIds, getChatboardMember, getChatboardMemberAbsolutely } = useChatboardMembers({ chatboardApi });
    const { txt } = useLanguage();

    // DATA
    const members = userIds?.map((userId) => getChatboardMemberAbsolutely(userId));
    const hotMembers = members?.filter((member) => {
        const rdbUser = getHotRdbUser(member.id);
        const currentChatboards = rdbUser?.currentSpace?.currentChatboards;
        if (currentChatboards) {
            return currentChatboards[chatboardApi.id];
        }
        return undefined;
    });
    const isCurrent = !!hotMembers?.find((member) => member.id === userClient.id);
    console.log({ members, hotMembers, isCurrent });
    return (
        <>
            <MuiButton
                buttonRef={buttonRef}
                className={clsx(c.root, className, {
                    [c.current]: isCurrent,
                    [c.Notcurrent]: !isCurrent,
                })}
                startIcon={isHasUnconfirmedMark ? <SvgEllipsis className={c.unconfirmed} /> : undefined}
                fullWidth
                onClick={() => {
                    if (!isCurrent) {
                        userClient.addCurrentChatboard({
                            newChatboardId: chatboardApi.id,
                            newChatboardUniqueName: chatboardApi.uniqueName,
                        });
                    }
                }}
            >
                <div className={c.name}>{chatboardApi.uniqueName}</div>
                <div className={c.hotMembers}>
                    {hotMembers?.map((member) => (
                        <UserIcon
                            className={c.userIcon}
                            key={chatboardApi.id + member.id}
                            userName={getSpaceMemberAbsolutely(member.id).spaceMembershipApi.uniqueName}
                            isHot
                        />
                    ))}
                </div>
            </MuiButton>
            {buttonRef.current && (
                <Window
                    anchorEl={buttonRef.current}
                    initialHeight={327}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorOffset={{
                        top: 0,
                        left: 5,
                    }}
                    isOpen={isCurrent}
                    onClose={() => {
                        userClient.removeCurrentChatboard({
                            chatboardId: chatboardApi.id,
                        });
                    }}
                    title={chatboardApi.uniqueName}
                    windowKey={`chatboard-${chatboardApi.id}`}
                    icon={<SvgChatboard />}
                >
                    <ChatboardView chatboardApi={chatboardApi} chatboardMembershipApi={chatboardMembershipApi} />
                </Window>
            )}
        </>
    );
};
ChatboardButton.displayName = 'ChatboardButton';
