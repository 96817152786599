import { FC, useState, useCallback, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SvgPlus from '@/svgs/fa-plus-solid';

import {
    User,
    SpaceMembership,
    SpaceMembershipApi,
    SpacePermissionType,
    ChatboardPermissionType,
} from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely, useSpaceMembersCtx } from '@/utils/ctxs';
import { useLanguage, ItemType } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { Button } from '@/components/0_atom/Button';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { InlineInputAndButton } from '@/components/1_mol/InlineInputAndButton';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';
import { FindUserInput } from '@/components/2_org/FindUserInput';
import { FindMember } from '@/components/2_org/FindMember';
import { ConfigBlock } from '@/components/2_org/ConfigBlock';

const useStyles = makeStyles((theme) => ({
    root: {},
    addMember: {
        marginTop: 30,
    },
    user: {},
    userIcon: {
        width: 38,
        height: 38,
        marginRight: 10,
    },
    li: {
        paddingBottom: 0,
    },
    removeUserButton: {
        color: theme.palette.error.main,
        '& svg': {
            height: 10,
            width: 10,
            transform: 'rotate(45deg)',
        },
    },
}));

type MemberItem = {
    id: string;
    spaceMembershipApi: SpaceMembershipApi;
    permission: ChatboardPermissionType;
};

export const AddChatboardModalStep2: FC<{
    userIds: string[];
    getMemberAbsolutely: (id: string) => MemberItem;
    addMember: ({
        newItem,
        isIgnoreDuplicationError,
        isAddToTop,
    }: {
        newItem: MemberItem;
        isIgnoreDuplicationError?: boolean | undefined;
        isAddToTop?: boolean | undefined;
    }) => void;
    updateMember: ({
        id,
        updator,
        isIgnoreMissingError,
    }: {
        id: string;
        updator: (prevItem: MemberItem) => MemberItem;
        isIgnoreMissingError?: boolean | undefined;
    }) => void;
    removeMember: ({ id, isIgnoreMissingError }: { id: string; isIgnoreMissingError?: boolean | undefined }) => void;
}> = ({ userIds, getMemberAbsolutely, addMember, updateMember, removeMember }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userIds: spaceUserIds } = useSpaceMembersCtx();
    const { userClient } = useUserCtxAbsolutely();
    const { txt } = useLanguage();

    return (
        <>
            <ConfigBlock
                title={txt({
                    en: 'Chatboard members to be added',
                    ja: '追加するルームメンバー',
                })}
                items={userIds.map((userId) => {
                    const member = getMemberAbsolutely(userId);
                    // later: 権限設定
                    return {
                        label: (
                            <UserIconWithName
                                userName={member.spaceMembershipApi.uniqueName}
                                className={c.user}
                                iconClassName={c.userIcon}
                            />
                        ),
                        value: (
                            <MuiIconButton onClick={() => removeMember({ id: userId })} className={c.removeUserButton}>
                                <SvgPlus />
                            </MuiIconButton>
                        ),
                    };
                })}
            />
            <FindMember
                userIds={spaceUserIds?.filter((spaceUserId) => !userIds.includes(spaceUserId))}
                onSelect={(selectedMember) =>
                    addMember({
                        newItem: {
                            id: selectedMember.id,
                            spaceMembershipApi: selectedMember.spaceMembershipApi,
                            permission: {
                                read: true,
                                write: true,
                            },
                        },
                    })
                }
            />
        </>
    );
};
AddChatboardModalStep2.displayName = 'AddChatboardModal/Step2';
