export const prohibitedUserUniqueNames = ['official', 'shintaro', 'bot', 'system', 'app', 'application'];
export const prohibitedUserUniqueNameRegs = [/power/, /uodka/, /noshift/];

export const getIsProhibitedUserUniqueName = (userUniqueNameCandidate: string) => {
    return (
        prohibitedUserUniqueNames.includes(userUniqueNameCandidate) ||
        !!prohibitedUserUniqueNameRegs.find((prohibitedUserUniqueName) =>
            prohibitedUserUniqueName.test(userUniqueNameCandidate)
        )
    );
};
