import { ReactNode, useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiTypography from '@material-ui/core/Typography';

import SvgChevronUp from '@/svgs/fa-chevron-up-regular';

import { useLanguage } from '@/utils/customHooks';
import { Button } from '@/components/0_atom/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        overflowX: 'hidden',
        width: '100%',
        position: 'relative',
        '& .MuiDrawer-paper': {
            top: 'auto',
            right: 'auto',
            bottom: 'auto',
            left: 'auto',
            border: 'none',
            position: 'absolute',
            width: '100%',
        },
    },
    header: {
        marginBottom: 15,
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
        backdropFilter: 'blur(4px)',
        zIndex: 1,
    },
    backButton: {
        position: 'absolute',
        '& svg': {
            transform: 'rotate(270deg)',
        },
    },
    title: {
        textAlign: 'center',
        padding: '12px 140px',
    },
    titleText: {
        fontWeight: 600,
    },
    drawer: {
        transition: 'opacity .15s',
    },
    prev: {
        opacity: 0,
    },
    drawerPaper: {
        height: '100%',
    },
}));

export type PagingContent<TIndex extends string> = {
    children: ReactNode;
    title?: string | ReactNode;
    isShowTitle: boolean;
    level: number;
    prevIndex: TIndex | undefined;
};

export const PagingView = <TIndex extends string>({
    className,
    drawerPaperClassName,
    contents,
    currentIndex,
    setCurrentIndex,
    height,
    headerBackground,
    contentClassName,
    headerClassName,
}: {
    className?: string;
    drawerPaperClassName?: string;
    contents: Record<TIndex, PagingContent<TIndex>>;
    currentIndex: TIndex;
    setCurrentIndex: (currentIndex: TIndex) => void;
    height: string | number;
    headerBackground?: string;
    contentClassName?: string;
    headerClassName?: string;
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [{ prev }, dispatchPrevIndex] = useReducer<
        (
            state: {
                current: TIndex;
                prev: TIndex | undefined;
            },
            input: {
                newIndex: TIndex;
            }
        ) => {
            current: TIndex;
            prev: TIndex | undefined;
        }
    >(
        (state, { newIndex }) => {
            return {
                current: newIndex,
                prev: state.current,
            };
        },
        {
            current: currentIndex,
            prev: undefined,
        }
    );

    // USEEFFECT
    useEffect(() => {
        dispatchPrevIndex({ newIndex: currentIndex });
    }, [currentIndex]);
    console.log({ currentIndex, prev });
    return (
        <div className={clsx(c.root, className)} style={{ height }}>
            {(Object.keys(contents) as TIndex[])
                // .filter((index) => index === currentIndex || index === prev)
                .map((index) => {
                    const content = contents[index];
                    const currentContent = contents[currentIndex];
                    if (!content) {
                        throw new Error('never');
                    }
                    const { children, title, isShowTitle, level, prevIndex } = content;
                    return (
                        <MuiDrawer
                            key={index}
                            anchor={'right'}
                            open={level <= (currentContent?.level || 0)}
                            variant={'persistent'}
                            transitionDuration={{ enter: 400, exit: 300 }}
                            className={clsx(c.drawer, {
                                [c.prev]: level < (currentContent?.level || 0),
                            })}
                            PaperProps={{
                                className: clsx(c.drawerPaper, drawerPaperClassName),
                                style: { zIndex: 1 + level + (currentIndex === index || prev === index ? 1 : 0) },
                            }}
                        >
                            <div className={clsx(c.header, headerClassName)} style={{ background: headerBackground }}>
                                {level > 0 && prevIndex && (
                                    <Button
                                        startIcon={<SvgChevronUp />}
                                        onClick={() => setCurrentIndex(prevIndex)}
                                        className={c.backButton}
                                    >
                                        {contents[prevIndex]?.title ||
                                            txt({
                                                en: 'Back',
                                                ja: '戻る',
                                            })}
                                    </Button>
                                )}
                                {isShowTitle && (
                                    <div className={c.title}>
                                        {typeof title === 'string' ? (
                                            <MuiTypography variant={'body1'} className={c.titleText}>
                                                {title}
                                            </MuiTypography>
                                        ) : (
                                            title
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={contentClassName}>{children}</div>
                        </MuiDrawer>
                    );
                })}
        </div>
    );
};
PagingView.displayName = 'PagingView';
