import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiGrow from '@material-ui/core/Grow';

import { useLanguage } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { Button } from '@/components/0_atom/Button';
import { InlineInputAndButton } from '@/components/1_mol/InlineInputAndButton';
import { ConfigBlock } from '@/components/2_org/ConfigBlock';

const useStyles = makeStyles((theme) => ({
    name: {
        marginBottom: 21,
    },
    nameInput: {
        // background: '#fff',
    },
    button: {
        paddingLeft: 24,
        paddingRight: 24,
    },
}));

export const AddSpaceModalStep1: FC<{
    spaceName: string | undefined;
    setSpaceName: (spaceName: string | undefined) => void;
    handleNext: () => void;
}> = ({ spaceName, setSpaceName, handleNext }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

    return (
        <>
            <InlineInputAndButton
                className={c.name}
                title={txt({
                    en: 'Name for the new space',
                    ja: '新しいスペースの名前',
                })}
                input={
                    <TextInput
                        className={c.nameInput}
                        value={spaceName}
                        setValue={setSpaceName}
                        placeholder={txt({
                            en: 'Name for the new space',
                            ja: '新しいスペースの名前',
                        })}
                    />
                }
                button={
                    <Button disabled={!spaceName} className={c.button} role={'submit'} onClick={handleNext}>
                        {txt({
                            en: 'Next',
                            ja: '次へ',
                        })}
                    </Button>
                }
            />
        </>
    );
};
AddSpaceModalStep1.displayName = 'SpacesTemplate/AddSpaceModal/Step1';
