import { GqlSpacePermission } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';

export type SpacePermissionType = {
    admin: boolean;
    lobby: {
        read: boolean;
        write: boolean;
    };
};

export class SpacePermission implements SpacePermissionType {
    admin: boolean;

    lobby: {
        read: boolean;
        write: boolean;
    };

    constructor({ admin, lobby }: SpacePermissionType) {
        this.admin = admin;
        this.lobby = {
            read: lobby.read,
            write: lobby.write,
        };
    }
}

export const getSpacePermissionFromGql = ({ admin, lobby }: GqlSpacePermission) => {
    return new SpacePermission({
        admin,
        lobby,
    });
};
