import { ref, query, orderByChild, equalTo, onChildRemoved, onChildAdded, onChildChanged } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { RdbUser } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToHotUsersForSpace = ({
    spaceId,
    onAdded,
    onChanged,
    onRemoved,
}: {
    spaceId: string;
    onAdded: (input: { newUser: RdbUser }) => void;
    onChanged: (input: { changedUser: RdbUser }) => void;
    onRemoved: (input: { removedUser: RdbUser }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const usersRef = ref(db, `users`);
    const q = query(usersRef, orderByChild('currentSpace/id'), equalTo(spaceId));
    const unsubscribeOnChildAdded = onChildAdded(q, (data) => {
        if (!data.key) {
            throw new Error('listenToHotUsersForSpace: unexectedly !data.key.');
        }
        const value: Omit<RdbUser, 'id'> = data.val();
        onAdded({
            newUser: {
                ...value,
                id: data.key,
            },
        });
    });
    const unsubscribeOnChildChanged = onChildChanged(q, (data) => {
        if (!data.key) {
            throw new Error('listenToHotUsersForSpace: unexectedly !data.key.');
        }
        const value: Omit<RdbUser, 'id'> = data.val();
        onChanged({
            changedUser: {
                ...value,
                id: data.key,
            },
        });
    });
    const unsubscribeOnChildRemoved = onChildRemoved(q, (data) => {
        if (!data.key) {
            throw new Error('listenToHotUsersForSpace: unexectedly !data.key.');
        }
        const value: Omit<RdbUser, 'id'> = data.val();
        onRemoved({
            removedUser: {
                ...value,
                currentSpace: undefined,
                id: data.key,
            },
        });
    });
    return {
        unsubscribeListenToHotUsersForSpace: () => {
            unsubscribeOnChildAdded();
            unsubscribeOnChildChanged();
            unsubscribeOnChildRemoved();
        },
    };
};
