import { useEffect } from 'react';

import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi } from '@/utils/PowerchatClient';
import { useLastMessageCtx } from '@/utils/ctxs';
import { useItems } from '@/utils/customHooks';

export const useLobbyReplyMessages = ({ masterMessageId }: { masterMessageId: string }) => {
    // CTXS
    const { lastMessageLog } = useLastMessageCtx();

    // HOOKS
    const { ids, getItemAbsolutely, addItem, updateItem } = useItems<{
        id: string;
        messageApi: MessageApi;
        initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
        initialReplyObserverApis: ReplyObserverApi[];
    }>([]);
    console.log({ masterMessageId });
    // LISTENER
    useEffect(() => {
        if (lastMessageLog && !lastMessageLog.messageApi.chatboardId) {
            console.log({ lastMessageLog });
            if (lastMessageLog.action === 'add' && lastMessageLog.messageApi.replyToMessageId === masterMessageId) {
                addItem({
                    newItem: {
                        id: lastMessageLog.messageApi.id,
                        ...lastMessageLog,
                    },
                    isIgnoreDuplicationError: true,
                });
            } else if (
                lastMessageLog.action === 'modify' &&
                lastMessageLog.messageApi.replyToMessageId === masterMessageId
            ) {
                updateItem({
                    id: lastMessageLog.messageApi.id,
                    updator: (prevItem) => ({
                        ...prevItem,
                        messageApi: lastMessageLog.messageApi,
                    }),
                    isIgnoreMissingError: true,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessageLog]);

    return {
        messageIds: ids,
        getMessageItemAbsolutely: getItemAbsolutely,
    };
};
