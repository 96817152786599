import { FC, ReactNode } from 'react';

import { SpacesCtx, SpaceCtx } from '@/utils/ctxs';
import { useSpaces } from '@/utils/customHooks';

export const SpacesProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const {
        spaceIds,
        getSpaceWithMembershipAbsolutely,
        setSpaceId,
        addSpace,
        updateSpaceApi,
        updateSpaceMembershipApi,
        removeSpace,
        currentSpace,
    } = useSpaces();

    return (
        <SpacesCtx.Provider
            value={{
                spaceIds,
                getSpaceWithMembershipAbsolutely,
                setSpaceId,
                addSpace,
                updateSpaceApi,
                updateSpaceMembershipApi,
                removeSpace,
            }}
        >
            <SpaceCtx.Provider
                value={{
                    current: currentSpace,
                }}
            >
                {children}
            </SpaceCtx.Provider>
        </SpacesCtx.Provider>
    );
};
SpacesProvider.displayName = 'SpacesProvider';
