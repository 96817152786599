import { createContext, useContext } from 'react';

import { SpaceApi, SpaceMembershipApi } from '@/utils/PowerchatClient';
import { SpaceWithMembership } from '@/utils/ctxs/SpaceCtx';

export type SpacesCtxType = {
    spaceIds: string[] | undefined;
    getSpaceWithMembershipAbsolutely: (spaceId: string) => SpaceWithMembership;
    setSpaceId: (spaceId: string | undefined) => void;
    addSpace: (input: { spaceApi: SpaceApi; spaceMembershipApi: SpaceMembershipApi }) => void;
    updateSpaceApi: (spaceApi: SpaceApi) => void;
    updateSpaceMembershipApi: (spaceMembershipApi: SpaceMembershipApi) => void;
    removeSpace: (spaceId: string) => void;
};

export const SpacesCtx = createContext<SpacesCtxType | undefined>(undefined);
export const useSpacesCtx = () => {
    return useContext(SpacesCtx);
};
export const useSpacesCtxAbsolutely = () => {
    const ctx = useSpacesCtx();
    if (!ctx?.spaceIds) {
        throw new Error('useSpacesCtxAbsolutely: !spaceIds.');
    }
    return {
        ...ctx,
        spaceIds: ctx.spaceIds,
    };
};
