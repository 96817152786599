import { MessageApi } from '@/utils/PowerchatClient/models/Message';
import { ReactionApi } from '@/utils/PowerchatClient/models/Reaction';
import { ReplyObserverApi } from '@/utils/PowerchatClient/models/ReplyObserver';
import { UnconfirmedMarkApi } from '@/utils/PowerchatClient/models/UnconfirmedMark';

export type MessageWithMarkType = {
    messageApi: MessageApi;
    reactionApis: ReactionApi[];
    replyObserverApis: ReplyObserverApi[];
    unconfirmedMarkApi: UnconfirmedMarkApi | undefined;
};

export class MessageWithMark implements MessageWithMarkType {
    readonly messageApi: MessageApi;

    readonly reactionApis: ReactionApi[];

    readonly replyObserverApis: ReplyObserverApi[];

    readonly unconfirmedMarkApi: UnconfirmedMarkApi | undefined;

    constructor({ messageApi, reactionApis, replyObserverApis, unconfirmedMarkApi }: MessageWithMarkType) {
        this.messageApi = messageApi;
        this.reactionApis = reactionApis;
        this.replyObserverApis = replyObserverApis;
        this.unconfirmedMarkApi = unconfirmedMarkApi;
    }
}
