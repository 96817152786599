import { FC, useRef, MouseEvent } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import SvgEllipsis from '@/svgs/ellipsis';

import {
    MessageApi,
    ReplyObserverApi,
    UnconfirmedMarkApi,
    ReactionApi,
    MessageWithMark,
} from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely, useSpaceMembersCtx, useHotMembersCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { generateRgbColorForText, getOptionalUserName } from '@/utils/utilFunctions';
import { UserIcon } from '@/components/2_org/UserIcon';
import { UserName } from '@/components/2_org/UserName';
import { MessageBodyViewer } from '@/components/2_org/MessageBodyViewer';

import { ReactionsView } from './ReactionsView';
import { RepliesView } from './RepliesView';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 5,
        display: 'flex',
    },
    inner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    main: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    userIcon: {
        width: 22,
        height: 22,
        flexShrink: 0,
        flexGrow: 0,
        fontSize: 12,
        marginTop: 3,
        marginRight: 3,
    },
    userIconPlaceholder: {
        width: 22,
        height: 22,
        flexShrink: 0,
        flexGrow: 0,
        marginTop: 3,
        marginRight: 3,
    },
    card: {
        flexShrink: 1,
        flexGrow: 0,
        borderRadius: 14,
        padding: '5px 13px',
        lineHeight: '1.5',
        position: 'relative',
    },
    userName: {
        display: 'inline-block',
        float: 'left',
        fontSize: 10,
        lineHeight: '18px',
        marginRight: 4,
    },
    body: {
        fontSize: 12,
        display: 'inline',
    },
    bodyCanceled: {
        textDecoration: 'line-through',
        opacity: 0.5,
    },
    marks: {
        position: 'absolute',
        top: 1,
        left: 0,
    },
    unconfirmedMark: {
        display: 'block',
        width: 6,
        height: 6,
        color: '#fff',
        marginBottom: 3,
    },
    replyOberver: {
        display: 'block',
        width: 6,
        height: 6,
        color: '#FF9901',
    },
}));

export const MessageCardUI: FC<{
    className?: string;
    messageApi: MessageApi;
    replyObserverApis: ReplyObserverApi[];
    unconfirmedMarkApi: UnconfirmedMarkApi | undefined;
    reactionApis: ReactionApi[];
    onContextMenu?: (input: {
        mouseEvent: MouseEvent<HTMLDivElement, globalThis.MouseEvent>;
        rootElm: HTMLDivElement;
        cardElm: HTMLDivElement;
    }) => void;
    replis?: Omit<MessageWithMark, 'replyObserverApis'>[];
    openThread?: (input: { rootElm: HTMLDivElement; cardElm: HTMLDivElement }) => void;
    isOmitUser?: boolean;
}> = ({
    className,
    messageApi,
    unconfirmedMarkApi,
    replyObserverApis,
    reactionApis,
    onContextMenu,
    replis,
    openThread,
    isOmitUser,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // REF
    const rootRef = useRef<HTMLDivElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);

    // CTX
    const { userClient } = useUserCtxAbsolutely();
    const { getSpaceMember } = useSpaceMembersCtx();
    const { getHotRdbUser } = useHotMembersCtxAbsolutely();

    // HOOKS
    const { txt, getDateTimeStr } = useLanguage();

    // DATA
    const member = getSpaceMember(messageApi.userId);
    const { uniqueName } = getOptionalUserName({ spaceMembershipApi: member?.spaceMembershipApi, txt });
    const { rgbColor } = generateRgbColorForText({ text: uniqueName });

    return (
        <div ref={rootRef} className={clsx(c.root, className)}>
            <div className={c.inner}>
                <div className={c.main}>
                    {isOmitUser ? (
                        <div className={c.userIconPlaceholder} />
                    ) : (
                        <UserIcon
                            className={c.userIcon}
                            userName={uniqueName}
                            isHot={
                                (member && getHotRdbUser(member.id)?.currentSpace?.id === messageApi.spaceId) || false
                            }
                        />
                    )}
                    <div
                        ref={cardRef}
                        className={c.card}
                        style={{
                            background: rgbColor.getCssValue({ opacity: 0.1 }),
                        }}
                        onContextMenu={(mouseEvent) => {
                            if (onContextMenu) {
                                if (rootRef.current) {
                                    mouseEvent.preventDefault();
                                    onContextMenu({
                                        mouseEvent,
                                        rootElm: rootRef.current,
                                        cardElm: mouseEvent.currentTarget,
                                    });
                                }
                            }
                        }}
                    >
                        {!isOmitUser && <UserName userName={uniqueName} className={c.userName} />}
                        <MessageBodyViewer
                            text={messageApi.body}
                            className={clsx(c.body, {
                                [c.bodyCanceled]: !!messageApi.canceledAt,
                            })}
                        />
                        {reactionApis.length > 0 && (
                            <ReactionsView messageApi={messageApi} reactionApis={reactionApis} />
                        )}
                        {(!!unconfirmedMarkApi || replyObserverApis.length > 0) && (
                            <div className={c.marks}>
                                {!!unconfirmedMarkApi && <SvgEllipsis className={c.unconfirmedMark} />}
                                {replyObserverApis.length > 0 && <SvgEllipsis className={c.replyOberver} />}
                            </div>
                        )}
                    </div>
                </div>
                {replis && replis.length > 0 && (
                    <RepliesView
                        messageApi={messageApi}
                        replis={replis}
                        onClick={() => {
                            if (rootRef.current && cardRef.current && openThread) {
                                openThread({
                                    rootElm: rootRef.current,
                                    cardElm: cardRef.current,
                                });
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
};
MessageCardUI.displayName = 'ChatboardTemplate/MessageCardUI';
