import { PopoverOrigin, PopoverPosition } from '@material-ui/core/Popover';

export const getAbsolutePosition = ({
    anchorEl,
    newElmHeight,
    newElmWidth,
    anchorOrigin,
    transformOrigin,
    offset,
}: {
    anchorEl: HTMLElement;
    newElmHeight: number;
    newElmWidth: number;
    anchorOrigin: PopoverOrigin;
    transformOrigin: PopoverOrigin;
    offset: PopoverPosition;
}) => {
    const rect = anchorEl.getBoundingClientRect();
    const { top, left, width, height, bottom, right } = rect;
    const anchorTop = (() => {
        if (anchorOrigin.vertical === 'center') {
            return top + height / 2 + offset.top;
        }
        if (anchorOrigin.vertical === 'bottom') {
            return bottom + offset.top;
        }
        return top + offset.top;
    })();
    const anchorLeft = (() => {
        if (anchorOrigin.horizontal === 'center') {
            return left + width / 2 + offset.left;
        }
        if (anchorOrigin.horizontal === 'right') {
            return right + offset.left;
        }
        return left + offset.left;
    })();
    const newElmTop = (() => {
        if (transformOrigin.vertical === 'center') {
            return anchorTop - newElmHeight / 2;
        }
        if (transformOrigin.vertical === 'bottom') {
            return anchorTop - newElmHeight;
        }
        return anchorTop;
    })();
    const newElmLeft = (() => {
        if (transformOrigin.horizontal === 'center') {
            return anchorLeft + newElmWidth / 2;
        }
        if (transformOrigin.horizontal === 'right') {
            return anchorLeft - newElmWidth;
        }
        return anchorLeft;
    })();
    console.log({
        top,
        left,
        width,
        height,
        bottom,
        right,
        anchorTop,
        anchorLeft,
        newElmTop,
        newElmLeft,
        anchorEl,
        newElmHeight,
        newElmWidth,
        anchorOrigin,
        transformOrigin,
        offset,
    });
    return {
        top: newElmTop,
        left: newElmLeft,
    };
};
