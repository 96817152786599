import { getApp, initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';
import { getEnvVariables } from '@/utils/envVariables';

export const getFirebaseCore = () => {
    const existinApp = (() => {
        try {
            return getApp('core');
        } catch {
            return undefined;
        }
    })();
    if (existinApp) {
        return existinApp;
    }
    const { projectId, authDomain, apiKey, databaseURL, storageBucket, messagingSenderId, appId } =
        getEnvVariables().firebase.core;
    return initializeApp(
        {
            projectId,
            authDomain,
            apiKey,
            databaseURL,
            storageBucket,
            messagingSenderId,
            appId,
        },
        'core'
    );
};

export const getFirebaseAuth = () => {
    const app = getFirebaseCore();
    const auth = getAuth(app);
    auth.useDeviceLanguage();
    return auth;
};

export const handleStartAuth = () => {
    const provider = new GoogleAuthProvider();
    const auth = getFirebaseAuth();
    signInWithRedirect(auth, provider);
};

export const getFirebaseStorage = () => {
    const app = getFirebaseCore();
    const storage = getStorage(app);
    return storage;
};

export const getFirebaseRealtimeDatabase = () => {
    const app = getFirebaseCore();
    const database = getDatabase(app);
    return database;
};

export const getFirebaseFirestore = () => {
    const app = getFirebaseCore();
    const firestore = getFirestore(app);
    return firestore;
};

export const getFirebaseCloudMessaging = () => {
    const app = getFirebaseCore();
    const messaging = getMessaging(app);
    return {
        messaging,
        getFcmToken: async () => {
            return getToken(messaging, {
                vapidKey: getEnvVariables().firebase.core.messagingVapidKey,
            }).catch(() => {
                throw new Error('getFcmToken: failed!');
            });
        },
    };
};
