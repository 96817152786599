import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import { useErrorCtx, useUserCtx, useHealthCtx, useSpacesCtx, useSpaceCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { FetchingText } from '@/components/0_atom/FetchingText';
import { Window } from '@/components/0_atom/Window';
import { IconButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';
import { AddSpaceModal } from '@/components/3_template/ConsoleTemplate/AddSpaceModal';

import SvgBrand from '@/svgs/brandIcon';

const useStyles = makeStyles((theme) => ({
    brand: {
        height: 10,
        color: theme.palette.primary.main,
    },
}));

export const PowerButton: FC<{
    onClose?: (mouseEvent?: { clientX: number }) => void;
    startExpand: () => void;
}> = ({ onClose, startExpand }) => {
    // STYLE
    const c = useStyles(useTheme());

    // CTXS
    const { current } = useSpaceCtx();
    const spacesCtx = useSpacesCtx();

    // HOOKS
    const { txt } = useLanguage();
    const [isOpenAddSpace, setIsOpenAddSpace] = useState(false);

    return (
        <>
            <IconButtonWithTextMenu
                onClose={(e) => {
                    !!onClose && onClose(typeof e?.clientX === 'number' ? { clientX: e.clientX } : undefined);
                }}
                items={[
                    {
                        text: txt({
                            en: 'User settings',
                            ja: 'ユーザー設定',
                        }),
                        isBottomBorder: true,
                    },
                    {
                        text: txt({
                            en: 'Create a new space',
                            ja: '新しいスペースを作成',
                        }),
                        onClick: () => setIsOpenAddSpace(true),
                    },
                    {
                        text: txt({
                            en: 'Spaces',
                            ja: 'スペース一覧',
                        }),
                        onClick: () => {
                            if (spacesCtx && current) {
                                spacesCtx.setSpaceId(undefined);
                                startExpand();
                            }
                        },
                        isDisabled: !spacesCtx || !current,
                    },
                ]}
            >
                <SvgBrand className={c.brand} />
            </IconButtonWithTextMenu>
            {/* <Menu anchorEl={} open={isOpen} /> */}
            <AddSpaceModal isOpen={isOpenAddSpace} onClose={() => setIsOpenAddSpace(false)} />
            {/* <Window title={'Test'} windowKey={'test'}>
                {'test'}
            </Window> */}
        </>
    );
};
PowerButton.displayName = 'PowerButton';
