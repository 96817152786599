import { createTheme } from '@material-ui/core/styles';

export const muiTheme = createTheme({
    palette: {
        primary: {
            main: '#FF3364',
        },
        secondary: {
            main: '#F746FB',
        },
        error: {
            main: '#FF4747',
        },
        warning: {
            main: '#FFD338',
        },
        info: {
            main: '#64b5f6',
        },
        success: {
            main: '#60D34E',
        },
        background: {
            default: '#29292B',
            paper: 'rgba(0,0,0,.23)',
        },
        text: {
            primary: 'rgba(255, 255, 255, .85)',
            secondary: 'rgba(255, 255, 255, .75)',
            disabled: 'rgba(255, 255, 255, .40)',
            // paper
        },
        divider: 'rgba(255, 255, 255, 0.06)',
        // action: {
        //     active: '#000',
        // },
    },
    typography: {
        fontFamily: [
            'Poppins',
            'Montserrat Alternates',
            'Montserrat',
            'Avenir Next',
            'Avenir',
            'メイリオ',
            'Meiryo',
            'Hiragino Kaku Gothic Pro',
            'Hiragino Kaku Gothic ProN',
            'Yu Gothic',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: 23,
            fontWeight: 600,
        },
        h2: {
            fontSize: 15,
            fontWeight: 600,
        },
        h3: {
            // fontSize: '2rem',
            // fontWeight: 600,
        },
        h4: {
            // fontSize: '0.9rem',
            // fontWeight: 600,
        },
        h5: {
            // fontSize: '0.9rem',
            // fontWeight: 600,
        },
        h6: {
            // fontSize: '0.9rem',
            // fontWeight: 600,
        },
        subtitle1: {
            fontWeight: 600,
        },
        button: {
            textTransform: 'none',
            fontSize: 12,
            lineHeight: '1',
        },
        body1: {
            fontSize: 12,
            lineHeight: '1.5',
        },
        body2: {
            fontSize: 10,
            lineHeight: '1.5',
        },
        caption: {
            fontSize: 8,
        },
    },
    spacing: 15,
    overrides: {
        // MuiCardContent: {
        //     root: {
        //         padding: '2.1rem 2rem',
        //         '&:last-child': {
        //             paddingBottom: '2.1rem',
        //         },
        //         '&:not(:first-child)': {
        //             paddingTop: 0,
        //         },
        //     },
        // },
        MuiCssBaseline: {
            '@global': {
                // html: {
                //     boxSizing: 'border-box',
                // },
                // '*': {
                //     boxSizing: 'border-box',
                // },
                input: {
                    boxSizing: 'border-box',
                    WebkitAppearance: 'none',
                    // '&::placeholder': {
                    //     WebkitAppearance: 'none',
                    //     display: 'none',
                    // },
                },
                body: {
                    fontSize: 12,
                    scrollBehavior: 'smooth',
                    '& a': {
                        color: 'inherit',
                        textDecoration: 'none',
                    },
                },
                hr: {
                    margin: 0,
                    border: 'none',
                },
            },
        },
        // MuiPaper: {
        //     elevation0: {
        //         boxShadow: 'none',
        //     },
        //     elevation1: {
        //         boxShadow: '0 0px 4px 0 rgba(0, 0, 0, .02)',
        //     },
        //     elevation2: {
        //         boxShadow: '0 0px 4px 0 rgba(0, 0, 0, .1)',
        //     },
        //     elevation8: {
        //         boxShadow: '0 24px 32px 0 rgba(0, 0, 0, .1)',
        //     },
        //     elevation16: {
        //         boxShadow: '0 64px 64px 0 rgba(0, 0, 0, .2)',
        //     },
        //     rounded: {
        //         borderRadius: 2,
        //     },
        // },
        MuiButton: {
            root: {
                justifyContent: 'flex-start',
                '&:hover': {
                    backgroundColor: 'rgba(0,0,0,.18)',
                },
                '&.Mui-disabled': {
                    color: 'rgba(255,255,255,.4)',
                },
            },
            // root: {
            //     padding: '6px 12px',
            //     color: 'inherit',
            //     fontSize: 15,
            //     '&:hover': {
            //         backgroundColor: 'rgba(0,0,0,.2)',
            //     },
            //     borderRadius: 2,
            // },
            // text: {
            //     padding: undefined,
            //     color: 'inherit',
            // },
            // contained: {
            //     color: 'inherit',
            //     backgroundColor: '#fff',
            //     boxShadow: 'none',
            //     '&:hover': {
            //         boxShadow: 'none',
            //         backgroundColor: '#f3f3f3',
            //     },
            // },
            // sizeSmall: {
            //     padding: '6px 12px',
            //     fontSize: 10,
            //     minWidth: 80,
            // },
            // startIcon: {
            //     display: 'flex',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            //     marginLeft: 0,
            //     '& svg': {
            //         height: '100%',
            //     },
            // },
            // endIcon: {
            //     display: 'flex',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            //     marginLeft: 0,
            // },
        },
        MuiIconButton: {
            root: {
                color: 'inherit',
                '&:hover': {
                    backgroundColor: 'rgba(255,255,255,.09)',
                },
                '&.Mui-disabled': {
                    color: 'rgba(255,255,255,.4)',
                },
            },
        },
        // MuiAccordion: {
        //     root: {
        //         padding: undefined,
        //         boxShadow: 'none',
        //         backgroundColor: 'inherit',
        //         '&$expanded': {
        //             margin: undefined,
        //         },
        //         '&::before': {
        //             content: 'none',
        //         },
        //     },
        // },
        // MuiAccordionSummary: {
        //     root: {
        //         padding: undefined,
        //         minHeight: undefined,
        //         '&$expanded': {
        //             minHeight: undefined,
        //         },
        //     },
        //     content: {
        //         margin: undefined,
        //         '&$expanded': {
        //             margin: undefined,
        //         },
        //     },
        // },
        // MuiAccordionDetails: {
        //     root: {
        //         padding: undefined,
        //         display: undefined,
        //     },
        // },
        MuiList: {
            root: {
                padding: 0,
            },
        },
        // MuiCardActionArea: {
        //     root: {
        //         '&:hover': {
        //             backgroundColor: '#fff !important',
        //         },
        //     },
        // },
        // MuiInputBase: {
        //     input: {
        //         minWidth: 200,
        //     },
        // },
    },
});
