import { forwardRef, ComponentProps, useState, MouseEvent, MouseEventHandler } from 'react';

import MuiIconButton from '@material-ui/core/IconButton';

import { TextMenu, TextMenuItemData } from '@/components/0_atom/Menu';

export const IconButtonWithTextMenu = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof MuiIconButton> & {
        items: TextMenuItemData[];
        menuClassName?: string;
        onClose?: (mouseEvent?: MouseEvent) => void;
    }
>(({ items, onClick, menuClassName, onClose, ...otherProps }, ref) => {
    // HOOKS
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(undefined);

    return (
        <>
            <MuiIconButton
                ref={ref}
                {...otherProps}
                onClick={(e) => {
                    if (otherProps.disabled) {
                        return;
                    }
                    setAnchorEl(e.currentTarget);
                    !!onClick && onClick(e);
                }}
            />
            <TextMenu
                anchorEl={anchorEl}
                isOpen={!!anchorEl}
                onClose={(e, reason) => {
                    setAnchorEl(undefined);
                    if (onClose) {
                        onClose(reason === 'backdropClick' ? e : undefined);
                    }
                }}
                items={items}
                className={menuClassName}
            />
        </>
    );
});
IconButtonWithTextMenu.displayName = 'IconButtonWithTextMenu';
