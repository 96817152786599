import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 365 421" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M364.663 182.224C364.663 282.863 283.079 364.448 182.439 364.448C138.713 364.448 98.5839 349.046 67.1806 323.373C68.5917 371.423 70.8561 421 70.8561 421H4.87854C4.87854 421 0.943904 334.857 0.215659 279.619C-0.269574 242.814 0.215659 185.366 0.215659 185.366H0.241985C0.224317 184.32 0.215448 183.273 0.215448 182.224C0.215448 81.5844 81.7998 0 182.439 0C283.079 0 364.663 81.5844 364.663 182.224ZM66.1931 185.389C66.1932 185.373 66.1933 185.366 66.1933 185.366H66.1931C66.1932 122.9 116.832 72.2614 179.298 72.2614C241.763 72.2614 292.402 122.9 292.402 185.366C292.402 247.832 241.763 298.47 179.298 298.47C116.839 298.47 66.2055 247.844 66.1931 185.389Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/brandIcon';
export default Svg;
