import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { PowerchatUserClient } from '@/utils/PowerchatClient/clients/User';
import { getUserFromGql } from '@/utils/PowerchatClient/models';
import { Language } from '@/utils/customHooks';

type PowerchatFirebaseUserClientType = {
    getIsUserUniqueNameAvailable: (input: { uniqueName: string }) => Promise<{
        isAvailable: boolean;
    }>;
    createUser: (input: { uniqueName: string; displayName: string | undefined }) => Promise<{
        userClient: PowerchatUserClient;
    }>;
};

type ConstructorInput = {
    graphqlSdk: GraphQLSdk;
    firebaseUid: string;
    currentFcmToken: string;
    language: Language | undefined;
};

export class PowerchatFirebaseUserClient implements PowerchatFirebaseUserClientType {
    protected _graphqlSdk: GraphQLSdk;

    readonly firebaseUid: string;

    readonly currentFcmToken: string;

    language: Language | undefined;

    constructor({ graphqlSdk, firebaseUid, currentFcmToken, language }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
        this.firebaseUid = firebaseUid;
        this.currentFcmToken = currentFcmToken;
        this.language = language;
    }

    async getIsUserUniqueNameAvailable({ uniqueName }: { uniqueName: string }) {
        const {
            getIsUserUniqueNameAvailable: { isAvailable },
        } = await this._graphqlSdk.getIsUserUniqueNameAvailable({
            input: {
                userUniqueName: uniqueName,
            },
        });
        return {
            isAvailable,
        };
    }

    async createUser({ uniqueName, displayName }: { uniqueName: string; displayName: string | undefined }) {
        const {
            createUser: { user },
        } = await this._graphqlSdk.createUser({
            input: {
                uniqueName,
                displayName,
            },
        });
        return {
            userClient: new PowerchatUserClient({
                graphqlSdk: this._graphqlSdk,
                ...getUserFromGql(user),
                currentFcmToken: this.currentFcmToken,
                language: this.language,
            }),
        };
    }
}
