import { FC, ReactNode, MouseEvent } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 0,
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        lineHeight: '1',
        padding: '7px 11px',
        '&:first-child': {
            paddingTop: 11,
        },
        '&:last-child': {
            paddingBottom: 11,
        },
        '& .MuiButton-startIcon, .MuiButton-endIcon': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 11,
        },
    },
    isWithStartIcon: {},
    isWithEndIcon: {},
    alignRight: {
        justifyContent: 'flex-end',
    },
    withBorderBox: {
        '& > button': {
            paddingBottom: 10,
        },
        '& + button': {
            paddingTop: 10,
        },
    },
    hr: {
        background: 'rgba(255,255,255,.06)',
        height: 1,
        // width: '100%',
        margin: '0 11px',
        borderRadius: 0.5,
    },
}));

export type TextMenuItemData = {
    text: string;
    className?: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    color?: string;
    onClick?: () => void;
    isDisabled?: boolean;
    isBottomBorder?: boolean;
    isIgnore?: boolean;
    isKeepOpen?: boolean;
};

export const StaticTextMenu: FC<{
    className?: string;
    items: (TextMenuItemData | undefined)[];
    onClose?: (mouseEvent: MouseEvent | undefined, reason: 'backdropClick' | 'escapeKeyDown' | 'itemClick') => void;
    align?: 'left' | 'right';
}> = ({ className, items, onClose, align }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const isWithStartIcon = !!items.find((item) => item?.startIcon);
    const isWithEndIcon = !!items.find((item) => item?.endIcon);

    return (
        <div className={className}>
            {items.map((item) => {
                if (!item || item.isIgnore) {
                    return <></>;
                }
                const { text, className, startIcon, endIcon, color, onClick, isDisabled, isBottomBorder, isKeepOpen } =
                    item;
                return isBottomBorder ? (
                    <div key={text} className={c.withBorderBox}>
                        <MuiButton
                            style={{ color }}
                            onClick={() => {
                                !!onClick && onClick();
                                onClose && !isKeepOpen && onClose(undefined, 'itemClick');
                            }}
                            className={clsx(
                                c.button,
                                {
                                    [c.isWithStartIcon]: isWithStartIcon,
                                    [c.isWithEndIcon]: isWithEndIcon,
                                    [c.alignRight]: align === 'right',
                                },
                                className
                            )}
                            startIcon={startIcon}
                            endIcon={endIcon}
                            disabled={isDisabled}
                        >
                            {text}
                        </MuiButton>
                        <hr className={c.hr} />
                    </div>
                ) : (
                    <MuiButton
                        key={text}
                        style={{ color }}
                        onClick={() => {
                            !!onClick && onClick();
                            onClose && onClose(undefined, 'backdropClick');
                        }}
                        className={clsx(
                            c.button,
                            {
                                [c.isWithStartIcon]: isWithStartIcon,
                                [c.isWithEndIcon]: isWithEndIcon,
                                [c.alignRight]: align === 'right',
                            },
                            className
                        )}
                        startIcon={startIcon}
                        endIcon={endIcon}
                        disabled={isDisabled}
                    >
                        {text}
                    </MuiButton>
                );
            })}
        </div>
    );
};
StaticTextMenu.displayName = 'StaticTextMenu';
