import { useEffect, useState } from 'react';

import { useSpaceCtx, LastCheckinLog } from '@/utils/ctxs';
import { useSpaceMembers } from '@/utils/customHooks/useSpaceMembers';

// later: checkinは分ける（useHotMembersCtxを利用する）

export const useSpaceMembershipsAndLastCheckinLog = () => {
    // HOOKS
    const { current: currentSpaceCtx } = useSpaceCtx();
    const {
        userIds,
        getSpaceMember,
        getSpaceMemberAbsolutely,
        addSpaceMember,
        updateSpaceMembershipApi,
        // updateSpaceMemberRdbUser,
        removeSpaceMember,
    } = useSpaceMembers({ spaceApi: currentSpaceCtx?.spaceApi });
    const [lastCheckinLog, setLastCheckinLog] = useState<LastCheckinLog | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        if (userIds && currentSpaceCtx) {
            const { spaceApi } = currentSpaceCtx;
            const { unsubscribeListenToSpaceMemberships } = spaceApi.listenToSpaceMemberships({
                onAdded: ({ newSpaceMembershipApi }) => {
                    addSpaceMember(newSpaceMembershipApi);
                },
                onModified: ({ modifiedSpaceMembershipApi }) => {
                    updateSpaceMembershipApi(modifiedSpaceMembershipApi);
                },
                onRemoved: ({ removedSpaceMembershipApi }) => {
                    removeSpaceMember(removedSpaceMembershipApi.userId);
                },
            });
            return unsubscribeListenToSpaceMemberships;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIds, currentSpaceCtx?.spaceApi.id]);

    // useEffect(() => {
    //     if (userIds !== undefined && currentSpaceCtx) {
    //         const { spaceApi } = currentSpaceCtx;
    //         const { unsubscribeListenToHotUsersForSpace } = spaceApi.listenToHotUsersForSpace({
    //             onAdded: ({ newUser }) => {
    //                 setLastCheckinLog({
    //                     action: 'add',
    //                     at: new Date(),
    //                     userId: newUser.id,
    //                     spaceId: spaceApi.id,
    //                 });
    //             },
    //             onChanged: ({ changedUser }) => {
    //                 updateSpaceMemberRdbUser(changedUser);
    //             },
    //             onRemoved: ({ removedUser }) => {
    //                 setLastCheckinLog({
    //                     action: 'left',
    //                     at: new Date(),
    //                     userId: removedUser.id,
    //                     spaceId: spaceApi.id,
    //                 });
    //             },
    //         });
    //         return unsubscribeListenToHotUsersForSpace;
    //     }
    //     return undefined;
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [userIds, currentSpaceCtx?.spaceApi.id]);

    return {
        userIds,
        getSpaceMember,
        getSpaceMemberAbsolutely,
        lastCheckinLog,
    };
};
