import { serverTimestamp } from 'firebase/database';

// EDGE
export type MentionToUser = {
    id: string;
    toUserUniqueName: string;
    toUserDisplayName: string | undefined;
};
export type ReplyToUser = {
    id: string;
    toUserUniqueName: string;
    toUserDisplayName: string | undefined;
};
export type RdbLastUnconfirmedMarkInNewMessage = {
    id: string;
    toUserId: string;
};
export type RdbLastReplyObserverInNewMessage = {
    id: string;
    toUserId: string;
};

// NOTIFICATION
export type RdbLastMessageAddNotification = {
    replyToUser: ReplyToUser | undefined;
    mentions: MentionToUser[] | undefined;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    spaceName: string;
    chatboardUniqueName: string | undefined;
    unconfirmedMarks: RdbLastUnconfirmedMarkInNewMessage[] | undefined;
    replyObservers: RdbLastReplyObserverInNewMessage[] | undefined;
};
export type RdbLastMessageModifyNotification = {
    chatboardUniqueName: string | undefined;
};

// TYPE
export type RdbLastMessage = {
    action: 'add' | 'modify';
    id: string;
    createdAt: number;
    updatedAt: number | undefined;
    userId: string;
    chatboardId: string | undefined;
    body: string;
    canceledAt: number | undefined;
    replyToMessageId: string | undefined;
    mentionToUserIds: string[] | undefined;
    // for notification
    addNotification: RdbLastMessageAddNotification | undefined;
    modifyNotification: RdbLastMessageModifyNotification | undefined;
};

// INPUT
export type RdbLastMessageInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    chatboardId: string | undefined;
    body: string;
    canceledAt: Date | 'serverTimestamp' | undefined;
    replyToMessageId: string | undefined;
    mentionToUserIds: string[] | undefined;
    // for notification
    addNotification: RdbLastMessageAddNotification | undefined;
    modifyNotification: RdbLastMessageModifyNotification | undefined;
};
export type AddRdbLastMessageInput = {
    userId: string;
    chatboardId: string | undefined;
    body: string;
    replyToMessageId: string | undefined;
    // for notification
    addNotification: RdbLastMessageAddNotification;
};
export type ModifyRdbLastMessageInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    userId: string;
    chatboardId: string | undefined;
    body: string;
    canceledAt: Date | 'serverTimestamp' | undefined;
    replyToMessageId: string | undefined;
    mentionToUserIds: string[] | undefined;
    // for notification
    modifyNotification: RdbLastMessageModifyNotification;
};

export const getRdbLastMessageInput = ({
    id,
    createdAt,
    updatedAt,
    userId,
    chatboardId,
    body,
    canceledAt,
    replyToMessageId,
    mentionToUserIds,
    addNotification,
    modifyNotification,
}: RdbLastMessageInput): {
    id: string;
    createdAt: number | ReturnType<typeof serverTimestamp>;
    updatedAt: number | ReturnType<typeof serverTimestamp> | null;
    userId: string;
    chatboardId: string | null;
    body: string;
    canceledAt: number | ReturnType<typeof serverTimestamp> | null;
    replyToMessageId: string | null;
    mentionToUserIds: string[] | null;
    // for notification
    addNotification: {
        replyToUser: {
            id: string;
            toUserUniqueName: string;
            toUserDisplayName: string | null;
        } | null;
        mentions:
            | {
                  id: string;
                  toUserUniqueName: string;
                  toUserDisplayName: string | null;
              }[]
            | null;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        spaceName: string;
        chatboardUniqueName: string | null;
        unconfirmedMarks: RdbLastUnconfirmedMarkInNewMessage[] | null;
        replyObservers: RdbLastReplyObserverInNewMessage[] | null;
    } | null;
    modifyNotification: {
        chatboardUniqueName: string | null;
    } | null;
} => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        updatedAt: updatedAt === 'serverTimestamp' ? serverTimestamp() : updatedAt ? updatedAt.getTime() : null,
        userId,
        chatboardId: chatboardId || null,
        body,
        canceledAt: canceledAt === 'serverTimestamp' ? serverTimestamp() : canceledAt ? canceledAt.getTime() : null,
        replyToMessageId: replyToMessageId || null,
        mentionToUserIds: mentionToUserIds || null,
        addNotification: (() => {
            if (addNotification) {
                const {
                    mentions,
                    replyToUser,
                    spaceName,
                    fromUserUniqueName,
                    fromUserDisplayName,
                    chatboardUniqueName,
                    unconfirmedMarks,
                    replyObservers,
                } = addNotification;
                return {
                    mentions:
                        mentions?.map(({ id, toUserUniqueName, toUserDisplayName }) => ({
                            id,
                            toUserUniqueName,
                            toUserDisplayName: toUserDisplayName || null,
                        })) || null,
                    replyToUser: (() => {
                        if (replyToUser) {
                            return {
                                id: replyToUser.id,
                                toUserUniqueName: replyToUser.toUserUniqueName,
                                toUserDisplayName: replyToUser.toUserDisplayName || null,
                            };
                        }
                        return null;
                    })(),
                    spaceName,
                    fromUserUniqueName,
                    fromUserDisplayName: fromUserDisplayName || null,
                    chatboardUniqueName: chatboardUniqueName || null,
                    unconfirmedMarks: unconfirmedMarks?.map(({ id, toUserId }) => ({ id, toUserId })) || null,
                    replyObservers: replyObservers?.map(({ id, toUserId }) => ({ id, toUserId })) || null,
                };
            }
            return null;
        })(),
        modifyNotification: (() => {
            if (modifyNotification) {
                const { chatboardUniqueName } = modifyNotification;
                return {
                    chatboardUniqueName: chatboardUniqueName || null,
                };
            }
            return null;
        })(),
    };
};
