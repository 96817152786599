import { FC, useState, useCallback, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SvgPlus from '@/svgs/fa-plus-solid';

import { User, SpaceMembership, SpaceMembershipApi, SpacePermissionType } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, ItemType } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { Button } from '@/components/0_atom/Button';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { InlineInputAndButton } from '@/components/1_mol/InlineInputAndButton';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';
import { FindUserInput } from '@/components/2_org/FindUserInput';
import { ConfigBlock } from '@/components/2_org/ConfigBlock';

const useStyles = makeStyles((theme) => ({
    root: {},
    addMember: {
        marginTop: 30,
    },
    user: {},
    userIcon: {
        width: 38,
        height: 38,
        marginRight: 10,
    },
    li: {
        paddingBottom: 0,
    },
    removeUserButton: {
        color: theme.palette.error.main,
        '& svg': {
            height: 10,
            width: 10,
            transform: 'rotate(45deg)',
        },
    },
}));

export const AddSpaceModalStep2: FC<{
    userIds: string[];
    getMemberAbsolutely: (id: string) => ItemType<{
        id: string;
        userUniqueName: string;
        userUniqueCode: string;
        membershipUniqueName: string | undefined;
        membershipDisplayName: string | undefined;
        permission: SpacePermissionType;
    }>;
    addMember: ({
        newItem,
        isIgnoreDuplicationError,
        isAddToTop,
    }: {
        newItem: ItemType<{
            id: string;
            userUniqueName: string;
            userUniqueCode: string;
            membershipUniqueName: string | undefined;
            membershipDisplayName: string | undefined;
            permission: SpacePermissionType;
        }>;
        isIgnoreDuplicationError?: boolean | undefined;
        isAddToTop?: boolean | undefined;
    }) => void;
    updateMember: ({
        id,
        updator,
        isIgnoreMissingError,
    }: {
        id: string;
        updator: (
            prevItem: ItemType<{
                id: string;
                userUniqueName: string;
                userUniqueCode: string;
                membershipUniqueName: string | undefined;
                membershipDisplayName: string | undefined;
                permission: SpacePermissionType;
            }>
        ) => ItemType<{
            id: string;
            userUniqueName: string;
            userUniqueCode: string;
            membershipUniqueName: string | undefined;
            membershipDisplayName: string | undefined;
            permission: SpacePermissionType;
        }>;
        isIgnoreMissingError?: boolean | undefined;
    }) => void;
    removeMember: ({ id, isIgnoreMissingError }: { id: string; isIgnoreMissingError?: boolean | undefined }) => void;
}> = ({ userIds, getMemberAbsolutely, addMember, updateMember, removeMember }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { txt } = useLanguage();

    return (
        <>
            <ConfigBlock
                title={txt({
                    en: 'Space members to be added',
                    ja: '追加するスペースメンバー',
                })}
                items={[
                    {
                        label: (
                            <UserIconWithName
                                userName={userClient.uniqueName}
                                className={c.user}
                                iconClassName={c.userIcon}
                            />
                        ),
                    },
                    ...userIds.map((userId) => {
                        const member = getMemberAbsolutely(userId);
                        // later: 権限設定
                        return {
                            label: (
                                <UserIconWithName
                                    userName={member.membershipUniqueName || member.userUniqueName}
                                    className={c.user}
                                    iconClassName={c.userIcon}
                                />
                            ),
                            value: (
                                <MuiIconButton
                                    onClick={() => removeMember({ id: userId })}
                                    className={c.removeUserButton}
                                >
                                    <SvgPlus />
                                </MuiIconButton>
                            ),
                        };
                    }),
                ]}
            />
            {/* <MuiList disablePadding>
                <MuiListItem disableGutters className={c.li}>
                    <UserIconWithName userName={userClient.uniqueName} className={c.user} iconClassName={c.userIcon} />
                </MuiListItem>
                {userIds.map((userId) => {
                    const member = getMemberAbsolutely(userId);
                    return (
                        <MuiListItem key={userId} disableGutters className={c.li}>
                            <UserIconWithName
                                userName={member.membershipUniqueName || member.userUniqueName}
                                className={c.user}
                                iconClassName={c.userIcon}
                            />
                            <MuiListItemSecondaryAction>
                                <MuiIconButton onClick={() => removeMember({ id: userId })}>
                                    <SvgPlus />
                                </MuiIconButton>
                            </MuiListItemSecondaryAction>
                        </MuiListItem>
                    );
                })}
            </MuiList> */}
            <FindUserInput
                className={c.addMember}
                title={undefined}
                setFetchedUser={(fetchedUser) => {
                    if (fetchedUser) {
                        addMember({
                            newItem: {
                                id: fetchedUser.id,
                                userUniqueName: fetchedUser.uniqueName,
                                userUniqueCode: fetchedUser.uniqueCode,
                                membershipUniqueName: fetchedUser.uniqueName,
                                membershipDisplayName: fetchedUser.displayName,
                                permission: {
                                    admin: false,
                                    lobby: {
                                        read: true,
                                        write: true,
                                    },
                                },
                            },
                        });
                    }
                }}
                buttonText={txt({
                    en: 'Add',
                    ja: '追加',
                })}
                onFetched={({ setUserCode, fetchedUser }) => fetchedUser && setUserCode(undefined)}
                excludingUsers={[
                    {
                        userCodes: userIds.map((userId) => getMemberAbsolutely(userId).userUniqueCode),
                        errorMessage: txt({
                            en: 'Already been added.',
                            ja: 'そのユーザーは追加済みです',
                        }),
                    },
                ]}
            />
        </>
    );
};
AddSpaceModalStep2.displayName = 'SpacesTemplate/AddSpaceModal/Step2';
