import { serverTimestamp } from 'firebase/database';
import { Language } from '@/utils/customHooks/useLanguage';

// EDGE
export type CurrentChatboard = {
    uniqueName: string;
};
export type CurrentSpace = {
    id: string;
    name: string;
    membershipUniqueName: string;
    membershipDisplayName: string | undefined;
    currentChatboards: {
        [chatboardId: string]: CurrentChatboard;
    };
};

// TYPE
export type RdbUser = {
    id: string;
    updatedAt: number;
    isOnline: boolean;
    uniqueName: string;
    displayName: string | undefined;
    currentConfigSpaceId: string | undefined;
    currentSpace: CurrentSpace | undefined;
    language: Language | undefined;
};

// INPUT
export type RdbUserInput = {
    updatedAt: Date | 'serverTimestamp';
    isOnline: boolean;
    uniqueName: string;
    displayName: string | undefined;
    currentConfigSpaceId: string | undefined;
    currentSpace: CurrentSpace | undefined;
    language: Language | undefined;
    currentFcmToken: string | undefined;
};

export const getRdbUserInput = ({
    updatedAt,
    isOnline,
    uniqueName,
    displayName,
    currentConfigSpaceId,
    currentSpace,
    language,
    currentFcmToken,
}: RdbUserInput): {
    updatedAt: number | ReturnType<typeof serverTimestamp>;
    isOnline: boolean;
    uniqueName: string;
    displayName: string | null;
    currentConfigSpaceId: string | null;
    currentSpace: {
        id: string;
        name: string;
        membershipUniqueName: string;
        membershipDisplayName: string | null;
        currentChatboards: {
            [key: string]: CurrentChatboard;
        };
    } | null;
    language: Language | null;
    currentFcmToken: string | null;
} => {
    return {
        updatedAt: updatedAt === 'serverTimestamp' ? serverTimestamp() : updatedAt.getTime(),
        isOnline,
        uniqueName,
        displayName: displayName || null,
        currentConfigSpaceId: currentConfigSpaceId || null,
        currentSpace: (() => {
            if (currentSpace) {
                return {
                    id: currentSpace.id,
                    name: currentSpace.name,
                    membershipUniqueName: currentSpace.membershipUniqueName,
                    membershipDisplayName: currentSpace.membershipDisplayName || null,
                    currentChatboards: currentSpace.currentChatboards,
                };
            }
            return null;
        })(),
        language: language || null,
        currentFcmToken: currentFcmToken || null,
    };
};
