import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { Chatboard } from '@/utils/PowerchatClient/models';
import { getRdbLastChatboardInput, AddRdbLastChatboardInput } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbChatboard = ({
    spaceId,
    newChatboard,
}: {
    spaceId: string;
    newChatboard: AddRdbLastChatboardInput;
}) => {
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastChatboardRef = ref(realtimeDb, `lastChatboards/${spaceId}`);
    set(lastChatboardRef, {
        action: 'add',
        ...getRdbLastChatboardInput({
            ...newChatboard,
            createdAt: 'serverTimestamp',
            updatedAt: undefined,
            closedAt: undefined,
            modifyNotification: undefined,
        }),
    });
    return {
        newChatboard: new Chatboard({
            id: newChatboard.id,
            createdAt: new Date(),
            updatedAt: undefined,
            serialNumber: undefined,
            spaceId,
            uniqueName: newChatboard.uniqueName,
            closedAt: undefined,
        }),
    };
};
