import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { ReplyObserver, ReplyObserverType } from '@/utils/PowerchatClient/models/ReplyObserver/data/ReplyObserver';
import { removeRdbReplyObserver } from '@/utils/PowerchatClient/RealtimeDatabase';

type ReplyObserverApiType = {
    // UPDATE SELF
    remove: () => Promise<void>;
};

type ConstructorInput = ReplyObserverType & {
    graphqlSdk: GraphQLSdk;
};

export class ReplyObserverApi extends ReplyObserver implements ReplyObserverApiType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    // UPDATE SELF
    async remove() {
        removeRdbReplyObserver({
            spaceId: this.spaceId,
            replyObserver: this,
        });
        if (this.chatboardId) {
            await this._graphqlSdk.removeChatboardReplyObserver({
                input: {
                    spaceId: this.spaceId,
                    chatboardId: this.chatboardId,
                    replyObserverId: this.id,
                },
            });
        } else {
            await this._graphqlSdk.removeLobbyReplyObserver({
                input: {
                    spaceId: this.spaceId,
                    replyObserverId: this.id,
                },
            });
        }
    }
}
