import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 551 428" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.0388 5.63112C30.5642 -3.0017 14.2634 -0.697985 5.63063 10.7766C-3.00218 22.2512 -0.698473 38.5515 10.7761 47.1843L509.164 422.142C520.639 430.775 536.939 428.471 545.572 416.997C554.205 405.522 551.901 389.222 540.427 380.589L404.395 278.247C413.748 258.86 419 237.054 419 214C419 133.366 354.753 67.9998 275.5 67.9998C237.553 67.9998 203.046 82.9851 177.385 107.457L42.0388 5.63112ZM132 214C132 207.514 132.415 201.127 133.221 194.865L335.185 346.811C317.01 355.28 296.797 360 275.5 360C196.247 360 132 294.634 132 214Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/ellipsis';
export default Svg;
