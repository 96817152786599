import { ref, set, onDisconnect as firebaseOnDisconnect, serverTimestamp, onValue, update } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { getRdbUserInput, CurrentSpace } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';
import { Language } from '@/utils/customHooks/useLanguage';

export const startConnectingRealtimeDatabase = ({
    userId,
    uniqueName,
    displayName,
    initialSpace,
    initialConfigSpaceId,
    currentFcmToken,
    language,
    onChanged,
}: // onModified,
{
    userId: string;
    uniqueName: string;
    displayName: string | undefined;
    initialSpace: CurrentSpace | undefined;
    initialConfigSpaceId: string | undefined;
    currentFcmToken: string;
    language: Language | undefined;
    // onModified: (input: { modifiedUser: RdbUser }) => void;
    onChanged: (input: { isOnline: boolean }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const userRef = ref(db, `users/${userId}`);

    set(
        userRef,
        getRdbUserInput({
            updatedAt: 'serverTimestamp',
            isOnline: true,
            currentFcmToken,
            currentSpace: initialSpace,
            currentConfigSpaceId: initialConfigSpaceId,
            uniqueName,
            displayName,
            language,
        })
    );

    firebaseOnDisconnect(userRef).set(
        getRdbUserInput({
            updatedAt: 'serverTimestamp',
            isOnline: false,
            currentFcmToken: undefined,
            currentSpace: undefined,
            currentConfigSpaceId: undefined,
            uniqueName,
            displayName,
            language: undefined,
        })
    );

    let isInitialized = false;
    let localIsOnline = true;
    // const startGoOnline = () => {
    //     console.log('startGoOnline fire', { localIsOnline });
    //     if (localIsOnline) {
    //         return;
    //     }
    //     try {
    //         goOffline(db);
    //         goOnline(db);
    //     } catch (e) {
    //         console.error({ e });
    //         const timer = setInterval(() => {
    //             if (localIsOnline) {
    //                 clearInterval(timer);
    //                 return;
    //             }
    //             try {
    //                 goOffline(db);
    //                 goOnline(db);
    //             } catch (e) {
    //                 console.error({ e });
    //             }
    //         }, 1000);
    //     }
    // };
    // const userIsOnlineRef = ref(db, `users/${firebaseUid}/isOnline`);
    const connectedRef = ref(db, '.info/connected');
    onValue(connectedRef, (snapshot) => {
        const isConnected: boolean = snapshot.val();
        console.log({ isConnected });
        if (isConnected && !localIsOnline) {
            update(userRef, {
                updatedAt: serverTimestamp(),
                isOnline: isConnected,
                // currentFcmToken,
                // currentChatboardUniqueName: initialChatboardUniqueName || null,
                // currentChatboardName: initialChatboardName || null,
                // userId,
                // userUniqueName,
            });
        }
        if (isConnected !== localIsOnline) {
            localIsOnline = isConnected;
            if (isInitialized) {
                onChanged({ isOnline: isConnected });
            } else if (isConnected) {
                isInitialized = true;
                onChanged({ isOnline: true });
            }
        }
    });
    // onValue(userRef, (snapshot) => {
    //     const { isOnline }: { isOnline: boolean } = snapshot.val();
    //     console.log({ isOnline });
    //     if (isOnline !== localIsOnline) {
    //         localIsOnline = isOnline;
    //         update(userRef, {
    //             updatedAt: serverTimestamp(),
    //             isOnline: true,
    //             currentFcmToken,
    //             // currentChatboardUniqueName: initialChatboardUniqueName || null,
    //             // currentChatboardName: initialChatboardName || null,
    //             userId,
    //             userUniqueName,
    //         });
    //         onChanged({ isOnline });
    //     }
    //     // if (!isOnline) {
    //     //     startGoOnline();
    //     // }
    // });
    // return {
    //     isOnline: localIsOnline,
    // };
};
