import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { UnconfirmedMark } from '@/utils/PowerchatClient/models';
import {
    RdbLastUnconfirmedMark,
    RdbLastUnconfirmedMarkAddNotification,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToUnconfirmedMarks = ({
    spaceId,
    onAdded,
    onRemoved,
}: {
    spaceId: string;
    onAdded: (input: {
        newUnconfirmedMark: UnconfirmedMark;
        addNotification: RdbLastUnconfirmedMarkAddNotification;
    }) => void;
    onRemoved: (input: { removedUnconfirmedMark: UnconfirmedMark }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastUnconfirmedMarkRef = ref(db, `lastUnconfirmedMarks/${spaceId}`);
    const firedAt = new Date();
    const unsubscribeListenToUnconfirmedMarks = onValue(lastUnconfirmedMarkRef, (data) => {
        if (data.exists()) {
            const { action, removedAt, addNotification, ...unconfirmedMarkData }: RdbLastUnconfirmedMark = data.val();
            const unconfirmedMark = new UnconfirmedMark({
                id: unconfirmedMarkData.id,
                createdAt: new Date(unconfirmedMarkData.createdAt),
                spaceId,
                chatboardId: unconfirmedMarkData.chatboardId,
                messageId: unconfirmedMarkData.messageId,
                fromUserId: unconfirmedMarkData.fromUserId,
                toUserId: unconfirmedMarkData.toUserId,
            });
            if (firedAt <= unconfirmedMark.createdAt && action === 'add' && addNotification) {
                onAdded({
                    addNotification,
                    newUnconfirmedMark: unconfirmedMark,
                });
            } else if (removedAt && firedAt <= new Date(removedAt) && action === 'remove') {
                onRemoved({
                    removedUnconfirmedMark: unconfirmedMark,
                });
            }
        }
    });
    return {
        unsubscribeListenToUnconfirmedMarks,
    };
};
