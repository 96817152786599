import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { Chatboard } from '@/utils/PowerchatClient/models';
import {
    RdbLastChatboard,
    RdbLastChatboardAddNotification,
    RdbLastChatboardModifyNotification,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToChatboards = ({
    spaceId,
    onAdded,
    onModified,
}: {
    spaceId: string;
    onAdded: (input: { newChatboard: Chatboard; addNotification: RdbLastChatboardAddNotification }) => void;
    onModified: (input: {
        modifiedChatboard: Chatboard;
        modifyNotification: RdbLastChatboardModifyNotification;
    }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastChatboardRef = ref(db, `lastChatboards/${spaceId}`);
    const firedAt = new Date();
    const unsubscribeListenToChatboards = onValue(lastChatboardRef, (data) => {
        if (data.exists()) {
            const { action, addNotification, modifyNotification, ...chatboardData }: RdbLastChatboard = data.val();
            const chatboard = new Chatboard({
                id: chatboardData.id,
                createdAt: new Date(chatboardData.createdAt),
                updatedAt: chatboardData.updatedAt ? new Date(chatboardData.updatedAt) : undefined,
                spaceId,
                serialNumber: undefined,
                uniqueName: chatboardData.uniqueName,
                closedAt: chatboardData.closedAt ? new Date(chatboardData.closedAt) : undefined,
            });
            if (firedAt <= chatboard.createdAt && action === 'add' && addNotification) {
                onAdded({
                    addNotification,
                    newChatboard: chatboard,
                });
            } else if (
                chatboard.updatedAt &&
                firedAt <= chatboard.updatedAt &&
                action === 'modify' &&
                modifyNotification
            ) {
                onModified({
                    modifyNotification,
                    modifiedChatboard: chatboard,
                });
            }
        }
    });
    return {
        unsubscribeListenToChatboards,
    };
};
