import { FC, useState, useCallback } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import SvgMessage from '@/svgs/message';
import SvgCancelMessage from '@/svgs/message-slash';
import SvgEllipsis from '@/svgs/ellipsis';
import SvgEllipsisSlash from '@/svgs/ellipsis-slash';
import SvgSmile from '@/svgs/fa-smile-plus-solid';

import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi, ReactionApi } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { TextMenu } from '@/components/0_atom/Menu';

import { SelectReactionMenu } from './SelectReactionMenu';

const useStyles = makeStyles((theme) => ({
    root: {},
    messageIcon: {
        width: 11,
    },
    cancelMessageIcon: {
        width: 11,
    },
    markUnconfirmedIcon: {
        width: 5,
    },
    markReleaseUnconfirmedIcon: {
        width: 11,
    },
    replyObserverIcon: {
        width: 5,
    },
    releaseReplyObserverIcon: {
        width: 11,
    },
    addReactionIcon: {
        width: 11,
    },
}));

export const MessageCardMenu: FC<{
    className?: string;
    anchorEl: Element;
    isOpen: boolean;
    onClose: () => void;
    messageApi: MessageApi;
    unconfirmedMarkApi: UnconfirmedMarkApi | undefined;
    reactionApis: ReactionApi[];
    replyObserverApis: ReplyObserverApi[];
    openThread: (() => void) | undefined;
    option: {
        cancel: boolean;
        unconfirmedMark: boolean;
        replyObserver: boolean;
        reaction: boolean;
        thread: boolean;
    };
}> = ({
    className,
    anchorEl,
    isOpen,
    onClose: onClose_,
    messageApi,
    unconfirmedMarkApi,
    reactionApis,
    replyObserverApis,
    openThread,
    option,
}) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(useTheme());

    // CTX
    const { userClient } = useUserCtxAbsolutely();

    // HOOKS
    const { txt } = useLanguage();
    const [isOpenReactions, setIsOpenReactions] = useState(false);

    // CALLBACK
    const onClose = useCallback(() => {
        onClose_();
    }, [onClose_]);

    const handleSelectLetter = useCallback(
        ({ letter, userReactionApi }: { letter: string; userReactionApi: ReactionApi | undefined }) => {
            if (userReactionApi) {
                userReactionApi.remove();
            } else {
                messageApi.createReaction({ letter });
            }
            onClose();
        },
        [messageApi, onClose]
    );

    return (
        <>
            <TextMenu
                className={clsx(c.root, className)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                isOpen={isOpen}
                onClose={onClose}
                items={[
                    {
                        text: txt({
                            en: 'Add a reaction',
                            ja: 'リアクションを追加',
                        }),
                        startIcon: <SvgSmile className={c.addReactionIcon} />,
                        onClick: () => setIsOpenReactions(true),
                        isIgnore: !option.reaction,
                    },
                    {
                        text: txt({
                            en: 'Reply in thread',
                            ja: 'スレッドで返信',
                        }),
                        startIcon: <SvgMessage className={c.messageIcon} />,
                        onClick: () => !!openThread && openThread(),
                        isIgnore: !option.thread || !!messageApi.replyToMessageId || !openThread,
                    },
                    {
                        text: txt({
                            en: unconfirmedMarkApi ? 'Release unconfirmed' : 'Mark unconfirmed',
                            ja: unconfirmedMarkApi ? '確認済みにする' : '要確認としてマーク',
                        }),
                        startIcon: unconfirmedMarkApi ? (
                            <SvgEllipsisSlash className={c.markReleaseUnconfirmedIcon} />
                        ) : (
                            <SvgEllipsis className={c.markUnconfirmedIcon} />
                        ),
                        onClick: () => {
                            if (unconfirmedMarkApi) {
                                unconfirmedMarkApi.remove();
                            } else {
                                messageApi.createUnconfirmedMark();
                            }
                        },
                        isIgnore: !option.unconfirmedMark,
                    },
                    {
                        text: txt({
                            en: replyObserverApis.length > 0 ? 'Release awaiting reply' : 'Await reply',
                            ja: replyObserverApis.length > 0 ? '返信待ちを解除' : '返信待ちにする',
                        }),
                        startIcon:
                            replyObserverApis.length > 0 ? (
                                <SvgEllipsisSlash className={c.releaseReplyObserverIcon} />
                            ) : (
                                <SvgEllipsis className={c.replyObserverIcon} />
                            ),
                        onClick: () => {
                            if (replyObserverApis.length > 0) {
                                replyObserverApis.forEach((replyObserverApi) => replyObserverApi.remove());
                            } else {
                                messageApi.createReplyObservers();
                            }
                        },
                        isIgnore:
                            !option.replyObserver ||
                            messageApi.mentionToUserIds.length < 1 ||
                            !!messageApi.replyToMessageId,
                    },
                    {
                        text: txt({
                            en: 'Cancel this message',
                            ja: 'このメッセージをキャンセル',
                        }),
                        color: theme.palette.error.main,
                        startIcon: <SvgCancelMessage className={c.cancelMessageIcon} />,
                        onClick: () => messageApi.cancel(),
                        isIgnore: !option.cancel || messageApi.userId !== userClient.id || !!messageApi.canceledAt,
                    },
                ]}
            />
            {option.reaction && (
                <SelectReactionMenu
                    anchorEl={anchorEl}
                    isOpen={isOpenReactions}
                    onClose={() => setIsOpenReactions(false)}
                    onSelected={handleSelectLetter}
                    userReactionApis={reactionApis.filter(({ userId }) => userId === userClient.id)}
                />
            )}
        </>
    );
};
MessageCardMenu.displayName = 'ChatboardTemplate/MessageCardMenu';
