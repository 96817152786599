import { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTypography from '@material-ui/core/Typography';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiListItemText from '@material-ui/core/ListItemText';

import SvgPlus from '@/svgs/fa-plus-solid';

import { ChatboardPermissionType, SpaceMembershipApi } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely, useSpaceCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useItems } from '@/utils/customHooks';
import { PagingView } from '@/components/0_atom/PagingView';
import { ConfirmationModal } from '@/components/1_mol/ConfirmationModal';
import { SpaceIconWithName } from '@/components/2_org/SpaceIconWithName';
import { AddChatboardModalStep1 } from '@/components/3_template/ConsoleTemplate/AddChatboardModal/Step1';
import { AddChatboardModalStep2 } from '@/components/3_template/ConsoleTemplate/AddChatboardModal/Step2';

const useStyles = makeStyles((theme) => ({
    root: {
        '& svg': {
            width: 14,
            height: 14,
        },
    },
    current: {},
    modalPaper: {
        background: '#fafafa',
    },
}));

export const AddChatboardModal: FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const {
        current: { spaceApi, spaceMembershipApi },
    } = useSpaceCtxAbsolutely();
    const { userClient } = useUserCtxAbsolutely();
    const { txt } = useLanguage();
    const [currentIndex, setCurrentIndex] = useState<'0' | '1'>('0');
    const [chatboardName, setChatboardName] = useState<string | undefined>(undefined);
    const {
        ids: userIds,
        getItemAbsolutely: getMemberAbsolutely,
        addItem: addMember,
        updateItem: updateMember,
        removeItem: removeMember,
        initialize: initializeMembers,
    } = useItems<{
        id: string;
        spaceMembershipApi: SpaceMembershipApi;
        permission: ChatboardPermissionType;
    }>([
        {
            id: spaceMembershipApi.userId,
            spaceMembershipApi,
            permission: {
                read: true,
                write: true,
            },
        },
    ]);

    // CALLBACK
    const runCreateChatboard = useCallback(async () => {
        if (chatboardName) {
            await spaceApi.createChatboard({
                uniqueName: chatboardName,
                users: userIds.map((userId) => getMemberAbsolutely(userId)),
            });
            onClose();
            return 'success' as const;
        }
        throw new Error('never');
    }, [spaceApi, getMemberAbsolutely, onClose, chatboardName, userIds]);

    // USEEFFECT
    useEffect(() => {
        return () => {
            setCurrentIndex('0');
            setChatboardName(undefined);
            initializeMembers([
                {
                    id: spaceMembershipApi.userId,
                    spaceMembershipApi,
                    permission: {
                        read: true,
                        write: true,
                    },
                },
            ]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            title={txt({
                en: 'New chatboard',
                ja: '新しいチャットボードを作成',
            })}
            overTitle={<SpaceIconWithName spaceId={spaceApi.id} spaceName={spaceApi.name} />}
            mutation={{
                run: runCreateChatboard,
                description: txt({
                    en: 'Create new chatboard',
                    ja: '新しいチャットボードを作成',
                }),
            }}
            submitText={txt({
                en: 'Create',
                ja: '作成',
            })}
            isHideSubmits={currentIndex === '0'}
            paperClassName={c.modalPaper}
        >
            <PagingView
                contents={{
                    '0': {
                        title: undefined,
                        children: (
                            <AddChatboardModalStep1
                                chatboardName={chatboardName}
                                setChatboardName={setChatboardName}
                                handleNext={() => setCurrentIndex('1')}
                            />
                        ),
                        isShowTitle: false,
                        level: 0,
                        prevIndex: undefined,
                    },
                    '1': {
                        title: txt({
                            en: 'Add members',
                            ja: 'メンバーを追加',
                        }),
                        children: (
                            <AddChatboardModalStep2
                                userIds={userIds}
                                getMemberAbsolutely={getMemberAbsolutely}
                                addMember={addMember}
                                updateMember={updateMember}
                                removeMember={removeMember}
                            />
                        ),
                        isShowTitle: true,
                        level: 1,
                        prevIndex: '0',
                    },
                }}
                currentIndex={currentIndex}
                setCurrentIndex={(index) => setCurrentIndex(index)}
                height={420}
                drawerPaperClassName={c.modalPaper}
            />
        </ConfirmationModal>
    );
};
AddChatboardModal.displayName = 'AddChatboardModal';
