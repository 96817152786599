import { useState, useReducer, useEffect, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RecordWithId = Record<string, any> & {
    id: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ItemType<T extends Record<string, any>> = T & {
    id: string;
};

const initItems = <T extends RecordWithId>(items?: ItemType<T>[]) => items;

export const useLazyItems = <T extends RecordWithId>(items?: ItemType<T>[]) => {
    // HOOKS
    const [ids, setIds] = useState<string[] | undefined>(() => items?.map(({ id }) => id));
    const [itemDatas, dispatchItemDatas] = useReducer<
        (
            state: ItemType<T>[] | undefined,
            input: {
                add?: {
                    newItem: ItemType<T>;
                    isIgnoreDuplicationError?: boolean;
                    isAddToTop?: boolean;
                };
                remove?: {
                    id: string;
                    isIgnoreMissingError?: boolean;
                };
                update?: {
                    id: string;
                    updator: (prevItem: ItemType<T>) => ItemType<T>;
                    isIgnoreMissingError?: boolean;
                };
                reset?: { items?: ItemType<T>[] };
            }
        ) => ItemType<T>[] | undefined,
        ItemType<T>[] | undefined
    >(
        (state, { add, remove, update, reset }) => {
            if (add) {
                const sameIdItem = (state || []).find((item) => item.id === add.newItem.id);
                if (sameIdItem) {
                    if (add.isIgnoreDuplicationError) {
                        return state;
                    }
                    throw new Error('useLazyItem.addItem: !!sameIdItem.');
                }
                if (add.isAddToTop) {
                    return [add.newItem, ...(state || [])];
                }
                return [...(state || []), add.newItem];
            }
            if (remove) {
                if (remove.isIgnoreMissingError) {
                    return state?.filter((item) => item.id !== remove.id);
                }
                if (state) {
                    return state.filter((item) => item.id !== remove.id);
                }
                throw new Error('useLazyItem.removeItem: missing id.');
            }
            if (update) {
                const { id, updator } = update;
                const prevItem = state?.find((item) => item.id === id);
                if (!prevItem || !state) {
                    if (update.isIgnoreMissingError) {
                        return state;
                    }
                    console.error('useLazyItems.updator: !prevItem.', {
                        id,
                        updator,
                        state,
                    });
                    throw new Error('useLazyItems.updator: !prevItem.');
                }
                const newItem = updator(prevItem);
                return state.map((item) => {
                    if (item.id === newItem.id) {
                        return newItem;
                    }
                    return item;
                });
            }
            if (reset) {
                return initItems(reset.items);
            }
            throw new Error('never');
        },
        items,
        initItems
    );

    // USEEFFECT
    useEffect(() => {
        // when added or initialized
        // if (itemDatas && (!ids || itemDatas.length > ids.length)) {
        if (itemDatas?.length !== ids?.length) {
            setIds(itemDatas?.map(({ id }) => id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemDatas]);

    // HANDLER (CALLBACKS)
    const getItemData = useCallback(
        (id: string) => {
            if (!itemDatas) {
                return undefined;
            }
            return itemDatas.find((item) => item.id === id);
        },
        [itemDatas]
    );

    const getItemDataAbsolutely = useCallback(
        (id: string) => {
            const target = getItemData(id);
            if (target) {
                return target;
            }
            console.error('useLazyItems.getItemDataAbsolutely: item for id not found!', {
                givenId: id,
                ids,
                itemDatas,
            });
            throw new Error('useLazyItems.getItemDataAbsolutely: item for id not found!');
        },
        [ids, itemDatas, getItemData]
    );

    const updateItem = useCallback(
        ({
            id,
            updator,
            isIgnoreMissingError,
        }: {
            id: string;
            updator: (prevItem: ItemType<T>) => ItemType<T>;
            isIgnoreMissingError?: boolean;
        }) => {
            dispatchItemDatas({
                update: {
                    id,
                    updator,
                    isIgnoreMissingError,
                },
            });
        },
        []
    );
    const removeItem = useCallback(
        ({ id, isIgnoreMissingError }: { id: string; isIgnoreMissingError?: boolean }) => {
            setIds(ids?.filter((item) => item !== id));
            dispatchItemDatas({ remove: { id, isIgnoreMissingError } });
        },
        [ids]
    );
    const addItem = useCallback(
        ({
            newItem,
            isIgnoreDuplicationError,
            isAddToTop,
        }: {
            newItem: ItemType<T>;
            isIgnoreDuplicationError?: boolean;
            isAddToTop?: boolean;
        }) => {
            dispatchItemDatas({ add: { newItem, isIgnoreDuplicationError, isAddToTop } });
        },
        []
    );
    const initialize = useCallback((items?: ItemType<T>[]) => {
        // if (!items || items.length < (itemDatas?.length || 0)) {
        //     setIds(items?.map(({ id }) => id));
        // }
        setIds(undefined);
        dispatchItemDatas({ reset: { items } });
    }, []);

    return {
        ids,
        itemDatas,
        getItem: getItemData,
        getItemAbsolutely: getItemDataAbsolutely,
        updateItem,
        removeItem,
        addItem,
        initialize,
    };
};

export const useItems = <T extends RecordWithId>(items: ItemType<T>[]) => {
    // HOOKS
    const { ids, itemDatas, getItem, getItemAbsolutely, updateItem, removeItem, addItem, initialize } =
        useLazyItems(items);

    return {
        ids: ids || [],
        itemDatas: itemDatas || [],
        getItem,
        getItemAbsolutely,
        updateItem,
        removeItem,
        addItem,
        initialize: (items: ItemType<T>[]) => initialize(items),
    };
};
