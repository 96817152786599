import { serverTimestamp } from 'firebase/database';

// TYPE
export type RdbLastReplyObserver = {
    action: 'add' | 'remove';
    id: string;
    createdAt: number;
    removedAt: number | undefined;
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
};

// INPUT
export type RdbLastReplyObserverInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    removedAt: Date | 'serverTimestamp' | undefined;
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
};
export type AddRdbLastReplyObserverInput = {
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
};
export type RemoveRdbLastReplyObserverInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
};

export const getRdbLastReplyObserverInput = ({
    id,
    createdAt,
    removedAt,
    spaceId,
    chatboardId,
    messageId,
    fromUserId,
    toUserId,
}: RdbLastReplyObserverInput): {
    id: string;
    createdAt: number | ReturnType<typeof serverTimestamp>;
    removedAt: number | ReturnType<typeof serverTimestamp> | null;
    spaceId: string;
    chatboardId: string | null;
    messageId: string;
    fromUserId: string;
    toUserId: string;
} => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        removedAt: removedAt === 'serverTimestamp' ? serverTimestamp() : removedAt ? removedAt.getTime() : null,
        spaceId,
        chatboardId: chatboardId || null,
        messageId,
        fromUserId,
        toUserId,
    };
};
