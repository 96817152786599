import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { ReplyObserver } from '@/utils/PowerchatClient/models';
import { RdbLastReplyObserver } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToReplyObservers = ({
    spaceId,
    onAdded,
    onRemoved,
}: {
    spaceId: string;
    onAdded: (input: { newReplyObserver: ReplyObserver }) => void;
    onRemoved: (input: { removedReplyObserver: ReplyObserver }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastReplyObserverRef = ref(db, `lastReplyObservers/${spaceId}`);
    const firedAt = new Date();
    const unsubscribeListenToReplyObservers = onValue(lastReplyObserverRef, (data) => {
        if (data.exists()) {
            const { action, removedAt, ...replyObserverData }: RdbLastReplyObserver = data.val();
            const replyObserver = new ReplyObserver({
                id: replyObserverData.id,
                createdAt: new Date(replyObserverData.createdAt),
                spaceId,
                chatboardId: replyObserverData.chatboardId,
                messageId: replyObserverData.messageId,
                fromUserId: replyObserverData.fromUserId,
                toUserId: replyObserverData.toUserId,
            });
            if (firedAt <= replyObserver.createdAt && action === 'add') {
                onAdded({
                    newReplyObserver: replyObserver,
                });
            } else if (removedAt && firedAt <= new Date(removedAt) && action === 'remove') {
                onRemoved({
                    removedReplyObserver: replyObserver,
                });
            }
        }
    });
    return {
        unsubscribeListenToReplyObservers,
    };
};
