import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { SpaceMembership } from '@/utils/PowerchatClient/models';
import {
    getRdbLastSpaceMembershipInput,
    AddRdbLastSpaceMembershipInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbSpaceMembership = ({
    spaceId,
    newSpaceMembership,
}: {
    spaceId: string;
    newSpaceMembership: AddRdbLastSpaceMembershipInput;
}) => {
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastSpaceMembershipRef = ref(realtimeDb, `lastSpaceMemberships/${spaceId}`);
    set(lastSpaceMembershipRef, {
        action: 'add',
        ...getRdbLastSpaceMembershipInput({
            ...newSpaceMembership,
            createdAt: 'serverTimestamp',
            updatedAt: undefined,
            removedAt: undefined,
            modifyNotification: undefined,
            removeNotification: undefined,
        }),
    });
    return {
        newSpaceMembership: new SpaceMembership({
            id: newSpaceMembership.id,
            createdAt: new Date(),
            updatedAt: undefined,
            serialNumber: undefined,
            userId: newSpaceMembership.userId,
            spaceId,
            uniqueName: newSpaceMembership.uniqueName,
            displayName: newSpaceMembership.displayName,
            permission: newSpaceMembership.permission,
            isOpenPresence: newSpaceMembership.isOpenPresence,
        }),
    };
};
