import { createContext, useContext } from 'react';
import { UnconfirmedMarkApi } from '@/utils/PowerchatClient';

export type LastUnconfirmedMarkLog = {
    action: 'add' | 'remove';
    unconfirmedMarkApi: UnconfirmedMarkApi;
};

type LastUnconfirmedMarkCtxType = {
    lastUnconfirmedMarkLog: LastUnconfirmedMarkLog | undefined;
};
export const LastUnconfirmedMarkCtx = createContext<LastUnconfirmedMarkCtxType>({
    lastUnconfirmedMarkLog: undefined,
});
export const useLastUnconfirmedMarkCtx = () => {
    return useContext(LastUnconfirmedMarkCtx);
};
