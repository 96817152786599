import { useEffect, useState, RefObject } from 'react';
import { useScroll } from '@/utils/customHooks/useScroll';

export const useScrollState = <T>({
    boxRef,
    initialState,
    getState,
}: {
    boxRef: RefObject<HTMLElement>;
    initialState: T;
    getState: (input: {
        prevState: T;
        clientHeight: number;
        scrollHeight: number;
        scrollTop: number;
        scrollBottom: number;
    }) => {
        newState: T;
        isUpdateState: boolean;
    };
}) => {
    // HOOKS
    const { clientHeight, scrollBottom, scrollHeight, scrollTop } = useScroll({ boxRef });
    const [state, setState] = useState(initialState);

    // USEEFFECT
    useEffect(() => {
        const { newState, isUpdateState } = getState({
            prevState: state,
            clientHeight,
            scrollBottom,
            scrollHeight,
            scrollTop,
        });
        if (isUpdateState) {
            setState(newState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientHeight, scrollBottom, scrollHeight, scrollTop, getState]);

    return state;
};
