import { forwardRef, ComponentProps } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import { getMutationStateIcon } from '@/components/0_atom/MutationStateIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '12px',
        '& .MuiButton-startIcon': {
            width: 14,
            height: 14,
        },
        '&.MuiButton-containedPrimary:hover': {
            // background: theme.palette.primary.main,
            background: '#ef3965',
        },
    },
    lightSubmit: {
        color: '#fff',
        fontWeight: 600,
        background: theme.palette.text.primary,
    },
    darkSubmit: {
        color: '#000',
        background: 'rgba(255,255,255,.8)',
        fontWeight: 600,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.5)',
        },
    },
    lightCancel: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
    darkCancel: {
        color: 'rgba(255,255,255,.8)',
        background: 'rgba(255,255,255,.2)',
        fontWeight: 600,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.04)',
        },
    },
    dangerouslySubmit: {
        color: '#fff',
        backgroundColor: theme.palette.error.main,
        fontWeight: 600,
        '&:hover': {
            background: theme.palette.error.light,
        },
    },
    rootError: {
        background: theme.palette.error.main,
        '&:hover': {
            background: theme.palette.error.light,
        },
    },
    errorColor: {
        color: theme.palette.error.main,
        background: 'rgba(255,71,71,.03)',
        '&:hover': {
            background: 'rgba(255,71,71,.06)',
        },
    },
    selected: {
        backgroundColor: '#fff',
    },
}));

export type ButtonRole = 'submit' | 'cancel' | 'dangerouslySubmit';

export const Button = forwardRef<
    HTMLButtonElement,
    Omit<ComponentProps<typeof MuiButton>, 'color'> & {
        colorMode?: 'light' | 'dark';
        role?: ButtonRole;
        actionState?: 'trying' | 'success' | 'error';
        isSelected?: boolean;
        color?: 'error';
    }
>(({ colorMode, role, actionState, isSelected, color, className, variant, startIcon, ...otherProps }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <MuiButton
            ref={ref}
            {...otherProps}
            className={clsx(
                c.root,
                {
                    [c.lightSubmit]: role === 'submit' && (colorMode === 'light' || !colorMode),
                    [c.darkSubmit]: role === 'submit' && colorMode === 'dark',
                    [c.lightCancel]: role === 'cancel' && (colorMode === 'light' || !colorMode),
                    [c.darkCancel]: role === 'cancel' && colorMode === 'dark',
                    [c.dangerouslySubmit]: role === 'dangerouslySubmit',
                    [c.rootError]: actionState === 'error',
                    [c.selected]: isSelected,
                    [c.errorColor]: color === 'error' && (variant === 'text' || variant === undefined),
                },
                className
            )}
            variant={role === 'submit' || role === 'dangerouslySubmit' ? 'contained' : variant}
            color={role === 'submit' ? 'primary' : undefined}
            startIcon={getMutationStateIcon({ actionState }) || startIcon}
        />
    );
});
Button.displayName = 'Button';
