import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontWeight: 600,
    },
    box: {
        display: 'flex',
        alignItems: 'flex-start',
        '& > :nth-child(2)': {
            marginLeft: 10,
            flexGrow: 0,
            height: 65,
        },
    },
    inputBox: {
        flexGrow: 1,
        '& :nth-child(1)': {
            width: '100%',
        },
    },
}));

export const InlineInputAndButton: FC<{
    className?: string;
    title?: string;
    input: ReactNode;
    button: ReactNode;
}> = ({ className, title, input, button }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS

    // DATA

    return (
        <div className={clsx(c.root, className)}>
            {title && <MuiTypography className={c.title}>{title}</MuiTypography>}
            <div className={c.box}>
                <div className={c.inputBox}>{input}</div>
                {button}
            </div>
        </div>
    );
};
InlineInputAndButton.displayName = 'InlineInputAndButton';
