import { ref, set } from 'firebase/database';
import { collection, doc } from 'firebase/firestore';
import { getFirebaseRealtimeDatabase, getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import { ReplyObserver } from '@/utils/PowerchatClient/models';
import {
    getRdbLastReplyObserverInput,
    AddRdbLastReplyObserverInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbReplyObserver = ({
    spaceId,
    newReplyObserver,
}: {
    spaceId: string;
    newReplyObserver: AddRdbLastReplyObserverInput;
}) => {
    const firestoreDb = getFirebaseFirestore();
    const { id } = doc(collection(firestoreDb, `Space/${spaceId}/ReplyObserver`));
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastReplyObserverRef = ref(realtimeDb, `lastReplyObservers/${spaceId}`);
    set(lastReplyObserverRef, {
        action: 'add',
        ...getRdbLastReplyObserverInput({
            ...newReplyObserver,
            id,
            createdAt: 'serverTimestamp',
            removedAt: undefined,
        }),
    });
    return {
        newReplyObserver: new ReplyObserver({
            id,
            createdAt: new Date(),
            spaceId,
            chatboardId: newReplyObserver.chatboardId,
            messageId: newReplyObserver.messageId,
            fromUserId: newReplyObserver.fromUserId,
            toUserId: newReplyObserver.toUserId,
        }),
    };
};
