/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import { FC } from 'react';
import { getEnvVariables } from '@/utils/envVariables';

export const getGoogleTagManagerHead = () => {
    const { uodkaEnv } = getEnvVariables();
    if (uodkaEnv === 'production') {
        const inHeadScript = ``;
        return <script>{inHeadScript}</script>;
    }
    return <></>;
};

export const GoogleTagManagerBody: FC = () => {
    const { uodkaEnv } = getEnvVariables();
    if (uodkaEnv === 'production') {
        const inBodyScript = ``;
        return <noscript dangerouslySetInnerHTML={{ __html: inBodyScript }} />;
    }

    return <></>;
};
GoogleTagManagerBody.displayName = 'GoogleTagManagerBody';
