import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { useChatboardsCtx, useSpaceMembersCtx, useHotMembersCtx } from '@/utils/ctxs';
import { useLanguage, useIsConnecting } from '@/utils/customHooks';
import { WindowsProvider } from '@/components/0_atom/Window';
import { FetchingText } from '@/components/0_atom/FetchingText';
import { LobbyTemplate } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate';
import { ChatboardTemplate } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate';
import { TemporaryAddChatboard } from '@/components/3_template/ConsoleTemplate/TemporaryAddChatboard';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        overflowX: 'hidden',
        paddingLeft: 56,
        // transitionProperty: 'background',
        // transitionDuration: '.6',
        // background: 'transparent',
    },
    connecting: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    blackscreen: {
        height: '100vh',
        width: '100vw',
        transitionProperty: 'opacity',
        transitionDuration: '.2s',
        zIndex: 9,
        position: 'fixed',
        background: '#1D1D1E',
    },
    blackscreenLoading: {
        opacity: 1,
    },
    blackscreenNotLoading: {
        opacity: 0,
    },
}));

export const SpaceTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // CTX
    const { chatboardIds } = useChatboardsCtx();
    const { userIds } = useSpaceMembersCtx();
    const { hotUserIds } = useHotMembersCtx();

    // HOOKS
    const { txt } = useLanguage();
    const [isBlackscreenVisible, setIsBlackscreenVisible] = useState(true);
    const [isBlackscreenAppear, setIsBlackscreenAppear] = useState(true);
    const { isConnecting } = useIsConnecting({});

    useEffect(() => {
        if (isConnecting) {
            setIsBlackscreenVisible(false);
            setIsBlackscreenAppear(true);
            setTimeout(() => {
                setIsBlackscreenVisible(true);
            }, 10);
        } else {
            setIsBlackscreenVisible(false);
            setTimeout(() => {
                setIsBlackscreenAppear(false);
            }, 600);
        }
    }, [isConnecting]);

    return (
        <div className={c.root}>
            {chatboardIds && userIds && hotUserIds && (
                <WindowsProvider>
                    <ChatboardTemplate />
                    <LobbyTemplate />
                    <TemporaryAddChatboard />
                </WindowsProvider>
            )}
            {isBlackscreenAppear && (
                <div
                    className={clsx(c.blackscreen, {
                        [c.blackscreenLoading]: isBlackscreenVisible,
                        [c.blackscreenNotLoading]: !isBlackscreenVisible,
                    })}
                >
                    <FetchingText
                        className={c.connecting}
                        text={txt({
                            en: 'Connecting',
                            ja: '接続中',
                        })}
                    />
                </div>
            )}
        </div>
    );
};
SpaceTemplate.displayName = 'SpaceTemplate';
