import { createContext, useContext } from 'react';

import { SpaceApi, SpaceMembershipApi } from '@/utils/PowerchatClient';

export type SpaceWithMembership = {
    id: string;
    spaceApi: SpaceApi;
    spaceMembershipApi: SpaceMembershipApi;
};

export type SpaceCtxType = {
    current: SpaceWithMembership | undefined;
};

export const SpaceCtx = createContext<SpaceCtxType>({
    current: undefined,
});
export const useSpaceCtx = () => {
    return useContext(SpaceCtx);
};
export const useSpaceCtxAbsolutely = () => {
    const { current, ...otherProps } = useSpaceCtx();
    if (!current) {
        throw new Error('useSpaceCtxAbsolutely: ! current.');
    }
    return {
        current,
        ...otherProps,
    };
};
