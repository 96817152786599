import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi, ChatboardApi } from '@/utils/PowerchatClient';
import { useChatboardMasterMessages, useLanguage, usePastThreads } from '@/utils/customHooks';
import { FetchingText } from '@/components/0_atom/FetchingText';
import { ChatBoxState } from '@/components/0_atom/ChatBox';
import { MainMessageCard } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/MessageCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {},
}));

export const ChatboardViewPastContent: FC<
    ChatBoxState & {
        className?: string;
        chatboardApi: ChatboardApi;
    }
> = ({ className, chatboardApi, ...chatboxState }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const {
        pastMessageIds,
        threads,
        getPastThread,
        getPastThreadAbsolutely,
        fetchPastThreads,
        isFetchingPastThreads,
        oldestMessageApi,
        isAllFetched,
    } = usePastThreads({ chatboardApi, ...chatboxState });

    return (
        <div className={c.root}>
            <MuiButton onClick={fetchPastThreads} disabled={isAllFetched || isFetchingPastThreads}>
                {'fetch'}
            </MuiButton>
            <span>{isAllFetched ? 'isAllFetched' : isFetchingPastThreads ? 'isFetchingPastThreads' : 'ready'}</span>
            {threads?.map(({ master, replies }, i) => {
                return (
                    <MainMessageCard
                        key={master.messageApi.id}
                        chatboardApi={chatboardApi}
                        messageApi={master.messageApi}
                        initialReactionApis={master.reactionApis}
                        initialReplyObserverApis={master.replyObserverApis}
                        initialUnconfirmedMarkApi={master.unconfirmedMarkApi}
                        initialReplies={replies}
                        isOmitUser={!!threads && threads[i - 1]?.master.messageApi.id === master.messageApi.id}
                    />
                );
            }) || <FetchingText />}
        </div>
    );
};
ChatboardViewPastContent.displayName = 'ChatboardViewPastContent';
