import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import {} from '@/utils/PowerchatClient';
import { useLobbyMasterMessages } from '@/utils/customHooks';
import { MainView } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/MainView';
import { ThreadView } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/ThreadView';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: '100%',
        width: '100%',
    },
    rootInner: {
        position: 'relative',
        height: '100%',
        width: '100%',
        padding: '0 27px 18px 0',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    threads: {
        display: 'flex',
        alignItems: 'flex-end',
        overflowX: 'scroll',
        paddingLeft: 56 + 5,
        marginRight: 5,
    },
}));

export const LobbyTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { messageIds, getMessageItemAbsolutely } = useLobbyMasterMessages();
    console.log({ messageIds });
    return (
        <div className={c.root}>
            <div className={c.rootInner}>
                <div className={c.threads}>
                    {messageIds.map((messageId) => (
                        <ThreadView
                            key={`${messageId}-thread`}
                            masterMessageId={messageId}
                            getMasterMessageAbsolutely={getMessageItemAbsolutely}
                        />
                    ))}
                </div>
                <MainView masterMessageIds={messageIds} getMasterMessageAbsolutely={getMessageItemAbsolutely} />
            </div>
        </div>
    );
};
LobbyTemplate.displayName = 'LobbyTemplate';
