import { forwardRef, ComponentProps, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiDialog from '@material-ui/core/Dialog';
import MuiSlide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
    paper: {
        background: '#2a2a2b',
        boxShadow: '0 64px 64px -16px rgb(0 0 0 / 48%)',
        padding: 22,
        borderRadius: 4,
        width: '100%',
        maxWidth: 700,
        height: '90%',
        margin: 'auto auto auto auto',
        overflowY: 'scroll',
    },
    // paperTop: {
    //     borderRadius: '0 0 23px 23px',
    //     maxWidth: 700,
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    // },
    // paperBottom: {
    //     borderRadius: '23px 23px 0 0',
    //     width: '100%',
    //     maxWidth: 700,
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //     // height: '90%',
    //     minHeight: '90%',
    // },
    // paperRight: {
    //     borderRadius: '23px 0 0 23px',
    // },
    // paperLeft: {
    //     borderRadius: '0 23px 23px 0',
    // },
    backdrop: {
        background: 'rgba(0,0,0,.1)',
    },
}));

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <MuiSlide direction="up" ref={ref} {...props} />;
});

export const Modal = forwardRef<
    HTMLDivElement,
    ComponentProps<typeof MuiDialog> & {
        drawerZIndex?: number;
        paperClassName?: string;
        directChildren?: ReactNode;
    }
>(({ drawerZIndex, paperClassName, children, directChildren, ...props }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <MuiDialog
            ref={ref}
            {...props}
            BackdropProps={{
                classes: {
                    root: c.backdrop,
                },
            }}
            classes={{
                paper: clsx(
                    c.paper,
                    // {
                    //     [c.paperTop]: anchor === 'top',
                    //     [c.paperBottom]: anchor === 'bottom' || !anchor,
                    //     [c.paperLeft]: anchor === 'left',
                    //     [c.paperRight]: anchor === 'right',
                    // },
                    paperClassName
                ),
            }}
            style={{
                zIndex: drawerZIndex || 1300,
            }}
            transitionDuration={{ enter: 500, exit: 250 }}
            TransitionComponent={Transition}
        >
            <div>{children}</div>
            {directChildren}
        </MuiDialog>
    );
});
Modal.displayName = 'Modal';
