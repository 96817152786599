import { GqlChatboardMasterMessage } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import { LobbyMasterMessageType, LobbyMasterMessage } from './LobbyMasterMessage';

export type ChatboardMasterMessageType = LobbyMasterMessageType & {
    chatboardId: string;
};

export class ChatboardMasterMessage extends LobbyMasterMessage implements ChatboardMasterMessageType {
    readonly chatboardId: string;

    constructor({ chatboardId, ...input }: ChatboardMasterMessageType) {
        super(input);
        this.chatboardId = chatboardId;
    }
}

export const getChatboardMasterMessageFromGql = ({
    id,
    createdAt,
    updatedAt,
    serialNumber,
    userId,
    spaceId,
    chatboardId,
    body,
    mentionToUserIds,
    canceledAt,
}: GqlChatboardMasterMessage) => {
    return new ChatboardMasterMessage({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        serialNumber,
        spaceId,
        chatboardId,
        userId,
        body,
        mentionToUserIds,
        canceledAt: isoToDateOptionally(canceledAt),
    });
};
