import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiGrow from '@material-ui/core/Grow';

import SvgCheck from '@/svgs/fa-check-solid';

import { useSpaceMembersCtx, useChatboardsCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { Button } from '@/components/0_atom/Button';
import { Popover } from '@/components/0_atom/Popover';
import { IntInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { InlineInputAndButton } from '@/components/1_mol/InlineInputAndButton';

const useStyles = makeStyles((theme) => ({
    name: {
        marginBottom: 21,
    },
    nameInput: {
        background: '#fff',
        '& .MuiFormHelperText-root': {
            height: 45,
        },
    },
    button: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    check: {
        color: theme.palette.success.main,
        width: 18,
        height: 18,
    },
}));

export const AddChatboardModalStep1: FC<{
    chatboardName: string | undefined;
    setChatboardName: (chatboardName: string | undefined) => void;
    handleNext: () => void;
}> = ({ chatboardName, setChatboardName, handleNext }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { chatboardIds, getChatboardWithMembershipAbsolutely } = useChatboardsCtx();
    const { userIds } = useSpaceMembersCtx();
    const { txt } = useLanguage();

    // DATA
    const isNameUnique: boolean | undefined = (() => {
        const chatboardNames = chatboardIds?.map((chatboardId) =>
            getChatboardWithMembershipAbsolutely(chatboardId).chatboardApi.uniqueName.toLocaleLowerCase()
        );
        if (chatboardNames) {
            if (chatboardName) {
                return !chatboardNames.includes(chatboardName.toLocaleLowerCase());
            }
        }
        return undefined;
    })();

    return (
        <>
            <InlineInputAndButton
                className={c.name}
                title={txt({
                    en: 'Name for the new chatboard',
                    ja: '新しいルームの名前',
                })}
                input={
                    <TextInput
                        className={c.nameInput}
                        value={chatboardName}
                        setValue={setChatboardName}
                        placeholder={txt({
                            en: 'Name for the new chatboard',
                            ja: '新しいルームの名前',
                        })}
                        messages={[
                            {
                                message: txt({
                                    en: 'Chatboard name must be unique in the space',
                                    ja: 'ルーム名はスペース内でユニークである必要があります',
                                }),
                                isVisible: true,
                                variant: isNameUnique === false ? 'error' : 'plain',
                            },
                        ]}
                        endAdornment={
                            chatboardIds === undefined ? (
                                <LoadingIndicator />
                            ) : isNameUnique ? (
                                <SvgCheck className={c.check} />
                            ) : undefined
                        }
                    />
                }
                button={
                    <Button disabled={!chatboardName} className={c.button} role={'submit'} onClick={handleNext}>
                        {txt({
                            en: 'Next',
                            ja: '次へ',
                        })}
                    </Button>
                }
            />
        </>
    );
};
AddChatboardModalStep1.displayName = 'AddChatboardModal/Step1';
