import { FC, ReactNode, useState, useCallback, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiGrow from '@material-ui/core/Grow';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiPortal from '@material-ui/core/Portal';
import { PopoverOrigin, PopoverPosition } from '@material-ui/core/Popover';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiPaper from '@material-ui/core/Paper';

import SvgPlus from '@/svgs/fa-plus-solid';

import { useWindowsBoxCtxAbsolutely } from '@/utils/ctxs';

import { getAbsolutePosition } from './getAbsolutePosition';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        background: 'rgba(0,0,0,.23)',
        boxShadow: '0 32px 32px -32px rgba(0,0,0,.45)',
        backdropFilter: 'blur(40px)',
        borderRadius: 4,
    },
    inner: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    handle: {
        position: 'absolute',
    },
    handleE: {
        height: 'calc(100% - 10px)',
        width: 10,
        top: 0,
        right: -10,
        cursor: 'e-resize',
    },
    handleS: {
        height: 10,
        width: 'calc(100% - 10px)',
        bottom: -10,
        left: 0,
        cursor: 's-resize',
    },
    handleSE: {
        height: 20,
        width: 20,
        bottom: -10,
        right: -10,
        cursor: 'se-resize',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 14 + 2 * 2,
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        background: 'rgba(32,32,33, .8)',
        borderRadius: '4px 4px 0 0',
        color: 'rgba(255,255,255,.57)',
        fontSize: 9,
        lineHeight: '1',
    },
    headerHeading: {
        marginLeft: 6,
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        '& > svg': {
            height: 9,
        },
        marginRight: 5,
    },
    headerTitle: {},
    headerDrag: {
        flexGrow: 1,
        cursor: 'move',
        height: '100%',
    },
    closeButton: {
        padding: 4,
        transform: 'rotate(45deg)',
        marginRight: 3,
        color: 'rgba(255,255,255,.34)',
    },
    closeIcon: {
        width: 6,
        height: 6,
    },
    content: {
        width: '100%',
        height: '100%',
        // paddingTop: 18,
        overflowY: 'scroll',
    },
    absoluteCloseButton: {
        padding: 4,
        transform: 'rotate(45deg)',
        color: 'rgba(255,255,255,.34)',
        position: 'absolute',
        top: 0,
        right: 0,
        background: '#202021',
    },
    absoluteDrag: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));

const getPosition = ({
    anchorEl,
    anchorOrigin,
    transformOrigin,
    anchorOffset,
    initialWidth,
    initialHeight,
}: {
    anchorEl?: HTMLElement;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    anchorOffset?: PopoverPosition;
    initialWidth: number;
    initialHeight: number;
}) => {
    if (anchorEl) {
        return getAbsolutePosition({
            anchorEl,
            newElmHeight: initialHeight,
            newElmWidth: initialWidth,
            anchorOrigin: anchorOrigin || {
                vertical: 'top',
                horizontal: 'left',
            },
            transformOrigin: transformOrigin || {
                vertical: 'top',
                horizontal: 'left',
            },
            offset: anchorOffset || {
                top: 0,
                left: 0,
            },
        });
    }
    const { innerHeight, innerWidth } = window;
    return {
        top: (innerHeight - initialHeight) / 2,
        left: (innerWidth - initialWidth) / 2,
    };
};

export const Window: FC<{
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    initialWidth?: number;
    initialHeight?: number;
    icon?: ReactNode;
    title?: string;
    windowKey: string;
    anchorEl?: HTMLElement;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    anchorOffset?: PopoverPosition;
}> = ({
    className,
    isOpen: isOpen_,
    onClose: onClose_,
    children,
    initialWidth = 300,
    initialHeight = 200,
    icon,
    title,
    windowKey,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    anchorOffset,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // CTX
    const {
        elm: parentElm,
        highestPriority,
        setHighestPriority,
        rootZIndex,
        addWindow,
        removeWindow,
    } = useWindowsBoxCtxAbsolutely();

    // HOOKS
    const [isOpen, setIsOpen] = useState(isOpen_);
    const [priority, dispatchPriority] = useReducer<
        (state: number, input: { increase?: boolean; bringToFront?: boolean; reset?: boolean }) => number
    >((state, { increase, bringToFront, reset }) => {
        if (increase) {
            return state + 1;
        }
        if (bringToFront) {
            if (highestPriority < state) {
                setHighestPriority(state);
                return state;
            }
            const newHighestPriority = highestPriority + 1;
            setHighestPriority(newHighestPriority);
            return newHighestPriority;
        }
        if (reset) {
            return 0;
        }
        throw new Error('never');
    }, 0);
    const [{ top, left }] = useState(
        getPosition({ anchorEl, anchorOrigin, transformOrigin, anchorOffset, initialWidth, initialHeight })
    );

    // CALLBACK
    const bringToFront = useCallback(() => {
        if (priority < highestPriority) {
            dispatchPriority({ bringToFront: true });
        }
    }, [priority, highestPriority]);
    const onClose = useCallback(() => {
        setIsOpen(false);
        setTimeout(() => {
            onClose_();
            dispatchPriority({ reset: true });
            removeWindow();
        }, 400);
    }, [onClose_, removeWindow]);

    // DATA
    const handleClassName = `component-Window-handler-${windowKey}`;
    const zIndex = rootZIndex + priority;

    // USEEFFECT
    useEffect(() => {
        if (isOpen_) {
            addWindow();
            dispatchPriority({ bringToFront: true });
            setIsOpen(isOpen_);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen_]);

    return isOpen_ ? (
        <MuiPortal container={parentElm}>
            <Draggable handle={`.${handleClassName}`}>
                <div style={{ zIndex, position: 'absolute', top, left }}>
                    <MuiGrow in={isOpen}>
                        <ResizableBox
                            width={initialWidth}
                            height={initialHeight}
                            // style={{ zIndex: rootZIndex + priority }}
                            // onClick={bringToFront}
                            className={clsx(c.root, className)}
                            handle={(resizeHandle) => (
                                <span
                                    className={clsx(c.handle, {
                                        // [c.handleN]: resizeHandle === 'n',
                                        [c.handleE]: resizeHandle === 'e',
                                        [c.handleS]: resizeHandle === 's',
                                        // [c.handleW]: resizeHandle === 'w',
                                        // [c.handleNE]: resizeHandle === 'ne',
                                        // [c.handleNW]: resizeHandle === 'nw',
                                        [c.handleSE]: resizeHandle === 'se',
                                        // [c.handleSW]: resizeHandle === 'sw',
                                    })}
                                />
                            )}
                            resizeHandles={['se', 'e', 's']}
                        >
                            <div
                                className={c.inner}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    bringToFront();
                                }}
                            >
                                {!!title && (
                                    <div className={c.header} style={{ zIndex: zIndex + 1 }}>
                                        <div className={c.headerHeading}>
                                            {!!icon && <div className={c.headerIcon}>{icon}</div>}
                                            <div className={c.headerTitle}>{title}</div>
                                        </div>
                                        <div className={clsx(handleClassName, c.headerDrag)} />
                                        <MuiIconButton className={c.closeButton} onClick={onClose}>
                                            <SvgPlus className={c.closeIcon} />
                                        </MuiIconButton>
                                    </div>
                                )}
                                {!title && (
                                    <>
                                        <div className={clsx(handleClassName, c.absoluteDrag)} />
                                        <MuiIconButton className={c.absoluteCloseButton} onClick={onClose}>
                                            <SvgPlus className={c.closeIcon} />
                                        </MuiIconButton>
                                    </>
                                )}
                                <div className={c.content}>{children}</div>
                            </div>
                        </ResizableBox>
                    </MuiGrow>
                </div>
            </Draggable>
        </MuiPortal>
    ) : (
        <></>
    );
};
Window.displayName = 'Window';
