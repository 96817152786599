import { forwardRef, ComponentProps, MouseEvent } from 'react';

import { Popover } from '@/components/0_atom/Popover';
import { StaticTextMenu, TextMenuItemData } from '@/components/0_atom/Menu/StaticTextMenu';

export const TextMenu = forwardRef<
    HTMLElement,
    Omit<ComponentProps<typeof Popover>, 'children' | 'onClose'> & {
        items: (TextMenuItemData | undefined)[];
        align?: 'right' | 'left';
        onClose?: (mouseEvent: MouseEvent | undefined, reason: 'backdropClick' | 'escapeKeyDown' | 'itemClick') => void;
    }
>(({ items, onClose, align, ...popoverProps }, ref) => {
    return (
        <Popover ref={ref} {...popoverProps} onClose={onClose}>
            <StaticTextMenu items={items} onClose={onClose} align={align} />
        </Popover>
    );
});
TextMenu.displayName = 'TextMenu';
