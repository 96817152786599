import { useEffect } from 'react';

import { MessageApi, UnconfirmedMarkApi, ReactionApi, MessageWithMark } from '@/utils/PowerchatClient';
import { useLastMessageCtx, useLastReactionCtx, useLastUnconfirmedMarkCtx, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useItems } from '@/utils/customHooks';

export const useChatboardReplyMessages = ({
    chatboardId,
    masterMessageId,
    initialReplies,
}: {
    chatboardId: string;
    masterMessageId: string;
    initialReplies: Omit<MessageWithMark, 'replyObserverApis'>[];
}) => {
    // CTXS
    const { userClient } = useUserCtxAbsolutely();
    const { lastMessageLog } = useLastMessageCtx();
    const { lastReactionLog } = useLastReactionCtx();
    const { lastUnconfirmedMarkLog } = useLastUnconfirmedMarkCtx();

    // HOOKS
    const { ids, itemDatas, getItemAbsolutely, addItem, updateItem } = useItems<{
        id: string;
        messageApi: MessageApi;
        reactionApis: ReactionApi[];
        unconfirmedMarkApi: UnconfirmedMarkApi | undefined;
    }>(
        initialReplies.map(({ messageApi, reactionApis, unconfirmedMarkApi }) => ({
            id: messageApi.id,
            messageApi,
            reactionApis,
            unconfirmedMarkApi,
        }))
    );

    // LISTENER
    useEffect(() => {
        if (lastMessageLog) {
            if (lastMessageLog.action === 'add' && lastMessageLog.messageApi.replyToMessageId === masterMessageId) {
                addItem({
                    newItem: {
                        id: lastMessageLog.messageApi.id,
                        messageApi: lastMessageLog.messageApi,
                        reactionApis: [],
                        unconfirmedMarkApi: lastMessageLog.initialUnconfirmedMarkApi,
                    },
                    isIgnoreDuplicationError: true,
                });
            } else if (
                lastMessageLog.action === 'modify' &&
                lastMessageLog.messageApi.replyToMessageId === masterMessageId
            ) {
                updateItem({
                    id: lastMessageLog.messageApi.id,
                    updator: (prevItem) => ({
                        ...prevItem,
                        messageApi: lastMessageLog.messageApi,
                    }),
                    isIgnoreMissingError: true,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessageLog]);

    useEffect(() => {
        if (
            lastReactionLog &&
            lastReactionLog.reactionApi.chatboardId === chatboardId &&
            ids.includes(lastReactionLog.reactionApi.messageId)
        ) {
            const targetItem = itemDatas.find(({ id }) => lastReactionLog.reactionApi.messageId === id);
            if (targetItem) {
                if (lastReactionLog.action === 'add') {
                    updateItem({
                        id: targetItem.id,
                        updator: (prevItem) => {
                            console.log({ prevItem, lastReactionLog });
                            if (prevItem.reactionApis.some(({ id }) => id === lastReactionLog.reactionApi.id)) {
                                return prevItem;
                            }
                            return {
                                ...prevItem,
                                reactionApis: [...prevItem.reactionApis, lastReactionLog.reactionApi],
                            };
                        },
                        isIgnoreMissingError: true,
                    });
                } else if (lastReactionLog.action === 'remove') {
                    updateItem({
                        id: targetItem.id,
                        updator: (prevItem) => ({
                            ...prevItem,
                            reactionApis: prevItem.reactionApis.filter(
                                ({ id }) => id !== lastReactionLog.reactionApi.id
                            ),
                        }),
                        isIgnoreMissingError: true,
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastReactionLog]);

    useEffect(() => {
        if (
            lastUnconfirmedMarkLog &&
            lastUnconfirmedMarkLog.unconfirmedMarkApi.chatboardId === chatboardId &&
            lastUnconfirmedMarkLog.unconfirmedMarkApi.toUserId === userClient.id &&
            ids.includes(lastUnconfirmedMarkLog.unconfirmedMarkApi.messageId)
        ) {
            const targetItem = itemDatas.find(({ id }) => lastUnconfirmedMarkLog.unconfirmedMarkApi.messageId === id);
            if (targetItem) {
                if (lastUnconfirmedMarkLog.action === 'add') {
                    updateItem({
                        id: targetItem.id,
                        updator: (prevItem) => ({
                            ...prevItem,
                            unconfirmedMarkApi: lastUnconfirmedMarkLog.unconfirmedMarkApi,
                        }),
                        isIgnoreMissingError: true,
                    });
                } else if (lastUnconfirmedMarkLog.action === 'remove') {
                    updateItem({
                        id: targetItem.id,
                        updator: (prevItem) => ({
                            ...prevItem,
                            unconfirmedMarkApi: undefined,
                        }),
                        isIgnoreMissingError: true,
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUnconfirmedMarkLog]);

    return {
        messageIds: ids,
        replies: itemDatas,
        getMessageItemAbsolutely: getItemAbsolutely,
    };
};
