import { FC, useState, MouseEvent } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi } from '@/utils/PowerchatClient';
import { useSpaceCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { ChatBox } from '@/components/0_atom/ChatBox';
import { AddMessageInput } from '@/components/2_org/AddMessageInput';
import { MessageCardMenu } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/Menu';
import { MainViewContent } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/MainViewContent';
import { OpenMessageMenuInput } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/MessageCard';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 210,
        flexShrink: 0,
    },
    chatbox: {},
    input: {
        marginLeft: 25,
    },
}));

export const MainView: FC<{
    masterMessageIds: string[];
    getMasterMessageAbsolutely: (id: string) => {
        id: string;
        messageApi: MessageApi;
        initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
        initialReplyObserverApis: ReplyObserverApi[];
    };
}> = ({ masterMessageIds, getMasterMessageAbsolutely }) => {
    // STYLE
    const c = useStyles(useTheme());

    // CTXS
    const {
        current: { spaceMembershipApi, spaceApi },
    } = useSpaceCtxAbsolutely();

    // HOOKS
    const { txt } = useLanguage();
    const [currentMenuData, setCurrentMenuData] = useState<OpenMessageMenuInput | undefined>();

    // DATA
    const chatboxElmId = 'power-lobby-chatbox-main';

    return (
        <div className={c.root}>
            <ChatBox
                id={chatboxElmId}
                className={c.chatbox}
                initialChildren={undefined}
                upstreamChildren={undefined}
                downstreamChildren={{
                    component: MainViewContent,
                    props: {
                        messageIds: masterMessageIds,
                        getMessageItemAbsolutely: getMasterMessageAbsolutely,
                        openMessageMenu: setCurrentMenuData,
                    },
                }}
                otherChildren={undefined}
            />
            <AddMessageInput
                className={c.input}
                handleSendMessage={async ({ body }) => {
                    spaceApi.createLobbyMessage({
                        body,
                        replyTo: undefined,
                    });
                }}
                isPermitted={spaceMembershipApi.permission.lobby.write}
                placeholder={txt({
                    en: `To ${spaceApi.name}`,
                    ja: `${spaceApi.name}へ`,
                })}
            />
            {!!currentMenuData && (
                <MessageCardMenu openMenuInput={currentMenuData} onClose={() => setCurrentMenuData(undefined)} />
            )}
        </div>
    );
};
MainView.displayName = 'MainView';
