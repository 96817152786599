import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { generateRgbColorForText } from '@/utils/utilFunctions';
import { Button } from '@/components/0_atom/Button';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const UserName: FC<{
    className?: string;
    onClick?: () => void;
    userName: string;
}> = ({ className, onClick, userName }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const { rgbColor } = generateRgbColorForText({ text: userName });

    return onClick ? (
        <Button
            className={clsx(c.root, className)}
            onClick={onClick}
            style={{
                color: rgbColor.getCssValue({ opacity: 1 }),
            }}
        >
            {(userName[0]?.toUpperCase() || '') + userName.slice(1)}
        </Button>
    ) : (
        <span
            className={clsx(c.root, className)}
            style={{
                color: rgbColor.getCssValue({ opacity: 1 }),
            }}
        >
            {(userName[0]?.toUpperCase() || '') + userName.slice(1)}
        </span>
    );
};
UserName.displayName = 'UserName';
