import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    RemoveRdbLastUnconfirmedMarkInput,
    getRdbLastUnconfirmedMarkInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const removeRdbUnconfirmedMark = ({
    spaceId,
    unconfirmedMark,
}: {
    spaceId: string;
    unconfirmedMark: RemoveRdbLastUnconfirmedMarkInput;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastUnconfirmedMarkRef = ref(db, `lastUnconfirmedMarks/${spaceId}`);
    set(lastUnconfirmedMarkRef, {
        action: 'remove',
        ...getRdbLastUnconfirmedMarkInput({
            ...unconfirmedMark,
            removedAt: 'serverTimestamp',
            addNotification: undefined,
        }),
    });
};
