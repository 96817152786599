import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import { generateRgbColorForText, getTextColorForBackgroundColor } from '@/utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 10,
        display: 'inline-flex',
        width: 36,
        height: 36,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
        flexGrow: 0,
        flexShrink: 0,
    },
    isHot: {
        // outline: 'solid 2px #fff',
        // boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    },
}));

export const SpaceIcon: FC<{
    className?: string;
    onClick?: () => void;
    spaceName: string;
    spaceId: string;
    isHot?: boolean;
}> = ({ className, onClick, spaceName, spaceId, isHot }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const { rgbColor } = generateRgbColorForText({ text: spaceId.toLowerCase() });
    const backgroundColor = rgbColor.getCssValue({ opacity: 1 });
    const textColor = getTextColorForBackgroundColor({ textColor: '#000', backgroundColor });

    return onClick ? (
        <MuiButton
            className={clsx(c.root, className, {
                [c.isHot]: isHot,
            })}
            onClick={onClick}
            style={{
                background: backgroundColor,
                color: textColor,
            }}
        >
            {spaceName[0]?.toUpperCase()}
        </MuiButton>
    ) : (
        <span
            className={clsx(c.root, className, {
                [c.isHot]: isHot,
            })}
            style={{
                background: backgroundColor,
                color: textColor,
            }}
        >
            {spaceName[0]?.toUpperCase()}
        </span>
    );
};
SpaceIcon.displayName = 'SpaceIcon';
