import { FC, useCallback, useState, useRef } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgSmile from '@/svgs/fa-smile-plus-solid';

import { MessageApi, ReactionApi } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { SelectReactionMenu } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/MessageCard/Menu/SelectReactionMenu';
import { ReactionButton } from './ReactionButton';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 4,
    },
    addButton: {
        padding: 6,
        marginLeft: 2,
    },
    addButtonIcon: {
        width: 12,
        height: 12,
    },
}));

export const ReactionsView: FC<{
    className?: string;
    messageApi: MessageApi;
    reactionApis: ReactionApi[];
}> = ({ className, messageApi, reactionApis }) => {
    // STYLE
    const c = useStyles(useTheme());

    // REF
    const addButtonRef = useRef<HTMLButtonElement>(null);

    // CTX
    const { userClient } = useUserCtxAbsolutely();

    // HOOKS
    const [isOpen, setIsOpen] = useState(false);

    // DATA
    const reactionApisForLetters = reactionApis.reduce(
        (acc, reactionApi) => {
            const taregtItem = acc.find(({ letter }) => letter === reactionApi.letter);
            if (taregtItem) {
                return acc.map((item) => {
                    if (item.letter === reactionApi.letter) {
                        return {
                            letter: item.letter,
                            reactionApis: [...item.reactionApis, reactionApi],
                            userReactionApi: reactionApi.userId === userClient.id ? reactionApi : undefined,
                        };
                    }
                    return item;
                });
            }
            return [
                ...acc,
                {
                    letter: reactionApi.letter,
                    reactionApis: [reactionApi],
                    userReactionApi: reactionApi.userId === userClient.id ? reactionApi : undefined,
                },
            ];
        },
        [] as {
            letter: string;
            reactionApis: ReactionApi[];
            userReactionApi: ReactionApi | undefined;
        }[]
    );

    // HANDLER
    const handleSelectLetter = useCallback(
        ({ letter, userReactionApi }: { letter: string; userReactionApi: ReactionApi | undefined }) => {
            if (userReactionApi) {
                userReactionApi.remove();
                return;
            }
            messageApi.createReaction({ letter });
        },
        [messageApi]
    );

    return (
        <>
            <div className={clsx(c.root, className)}>
                {reactionApisForLetters.map(({ letter, reactionApis, userReactionApi }) => (
                    <ReactionButton
                        key={letter}
                        letter={letter}
                        reactionApis={reactionApis}
                        messageApi={messageApi}
                        userReactionApi={userReactionApi}
                    />
                ))}
                <MuiIconButton ref={addButtonRef} className={c.addButton} onClick={() => setIsOpen(true)}>
                    <SvgSmile className={c.addButtonIcon} />
                </MuiIconButton>
            </div>
            {!!addButtonRef.current && (
                <SelectReactionMenu
                    anchorEl={addButtonRef.current}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onSelected={handleSelectLetter}
                    userReactionApis={reactionApisForLetters.reduce((acc, { userReactionApi }) => {
                        if (userReactionApi) {
                            return [...acc, userReactionApi];
                        }
                        return acc;
                    }, [] as ReactionApi[])}
                />
            )}
        </>
    );
};
ReactionsView.displayName = 'ChatboardTemplate/ReactionsView';
