import { createContext, useContext } from 'react';

export type LastCheckinLog = {
    action: 'add' | 'left';
    at: Date;
    userId: string;
    spaceId: string;
};

type LastCheckinCtxType = {
    lastCheckinLog: LastCheckinLog | undefined;
};
export const LastCheckinCtx = createContext<LastCheckinCtxType>({
    lastCheckinLog: undefined,
});
export const useLastCheckinCtx = () => {
    return useContext(LastCheckinCtx);
};
