import { useState, useEffect, useRef, useReducer, useCallback } from 'react';

import { useScrollState } from '@/utils/customHooks';

export const useChatBoxState = () => {
    // REF
    const rootRef = useRef<HTMLDivElement>(null);
    const upRef = useRef<HTMLDivElement>(null);
    const downRef = useRef<HTMLDivElement>(null);

    // HOOKS
    const [prevScrollHeight, setPrevScrollHeight] = useState<number | undefined>(undefined);
    const [prevUpScrollHeight, setPrevUpScrollHeight] = useState<number | undefined>(undefined);
    const [prevDownScrollHeight, setPrevDownScrollHeight] = useState<number | undefined>(undefined);
    const [unreadCount, dispatchUnreadCount] = useReducer<
        (
            state: number,
            input: {
                increase?: boolean;
                reset?: boolean;
            }
        ) => number
    >((state, { increase, reset }) => {
        if (increase) {
            return state + 1;
        }
        if (reset) {
            return 0;
        }
        throw new Error('never');
    }, 0);
    const { isSeeingRecent, isSeeingOldest } = useScrollState<{
        isSeeingRecent: boolean;
        isSeeingOldest: boolean;
    }>({
        boxRef: rootRef,
        initialState: {
            isSeeingRecent: true,
            isSeeingOldest: true,
        },
        getState: ({ prevState, scrollBottom, scrollTop }) => {
            const isSeeingRecent = (() => {
                if (scrollBottom <= 100) {
                    return true;
                }
                return false;
            })();
            const isSeeingOldest = (() => {
                if (scrollTop <= 100) {
                    return true;
                }
                return false;
            })();
            return {
                newState: {
                    isSeeingRecent,
                    isSeeingOldest,
                },
                isUpdateState:
                    prevState.isSeeingRecent !== isSeeingRecent || prevState.isSeeingOldest !== isSeeingOldest,
            };
        },
    });
    // const isSeeingRecent = useScrollState<boolean>({
    //     boxRef: rootRef,
    //     initialState: true,
    //     getState: ({ scrollBottom, scrollTop }) => {
    //         console.log({ scrollBottom, scrollTop });
    //         const isSeeingRecent = (() => {
    //             if (scrollBottom <= 100) {
    //                 return true;
    //             }
    //             return false;
    //         })();
    //         const isSeeingOldest = (() => {
    //             if (scrollTop <= 100) {
    //                 return true;
    //             }
    //             return false;
    //         })();
    //         return isSeeingRecent;
    //     },
    // });
    const [onSeeOldest, setOnSeeOldest] = useState<(() => void) | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        console.log({ prevScrollHeight, prevUpScrollHeight, prevDownScrollHeight });
    }, [prevScrollHeight, prevUpScrollHeight, prevDownScrollHeight]);
    useEffect(() => {
        // 初期化
        if (rootRef.current && upRef.current && downRef.current) {
            setPrevScrollHeight(rootRef.current.scrollHeight);
            setPrevUpScrollHeight(upRef.current.scrollHeight);
            setPrevDownScrollHeight(downRef.current.scrollHeight);
            console.log({
                clientHeight: rootRef.current.clientHeight,
                offsetHeight: rootRef.current.offsetHeight,
                scrollHeight: rootRef.current.scrollHeight,
            });
        }
    }, []);
    useEffect(() => {
        if (isSeeingRecent) {
            dispatchUnreadCount({ reset: true });
        }
    }, [isSeeingRecent]);

    // CALLBACK
    const onAddUpstream = useCallback(() => {
        console.log('fire onAddUpstream');
        if (
            rootRef.current &&
            upRef.current &&
            downRef.current &&
            prevScrollHeight !== undefined &&
            prevUpScrollHeight !== undefined
        ) {
            console.log('fire2 onAddUpstream');
            const currentUpScrollHeight = upRef.current.scrollHeight;
            const currentWholeScrollHeight = rootRef.current.scrollHeight;
            // 上方向
            if (currentUpScrollHeight !== prevUpScrollHeight) {
                console.log('fire up onAddUpstream');
                const prevScrollTop = rootRef.current.scrollTop;
                const heightDiff = currentWholeScrollHeight - prevScrollHeight;
                const newScrollTop = prevScrollTop + heightDiff;
                rootRef.current.scrollTop = newScrollTop;
                setPrevUpScrollHeight(currentUpScrollHeight);
            }
            // 同期
            setPrevScrollHeight(currentWholeScrollHeight);
        }
    }, [prevScrollHeight, prevUpScrollHeight]);
    const onAddDownstream = useCallback(() => {
        console.log('fire onAddDownstream');
        if (rootRef.current && upRef.current && downRef.current && prevDownScrollHeight !== undefined) {
            console.log('fire2 onAddDownstream');
            const currentDownScrollHeight = downRef.current.scrollHeight;
            const currentWholeScrollHeight = rootRef.current.scrollHeight;
            // 下方向
            if (currentDownScrollHeight !== prevDownScrollHeight) {
                console.log('fire down onAddDownstream', { isSeeingRecent });
                if (isSeeingRecent) {
                    rootRef.current.scrollTo({
                        top: rootRef.current.scrollHeight - rootRef.current.clientHeight,
                        behavior: 'smooth',
                    });
                } else {
                    dispatchUnreadCount({ increase: true });
                }
                setPrevDownScrollHeight(currentDownScrollHeight);
            }
            // 同期
            setPrevScrollHeight(currentWholeScrollHeight);
        }
    }, [isSeeingRecent, prevDownScrollHeight]);
    // useEffect(() => {
    //     console.log('fire');
    //     if (
    //         rootRef.current &&
    //         upRef.current &&
    //         downRef.current &&
    //         prevScrollHeight !== undefined &&
    //         prevUpScrollHeight !== undefined &&
    //         prevDownScrollHeight !== undefined
    //     ) {
    //         console.log('fire2');
    //         const currentUpScrollHeight = upRef.current.scrollHeight;
    //         const currentDownScrollHeight = downRef.current.scrollHeight;
    //         const currentWholeScrollHeight = rootRef.current.scrollHeight;
    //         // 上方向
    //         if (currentUpScrollHeight !== prevUpScrollHeight) {
    //             console.log('fire up');
    //             const prevScrollTop = rootRef.current.scrollTop;
    //             const heightDiff = currentWholeScrollHeight - prevScrollHeight;
    //             const newScrollTop = prevScrollTop + heightDiff;
    //             rootRef.current.scrollTop = newScrollTop;
    //             setPrevUpScrollHeight(currentUpScrollHeight);
    //         }
    //         // 下方向
    //         if (currentDownScrollHeight !== prevDownScrollHeight) {
    //             console.log('fire down', { isSeeingRecent });
    //             if (isSeeingRecent) {
    //                 rootRef.current.scrollTo({
    //                     top: rootRef.current.scrollHeight - rootRef.current.clientHeight,
    //                     behavior: 'smooth',
    //                 });
    //             }
    //             setPrevDownScrollHeight(currentDownScrollHeight);
    //         }
    //         // 同期
    //         setPrevScrollHeight(currentWholeScrollHeight);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [upstreamChildren, downstreamChildren]);

    useEffect(() => {
        if (isSeeingOldest && prevUpScrollHeight && prevUpScrollHeight > 100) {
            // onSeeOldest();
            console.log('fire onSeeOldest');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSeeingOldest]);

    return {
        isSeeingRecent,
        isSeeingOldest,
        unreadCount,
        increaseUnreadCount: () => dispatchUnreadCount({ increase: true }),
        onAddUpstream,
        onAddDownstream,
        // setOnSeeOldest,
        upRef,
        downRef,
        rootRef,
    };
};
