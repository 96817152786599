import { FC, useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useChatBoxState } from '@/components/0_atom/ChatBox/useChatBoxState';

export * from '@/components/0_atom/ChatBox/useAddDownstreamMessageHandler';

const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
    upstreamChildren: {},
    downstreamChildren: {},
    fixedNotification: {},
}));

export type ChatBoxState = {
    isSeeingRecent: boolean;
    isSeeingOldest: boolean;
    unreadCount: number;
    increaseUnreadCount: () => void;
    onAddUpstream: () => void;
    onAddDownstream: () => void;
    // setOnSeeOldest: (onSeeOldest: () => void) => void;
};

type ComponentWithProps<TProps> = {
    component: FC<TProps & ChatBoxState>;
    props: TProps;
};

export const ChatBox = <InitialChildrenProps, UpstreamChildrenProps, DownstreamChildrenProps, OtherChildrenProps>({
    id,
    className,
    initialChildren,
    upstreamChildren,
    downstreamChildren,
    otherChildren,
}: {
    id?: string;
    className?: string;
    initialChildren: ComponentWithProps<InitialChildrenProps> | undefined;
    upstreamChildren: ComponentWithProps<UpstreamChildrenProps> | undefined;
    downstreamChildren: ComponentWithProps<DownstreamChildrenProps> | undefined;
    otherChildren: ComponentWithProps<OtherChildrenProps> | undefined;
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const {
        rootRef,
        upRef,
        downRef,
        isSeeingRecent,
        isSeeingOldest,
        unreadCount,
        increaseUnreadCount,
        onAddUpstream,
        onAddDownstream,
    } = useChatBoxState();

    return useMemo(
        () => (
            <div className={clsx(c.root, className)} ref={rootRef} id={id}>
                <div className={c.upstreamChildren} ref={upRef}>
                    {!!upstreamChildren && (
                        <upstreamChildren.component
                            {...{
                                isSeeingRecent,
                                isSeeingOldest,
                                unreadCount,
                                increaseUnreadCount,
                                onAddUpstream,
                                onAddDownstream,
                                ...upstreamChildren.props,
                            }}
                        />
                    )}
                </div>
                {!!initialChildren && (
                    <initialChildren.component
                        {...{
                            isSeeingRecent,
                            isSeeingOldest,
                            unreadCount,
                            increaseUnreadCount,
                            onAddUpstream,
                            onAddDownstream,
                            ...initialChildren.props,
                        }}
                    />
                )}
                <div className={c.downstreamChildren} ref={downRef}>
                    {!!downstreamChildren && (
                        <downstreamChildren.component
                            {...{
                                isSeeingRecent,
                                isSeeingOldest,
                                unreadCount,
                                increaseUnreadCount,
                                onAddUpstream,
                                onAddDownstream,
                                ...downstreamChildren.props,
                            }}
                        />
                    )}
                </div>
                {!!otherChildren && (
                    <otherChildren.component
                        {...{
                            isSeeingRecent,
                            isSeeingOldest,
                            unreadCount,
                            increaseUnreadCount,
                            onAddUpstream,
                            onAddDownstream,
                            ...otherChildren.props,
                        }}
                    />
                )}
            </div>
        ),
        [
            rootRef,
            upRef,
            downRef,
            isSeeingRecent,
            isSeeingOldest,
            unreadCount,
            increaseUnreadCount,
            onAddUpstream,
            onAddDownstream,
            id,
            className,
            initialChildren,
            upstreamChildren,
            downstreamChildren,
            otherChildren,
            c,
        ]
    );
};
ChatBox.displayName = 'ChatBox';
