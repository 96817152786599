import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SvgPlus from '@/svgs/fa-plus-solid';

import { useUserCtxAbsolutely, useSpaceMembersCtx } from '@/utils/ctxs';
import { useLanguage, SpaceMemberItem } from '@/utils/customHooks';
import { FetchingText } from '@/components/0_atom/FetchingText';
import { TextInput } from '@/components/0_atom/Input';
import { UserIconWithName } from '@/components/2_org/UserIconWithName';

const useStyles = makeStyles((theme) => ({
    li: {},
    user: {},
    userIcon: {
        width: 38,
        height: 38,
        marginRight: 10,
    },
    plus: {
        width: 15,
        height: 15,
    },
}));

export const FindMember: FC<{
    className?: string;
    userIds: string[] | undefined;
    onSelect: (spaceMemberItem: SpaceMemberItem) => void;
}> = ({ className, userIds, onSelect }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { getSpaceMemberAbsolutely } = useSpaceMembersCtx();
    const [value, setValue] = useState<string | undefined>();
    const { txt } = useLanguage();

    // DATA
    const filteredMembers = userIds?.reduce((acc, prev) => {
        const member = getSpaceMemberAbsolutely(prev);
        if (!value) {
            return [...acc, member];
        }
        if (
            new RegExp(value).test(
                `${member.spaceMembershipApi.uniqueName} ${member.spaceMembershipApi.getDisplayName() || ''}`
            )
        ) {
            return [...acc, member];
        }
        return acc;
    }, [] as SpaceMemberItem[]);

    return (
        <MuiList disablePadding className={className}>
            <TextInput
                value={value}
                setValue={setValue}
                placeholder={txt({
                    en: 'Search by Username',
                    ja: 'ユーザーネームで検索',
                })}
                fullWidth
            />
            {!filteredMembers ? (
                <MuiListItem disableGutters className={c.li}>
                    <FetchingText />
                </MuiListItem>
            ) : (
                filteredMembers.map((member) => {
                    return (
                        <MuiListItem key={member.id} disableGutters className={c.li}>
                            <UserIconWithName
                                userName={member.spaceMembershipApi.uniqueName}
                                className={c.user}
                                iconClassName={c.userIcon}
                            />
                            <MuiListItemSecondaryAction>
                                <MuiIconButton onClick={() => onSelect(member)}>
                                    <SvgPlus className={c.plus} />
                                </MuiIconButton>
                            </MuiListItemSecondaryAction>
                        </MuiListItem>
                    );
                })
            )}
        </MuiList>
    );
};
FindMember.displayName = 'ConfigTemplate/FindMember';
