import { createContext, useContext } from 'react';

import { ChatboardApi, ChatboardMembershipApi } from '@/utils/PowerchatClient';

export type ChatboardWithMembership = {
    id: string;
    chatboardApi: ChatboardApi;
    chatboardMembershipApi: ChatboardMembershipApi;
};

export type ChatboardsCtxType = {
    chatboardIds: string[] | undefined;
    getChatboardWithMembershipAbsolutely: (chatboardId: string) => ChatboardWithMembership;
    // addChatboard: (input: { chatboardApi: ChatboardApi; chatboardMembershipApi: ChatboardMembershipApi }) => void;
    // updateChatboardApi: (chatboardApi: ChatboardApi) => void;
    // updateChatboardMembershipApi: (chatboardMembershipApi: ChatboardMembershipApi) => void;
    // removeChatboard: (chatboardId: string) => void;
};

export const ChatboardsCtx = createContext<ChatboardsCtxType>({
    chatboardIds: undefined,
    getChatboardWithMembershipAbsolutely: () => {
        throw new Error('ChatboardsCtx.getChatboardWithMembershipAbsolutely: not initialized!');
    },
    // addChatboard: () => {
    //     throw new Error('ChatboardsCtx.addChatboardId: not initialized!');
    // },
    // updateChatboardApi: () => {
    //     throw new Error('ChatboardsCtx.updateApi: not initialized!');
    // },
    // updateChatboardMembershipApi: () => {
    //     throw new Error('ChatboardsCtx.updateChatboardMembershipApi: not initialized!');
    // },
    // removeChatboard: () => {
    //     throw new Error('ChatboardsCtx.removeChatboard: not initialized!');
    // },
});
export const useChatboardsCtx = () => {
    return useContext(ChatboardsCtx);
};
export const useChatboardsCtxAbsolutely = () => {
    const { chatboardIds, ...otherProps } = useChatboardsCtx();
    if (!chatboardIds) {
        throw new Error('useChatboardsCtxAbsolutely: !chatboardIds.');
    }
    return {
        chatboardIds,
        ...otherProps,
    };
};
