import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import {
    UnconfirmedMark,
    UnconfirmedMarkType,
} from '@/utils/PowerchatClient/models/UnconfirmedMark/data/UnconfirmedMark';
import { removeRdbUnconfirmedMark } from '@/utils/PowerchatClient/RealtimeDatabase';

type UnconfirmedMarkApiType = {
    // UPDATE SELF
    remove: () => Promise<void>;
};

type ConstructorInput = UnconfirmedMarkType & {
    graphqlSdk: GraphQLSdk;
};

export class UnconfirmedMarkApi extends UnconfirmedMark implements UnconfirmedMarkApiType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    // UPDATE SELF
    async remove() {
        removeRdbUnconfirmedMark({
            spaceId: this.spaceId,
            unconfirmedMark: this,
        });
        if (this.chatboardId) {
            await this._graphqlSdk.removeChatboardUnconfirmedMark({
                input: {
                    spaceId: this.spaceId,
                    chatboardId: this.chatboardId,
                    unconfirmedMarkId: this.id,
                },
            });
        } else {
            await this._graphqlSdk.removeLobbyUnconfirmedMark({
                input: {
                    spaceId: this.spaceId,
                    unconfirmedMarkId: this.id,
                },
            });
        }
    }
}
