import { GqlChatboard } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';

export type ChatboardType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    serialNumber: number | undefined;
    spaceId: string;
    uniqueName: string;
    closedAt: Date | undefined;
};

export class Chatboard implements ChatboardType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly serialNumber: number | undefined;

    readonly spaceId: string;

    readonly uniqueName: string;

    closedAt: Date | undefined;

    constructor({ id, createdAt, updatedAt, serialNumber, spaceId, uniqueName, closedAt }: ChatboardType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.serialNumber = serialNumber;
        this.spaceId = spaceId;
        this.uniqueName = uniqueName;
        this.closedAt = closedAt;
    }

    async _updateClosedAt({ closedAt, updatedAt }: { closedAt: Date; updatedAt: Date }) {
        this.closedAt = closedAt;
        this.updatedAt = updatedAt;
    }
}

export const getChatboardFromGql = ({
    id,
    createdAt,
    updatedAt,
    serialNumber,
    spaceId,
    uniqueName,
    closedAt,
}: GqlChatboard) => {
    return new Chatboard({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        serialNumber,
        spaceId,
        uniqueName,
        closedAt: isoToDateOptionally(closedAt),
    });
};
