import { FC } from 'react';

import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import { useLanguage } from '@/utils/customHooks';

import SvgEllipsis from '@/svgs/ellipsis';

const useNewStyles = ({
    theme,
    dotWidth,
    dotMarginLeft,
    dotMarginRight,
    animationKind,
}: {
    theme: Theme;
    dotWidth: number;
    dotMarginLeft: number;
    dotMarginRight: number;
    animationKind: 'move' | 'reach';
}) => {
    return makeStyles({
        '@keyframes dot': {
            '0%': {
                color: 'rgba(0,0,0,.2)',
            },
            '100%': {
                color: 'rgba(0,0,0,0)',
            },
        },
        '@keyframes dot2': {
            '0%': {
                opacity: 0,
            },
            '25%': {
                opacity: 1,
            },
            '100%': {
                opacity: 1,
            },
        },
        '@keyframes dot3': {
            '0%': {
                opacity: 0,
            },
            '50%': {
                opacity: 1,
            },
            '100%': {
                opacity: 1,
            },
        },
        '@keyframes dot4': {
            '0%': {
                opacity: 0,
            },
            '75%': {
                opacity: 1,
            },
            '100%': {
                opacity: 1,
            },
        },
        inner: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: dotWidth,
                '&:not(:last-child)': {
                    marginRight: dotMarginRight,
                },
            },
        },
        ellipsis: {},
        dots: (() => {
            if (animationKind === 'move') {
                return {
                    marginLeft: dotMarginLeft,
                    '& svg:nth-child(1)': {
                        animation: '$dot .8s linear infinite',
                    },
                    '& svg:nth-child(2)': {
                        animation: '$dot .8s linear infinite',
                        animationDelay: '0.2s',
                    },
                    '& svg:nth-child(3)': {
                        animation: '$dot .8s linear infinite',
                        animationDelay: '0.4s',
                    },
                    '& svg:nth-child(4)': {
                        animation: '$dot .8s linear infinite',
                        animationDelay: '0.6s',
                    },
                };
            }
            return {
                marginLeft: dotMarginLeft,
                '& svg:nth-child(2)': {
                    animation: '$dot2 .8s steps(1) infinite',
                },
                '& svg:nth-child(3)': {
                    animation: '$dot3 .8s steps(1) infinite',
                },
                '& svg:nth-child(4)': {
                    animation: '$dot4 .8s steps(1) infinite',
                },
            };
        })(),
    })();
};

export const FetchingText: FC<{
    className?: string;
    text?: string;
    size?: 'minimum';
    animationKind?: 'move' | 'reach';
}> = ({ className, text, size, animationKind }) => {
    // STYLE
    const c = useNewStyles({
        theme: useTheme(),
        dotWidth: size === 'minimum' ? 1.5 : 2,
        dotMarginLeft: size === 'minimum' ? 2 : 4,
        dotMarginRight: size === 'minimum' ? 2 : 3,
        animationKind: animationKind || 'reach',
    });

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={className}>
            <div className={c.inner}>
                <span>{text || txt({ en: 'Fetching', ja: '取得中' })}</span>
                <span className={c.dots}>
                    <SvgEllipsis className={c.ellipsis} />
                    <SvgEllipsis className={c.ellipsis} />
                    <SvgEllipsis className={c.ellipsis} />
                    <SvgEllipsis className={c.ellipsis} />
                </span>
            </div>
        </div>
    );
};
FetchingText.displayName = 'FetchingText';
