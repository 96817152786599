import { ref, set } from 'firebase/database';
import { collection, doc } from 'firebase/firestore';
import { getFirebaseRealtimeDatabase, getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import { Reaction } from '@/utils/PowerchatClient';
import { AddRdbLastReactionInput, getRdbLastReactionInput } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbReaction = ({
    spaceId,
    newReaction,
}: {
    spaceId: string;
    newReaction: AddRdbLastReactionInput;
}) => {
    const firestoreDb = getFirebaseFirestore();
    const { id } = doc(
        collection(
            firestoreDb,
            `Space/${spaceId}/Chatboard/${newReaction.chatboardId}/Message/${newReaction.messageId}/Reaction`
        )
    );
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastReactionRef = ref(realtimeDb, `lastReactions/${spaceId}`);
    set(lastReactionRef, {
        action: 'add',
        ...getRdbLastReactionInput({
            ...newReaction,
            id,
            createdAt: 'serverTimestamp',
            removedAt: undefined,
        }),
    });
    return {
        newReaction: new Reaction({
            id,
            createdAt: new Date(),
            userId: newReaction.userId,
            spaceId,
            chatboardId: newReaction.chatboardId,
            messageId: newReaction.messageId,
            letter: newReaction.letter,
        }),
    };
};
