import { GqlSpaceMembership } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import { SpacePermission, SpacePermissionType } from '@/utils/PowerchatClient/models/_interfaces';

export type SpaceMembershipType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    serialNumber: number | undefined;
    userId: string;
    spaceId: string;
    uniqueName: string;
    displayName: string | undefined;
    permission: SpacePermissionType;
    isOpenPresence: boolean;
};

export class SpaceMembership implements SpaceMembershipType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly serialNumber: number | undefined;

    readonly userId: string;

    readonly spaceId: string;

    readonly uniqueName: string;

    displayName: string | undefined;

    permission: SpacePermission;

    isOpenPresence: boolean;

    constructor({
        id,
        createdAt,
        updatedAt,
        serialNumber,
        userId,
        spaceId,
        uniqueName,
        displayName,
        permission,
        isOpenPresence,
    }: SpaceMembershipType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.serialNumber = serialNumber;
        this.userId = userId;
        this.spaceId = spaceId;
        this.uniqueName = uniqueName;
        this.displayName = displayName;
        this.permission = new SpacePermission(permission);
        this.isOpenPresence = isOpenPresence;
    }

    async _updateDisplayName({
        displayName,
        updatedAt,
    }: {
        displayName: string | undefined;
        updatedAt: Date | undefined;
    }) {
        this.displayName = displayName;
        this.updatedAt = updatedAt;
    }

    async _updatePermission({
        permission,
        updatedAt,
    }: {
        permission: SpacePermissionType;
        updatedAt: Date | undefined;
    }) {
        this.permission = new SpacePermission(permission);
        this.updatedAt = updatedAt;
    }

    async _updateIsOpenPresence({
        isOpenPresence,
        updatedAt,
    }: {
        isOpenPresence: boolean;
        updatedAt: Date | undefined;
    }) {
        this.isOpenPresence = isOpenPresence;
        this.updatedAt = updatedAt;
    }
}

export const getSpaceMembershipFromGql = ({
    id,
    createdAt,
    updatedAt,
    serialNumber,
    userId,
    spaceId,
    uniqueName,
    displayName,
    permission,
    isOpenPresence,
}: GqlSpaceMembership) => {
    return new SpaceMembership({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        serialNumber,
        userId,
        spaceId,
        uniqueName,
        displayName: displayName || undefined,
        permission,
        isOpenPresence,
    });
};
