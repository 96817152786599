import { FC, MouseEvent } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi } from '@/utils/PowerchatClient';
import { useLobbyReplyMessages } from '@/utils/customHooks';
import { ChatBoxState } from '@/components/0_atom/ChatBox';
import {
    MessageCard,
    OpenMessageMenu,
} from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/MessageCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {},
}));

export const ThreadViewContent: FC<
    ChatBoxState & {
        className?: string;
        master: {
            messageApi: MessageApi;
            initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
            initialReplyObserverApis: ReplyObserverApi[];
        };
        messageIds: string[];
        getMessageItemAbsolutely: (id: string) => {
            id: string;
            messageApi: MessageApi;
            initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
            initialReplyObserverApis: ReplyObserverApi[];
        };
        openMessageMenu: OpenMessageMenu;
    }
> = ({
    unreadCount,
    increaseUnreadCount,
    onAddDownstream,
    onAddUpstream,
    isSeeingOldest,
    isSeeingRecent,
    className,
    master,
    messageIds,
    getMessageItemAbsolutely,
    openMessageMenu,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <MessageCard
                messageApi={master.messageApi}
                initialReplyObserverApis={master.initialReplyObserverApis}
                initialUnconfirmedMarkApi={master.initialUnconfirmedMarkApi}
                openMessageMenu={openMessageMenu}
            />
            {messageIds.map((messageId) => {
                const { messageApi, initialReplyObserverApis, initialUnconfirmedMarkApi } =
                    getMessageItemAbsolutely(messageId);
                return (
                    <MessageCard
                        key={`${messageId}-reply`}
                        messageApi={messageApi}
                        initialReplyObserverApis={initialReplyObserverApis}
                        initialUnconfirmedMarkApi={initialUnconfirmedMarkApi}
                        openMessageMenu={openMessageMenu}
                    />
                );
            })}
        </div>
    );
};
ThreadViewContent.displayName = 'ThreadViewContent';
