import { useEffect, useCallback } from 'react';

import { listenToHotUsersForSpace, RdbUser } from '@/utils/PowerchatClient';
import { useSpacesCtx } from '@/utils/ctxs';
import { useLazyAssociativeItems } from '@/utils/customHooks/useAssociativeItems';

export type HotUsers = {
    [userId: string]: RdbUser;
};
type AllHotUsers = {
    [spaceId: string]: HotUsers;
};

export const useAllHotMembers = () => {
    // CTX
    const spacesCtx = useSpacesCtx();

    // HOOKS
    const { itemDatas, getItem, addItem, updateItem } = useLazyAssociativeItems<HotUsers>(undefined);

    // DATA
    const spaceIds = spacesCtx?.spaceIds;

    // CALLBACK
    const initListen = useCallback(() => {
        if (spaceIds) {
            const unsbscribers = spaceIds.map((spaceId) => {
                return listenToHotUsersForSpace({
                    spaceId,
                    onAdded: ({ newUser }) => {
                        const targetItem = getItem(spaceId);
                        if (targetItem) {
                            updateItem({
                                id: spaceId,
                                updator: (prevItem) => ({
                                    ...prevItem,
                                    [newUser.id]: newUser,
                                }),
                                isIgnoreMissingError: true,
                            });
                        } else {
                            addItem({
                                id: spaceId,
                                newItem: {
                                    [newUser.id]: newUser,
                                },
                            });
                        }
                    },
                    onChanged: ({ changedUser }) => {
                        updateItem({
                            id: spaceId,
                            updator: (prevItem) => ({
                                ...prevItem,
                                [changedUser.id]: changedUser,
                            }),
                            isIgnoreMissingError: true,
                        });
                    },
                    onRemoved: ({ removedUser }) => {
                        updateItem({
                            id: spaceId,
                            updator: (prevItem) => {
                                const newItem = prevItem;
                                delete newItem[removedUser.id];
                                return newItem;
                            },
                            isIgnoreMissingError: true,
                        });
                    },
                });
            });
            return {
                unsubscribeAll: () => {
                    unsbscribers.forEach(({ unsubscribeListenToHotUsersForSpace }) =>
                        unsubscribeListenToHotUsersForSpace()
                    );
                },
            };
        }
        return {
            unsubscribeAll: undefined,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaceIds]);

    // USEEFFECT
    useEffect(() => {
        const { unsubscribeAll } = initListen();
        return unsubscribeAll;
    }, [initListen]);

    // CALLBACK
    const getHotUser = useCallback(
        ({ spaceId, userId }: { spaceId: string; userId: string }) => {
            const item = getItem(spaceId);
            if (item) {
                return item[userId];
            }
            return undefined;
        },
        [getItem]
    );

    return {
        allHotUsers: itemDatas as AllHotUsers | undefined,
        getHotUsersForSpace: getItem as (spaceId: string) => HotUsers | undefined,
        getHotUser,
    };
};
