import { FC, useState, useRef, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';

import { RdbUser } from '@/utils/PowerchatClient';
import { useSpacesCtx } from '@/utils/ctxs';
import { useLanguage, HotUsers } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { SpaceItem } from '@/components/3_template/ConsoleTemplate/Sidebar/SpaceItem';
import { PowerButton } from '@/components/3_template/ConsoleTemplate/Sidebar/PowerButton';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        position: 'fixed',
        zIndex: 1000,
        padding: '19px 0 19px 4px',
        backdropFilter: 'blur(14px)',
        transitionProperty: 'width, background',
        transitionDuration: '.6s',
        transitionTimingFunction: 'cubic-bezier(0, 0, 0.17, 0.9)',
    },
    rootFullsreen: {
        width: '100vw',
        background: '#3C3C3E',
    },
    rootSideExpanded: {
        width: 360,
        background: 'rgba(255,255,255,.09)',
    },
    rootSideCollapsed: {
        width: 56,
        background: 'rgba(255,255,255,.09)',
    },
    list: {
        transitionProperty: 'top, left, transform',
        transitionDuration: '.6s',
        transitionTimingFunction: 'cubic-bezier(0, 0, 0.17, 0.9)',
    },
    listCentered: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
    },
    listAbsoluteLeftTop: {
        position: 'absolute',
        top: 19,
        left: 4,
        transform: 'translateY(0) translateX(0)',
    },
    contentInFullscreen: {
        borderRadius: 12,
        // paddingLeft: 10,
        // paddingRight: 6,
    },
    norooms: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
    },
    powerButtonBox: {
        position: 'fixed',
        left: 0,
        bottom: 0,
    },
}));

export const Sidebar: FC<{
    getHotUsersForSpace: (spaceId: string) => HotUsers | undefined;
    getHotUser: ({ spaceId, userId }: { spaceId: string; userId: string }) => RdbUser | undefined;
}> = ({ getHotUsersForSpace, getHotUser }) => {
    // STYLE
    const c = useStyles(useTheme());

    // REF
    const rootRef = useRef<HTMLDivElement>(null);

    // CTXS
    const spacesCtx = useSpacesCtx();

    // HOOKS
    const { txt } = useLanguage();
    const [isFullscreen, setIsFullscreen] = useState(true);
    const [isContentExpanded, setIsContentExpanded] = useState(false);
    const [listPositionClassName, setListPositionClassName] = useState<string | undefined>(c.listCentered);

    // CALLBACK
    const startCollapse = useCallback(() => {
        setIsContentExpanded(false);
        setTimeout(() => {
            setIsFullscreen(false);
            setListPositionClassName(c.listAbsoluteLeftTop);
            setTimeout(() => {
                setListPositionClassName(undefined);
            }, 600);
        }, 400);
    }, [c.listAbsoluteLeftTop]);
    const startExpand = useCallback(() => {
        setIsContentExpanded(false);
        setListPositionClassName(c.listAbsoluteLeftTop);
        setIsFullscreen(true);
        setTimeout(() => {
            setListPositionClassName(c.listCentered);
            setTimeout(() => {
                setIsContentExpanded(true);
            }, 600);
        }, 400);
    }, [c.listAbsoluteLeftTop, c.listCentered]);

    useEffect(() => {
        if (spacesCtx?.spaceIds) {
            setTimeout(() => {
                setIsContentExpanded(true);
            }, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!spacesCtx?.spaceIds]);

    return (
        <div
            ref={rootRef}
            className={clsx(c.root, {
                [c.rootFullsreen]: isFullscreen,
                [c.rootSideExpanded]: !isFullscreen && isContentExpanded,
                [c.rootSideCollapsed]: !isFullscreen && !isContentExpanded,
            })}
            onMouseEnter={() => setIsContentExpanded(true)}
            onMouseLeave={() => setIsContentExpanded(false)}
        >
            <MuiList className={clsx(c.list, listPositionClassName)} disablePadding>
                {!spacesCtx?.spaceIds ? (
                    <LoadingIndicator />
                ) : spacesCtx.spaceIds.length > 0 ? (
                    spacesCtx.spaceIds.map((spaceId) => {
                        const { spaceApi, spaceMembershipApi } = spacesCtx.getSpaceWithMembershipAbsolutely(spaceId);
                        const hotUsers = getHotUsersForSpace(spaceId);
                        return (
                            <SpaceItem
                                key={spaceId}
                                spaceApi={spaceApi}
                                spaceMembershipApi={spaceMembershipApi}
                                isExpanded={isContentExpanded}
                                startCollapse={startCollapse}
                                setIsExpanded={setIsContentExpanded}
                                className={clsx({
                                    [c.contentInFullscreen]: isFullscreen || !!listPositionClassName,
                                })}
                                hotUserIds={hotUsers ? Object.keys(hotUsers) : undefined}
                                getHotUser={(userId: string) => getHotUser({ spaceId, userId })}
                            />
                        );
                    })
                ) : (
                    <MuiListItem className={c.norooms}>
                        <MuiListItemText
                            primary={txt({
                                en: "No spaces you're in",
                                ja: '参加しているスペースがありません',
                            })}
                        />
                    </MuiListItem>
                )}
            </MuiList>
            <div className={c.powerButtonBox}>
                <PowerButton
                    startExpand={startExpand}
                    onClose={(mouseEvent) => {
                        if (mouseEvent && rootRef.current) {
                            const { clientX } = mouseEvent;
                            const { clientWidth: sidebarWidth } = rootRef.current;
                            if (clientX > sidebarWidth) {
                                setIsContentExpanded(false);
                            } else {
                                setIsContentExpanded(true);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};
Sidebar.displayName = 'Sidebar';
