import { serverTimestamp } from 'firebase/database';
import { SpacePermissionType } from '@/utils/PowerchatClient/models/_interfaces';

// NOTIFICATION
export type RdbLastSpaceMembershipAddNotification = {
    spaceName: string;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    toUserDisplayName: string | undefined;
};
export type RdbLastSpaceMembershipModifyNotification = {
    spaceName: string;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    toUserDisplayName: string | undefined;
};
export type RdbLastSpaceMembershipRemoveNotification = {
    spaceName: string;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    toUserDisplayName: string | undefined;
};

// TYPE
export type RdbLastSpaceMembership = {
    action: 'add' | 'modify' | 'remove';
    id: string;
    createdAt: number;
    updatedAt: number | undefined;
    removedAt: number | undefined;
    userId: string;
    uniqueName: string;
    displayName: string | undefined;
    permission: SpacePermissionType;
    isOpenPresence: boolean;
    // for notification
    addNotification: RdbLastSpaceMembershipAddNotification | undefined;
    modifyNotification: RdbLastSpaceMembershipModifyNotification | undefined;
    removeNotification: RdbLastSpaceMembershipRemoveNotification | undefined;
};

// INPUT
export type RdbLastSpaceMembershipInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    removedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    uniqueName: string;
    displayName: string | undefined;
    permission: SpacePermissionType;
    isOpenPresence: boolean;
    // for notification
    addNotification: RdbLastSpaceMembershipAddNotification | undefined;
    modifyNotification: RdbLastSpaceMembershipModifyNotification | undefined;
    removeNotification: RdbLastSpaceMembershipRemoveNotification | undefined;
};
export type AddRdbLastSpaceMembershipInput = {
    id: string;
    userId: string;
    uniqueName: string;
    displayName: string | undefined;
    permission: SpacePermissionType;
    isOpenPresence: boolean;
    // for notification
    addNotification: RdbLastSpaceMembershipAddNotification;
};
export type ModifyRdbLastSpaceMembershipInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    uniqueName: string;
    displayName: string | undefined;
    permission: SpacePermissionType;
    isOpenPresence: boolean;
    // for notification
    modifyNotification: RdbLastSpaceMembershipModifyNotification;
};
export type RemoveRdbLastSpaceMembershipInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    uniqueName: string;
    displayName: string | undefined;
    permission: SpacePermissionType;
    isOpenPresence: boolean;
    // for notification
    removeNotification: RdbLastSpaceMembershipRemoveNotification;
};

export const getRdbLastSpaceMembershipInput = ({
    id,
    createdAt,
    updatedAt,
    removedAt,
    userId,
    uniqueName,
    displayName,
    permission,
    isOpenPresence,
    addNotification,
    modifyNotification,
    removeNotification,
}: RdbLastSpaceMembershipInput): {
    id: string;
    createdAt: number | ReturnType<typeof serverTimestamp>;
    updatedAt: number | ReturnType<typeof serverTimestamp> | null;
    removedAt: number | ReturnType<typeof serverTimestamp> | null;
    userId: string;
    uniqueName: string;
    displayName: string | null;
    permission: SpacePermissionType;
    isOpenPresence: boolean;
    // for notification
    addNotification: {
        spaceName: string;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        toUserDisplayName: string | null;
    } | null;
    modifyNotification: {
        spaceName: string;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        toUserDisplayName: string | null;
    } | null;
    removeNotification: {
        spaceName: string;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        toUserDisplayName: string | null;
    } | null;
} => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        updatedAt: updatedAt === 'serverTimestamp' ? serverTimestamp() : updatedAt ? updatedAt.getTime() : null,
        removedAt: removedAt === 'serverTimestamp' ? serverTimestamp() : removedAt ? removedAt.getTime() : null,
        userId,
        uniqueName,
        displayName: displayName || null,
        permission: {
            admin: permission.admin,
            lobby: {
                read: permission.lobby.read,
                write: permission.lobby.write,
            },
        },
        isOpenPresence,
        addNotification: (() => {
            if (addNotification) {
                return {
                    spaceName: addNotification.spaceName,
                    fromUserUniqueName: addNotification.fromUserUniqueName,
                    fromUserDisplayName: addNotification.fromUserDisplayName || null,
                    toUserDisplayName: addNotification.toUserDisplayName || null,
                };
            }
            return null;
        })(),
        modifyNotification: (() => {
            if (modifyNotification) {
                return {
                    spaceName: modifyNotification.spaceName,
                    fromUserUniqueName: modifyNotification.fromUserUniqueName,
                    fromUserDisplayName: modifyNotification.fromUserDisplayName || null,
                    toUserDisplayName: modifyNotification.toUserDisplayName || null,
                };
            }
            return null;
        })(),
        removeNotification: (() => {
            if (removeNotification) {
                return {
                    spaceName: removeNotification.spaceName,
                    fromUserUniqueName: removeNotification.fromUserUniqueName,
                    fromUserDisplayName: removeNotification.fromUserDisplayName || null,
                    toUserDisplayName: removeNotification.toUserDisplayName || null,
                };
            }
            return null;
        })(),
    };
};
