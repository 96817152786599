import { useChatboardsAndLastChatboardMembershipLog } from '@/utils/customHooks/useSpace/useChatboardsAndLastChatboardMembershipLog';
import { useLastMessageLog } from '@/utils/customHooks/useSpace/useLastMessageLog';
import { useLastReactionLog } from '@/utils/customHooks/useSpace/useLastReactionLog';
import { useLastReplyObserverLog } from '@/utils/customHooks/useSpace/useLastReplyObserverLog';
import { useLastUnconfirmedMarkLog } from '@/utils/customHooks/useSpace/useLastUnconfirmedMarkLog';
import { useSpaceMembershipsAndLastCheckinLog } from '@/utils/customHooks/useSpace/useSpaceMembershipsAndLastCheckinLog';

export const useSpace = () => {
    const { chatboardIds, getChatboardWithMembershipAbsolutely, lastChatboardMembershipLog } =
        useChatboardsAndLastChatboardMembershipLog();
    const { lastMessageLog } = useLastMessageLog();
    const { lastReactionLog } = useLastReactionLog();
    const { lastReplyObserverLog } = useLastReplyObserverLog();
    const { lastUnconfirmedMarkLog } = useLastUnconfirmedMarkLog();
    const { userIds, getSpaceMember, getSpaceMemberAbsolutely, lastCheckinLog } =
        useSpaceMembershipsAndLastCheckinLog();

    return {
        chatboardIds,
        getChatboardWithMembershipAbsolutely,
        lastChatboardMembershipLog,
        lastMessageLog,
        lastReactionLog,
        lastReplyObserverLog,
        lastUnconfirmedMarkLog,
        lastCheckinLog,
        userIds,
        getSpaceMember,
        getSpaceMemberAbsolutely,
    };
};
