import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { SpaceMembership } from '@/utils/PowerchatClient/models';
import {
    RdbLastSpaceMembership,
    RdbLastSpaceMembershipAddNotification,
    RdbLastSpaceMembershipModifyNotification,
    RdbLastSpaceMembershipRemoveNotification,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToSpaceMemberships = ({
    spaceId,
    onAdded,
    onModified,
    onRemoved,
}: {
    spaceId: string;
    onAdded: (input: {
        newSpaceMembership: SpaceMembership;
        addNotification: RdbLastSpaceMembershipAddNotification;
    }) => void;
    onModified: (input: {
        modifiedSpaceMembership: SpaceMembership;
        modifyNotification: RdbLastSpaceMembershipModifyNotification;
    }) => void;
    onRemoved: (input: {
        removedSpaceMembership: SpaceMembership;
        removeNotification: RdbLastSpaceMembershipRemoveNotification;
    }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastSpaceMembershipRef = ref(db, `lastSpaceMemberships/${spaceId}`);
    const firedAt = new Date();
    const unsubscribeListenToSpaceMemberships = onValue(lastSpaceMembershipRef, (data) => {
        if (data.exists()) {
            const {
                action,
                removedAt,
                addNotification,
                modifyNotification,
                removeNotification,
                ...spaceMembershipData
            }: RdbLastSpaceMembership = data.val();
            const spaceMembership = new SpaceMembership({
                id: spaceMembershipData.id,
                createdAt: new Date(spaceMembershipData.createdAt),
                updatedAt: spaceMembershipData.updatedAt ? new Date(spaceMembershipData.updatedAt) : undefined,
                serialNumber: undefined,
                userId: spaceMembershipData.userId,
                spaceId,
                uniqueName: spaceMembershipData.uniqueName,
                displayName: spaceMembershipData.displayName,
                permission: spaceMembershipData.permission,
                isOpenPresence: spaceMembershipData.isOpenPresence,
            });
            if (firedAt <= spaceMembership.createdAt && action === 'add' && addNotification) {
                onAdded({
                    addNotification,
                    newSpaceMembership: spaceMembership,
                });
            } else if (
                spaceMembership.updatedAt &&
                firedAt <= spaceMembership.updatedAt &&
                action === 'modify' &&
                modifyNotification
            ) {
                onModified({
                    modifyNotification,
                    modifiedSpaceMembership: spaceMembership,
                });
            } else if (removedAt && firedAt <= new Date(removedAt) && action === 'remove' && removeNotification) {
                onRemoved({
                    removeNotification,
                    removedSpaceMembership: spaceMembership,
                });
            }
        }
    });
    return {
        unsubscribeListenToSpaceMemberships,
    };
};
