import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 92 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23 10.5C23 4.70101 27.701 0 33.5 0H78.5C84.299 0 89 4.70101 89 10.5C89 16.299 84.299 21 78.5 21H33.5C27.701 21 23 16.299 23 10.5Z"
                fill="#FF83A1"
                fillOpacity="0.8"
            />
            <path
                d="M23 34.5C23 28.701 27.701 24 33.5 24H70.5C76.299 24 81 28.701 81 34.5C81 40.299 76.299 45 70.5 45H33.5C27.701 45 23 40.299 23 34.5Z"
                fill="#DCB83B"
                fillOpacity="0.8"
            />
            <path
                d="M23 58.5C23 52.701 27.701 48 33.5 48H81.5C87.299 48 92 52.701 92 58.5C92 64.299 87.299 69 81.5 69H33.5C27.701 69 23 64.299 23 58.5Z"
                fill="#9D75F4"
                fillOpacity="0.8"
            />
            <path
                d="M19 10.5C19 15.7467 14.7467 20 9.5 20C4.25329 20 0 15.7467 0 10.5C0 5.25329 4.25329 1 9.5 1C14.7467 1 19 5.25329 19 10.5Z"
                fill="#FF83A1"
                fillOpacity="0.8"
            />
            <path
                d="M19 34.5C19 39.7467 14.7467 44 9.5 44C4.25329 44 0 39.7467 0 34.5C0 29.2533 4.25329 25 9.5 25C14.7467 25 19 29.2533 19 34.5Z"
                fill="#DCB83B"
                fillOpacity="0.8"
            />
            <path
                d="M19 58.5C19 63.7467 14.7467 68 9.5 68C4.25329 68 0 63.7467 0 58.5C0 53.2533 4.25329 49 9.5 49C14.7467 49 19 53.2533 19 58.5Z"
                fill="#9D75F4"
                fillOpacity="0.8"
            />
        </svg>
    );
};
Svg.displayName = 'svg/chatboard';
export default Svg;
