import { GqlChatboardPermission } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';

export type ChatboardPermissionType = {
    read: boolean;
    write: boolean;
};

export class ChatboardPermission implements ChatboardPermissionType {
    read: boolean;

    write: boolean;

    constructor({ read, write }: ChatboardPermissionType) {
        this.read = read;
        this.write = write;
    }
}

export const getChatboardPermissionFromGql = ({ read, write }: GqlChatboardPermission) => {
    return new ChatboardPermission({
        read,
        write,
    });
};
