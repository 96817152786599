import { FC, useState } from 'react';

import {
    ChatboardApi,
    MessageApi,
    ReplyObserverApi,
    UnconfirmedMarkApi,
    ReactionApi,
    MessageWithMark,
} from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useReplyObservers, useUnconfirmedMark, useChatboardReplyMessages, useReactions } from '@/utils/customHooks';

import { Window } from '@/components/0_atom/Window';
import { ThreadView } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/ThreadView';
import { MessageCardUI } from './MessageCardUI';
import { MessageCardMenu } from './Menu';

export const MainMessageCard: FC<{
    className?: string;
    chatboardApi: ChatboardApi;
    messageApi: MessageApi;
    initialReplyObserverApis: ReplyObserverApi[];
    initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
    initialReactionApis: ReactionApi[];
    initialReplies: Omit<MessageWithMark, 'replyObserverApis'>[];
    isOmitUser?: boolean;
}> = ({
    className,
    chatboardApi,
    messageApi,
    initialUnconfirmedMarkApi,
    initialReplyObserverApis,
    initialReactionApis,
    initialReplies,
    isOmitUser,
}) => {
    // CTX
    const { userClient } = useUserCtxAbsolutely();

    // HOOKS
    const { unconfirmedMarkApi } = useUnconfirmedMark({
        messageId: messageApi.id,
        initialUnconfirmedMarkApi,
    });
    const { replyObserverApis } = useReplyObservers({
        messageId: messageApi.id,
        initialReplyObserverApis: initialReplyObserverApis || [],
        fromUserId: userClient.id,
    });
    const { reactionApis } = useReactions({
        messageId: messageApi.id,
        initialReactionApis,
    });
    const { replies } = useChatboardReplyMessages({
        chatboardId: chatboardApi.id,
        masterMessageId: messageApi.id,
        initialReplies,
    });
    const [messageRowElm, setMessageRowElm] = useState<HTMLDivElement | undefined>(undefined);
    const [messageCardElm, setMessageCardElm] = useState<HTMLDivElement | undefined>(undefined);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isThreadOpen, setIsThreadOpen] = useState(false);

    return (
        <>
            <MessageCardUI
                className={className}
                messageApi={messageApi}
                reactionApis={reactionApis}
                unconfirmedMarkApi={unconfirmedMarkApi}
                replyObserverApis={replyObserverApis}
                onContextMenu={({ rootElm, cardElm }) => {
                    setMessageCardElm(cardElm);
                    setMessageRowElm(rootElm);
                    setIsMenuOpen(true);
                }}
                replis={replies}
                openThread={({ rootElm, cardElm }) => {
                    setMessageCardElm(cardElm);
                    setMessageRowElm(rootElm);
                    setIsThreadOpen(true);
                }}
                isOmitUser={isOmitUser}
            />
            {!!messageCardElm && (
                <>
                    <MessageCardMenu
                        anchorEl={messageCardElm}
                        isOpen={isMenuOpen}
                        onClose={() => setIsMenuOpen(false)}
                        messageApi={messageApi}
                        unconfirmedMarkApi={unconfirmedMarkApi}
                        reactionApis={reactionApis}
                        replyObserverApis={replyObserverApis}
                        openThread={() => setIsThreadOpen(true)}
                        option={{
                            cancel: true,
                            unconfirmedMark: true,
                            replyObserver: true,
                            reaction: true,
                            thread: true,
                        }}
                    />
                    <Window
                        windowKey={`chatboard-${chatboardApi.id}-thread-${messageApi.id}`}
                        initialHeight={239}
                        isOpen={isThreadOpen}
                        onClose={() => setIsThreadOpen(false)}
                        anchorEl={messageRowElm}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        anchorOffset={{
                            top: 0,
                            left: 5,
                        }}
                    >
                        <ThreadView
                            chatboardApi={chatboardApi}
                            thread={{
                                master: {
                                    messageApi,
                                    reactionApis,
                                    replyObserverApis,
                                    unconfirmedMarkApi,
                                },
                                replies,
                            }}
                        />
                    </Window>
                </>
            )}
        </>
    );
};
MainMessageCard.displayName = 'ChatboardTemplate/MainMessageCard';
