import { FC, useState, useCallback, useRef } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgSend from '@/svgs/fa-paper-plane-solid';

import { TextInput } from '@/components/0_atom/Input';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'rgba(255,255,255,.1)',
        borderRadius: 14,
        position: 'relative',
    },
    input: {
        '& textarea': {
            padding: '5px 13px 5px 13px',
            height: 'auto',
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderTop: 'none',
            lineHeight: '1.5',
        },
    },
    submitButton: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: 9,
        '& svg': {
            width: 10,
            height: 10,
        },
    },
}));

export const AddMessageInput: FC<{
    className?: string;
    handleSendMessage: (input: { body: string }) => Promise<void>;
    placeholder?: string;
    isPermitted: boolean;
    isAutoFocus?: boolean;
}> = ({ className, handleSendMessage, placeholder, isPermitted, isAutoFocus }) => {
    // STYLE
    const c = useStyles(useTheme());

    // REF
    const inputRef = useRef<HTMLInputElement>(null);

    // HOOKS
    const [text, setText] = useState<string | undefined>(undefined);

    // CALLBACK
    const runCreateMessage = useCallback(async () => {
        // まずはすぐにInputを空にし、操作性を軽くする
        // later: messages に status フィールドを用意し、送信中やエラーなどをもつ
        const body = text;
        setText(undefined);
        inputRef.current?.focus();
        if (body) {
            await handleSendMessage({ body });
            return;
        }
        throw new Error('never');
    }, [text, handleSendMessage]);

    return (
        <div className={clsx(c.root, className)}>
            <TextInput
                autoFocus={isAutoFocus}
                inputRef={inputRef}
                fullWidth
                className={c.input}
                value={text}
                setValue={setText}
                placeholder={placeholder}
                multiline
                disabled={!isPermitted}
            />
            <MuiIconButton onClick={runCreateMessage} disabled={!text || !isPermitted} className={c.submitButton}>
                <SvgSend />
            </MuiIconButton>
        </div>
    );
};
AddMessageInput.displayName = 'AddMessageInput';
