import { createContext, useContext } from 'react';
import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi } from '@/utils/PowerchatClient';

export type LastMessageLog = {
    action: 'add' | 'modify';
    messageApi: MessageApi;
    initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
    initialReplyObserverApis: ReplyObserverApi[];
};

type LastMessageCtxType = {
    lastMessageLog: LastMessageLog | undefined;
};
export const LastMessageCtx = createContext<LastMessageCtxType>({
    lastMessageLog: undefined,
});
export const useLastMessageCtx = () => {
    return useContext(LastMessageCtx);
};
