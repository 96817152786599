import { FC, useState } from 'react';

import { MessageApi, ReplyObserverApi, UnconfirmedMarkApi, ReactionApi } from '@/utils/PowerchatClient';
import { MessageCardUI } from './MessageCardUI';
import { MessageCardMenu } from './Menu';

export const ThreadMasterMessageCard: FC<{
    className?: string;
    messageApi: MessageApi;
    replyObserverApis: ReplyObserverApi[];
    unconfirmedMarkApi: UnconfirmedMarkApi | undefined;
    reactionApis: ReactionApi[];
}> = ({ className, messageApi, reactionApis, replyObserverApis, unconfirmedMarkApi }) => {
    // HOOKS
    const [messageCardElm, setMessageCardElm] = useState<HTMLDivElement | undefined>(undefined);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <MessageCardUI
                className={className}
                messageApi={messageApi}
                reactionApis={reactionApis}
                unconfirmedMarkApi={unconfirmedMarkApi}
                replyObserverApis={replyObserverApis}
                onContextMenu={({ cardElm }) => {
                    setMessageCardElm(cardElm);
                    setIsMenuOpen(true);
                }}
            />
            {!!messageCardElm && (
                <MessageCardMenu
                    anchorEl={messageCardElm}
                    isOpen={isMenuOpen}
                    onClose={() => setIsMenuOpen(false)}
                    messageApi={messageApi}
                    unconfirmedMarkApi={unconfirmedMarkApi}
                    reactionApis={reactionApis}
                    replyObserverApis={replyObserverApis}
                    openThread={undefined}
                    option={{
                        cancel: true,
                        unconfirmedMark: true,
                        replyObserver: true,
                        reaction: true,
                        thread: false,
                    }}
                />
            )}
        </>
    );
};
ThreadMasterMessageCard.displayName = 'ChatboardTemplate/ThreadMasterMessageCard';
