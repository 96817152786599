import { useEffect, useState } from 'react';

import { useSpaceCtx, LastMessageLog } from '@/utils/ctxs';

export const useLastMessageLog = () => {
    // HOOKS
    const { current: currentSpaceCtx } = useSpaceCtx();
    const [lastMessageLog, setLastMessageLog] = useState<LastMessageLog | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        if (currentSpaceCtx) {
            const { spaceApi } = currentSpaceCtx;
            const { unsubscribeListenToMessages } = spaceApi.listenToMessages({
                onAdded: ({ newMessageApi, initialUnconfirmedMarkApi, initialReplyObserverApis }) => {
                    setLastMessageLog({
                        action: 'add',
                        messageApi: newMessageApi,
                        initialUnconfirmedMarkApi,
                        initialReplyObserverApis,
                    });
                },
                onModified: ({ modifiedMessageApi }) => {
                    setLastMessageLog({
                        action: 'modify',
                        messageApi: modifiedMessageApi,
                        initialUnconfirmedMarkApi: undefined,
                        initialReplyObserverApis: [],
                    });
                },
            });
            return unsubscribeListenToMessages;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSpaceCtx?.spaceApi.id]);

    return {
        lastMessageLog,
    };
};
