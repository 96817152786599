import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { removeRdbReaction } from '@/utils/PowerchatClient/RealtimeDatabase';
import { Reaction, ReactionType } from '@/utils/PowerchatClient/models/Reaction/data/Reaction';

type ReactionApiType = {
    // UPDATE SELF
    remove: () => Promise<void>;
};

type ConstructorInput = ReactionType & {
    graphqlSdk: GraphQLSdk;
};

export class ReactionApi extends Reaction implements ReactionApiType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    // UPDATE SELF
    async remove() {
        removeRdbReaction({
            spaceId: this.spaceId,
            reaction: {
                ...this,
            },
        });
        await this._graphqlSdk.removeReaction({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.chatboardId,
                messageId: this.messageId,
                reactionId: this.id,
            },
        });
    }
}
