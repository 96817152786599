import { createContext, useContext } from 'react';

import { SpaceMembershipApi, RdbUser } from '@/utils/PowerchatClient';
import { SpaceMemberItem } from '@/utils/customHooks';

export type SpaceMembersCtxType = {
    userIds: string[] | undefined;
    getSpaceMember: (id: string) => SpaceMemberItem | undefined;
    getSpaceMemberAbsolutely: (id: string) => SpaceMemberItem;
    // addSpaceMember: (spaceMembershipApi: SpaceMembershipApi) => Promise<void>;
    // updateSpaceMembershipApi: (updatedSpaceMembershipApi: SpaceMembershipApi) => void;
    // updateSpaceMemberRdbUser: (updatedRdbUser: RdbUser) => void;
    // removeSpaceMember: (userId: string) => void;
};

export const SpaceMembersCtx = createContext<SpaceMembersCtxType>({
    userIds: undefined,
    getSpaceMember: () => {
        throw new Error('SpaceMembersCtx.getSpaceMember: not initialized!');
    },
    getSpaceMemberAbsolutely: () => {
        throw new Error('SpaceMembersCtx.getSpaceMemberAbsolutely: not initialized!');
    },
    // addSpaceMember: () => {
    //     throw new Error('SpaceMembersCtx.addSpaceMember: not initialized!');
    // },
    // updateSpaceMembershipApi: () => {
    //     throw new Error('SpaceMembersCtx.updateSpaceMembershipApi: not initialized!');
    // },
    // updateSpaceMemberRdbUser: () => {
    //     throw new Error('SpaceMembersCtx.updateSpaceMemberRdbUser: not initialized!');
    // },
    // removeSpaceMember: () => {
    //     throw new Error('SpaceMembersCtx.removeSpaceMember: not initialized!');
    // },
});
export const useSpaceMembersCtx = () => {
    return useContext(SpaceMembersCtx);
};

export const useSpaceMembersCtxAbsolutely = () => {
    const { userIds, ...otherProps } = useSpaceMembersCtx();
    if (!userIds) {
        throw new Error('useSpaceMembersCtxAbsolutely: !userIds.');
    }
    return {
        userIds,
        ...otherProps,
    };
};
