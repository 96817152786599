import { GqlReplyObserver } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate } from '@/utils/PowerchatClient/graphql/customScalars';
import { unNull } from '@/utils/utilFunctions/unNull';

export type ReplyObserverType = {
    id: string;
    createdAt: Date;
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
};

export class ReplyObserver implements ReplyObserverType {
    readonly id: string;

    readonly createdAt: Date;

    readonly spaceId: string;

    readonly chatboardId: string | undefined;

    readonly messageId: string;

    readonly fromUserId: string;

    readonly toUserId: string;

    constructor({ id, createdAt, spaceId, chatboardId, messageId, fromUserId, toUserId }: ReplyObserverType) {
        this.id = id;
        this.createdAt = createdAt;
        this.spaceId = spaceId;
        this.chatboardId = chatboardId;
        this.messageId = messageId;
        this.fromUserId = fromUserId;
        this.toUserId = toUserId;
    }
}

export const getReplyObserverFromGql = ({
    id,
    createdAt,
    spaceId,
    chatboardId,
    messageId,
    fromUserId,
    toUserId,
}: GqlReplyObserver) => {
    return new ReplyObserver({
        id,
        createdAt: isoToDate(createdAt),
        spaceId,
        chatboardId: unNull(chatboardId),
        messageId,
        fromUserId,
        toUserId,
    });
};
