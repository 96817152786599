import { GqlChatboardMembership } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import { ChatboardPermission, ChatboardPermissionType } from '@/utils/PowerchatClient/models/_interfaces';

export type ChatboardMembershipType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    userId: string;
    spaceId: string;
    chatboardId: string;
    permission: ChatboardPermissionType;
};

export class ChatboardMembership implements ChatboardMembershipType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly userId: string;

    readonly spaceId: string;

    readonly chatboardId: string;

    permission: ChatboardPermission;

    constructor({ id, createdAt, updatedAt, userId, spaceId, chatboardId, permission }: ChatboardMembershipType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.userId = userId;
        this.spaceId = spaceId;
        this.chatboardId = chatboardId;
        this.permission = new ChatboardPermission(permission);
    }

    async _updatePermission({ permission, updatedAt }: { permission: ChatboardPermissionType; updatedAt: Date }) {
        this.permission = new ChatboardPermission(permission);
        this.updatedAt = updatedAt;
    }
}

export const getChatboardMembershipFromGql = ({
    id,
    createdAt,
    updatedAt,
    userId,
    spaceId,
    chatboardId,
    permission,
}: GqlChatboardMembership) => {
    return new ChatboardMembership({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        userId,
        spaceId,
        chatboardId,
        permission,
    });
};
