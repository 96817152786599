import { GqlChatboardReplyMessage } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import { ChatboardMasterMessage, ChatboardMasterMessageType } from './ChatboardMasterMessage';

export type ChatboardReplyMessageType = ChatboardMasterMessageType & {
    replyToMessageId: string;
};

export class ChatboardReplyMessage extends ChatboardMasterMessage implements ChatboardReplyMessageType {
    readonly replyToMessageId: string;

    constructor({ replyToMessageId, ...input }: ChatboardReplyMessageType) {
        super(input);
        this.replyToMessageId = replyToMessageId;
    }
}

export const getChatboardReplyMessageFromGql = ({
    id,
    createdAt,
    updatedAt,
    serialNumber,
    userId,
    spaceId,
    chatboardId,
    body,
    replyToMessageId,
    mentionToUserIds,
    canceledAt,
}: GqlChatboardReplyMessage) => {
    return new ChatboardReplyMessage({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        serialNumber,
        spaceId,
        chatboardId,
        userId,
        body,
        replyToMessageId,
        mentionToUserIds,
        canceledAt: isoToDateOptionally(canceledAt),
    });
};
