import { useState, useCallback, useEffect } from 'react';

import { SpaceApi, SpaceMembershipApi } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely, useRdbUserCtx, SpaceWithMembership } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';

export const useSpaces = () => {
    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { ids, getItemAbsolutely, initialize, addItem, updateItem, removeItem } =
        useLazyItems<SpaceWithMembership>(undefined);
    const [current, setCurrent] = useState<SpaceWithMembership | undefined>(undefined);
    const { isOnline: isRdbUserOnline } = useRdbUserCtx();

    // CALLBACKS
    const fetchSpaces = useCallback(async () => {
        const fetchedItems = await userClient.getSpaceWithMembershipsForUser();
        initialize(
            fetchedItems.map((item) => ({
                ...item,
                id: item.spaceApi.id,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // USEEFFECT
    useEffect(() => {
        fetchSpaces();
    }, [fetchSpaces]);
    useEffect(() => {
        if (isRdbUserOnline === false) {
            setCurrent(undefined);
        }
    }, [isRdbUserOnline]);

    // CALLBACKS
    const setSpaceId = useCallback(
        (spaceId: string | undefined) => {
            if (spaceId) {
                const targetItem = getItemAbsolutely(spaceId);
                userClient.updateCurrentSpace({
                    currentSpace: targetItem
                        ? {
                              ...targetItem.spaceApi,
                              membershipUniqueName: targetItem.spaceMembershipApi.uniqueName,
                              membershipDisplayName: targetItem.spaceMembershipApi.getDisplayName(),
                              currentChatboards: {},
                          }
                        : undefined,
                    currentConfigSpaceId: undefined,
                });
                setCurrent({
                    id: targetItem.spaceApi.id,
                    spaceApi: targetItem.spaceApi,
                    spaceMembershipApi: targetItem.spaceMembershipApi,
                });
            } else {
                userClient.updateCurrentSpace({
                    currentSpace: undefined,
                    currentConfigSpaceId: undefined,
                });
                setCurrent(undefined);
            }
        },
        [userClient, getItemAbsolutely]
    );
    const addSpace = useCallback(
        ({ spaceApi, spaceMembershipApi }: { spaceApi: SpaceApi; spaceMembershipApi: SpaceMembershipApi }) => {
            addItem({
                newItem: {
                    id: spaceApi.id,
                    spaceApi,
                    spaceMembershipApi,
                },
                isIgnoreDuplicationError: true,
            });
        },
        [addItem]
    );
    const updateSpaceApi = useCallback(
        (updatedSpaceApi: SpaceApi) => {
            updateItem({
                id: updatedSpaceApi.id,
                updator: (prevItem) => ({
                    ...prevItem,
                    spaceApi: updatedSpaceApi,
                }),
                isIgnoreMissingError: true,
            });
        },
        [updateItem]
    );
    const updateSpaceMembershipApi = useCallback(
        (updatedSpaceMembershipApi: SpaceMembershipApi) => {
            updateItem({
                id: updatedSpaceMembershipApi.spaceId,
                updator: (prevItem) => ({
                    ...prevItem,
                    spaceMembershipApi: updatedSpaceMembershipApi,
                }),
                isIgnoreMissingError: true,
            });
        },
        [updateItem]
    );
    const removeSpace = useCallback(
        (spaceId: string) => {
            if (spaceId === current?.id) {
                setCurrent(undefined);
            }
            removeItem({
                id: spaceId,
                isIgnoreMissingError: true,
            });
        },
        [removeItem, current?.id]
    );

    return {
        spaceIds: ids,
        getSpaceWithMembershipAbsolutely: getItemAbsolutely,
        currentSpace: current,
        setSpaceId,
        addSpace,
        updateSpaceApi,
        updateSpaceMembershipApi,
        removeSpace,
    };
};
