import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    Message,
    ChatboardMasterMessage,
    ChatboardReplyMessage,
    LobbyMasterMessage,
    LobbyReplyMessage,
} from '@/utils/PowerchatClient/models';
import {
    RdbLastMessage,
    RdbLastMessageAddNotification,
    RdbLastMessageModifyNotification,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToMessages = ({
    spaceId,
    onAdded,
    onModified,
}: {
    spaceId: string;
    onAdded: (input: { newMessage: Message; addNotification: RdbLastMessageAddNotification }) => void;
    onModified: (input: { modifiedMessage: Message; modifyNotification: RdbLastMessageModifyNotification }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastMessageRef = ref(db, `lastMessages/${spaceId}`);
    const firedAt = new Date();

    // const g = get(lastMessageRef);
    // g.then((p) => {
    //     console.log({ spaceId, firedAt, p });
    // }).catch((e) => {
    //     console.error(e);
    // });
    console.log({ spaceId, firedAt });
    const unsubscribeListenToMessages = onValue(lastMessageRef, (data) => {
        console.log({ data, exists: data.exists() });
        if (data.exists()) {
            const { action, addNotification, modifyNotification, ...messageData }: RdbLastMessage = data.val();
            const message = new Message({
                id: messageData.id,
                createdAt: new Date(messageData.createdAt),
                updatedAt: messageData.updatedAt ? new Date(messageData.updatedAt) : undefined,
                serialNumber: undefined,
                userId: messageData.userId,
                spaceId,
                chatboardId: messageData.chatboardId,
                body: messageData.body,
                replyToMessageId: messageData.replyToMessageId,
                mentionToUserIds: messageData.mentionToUserIds || [],
                canceledAt: messageData.canceledAt ? new Date(messageData.canceledAt) : undefined,
            });
            console.log({ firedAt, message, action, addNotification, modifyNotification });
            if (firedAt <= message.createdAt && action === 'add' && addNotification) {
                onAdded({
                    addNotification,
                    newMessage: message,
                });
            } else if (message.updatedAt && firedAt <= message.updatedAt && action === 'modify' && modifyNotification) {
                onModified({
                    modifyNotification,
                    modifiedMessage: message,
                });
            }
        }
    });
    return {
        unsubscribeListenToMessages: () => {
            console.log('unsubscribeListenToMessages fired');
            unsubscribeListenToMessages();
        },
    };
};
