import { FC, useState, MouseEvent } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi } from '@/utils/PowerchatClient';
import { useSpaceCtxAbsolutely, useSpaceMembersCtxAbsolutely } from '@/utils/ctxs';
import { useLobbyReplyMessages, useLanguage } from '@/utils/customHooks';
import { getOptionalUserName } from '@/utils/utilFunctions';
import { ChatBox } from '@/components/0_atom/ChatBox';
import { AddMessageInput } from '@/components/2_org/AddMessageInput';
import { OpenMessageMenuInput } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/MessageCard';
import { ThreadViewContent } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/ThreadViewContent';
import { MessageCardMenu } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/Menu';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 180,
        flexShrink: 0,
        '&:not(:last-child)': {
            marginRight: 5,
        },
    },
    chatbox: {},
    input: {
        marginLeft: 25,
    },
}));

export const ThreadView: FC<{
    masterMessageId: string;
    getMasterMessageAbsolutely: (id: string) => {
        id: string;
        messageApi: MessageApi;
        initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
        initialReplyObserverApis: ReplyObserverApi[];
    };
}> = ({ masterMessageId, getMasterMessageAbsolutely }) => {
    // STYLE
    const c = useStyles(useTheme());

    // CTX
    const {
        current: { spaceMembershipApi, spaceApi },
    } = useSpaceCtxAbsolutely();
    const { userIds, getSpaceMember } = useSpaceMembersCtxAbsolutely();

    // HOOKS
    const { messageIds, getMessageItemAbsolutely } = useLobbyReplyMessages({ masterMessageId });
    const [currentMenuData, setCurrentMenuData] = useState<OpenMessageMenuInput | undefined>();
    const { txt } = useLanguage();

    // DATA
    const chatboxElmId = `power-lobby-chatbox-${masterMessageId}`;
    const master = getMasterMessageAbsolutely(masterMessageId);
    const messageAuthor = getSpaceMember(master.messageApi.userId);
    const { uniqueName: toUserUniqueName, displayName: toUserDisplayName } = getOptionalUserName({
        spaceMembershipApi: messageAuthor?.spaceMembershipApi,
        txt,
    });

    return messageIds.length > 0 ? (
        <div className={c.root}>
            <ChatBox
                id={chatboxElmId}
                className={c.chatbox}
                initialChildren={undefined}
                upstreamChildren={undefined}
                downstreamChildren={{
                    component: ThreadViewContent,
                    props: { master, messageIds, getMessageItemAbsolutely, openMessageMenu: setCurrentMenuData },
                }}
                otherChildren={undefined}
            />
            <AddMessageInput
                className={c.input}
                handleSendMessage={async ({ body }) => {
                    spaceApi.createLobbyMessage({
                        body,
                        replyTo: {
                            messageId: masterMessageId,
                            user: {
                                id: master.messageApi.userId,
                                toUserUniqueName,
                                toUserDisplayName,
                            },
                        },
                    });
                }}
                isPermitted={spaceMembershipApi.permission.lobby.write}
                placeholder={txt({
                    en: 'Reply to this thread',
                    ja: 'このスレッドへ',
                })}
            />
            {!!currentMenuData && (
                <MessageCardMenu openMenuInput={currentMenuData} onClose={() => setCurrentMenuData(undefined)} />
            )}
        </div>
    ) : (
        <></>
    );
};
ThreadView.displayName = 'ThreadView';
