import { FC, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiGrow from '@material-ui/core/Grow';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiInput from '@material-ui/core/Input';

import SvgCancelMessage from '@/svgs/message-slash';
import SvgEllipsis from '@/svgs/ellipsis';
import SvgEllipsisSlash from '@/svgs/ellipsis-slash';

import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi } from '@/utils/PowerchatClient';
import { useSpaceCtxAbsolutely, useSpaceMembersCtx, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { StaticTextMenu } from '@/components/0_atom/Menu';
import { TextInput } from '@/components/0_atom/Input';
import { Popover } from '@/components/0_atom/Popover';
import { AddMessageInput } from '@/components/2_org/AddMessageInput';
import { IconButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';
import {
    MessageCard,
    OpenMessageMenu,
    OpenMessageMenuInput,
} from '@/components/3_template/ConsoleTemplate/SpaceTemplate/LobbyTemplate/MessageCard';

const useStyles = makeStyles((theme) => ({
    presentation: {
        zIndex: 11,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0,
    },
    backscreen: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 10,
        transitionProperty: 'background, backdropFilter',
        transitionDuration: '.2s',
    },
    backscreenOpen: {
        background: 'rgba(41,41,43,.41)',
        backdropFilter: 'blur(14px)',
    },
    backscreenClosed: {
        background: 'rgba(41,41,43,0)',
        backdropFilter: 'blur(0px)',
    },
    box: {
        position: 'absolute',
        zIndex: 12,
    },
    messageClosed: {
        visibility: 'hidden',
    },
    cancelMessageIcon: {
        width: 11,
    },
    markUnconfirmedIcon: {
        width: 5,
    },
    markReleaseUnconfirmedIcon: {
        width: 11,
    },
    input: {
        margin: '5px 0 0 25px',
    },
    anchorEl: {
        visibility: 'hidden',
    },
}));

// メッセージ入力時に改行した際に適切に（bottomを固定して）位置を上げていくには、全体を一つの要素として表示するしかない。
// ↑それだと、メッセージが上部にあっても常に上方向に伸びてちょっと気持ち悪い。画面下から出てしまう時だけ、全体のポジションを調整すればいいのでは？

export const MessageCardMenu: FC<{
    className?: string;
    onClose: () => void;
    openMenuInput: OpenMessageMenuInput;
}> = ({ className, openMenuInput, onClose: onClose_ }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(useTheme());

    // CTX
    const {
        current: { spaceApi, spaceMembershipApi },
    } = useSpaceCtxAbsolutely();
    const { getSpaceMember } = useSpaceMembersCtx();
    const { userClient } = useUserCtxAbsolutely();

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(true);

    // DATA
    const {
        elm: anchorEl,
        getReplyObserverItemAbsolutely,
        messageApi,
        replyMessageIds,
        replyObserverIds,
        unconfirmedMarkApi,
    } = openMenuInput;
    const author = getSpaceMember(messageApi.userId);
    const isRenderReplyInput =
        !!spaceMembershipApi.permission.lobby.write &&
        !!author &&
        replyMessageIds.length === 0 &&
        !messageApi.replyToMessageId;
    const left = anchorEl.offsetLeft;
    const bottom =
        (anchorEl.offsetParent as HTMLElement).offsetHeight -
        anchorEl.offsetTop -
        anchorEl.offsetHeight -
        (isRenderReplyInput ? 5 + 28 : 5);

    // CALLBACK
    const onClose = () => {
        anchorEl.classList.remove(c.anchorEl);
        setIsOpen(false);
        setTimeout(() => {
            onClose_();
        }, 200);
    };

    useEffect(() => {
        anchorEl.classList.add(c.anchorEl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log({
        anchorEl,
        parentHeight: (anchorEl.offsetParent as HTMLElement).offsetHeight,
        anchorElOffetTop: anchorEl.offsetTop,
        anchorElOffetHeight: anchorEl.offsetHeight,
        left,
        bottom,
        messageApi,
    });
    return (
        <>
            <div
                className={clsx(c.backscreen, {
                    [c.backscreenOpen]: isOpen,
                    [c.backscreenClosed]: !isOpen,
                })}
            />
            <div className={c.presentation} onClick={onClose} />
            <div
                className={c.box}
                style={{
                    left,
                    bottom,
                    width: anchorEl.offsetWidth,
                }}
            >
                <MuiGrow in={isOpen} timeout={200}>
                    <div>
                        <StaticTextMenu
                            onClose={onClose}
                            align={'right'}
                            items={[
                                {
                                    text: txt({
                                        en: 'Cancel this message',
                                        ja: 'このメッセージをキャンセル',
                                    }),
                                    color: theme.palette.error.main,
                                    endIcon: <SvgCancelMessage className={c.cancelMessageIcon} />,
                                    onClick: () => messageApi.cancel(),
                                    isIgnore: messageApi.userId !== userClient.id || !!messageApi.canceledAt,
                                },
                                {
                                    text: txt({
                                        en: unconfirmedMarkApi ? 'Release unconfirmed' : 'Mark unconfirmed',
                                        ja: unconfirmedMarkApi ? '確認済みにする' : '要確認としてマーク',
                                    }),
                                    endIcon: unconfirmedMarkApi ? (
                                        <SvgEllipsisSlash className={c.markReleaseUnconfirmedIcon} />
                                    ) : (
                                        <SvgEllipsis className={c.markUnconfirmedIcon} />
                                    ),
                                    onClick: () => {
                                        if (unconfirmedMarkApi) {
                                            unconfirmedMarkApi.remove();
                                        } else {
                                            messageApi.createUnconfirmedMark();
                                        }
                                    },
                                },
                            ]}
                        />
                    </div>
                </MuiGrow>
                <MessageCard
                    className={clsx({
                        [c.messageClosed]: !isOpen,
                    })}
                    messageApi={messageApi}
                    initialReplyObserverApis={replyObserverIds.map((id) => getReplyObserverItemAbsolutely(id))}
                    initialUnconfirmedMarkApi={unconfirmedMarkApi}
                />
                {isRenderReplyInput && (
                    <MuiGrow in={isOpen} timeout={200}>
                        <div>
                            <AddMessageInput
                                isAutoFocus
                                className={c.input}
                                handleSendMessage={async ({ body }) => {
                                    spaceApi.createLobbyMessage({
                                        body,
                                        replyTo: {
                                            messageId: messageApi.id,
                                            user: {
                                                id: messageApi.userId,
                                                toUserUniqueName: author.spaceMembershipApi.uniqueName,
                                                toUserDisplayName: author.spaceMembershipApi.getDisplayName(),
                                            },
                                        },
                                    });
                                    onClose();
                                }}
                                placeholder={txt({
                                    en: 'Add Reply',
                                    ja: '返信',
                                })}
                                isPermitted={true}
                            />
                        </div>
                    </MuiGrow>
                )}
            </div>
        </>
    );
};
MessageCardMenu.displayName = 'LobbyTemplate/MessageCardMenu';
