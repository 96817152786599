import { useCallback, useEffect } from 'react';

import { ChatboardApi, ChatboardMembershipApi } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely, ChatboardWithMembership, SpaceWithMembership } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';

export const useChatboards = ({ currentSpace }: { currentSpace: Omit<SpaceWithMembership, 'id'> | undefined }) => {
    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const {
        ids,
        getItem,
        getItemAbsolutely,
        initialize: initializeChatboards,
        addItem,
        updateItem,
        removeItem,
    } = useLazyItems<ChatboardWithMembership>(undefined);

    // CALLBACKS
    const addChatboard = useCallback(
        ({
            chatboardApi,
            chatboardMembershipApi,
        }: {
            chatboardApi: ChatboardApi;
            chatboardMembershipApi: ChatboardMembershipApi;
        }) => {
            addItem({
                newItem: {
                    id: chatboardApi.id,
                    chatboardApi,
                    chatboardMembershipApi,
                },
                isIgnoreDuplicationError: true,
            });
        },
        [addItem]
    );
    const updateChatboardApi = useCallback(
        (updatedChatboardApi: ChatboardApi) => {
            updateItem({
                id: updatedChatboardApi.id,
                updator: (prevItem) => ({
                    ...prevItem,
                    chatboardApi: updatedChatboardApi,
                }),
                isIgnoreMissingError: true,
            });
        },
        [updateItem]
    );
    const updateChatboardMembershipApi = useCallback(
        (updatedChatboardMembershipApi: ChatboardMembershipApi) => {
            updateItem({
                id: updatedChatboardMembershipApi.chatboardId,
                updator: (prevItem) => ({
                    ...prevItem,
                    chatboardMembershipApi: updatedChatboardMembershipApi,
                }),
                isIgnoreMissingError: true,
            });
        },
        [updateItem]
    );
    const removeChatboard = useCallback(
        (chatboardId: string) => {
            userClient.removeCurrentChatboard({ chatboardId });
            removeItem({
                id: chatboardId,
                isIgnoreMissingError: true,
            });
        },
        [removeItem, userClient]
    );

    const initialize = useCallback(
        (items: ChatboardWithMembership[] | undefined) => {
            initializeChatboards(items);
        },
        [initializeChatboards]
    );
    const fetchChatboards = useCallback(async () => {
        // initialize(undefined);
        if (currentSpace) {
            const { spaceApi, spaceMembershipApi } = currentSpace;
            const fetchedItems = await spaceApi.getChatboardWithMembershipsForUser({ spaceMembershipApi });
            initialize(
                fetchedItems.map(({ chatboardApi, chatboardMembershipApi }) => ({
                    id: chatboardApi.id,
                    chatboardApi,
                    chatboardMembershipApi,
                }))
            );
        }
        // else {
        //     initialize(undefined);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSpace?.spaceApi.id]);

    // USEEFFECT
    useEffect(() => {
        fetchChatboards();
    }, [fetchChatboards]);
    useEffect(() => {
        initialize(undefined);
        return () => {
            initialize(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSpace?.spaceApi.id]);

    return {
        chatboardIds: ids,
        getChatboardWithMembership: getItem,
        getChatboardWithMembershipAbsolutely: getItemAbsolutely,
        addChatboard,
        updateChatboardApi,
        updateChatboardMembershipApi,
        removeChatboard,
        initializeChatboards: initialize,
    };
};
