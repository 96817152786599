import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { SpaceIcon } from '@/components/2_org/SpaceIcon';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 30,
        height: 30,
        borderRadius: 8,
        marginRight: 10,
    },
}));

export const SpaceIconWithName: FC<{
    className?: string;
    spaceId: string;
    spaceName: string;
}> = ({ className, spaceId, spaceName }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <SpaceIcon spaceName={spaceName} spaceId={spaceId} className={c.icon} />
            {spaceName}
        </div>
    );
};
SpaceIconWithName.displayName = 'SpaceIconWithName';
