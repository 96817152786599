import { useState, useEffect, useCallback } from 'react';

import { Thread, ChatboardApi } from '@/utils/PowerchatClient';
import { useLastMessageCtx } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';
import { ChatBoxState } from '@/components/0_atom/ChatBox';

export const usePastThreads = ({
    chatboardApi,
    onAddUpstream,
    isSeeingOldest,
    isSeeingRecent,
}: { chatboardApi: ChatboardApi } & ChatBoxState) => {
    // HOOKS
    const {
        ids,
        itemDatas,
        getItem,
        getItemAbsolutely,
        initialize: initializeItems,
        updateItem,
    } = useLazyItems<Thread & { id: string }>(undefined);
    const { lastMessageLog } = useLastMessageCtx();
    const [isFetching, setIsFetching] = useState(false);
    const [mountedAt, setMountedAt] = useState(new Date());
    const [isAllFetched, setIsAllFetched] = useState<boolean | undefined>(undefined);

    // DATA
    const oldestMessageApi = itemDatas ? itemDatas[0]?.master.messageApi : undefined;

    const fetchPastThreads = useCallback(async () => {
        if (isAllFetched) {
            return;
        }
        if (oldestMessageApi?.serialNumber === 1) {
            setIsAllFetched(true);
            return;
        }
        setIsFetching(true);
        const fetchedItems = await chatboardApi.getThreads({
            maxAmount: 10,
            startAfterCreatedAt: oldestMessageApi?.createdAt || mountedAt,
        });
        if (fetchedItems.length === 0) {
            initializeItems([]);
            setIsAllFetched(true);
            return;
        }
        const fetchedItemsAsc = fetchedItems.reverse();
        initializeItems([
            ...fetchedItemsAsc.map(({ master, replies }) => ({
                id: master.messageApi.id,
                master,
                replies,
            })),
            ...(itemDatas || []),
        ]);
        if (fetchedItemsAsc[0]?.master.messageApi.serialNumber === 1) {
            setIsAllFetched(true);
        }
        setIsFetching(false);
    }, [
        chatboardApi,
        initializeItems,
        isAllFetched,
        itemDatas,
        mountedAt,
        oldestMessageApi?.createdAt,
        oldestMessageApi?.serialNumber,
    ]);

    // INITILALIZER
    const initialize = useCallback(() => {
        setMountedAt(new Date());
        initializeItems(undefined);
        setIsAllFetched(undefined);
        setIsFetching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatboardApi.id]);

    // INITIALIZE
    useEffect(() => {
        initialize();
    }, [initialize]);

    // LISTERNER
    useEffect(() => {
        if (lastMessageLog?.messageApi.chatboardId === chatboardApi.id) {
            if (lastMessageLog.action === 'modify') {
                updateItem({
                    id: lastMessageLog.messageApi.id,
                    updator: (prevItem) => ({
                        ...prevItem,
                        messageApi: lastMessageLog.messageApi,
                    }),
                    isIgnoreMissingError: true,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatboardApi.id, lastMessageLog]);
    useEffect(() => {
        onAddUpstream();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids]);
    useEffect(() => {
        if (isSeeingOldest && !isFetching && !(oldestMessageApi?.serialNumber === 1 || isAllFetched)) {
            fetchPastThreads();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSeeingOldest]);
    console.log({ isSeeingOldest, isSeeingRecent, oldestMessageApi, isAllFetched, itemDatas });
    return {
        pastMessageIds: ids,
        threads: itemDatas,
        getPastThread: getItem,
        getPastThreadAbsolutely: getItemAbsolutely,
        fetchPastThreads,
        isFetchingPastThreads: isFetching,
        oldestMessageApi,
        isAllFetched,
    };
};
