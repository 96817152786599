import { collection, doc } from 'firebase/firestore';
import { getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { dateToIsoOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import {
    GqlMessage,
    GqlChatboardMembership,
    GqlUnconfirmedMark,
    GqlChatboard,
    GqlSpaceMembership,
    GqlReaction,
    GqlReplyObserver,
} from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import {
    postRdbChatboard,
    postRdbChatboardMembership,
    postRdbSpaceMembership,
    listenToMessages,
    listenToReactions,
    listenToHotUsersForSpace,
    listenToChatboardMemberships,
    listenToChatboards,
    listenToSpaceMemberships,
    listenToUnconfirmedMarks,
    listenToReplyObservers,
    RdbUser,
    ReplyToUser,
    MentionToUser,
    postRdbMessage,
} from '@/utils/PowerchatClient/RealtimeDatabase';
import { ChatboardPermissionType, SpacePermissionType } from '@/utils/PowerchatClient/models/_interfaces';
import { Space, SpaceType } from '@/utils/PowerchatClient/models/Space/data/Space';
import { SpaceMembershipApi, getSpaceMembershipFromGql } from '@/utils/PowerchatClient/models/SpaceMembership';
import { ChatboardApi, getChatboardFromGql } from '@/utils/PowerchatClient/models/Chatboard';
import {
    ChatboardMembershipApi,
    getChatboardMembershipFromGql,
} from '@/utils/PowerchatClient/models/ChatboardMembership';
import { MessageApi, getMessageFromGql } from '@/utils/PowerchatClient/models/Message';
import { ReactionApi, getReactionFromGql } from '@/utils/PowerchatClient/models/Reaction';
import { User, getUserFromGql } from '@/utils/PowerchatClient/models/User';
import { ReplyObserverApi, getReplyObserverFromGql } from '@/utils/PowerchatClient/models/ReplyObserver';
import { UnconfirmedMarkApi, getUnconfirmedMarkFromGql } from '@/utils/PowerchatClient/models/UnconfirmedMark';
import { MessageWithMark } from '@/utils/PowerchatClient/models/MessageWithMark';

type ReplyTo = {
    messageId: string;
    user: ReplyToUser;
};

type SpaceApiType = {
    // UPDATE SELF
    modifySpaceName: (input: { name: string }) => Promise<void>;
    close: () => Promise<void>;

    // GET CHILDREN
    getAllMarkedMessages: (input: { getChatboardApi: (id: string) => ChatboardApi }) => Promise<{
        lobby: MessageWithMark[];
        chatboard: {
            chatboardApi: ChatboardApi;
            messageWithMarks: MessageWithMark[];
        }[];
    }>;
    getChatboards: () => Promise<ChatboardApi[]>;
    getChatboardWithMembershipsForUser: (input: { spaceMembershipApi: SpaceMembershipApi }) => Promise<
        {
            chatboardApi: ChatboardApi;
            chatboardMembershipApi: ChatboardMembershipApi;
        }[]
    >;
    getSpaceMembershipWithUsers: () => Promise<
        {
            spaceMembershipApi: SpaceMembershipApi;
            user: User;
        }[]
    >;

    // CREATE CHILDREN
    createLobbyMessage: (input: { body: string; replyTo: ReplyTo | undefined }) => void;
    createLobbyHeyMessage: (input: { replyTo: ReplyTo | undefined; mentionToUser: MentionToUser }) => void;
    createLobbyAbsentMessage: (input: { body: string; mentionToUser: MentionToUser; isObserveReply: boolean }) => void;
    createChatboard: (input: {
        uniqueName: string;
        users: {
            spaceMembershipApi: SpaceMembershipApi;
            permission: ChatboardPermissionType;
        }[];
    }) => Promise<{
        chatboardApi: ChatboardApi;
    }>;
    createSpaceMembership: (input: {
        newMemberUserCode: string;
        uniqueName: string | undefined;
        displayName: string | undefined;
        permission: SpacePermissionType;
    }) => Promise<{
        spaceMembershipApi: SpaceMembershipApi;
    }>;
    createSpaceMemberships: (
        input: {
            newMemberUserCode: string;
            uniqueName: string | undefined;
            displayName: string | undefined;
            permission: SpacePermissionType;
        }[]
    ) => Promise<SpaceMembershipApi[]>;

    // LISTENERS
    listenToHotUsersForSpace: (input: {
        onAdded: (input: { newUser: RdbUser }) => void;
        onChanged: (input: { changedUser: RdbUser }) => void;
        onRemoved: (input: { removedUser: RdbUser }) => void;
    }) => {
        unsubscribeListenToHotUsersForSpace: () => void;
    };
    listenToMessages: (input: {
        onAdded: (input: {
            newMessageApi: MessageApi;
            initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
            initialReplyObserverApis: ReplyObserverApi[];
        }) => void;
        onModified: (input: { modifiedMessageApi: MessageApi }) => void;
    }) => {
        unsubscribeListenToMessages: () => void;
    };
    listenToReactions: (input: {
        onAdded: (input: { newReactionApi: ReactionApi }) => void;
        onRemoved: (input: { removedReactionApi: ReactionApi }) => void;
    }) => {
        unsubscribeListenToReactions: () => void;
    };
    listenToUnconfirmedMarks: (input: {
        onAdded: (input: { newUnconfirmedMarkApi: UnconfirmedMarkApi }) => void;
        onRemoved: (input: { removedUnconfirmedMarkApi: UnconfirmedMarkApi }) => void;
    }) => {
        unsubscribeListenToUnconfirmedMarks: () => void;
    };
    listenToReplyObservers: (input: {
        onAdded: (input: { newReplyObserverApi: ReplyObserverApi }) => void;
        onRemoved: (input: { removedReplyObserverApi: ReplyObserverApi }) => void;
    }) => {
        unsubscribeListenToReplyObservers: () => void;
    };
    listenToChatboards: (input: {
        onAdded: (input: { newChatboardApi: ChatboardApi }) => void;
        onModified: (input: { modifiedChatboardApi: ChatboardApi }) => void;
    }) => {
        unsubscribeListenToChatboards: () => void;
    };
    listenToSpaceMemberships: (input: {
        onAdded: (input: { newSpaceMembershipApi: SpaceMembershipApi }) => void;
        onModified: (input: { modifiedSpaceMembershipApi: SpaceMembershipApi }) => void;
        onRemoved: (input: { removedSpaceMembershipApi: SpaceMembershipApi }) => void;
    }) => {
        unsubscribeListenToSpaceMemberships: () => void;
    };
    listenToChatboardMemberships: (input: {
        onAdded: (input: { newChatboardMembershipApi: ChatboardMembershipApi }) => void;
        onModified: (input: { modifiedChatboardMembershipApi: ChatboardMembershipApi }) => void;
        onRemoved: (input: { removedChatboardMembershipApi: ChatboardMembershipApi }) => void;
    }) => {
        unsubscribeListenToChatboardMemberships: () => void;
    };
};

type ConstructorInput = SpaceType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    clientUser: User;
    clientUniqueName: string;
    clientDisplayName: string | undefined;
};

export class SpaceApi extends Space implements SpaceApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    protected _clientUser: User;

    protected _clientUniqueName: string;

    protected _clientDisplayName: string | undefined;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
        this._clientUser = input.clientUser;
        this._clientUniqueName = input.clientUniqueName;
        this._clientDisplayName = input.clientDisplayName;
    }

    // PRIVATE UTILS
    private _getMessageWithMarkFromGql({
        message,
        reactions,
        unconfirmedMark,
        replyObservers,
        chatboardUniqueName,
    }: {
        message: GqlMessage;
        reactions: GqlReaction[];
        unconfirmedMark: GqlUnconfirmedMark | undefined | null;
        replyObservers: GqlUnconfirmedMark[];
        chatboardUniqueName: string | undefined;
    }) {
        return new MessageWithMark({
            messageApi: new MessageApi({
                graphqlSdk: this._graphqlSdk,
                currentFcmToken: this._currentFcmToken,
                clientUser: this._clientUser,
                clientUniqueName: this._clientUniqueName,
                clientDisplayName: this._clientDisplayName,
                spaceName: this.name,
                chatboardUniqueName,
                ...getMessageFromGql(message),
            }),
            reactionApis: reactions.map(
                (reaction) =>
                    new ReactionApi({
                        graphqlSdk: this._graphqlSdk,
                        ...getReactionFromGql(reaction),
                    })
            ),
            unconfirmedMarkApi: unconfirmedMark
                ? new UnconfirmedMarkApi({
                      graphqlSdk: this._graphqlSdk,
                      ...getUnconfirmedMarkFromGql(unconfirmedMark),
                  })
                : undefined,
            replyObserverApis: replyObservers.map(
                (replyObserver) =>
                    new ReplyObserverApi({
                        graphqlSdk: this._graphqlSdk,
                        ...getReplyObserverFromGql(replyObserver),
                    })
            ),
        });
    }

    private _getChatboardApiFromGql(chatboard: GqlChatboard) {
        return new ChatboardApi({
            graphqlSdk: this._graphqlSdk,
            currentFcmToken: this._currentFcmToken,
            clientUser: this._clientUser,
            clientUniqueName: this._clientUniqueName,
            clientDisplayName: this._clientDisplayName,
            spaceName: this.name,
            ...getChatboardFromGql(chatboard),
        });
    }

    private _getChatboardMembershipApiFromGql({
        chatboardMembership,
        chatboardUniqueName,
        spaceMembershipPermission,
        spaceMembershipUniqueName,
        spaceMembershipDisplayName,
    }: {
        chatboardMembership: GqlChatboardMembership;
        chatboardUniqueName: string;
        spaceMembershipPermission: SpacePermissionType;
        spaceMembershipUniqueName: string;
        spaceMembershipDisplayName: string | undefined;
    }) {
        return new ChatboardMembershipApi({
            graphqlSdk: this._graphqlSdk,
            currentFcmToken: this._currentFcmToken,
            clientUser: this._clientUser,
            clientUniqueName: this._clientUniqueName,
            clientDisplayName: this._clientDisplayName,
            spaceName: this.name,
            chatboardUniqueName,
            spaceMembershipPermission,
            spaceMembershipUniqueName,
            spaceMembershipDisplayName,
            ...getChatboardMembershipFromGql(chatboardMembership),
        });
    }

    private _getSpaceMembershipApiFromGql({
        spaceMembership,
        memberUserDisplayName,
    }: {
        spaceMembership: GqlSpaceMembership;
        memberUserDisplayName: string | undefined;
    }) {
        return new SpaceMembershipApi({
            graphqlSdk: this._graphqlSdk,
            currentFcmToken: this._currentFcmToken,
            clientUser: this._clientUser,
            clientUniqueName: this._clientUniqueName,
            clientDisplayName: this._clientDisplayName,
            spaceName: this.name,
            memberUserDisplayName,
            ...getSpaceMembershipFromGql(spaceMembership),
        });
    }

    // UPDATE SELF
    async modifySpaceName({ name }: { name: string }) {
        this._updateName({ name, updatedAt: new Date() });
        await this._graphqlSdk.modifySpaceName({
            input: {
                spaceId: this.id,
                name,
            },
        });
    }

    async close() {
        const now = new Date();
        this._updateClosedAt({ closedAt: now, updatedAt: now });
        await this._graphqlSdk.closeSpace({
            input: {
                spaceId: this.id,
            },
        });
    }

    // GET CHILDREN
    async getAllMarkedMessages({ getChatboardApi }: { getChatboardApi: (id: string) => ChatboardApi }) {
        const {
            getAllMarkedMessages: { lobby, chatboard },
        } = await this._graphqlSdk.getAllMarkedMessages({
            input: {
                spaceId: this.id,
            },
        });
        return {
            lobby: lobby.map(({ message, unconfirmedMark, replyObservers }) =>
                this._getMessageWithMarkFromGql({
                    message,
                    unconfirmedMark,
                    replyObservers,
                    reactions: [],
                    chatboardUniqueName: undefined,
                })
            ),
            chatboard: chatboard.reduce(
                (acc, { message, unconfirmedMark, replyObservers, reactions }) => {
                    const { chatboardId } = message;
                    const targetItem = acc.find((item) => item.chatboardApi.id === chatboardId);
                    if (targetItem) {
                        return acc.map((item) => {
                            if (item.chatboardApi.id === chatboardId) {
                                return {
                                    ...item,
                                    messageWithMarks: [
                                        ...item.messageWithMarks,
                                        this._getMessageWithMarkFromGql({
                                            message,
                                            unconfirmedMark,
                                            replyObservers,
                                            reactions,
                                            chatboardUniqueName: item.chatboardApi.uniqueName,
                                        }),
                                    ],
                                };
                            }
                            return item;
                        });
                    }
                    const chatboardApi = getChatboardApi(message.chatboardId);
                    return [
                        ...acc,
                        {
                            chatboardApi,
                            messageWithMarks: [
                                this._getMessageWithMarkFromGql({
                                    message,
                                    unconfirmedMark,
                                    replyObservers,
                                    reactions,
                                    chatboardUniqueName: chatboardApi.uniqueName,
                                }),
                            ],
                        },
                    ];
                },
                [] as {
                    chatboardApi: ChatboardApi;
                    messageWithMarks: MessageWithMark[];
                }[]
            ),
        };
    }

    async getChatboards() {
        const {
            getChatboards: { chatboards },
        } = await this._graphqlSdk.getChatboards({
            input: {
                spaceId: this.id,
            },
        });
        return chatboards.map((chatboard) => this._getChatboardApiFromGql(chatboard));
    }

    async getChatboardWithMembershipsForUser({ spaceMembershipApi }: { spaceMembershipApi: SpaceMembershipApi }) {
        const {
            getChatboardWithMembershipsForUser: { chatboardWithMemberships },
        } = await this._graphqlSdk.getChatboardWithMembershipsForUser({
            input: {
                spaceId: this.id,
            },
        });
        return chatboardWithMemberships.map(({ chatboard, chatboardMembership }) => {
            const chatboardApi = this._getChatboardApiFromGql(chatboard);
            return {
                chatboardApi,
                chatboardMembershipApi: this._getChatboardMembershipApiFromGql({
                    chatboardMembership,
                    chatboardUniqueName: chatboardApi.uniqueName,
                    spaceMembershipPermission: spaceMembershipApi.permission,
                    spaceMembershipUniqueName: spaceMembershipApi.uniqueName,
                    spaceMembershipDisplayName: spaceMembershipApi.getDisplayName(),
                }),
            };
        });
    }

    async getSpaceMembershipWithUsers() {
        const {
            getSpaceMembershipWithUsers: { spaceMembershipWithUsers },
        } = await this._graphqlSdk.getSpaceMembershipWithUsers({
            input: {
                spaceId: this.id,
            },
        });
        return spaceMembershipWithUsers.map(({ spaceMembership, user: gqlUser }) => {
            const user = getUserFromGql(gqlUser);
            return {
                spaceMembershipApi: this._getSpaceMembershipApiFromGql({
                    spaceMembership,
                    memberUserDisplayName: user.displayName || undefined,
                }),
                user,
            };
        });
    }

    // CREATE CHILDREN
    async createLobbyMessage({ body, replyTo }: { body: string; replyTo: ReplyTo | undefined }) {
        const { newMessage } = postRdbMessage({
            spaceId: this.id,
            newMessage: {
                chatboardId: undefined,
                userId: this._clientUser.id,
                body,
                replyToMessageId: replyTo?.messageId,
                addNotification: {
                    replyToUser: replyTo?.user,
                    mentions: [],
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spaceName: this.name,
                    chatboardUniqueName: undefined,
                    unconfirmedMarks: [],
                    replyObservers: [],
                },
            },
        });
        if (this.isEnabledLobbyArchive) {
            this._graphqlSdk.createLobbyMessage({
                input: {
                    spaceId: this.id,
                    preparedId: newMessage.id,
                    body,
                    replyToMessageId: replyTo?.messageId,
                    mentionToUserIds: [],
                    unconfirmedMarkToUsers: [],
                    replyObserverToUsers: [],
                },
            });
        }
    }

    async createLobbyHeyMessage({
        replyTo,
        mentionToUser,
    }: {
        replyTo: ReplyTo | undefined;
        mentionToUser: MentionToUser;
    }) {
        const { newMessage } = postRdbMessage({
            spaceId: this.id,
            newMessage: {
                chatboardId: undefined,
                userId: this._clientUser.id,
                body: '',
                replyToMessageId: replyTo?.messageId,
                addNotification: {
                    replyToUser: replyTo?.user,
                    mentions: [mentionToUser],
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spaceName: this.name,
                    chatboardUniqueName: undefined,
                    unconfirmedMarks: [],
                    replyObservers: [],
                },
            },
        });
        if (this.isEnabledLobbyArchive) {
            this._graphqlSdk.createLobbyMessage({
                input: {
                    spaceId: this.id,
                    preparedId: newMessage.id,
                    body: '',
                    replyToMessageId: replyTo?.messageId,
                    mentionToUserIds: [mentionToUser.id],
                    unconfirmedMarkToUsers: [],
                    replyObserverToUsers: [],
                },
            });
        }
    }

    async createLobbyAbsentMessage({
        body,
        mentionToUser,
        isObserveReply,
    }: {
        body: string;
        mentionToUser: MentionToUser;
        isObserveReply: boolean;
    }) {
        const firestoreDb = getFirebaseFirestore();
        const unconfirmedMarkToUsers = [
            {
                id: doc(collection(firestoreDb, `Space/${this.id}/UnconfirmedMark`)).id,
                toUserId: mentionToUser.id,
            },
        ];
        const replyObserverToUsers = (() => {
            if (isObserveReply) {
                return [
                    {
                        id: doc(collection(firestoreDb, `Space/${this.id}/ReplyObserver`)).id,
                        toUserId: mentionToUser.id,
                    },
                ];
            }
            return [];
        })();
        const { newMessage } = postRdbMessage({
            spaceId: this.id,
            newMessage: {
                chatboardId: undefined,
                userId: this._clientUser.id,
                body,
                replyToMessageId: undefined,
                addNotification: {
                    replyToUser: undefined,
                    mentions: [mentionToUser],
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spaceName: this.name,
                    chatboardUniqueName: undefined,
                    unconfirmedMarks: unconfirmedMarkToUsers,
                    replyObservers: replyObserverToUsers,
                },
            },
        });
        this._graphqlSdk.createLobbyMessage({
            input: {
                spaceId: this.id,
                preparedId: newMessage.id,
                body,
                replyToMessageId: undefined,
                mentionToUserIds: [mentionToUser.id],
                unconfirmedMarkToUsers: unconfirmedMarkToUsers.map(({ id, toUserId }) => ({
                    preparedId: id,
                    toUserId,
                })),
                replyObserverToUsers: replyObserverToUsers.map(({ id, toUserId }) => ({
                    preparedId: id,
                    toUserId,
                })),
            },
        });
    }

    async createChatboard({
        uniqueName,
        users,
    }: {
        uniqueName: string;
        users: {
            spaceMembershipApi: SpaceMembershipApi;
            permission: ChatboardPermissionType;
        }[];
    }) {
        const {
            createChatboard: { chatboard, chatboardMemberships },
        } = await this._graphqlSdk.createChatboard({
            input: {
                spaceId: this.id,
                uniqueName,
                users: users.map(({ spaceMembershipApi, permission }) => ({
                    userId: spaceMembershipApi.userId,
                    permission,
                })),
            },
        });
        const chatboardMembershipWithSpaceMemberships = chatboardMemberships.map((chatboardMembership) => {
            const targetItem = users.find(
                ({ spaceMembershipApi }) => spaceMembershipApi.userId === chatboardMembership.userId
            );
            if (!targetItem) {
                throw new Error('never.');
            }
            return {
                chatboardMembership,
                spaceMembershipApi: targetItem.spaceMembershipApi,
            };
        });
        postRdbChatboard({
            spaceId: this.id,
            newChatboard: {
                id: chatboard.id,
                uniqueName,
                addNotification: {
                    spaceName: this.name,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    initialChatboardMemberships:
                        chatboardMembershipWithSpaceMemberships.length > 0
                            ? chatboardMembershipWithSpaceMemberships.map(
                                  ({ chatboardMembership, spaceMembershipApi }) => ({
                                      id: chatboardMembership.id,
                                      userId: chatboardMembership.userId,
                                      chatboardPermission: chatboardMembership.permission,
                                      toUserUniqueName: spaceMembershipApi.uniqueName,
                                      toUserDisplayName: spaceMembershipApi.getDisplayName(),
                                      spacePermission: spaceMembershipApi.permission,
                                  })
                              )
                            : undefined,
                },
            },
        });
        const chatboardApi = this._getChatboardApiFromGql(chatboard);
        return {
            chatboardApi,
            chatboardMembershipApis: chatboardMembershipWithSpaceMemberships.map(
                ({ chatboardMembership, spaceMembershipApi }) =>
                    this._getChatboardMembershipApiFromGql({
                        chatboardMembership,
                        chatboardUniqueName: chatboardApi.uniqueName,
                        spaceMembershipPermission: spaceMembershipApi.permission,
                        spaceMembershipUniqueName: spaceMembershipApi.uniqueName,
                        spaceMembershipDisplayName: spaceMembershipApi.getDisplayName(),
                    })
            ),
        };
    }

    async createSpaceMembership({
        newMemberUserCode,
        uniqueName,
        displayName,
        permission,
    }: {
        newMemberUserCode: string;
        uniqueName: string | undefined;
        displayName: string | undefined;
        permission: SpacePermissionType;
    }) {
        const {
            createSpaceMembership: { spaceMembership, user },
        } = await this._graphqlSdk.createSpaceMembership({
            input: {
                spaceId: this.id,
                userUniqueCode: newMemberUserCode,
                membershipUniqueName: uniqueName,
                membershipDisplayName: displayName,
                permission,
            },
        });
        postRdbSpaceMembership({
            spaceId: this.id,
            newSpaceMembership: {
                id: spaceMembership.id,
                userId: spaceMembership.userId,
                uniqueName: spaceMembership.uniqueName,
                displayName: spaceMembership.displayName || undefined,
                isOpenPresence: spaceMembership.isOpenPresence,
                permission,
                addNotification: {
                    spaceName: this.name,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    toUserDisplayName: user.displayName || undefined,
                },
            },
        });
        const spaceMembershipApi = this._getSpaceMembershipApiFromGql({
            spaceMembership,
            memberUserDisplayName: user.displayName || undefined,
        });
        return {
            spaceMembershipApi,
        };
    }

    async createSpaceMemberships(
        items: {
            newMemberUserCode: string;
            uniqueName: string | undefined;
            displayName: string | undefined;
            permission: SpacePermissionType;
        }[]
    ) {
        // later: 専用のエンドポイントを使う
        return Promise.all(items.map(async (item) => (await this.createSpaceMembership(item)).spaceMembershipApi));
    }

    // LISTENERS
    listenToHotUsersForSpace({
        onAdded,
        onChanged,
        onRemoved,
    }: {
        onAdded: (input: { newUser: RdbUser }) => void;
        onChanged: (input: { changedUser: RdbUser }) => void;
        onRemoved: (input: { removedUser: RdbUser }) => void;
    }) {
        return listenToHotUsersForSpace({
            spaceId: this.id,
            onAdded,
            onChanged,
            onRemoved,
        });
    }

    listenToMessages({
        onAdded,
        onModified,
    }: {
        onAdded: (input: {
            newMessageApi: MessageApi;
            initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
            initialReplyObserverApis: ReplyObserverApi[];
        }) => void;
        onModified: (input: { modifiedMessageApi: MessageApi }) => void;
    }) {
        return listenToMessages({
            spaceId: this.id,
            onAdded: ({ newMessage, addNotification }) => {
                onAdded({
                    newMessageApi: new MessageApi({
                        ...newMessage,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                        chatboardUniqueName: addNotification.chatboardUniqueName,
                    }),
                    initialUnconfirmedMarkApi: (() => {
                        const unconfirmedMarkForClient = addNotification.unconfirmedMarks?.find(
                            ({ toUserId }) => toUserId === this._clientUser.id
                        );
                        if (unconfirmedMarkForClient) {
                            return new UnconfirmedMarkApi({
                                graphqlSdk: this._graphqlSdk,
                                id: unconfirmedMarkForClient.id,
                                createdAt: new Date(),
                                spaceId: this.id,
                                chatboardId: newMessage.chatboardId,
                                messageId: newMessage.id,
                                fromUserId: newMessage.userId,
                                toUserId: unconfirmedMarkForClient.toUserId,
                            });
                        }
                        return undefined;
                    })(),
                    initialReplyObserverApis:
                        addNotification.replyObservers?.map(
                            ({ toUserId, id }) =>
                                new ReplyObserverApi({
                                    graphqlSdk: this._graphqlSdk,
                                    id,
                                    createdAt: new Date(),
                                    spaceId: this.id,
                                    chatboardId: newMessage.chatboardId,
                                    messageId: newMessage.id,
                                    fromUserId: newMessage.userId,
                                    toUserId,
                                })
                        ) || [],
                });
            },
            onModified: ({ modifiedMessage, modifyNotification }) => {
                console.log({ modifiedMessage });
                onModified({
                    modifiedMessageApi: new MessageApi({
                        ...modifiedMessage,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                        chatboardUniqueName: modifyNotification.chatboardUniqueName,
                    }),
                });
            },
        });
    }

    listenToReactions({
        onAdded,
        onRemoved,
    }: {
        onAdded: (input: { newReactionApi: ReactionApi }) => void;
        onRemoved: (input: { removedReactionApi: ReactionApi }) => void;
    }) {
        return listenToReactions({
            spaceId: this.id,
            onAdded: ({ newReaction }) => {
                onAdded({
                    newReactionApi: new ReactionApi({
                        ...newReaction,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
            onRemoved: ({ removedReaction }) => {
                console.log({ removedReaction });
                onRemoved({
                    removedReactionApi: new ReactionApi({
                        ...removedReaction,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
        });
    }

    listenToUnconfirmedMarks({
        onAdded,
        onRemoved,
    }: {
        onAdded: (input: { newUnconfirmedMarkApi: UnconfirmedMarkApi }) => void;
        onRemoved: (input: { removedUnconfirmedMarkApi: UnconfirmedMarkApi }) => void;
    }) {
        return listenToUnconfirmedMarks({
            spaceId: this.id,
            onAdded: ({ newUnconfirmedMark }) => {
                onAdded({
                    newUnconfirmedMarkApi: new UnconfirmedMarkApi({
                        ...newUnconfirmedMark,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
            onRemoved: ({ removedUnconfirmedMark }) => {
                console.log({ removedUnconfirmedMark });
                onRemoved({
                    removedUnconfirmedMarkApi: new UnconfirmedMarkApi({
                        ...removedUnconfirmedMark,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
        });
    }

    listenToReplyObservers({
        onAdded,
        onRemoved,
    }: {
        onAdded: (input: { newReplyObserverApi: ReplyObserverApi }) => void;
        onRemoved: (input: { removedReplyObserverApi: ReplyObserverApi }) => void;
    }) {
        return listenToReplyObservers({
            spaceId: this.id,
            onAdded: ({ newReplyObserver }) => {
                onAdded({
                    newReplyObserverApi: new ReplyObserverApi({
                        ...newReplyObserver,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
            onRemoved: ({ removedReplyObserver }) => {
                console.log({ removedReplyObserver });
                onRemoved({
                    removedReplyObserverApi: new ReplyObserverApi({
                        ...removedReplyObserver,
                        graphqlSdk: this._graphqlSdk,
                    }),
                });
            },
        });
    }

    listenToSpaceMemberships({
        onAdded,
        onModified,
        onRemoved,
    }: {
        onAdded: (input: { newSpaceMembershipApi: SpaceMembershipApi }) => void;
        onModified: (input: { modifiedSpaceMembershipApi: SpaceMembershipApi }) => void;
        onRemoved: (input: { removedSpaceMembershipApi: SpaceMembershipApi }) => void;
    }) {
        return listenToSpaceMemberships({
            spaceId: this.id,
            onAdded: ({ newSpaceMembership, addNotification }) => {
                onAdded({
                    newSpaceMembershipApi: new SpaceMembershipApi({
                        ...newSpaceMembership,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                        memberUserDisplayName: addNotification.toUserDisplayName,
                    }),
                });
            },
            onModified: ({ modifiedSpaceMembership, modifyNotification }) => {
                console.log({ modifiedSpaceMembership });
                onModified({
                    modifiedSpaceMembershipApi: new SpaceMembershipApi({
                        ...modifiedSpaceMembership,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                        memberUserDisplayName: modifyNotification.toUserDisplayName,
                    }),
                });
            },
            onRemoved: ({ removedSpaceMembership, removeNotification }) => {
                console.log({ removedSpaceMembership });
                onRemoved({
                    removedSpaceMembershipApi: new SpaceMembershipApi({
                        ...removedSpaceMembership,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                        memberUserDisplayName: removeNotification.toUserDisplayName,
                    }),
                });
            },
        });
    }

    listenToChatboards({
        onAdded,
        onModified,
    }: {
        onAdded: (input: {
            newChatboardApi: ChatboardApi;
            initialChatboardMemberships: ChatboardMembershipApi[];
        }) => void;
        onModified: (input: { modifiedChatboardApi: ChatboardApi }) => void;
    }) {
        return listenToChatboards({
            spaceId: this.id,
            onAdded: ({ newChatboard, addNotification }) => {
                onAdded({
                    newChatboardApi: new ChatboardApi({
                        ...newChatboard,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                    }),
                    initialChatboardMemberships:
                        addNotification.initialChatboardMemberships?.map((initialChatboardMembership) => {
                            return new ChatboardMembershipApi({
                                id: initialChatboardMembership.id,
                                createdAt: new Date(),
                                updatedAt: undefined,
                                userId: initialChatboardMembership.userId,
                                spaceId: this.id,
                                chatboardId: newChatboard.id,
                                permission: initialChatboardMembership.chatboardPermission,
                                graphqlSdk: this._graphqlSdk,
                                currentFcmToken: this._currentFcmToken,
                                clientUser: this._clientUser,
                                clientUniqueName: this._clientUniqueName,
                                clientDisplayName: this._clientDisplayName,
                                spaceName: this.name,
                                spaceMembershipPermission: initialChatboardMembership.spacePermission,
                                spaceMembershipUniqueName: initialChatboardMembership.toUserUniqueName,
                                spaceMembershipDisplayName: initialChatboardMembership.toUserDisplayName,
                                chatboardUniqueName: newChatboard.uniqueName,
                            });
                        }) || [],
                });
            },
            onModified: ({ modifiedChatboard }) => {
                console.log({ modifiedChatboard });
                onModified({
                    modifiedChatboardApi: new ChatboardApi({
                        ...modifiedChatboard,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                    }),
                });
            },
        });
    }

    listenToChatboardMemberships({
        onAdded,
        onModified,
        onRemoved,
    }: {
        onAdded: (input: { newChatboardMembershipApi: ChatboardMembershipApi }) => void;
        onModified: (input: { modifiedChatboardMembershipApi: ChatboardMembershipApi }) => void;
        onRemoved: (input: { removedChatboardMembershipApi: ChatboardMembershipApi }) => void;
    }) {
        return listenToChatboardMemberships({
            spaceId: this.id,
            onAdded: ({ newChatboardMembership, addNotification }) => {
                onAdded({
                    newChatboardMembershipApi: new ChatboardMembershipApi({
                        ...newChatboardMembership,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                        spaceMembershipPermission: addNotification.spacePermission,
                        spaceMembershipUniqueName: addNotification.toUserUniqueName,
                        spaceMembershipDisplayName: addNotification.toUserDisplayName,
                        chatboardUniqueName: addNotification.chatboardUniqueName,
                    }),
                });
            },
            onModified: ({ modifiedChatboardMembership, modifyNotification }) => {
                console.log({ modifiedChatboardMembership });
                onModified({
                    modifiedChatboardMembershipApi: new ChatboardMembershipApi({
                        ...modifiedChatboardMembership,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                        spaceMembershipPermission: modifyNotification.spacePermission,
                        spaceMembershipUniqueName: modifyNotification.toUserUniqueName,
                        spaceMembershipDisplayName: modifyNotification.toUserDisplayName,
                        chatboardUniqueName: modifyNotification.chatboardUniqueName,
                    }),
                });
            },
            onRemoved: ({ removedChatboardMembership, removeNotification }) => {
                console.log({ removedChatboardMembership });
                onRemoved({
                    removedChatboardMembershipApi: new ChatboardMembershipApi({
                        ...removedChatboardMembership,
                        graphqlSdk: this._graphqlSdk,
                        currentFcmToken: this._currentFcmToken,
                        clientUser: this._clientUser,
                        clientUniqueName: this._clientUniqueName,
                        clientDisplayName: this._clientDisplayName,
                        spaceName: this.name,
                        spaceMembershipPermission: removeNotification.spacePermission,
                        spaceMembershipUniqueName: removeNotification.toUserUniqueName,
                        spaceMembershipDisplayName: removeNotification.toUserDisplayName,
                        chatboardUniqueName: removeNotification.chatboardUniqueName,
                    }),
                });
            },
        });
    }
}
