import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { MessageApi, UnconfirmedMarkApi, ReplyObserverApi, ChatboardApi } from '@/utils/PowerchatClient';
import { ChatBoxState } from '@/components/0_atom/ChatBox';
import { MainMessageCard } from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/MessageCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {},
}));

export const ChatboardViewLocalContent: FC<
    ChatBoxState & {
        className?: string;
        chatboardApi: ChatboardApi;
        messageItems: {
            id: string;
            messageApi: MessageApi;
            initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
            initialReplyObserverApis: ReplyObserverApi[];
        }[];
        messageIds: string[];
        getMessageItemAbsolutely: (id: string) => {
            id: string;
            messageApi: MessageApi;
            initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
            initialReplyObserverApis: ReplyObserverApi[];
        };
    }
> = ({
    unreadCount,
    increaseUnreadCount,
    onAddDownstream,
    onAddUpstream,
    isSeeingOldest,
    isSeeingRecent,
    className,
    chatboardApi,
    messageItems,
    messageIds,
    getMessageItemAbsolutely,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            {messageItems.map(({ messageApi, initialReplyObserverApis, initialUnconfirmedMarkApi }, i) => {
                return (
                    <MainMessageCard
                        key={messageApi.id}
                        chatboardApi={chatboardApi}
                        messageApi={messageApi}
                        initialReactionApis={[]}
                        initialReplyObserverApis={initialReplyObserverApis}
                        initialUnconfirmedMarkApi={initialUnconfirmedMarkApi}
                        initialReplies={[]}
                        isOmitUser={messageItems[i - 1]?.messageApi.userId === messageApi.userId}
                    />
                );
            })}
        </div>
    );
};
ChatboardViewLocalContent.displayName = 'ChatboardViewLocalContent';
