import { forwardRef, ComponentProps, ReactNode, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { useTheme } from '@material-ui/core/styles';
import MuiInputBase from '@material-ui/core/InputBase';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import { useStyles, InputHelpers, getValidNumber, getInt } from '@/components/0_atom/Input/common';

export const IntInput = forwardRef<
    HTMLElement,
    ComponentProps<typeof MuiInputBase> & {
        value: number | undefined;
        setValue: (value: number | undefined) => void;
        messages?: {
            isVisible: boolean;
            isKeepSpace?: boolean;
            variant?: 'error' | 'success' | 'plain';
            message: ReactNode;
        }[];
        isSatisfied?: boolean;
        onInputMount: (inputElm: HTMLInputElement) => void;
        onGotEnter?: () => void;
    }
>(
    (
        {
            value,
            setValue,
            messages,
            isSatisfied,
            onInputMount,
            onGotEnter,
            error,
            className,
            startAdornment,
            endAdornment,
            ...otherProps
        },
        ref
    ) => {
        // STYLE
        const c = useStyles(useTheme());

        // REF
        const inputRef = useRef<HTMLInputElement>(null);

        // HOOKS
        const [isComposing, setIsComposing] = useState(false);

        // HANDLER
        const handleChange = (value: string) => {
            const numberValue = getValidNumber(value);
            if (numberValue !== undefined) {
                const intValue = getInt({ value: numberValue, method: 'roundDown' });
                setValue(intValue);
            } else {
                setValue(undefined);
            }
        };

        useEffect(() => {
            if (inputRef.current) {
                onInputMount(inputRef.current);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <>
                <MuiInputBase
                    ref={ref}
                    inputRef={inputRef}
                    {...otherProps}
                    className={clsx(c.root, className, {
                        [c.rootSatisfied]: isSatisfied,
                    })}
                    value={value === undefined ? '' : String(value)}
                    onChange={({ target }) => handleChange(target.value)}
                    error={!!messages?.find(({ variant, isVisible }) => variant === 'error' && isVisible) || error}
                    startAdornment={
                        startAdornment ? (
                            <MuiInputAdornment position={'start'}>{startAdornment}</MuiInputAdornment>
                        ) : undefined
                    }
                    endAdornment={
                        endAdornment ? (
                            <MuiInputAdornment position={'end'}>{endAdornment}</MuiInputAdornment>
                        ) : undefined
                    }
                    inputMode={'numeric'}
                    onCompositionStart={() => setIsComposing(true)}
                    onCompositionEnd={() => setIsComposing(false)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !isComposing && onGotEnter) {
                            onGotEnter();
                        }
                    }}
                />
                <InputHelpers items={messages} />
            </>
        );
    }
);
IntInput.displayName = 'IntInput';
