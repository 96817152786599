import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    RemoveRdbLastReplyObserverInput,
    getRdbLastReplyObserverInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const removeRdbReplyObserver = ({
    spaceId,
    replyObserver,
}: {
    spaceId: string;
    replyObserver: RemoveRdbLastReplyObserverInput;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastReplyObserverRef = ref(db, `lastReplyObservers/${spaceId}`);
    set(lastReplyObserverRef, {
        action: 'remove',
        ...getRdbLastReplyObserverInput({
            ...replyObserver,
            removedAt: 'serverTimestamp',
        }),
    });
};
