import { SpaceMembershipApi } from '@/utils/PowerchatClient';
import { MultilingualText } from '@/utils/customHooks';

export const getOptionalUserName = ({
    spaceMembershipApi,
    txt,
}: {
    spaceMembershipApi: SpaceMembershipApi | undefined;
    txt: (input: MultilingualText) => string;
}) => {
    return {
        uniqueName: spaceMembershipApi?.uniqueName || 'missingUser',
        displayName: spaceMembershipApi
            ? spaceMembershipApi.getDisplayName()
            : txt({
                  en: 'Missing user',
                  ja: '退会済みのユーザー',
              }),
    };
};
