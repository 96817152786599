import { serverTimestamp } from 'firebase/database';
import {
    ChatboardPermissionType,
    SpacePermissionType,
    SpacePermission,
    ChatboardPermission,
} from '@/utils/PowerchatClient/models/_interfaces';

// NOTIFICATION
export type RdbLastChatboardMembershipAddNotification = {
    spaceName: string;
    chatboardUniqueName: string;
    toUserUniqueName: string;
    toUserDisplayName: string | undefined;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    spacePermission: SpacePermissionType;
};
export type RdbLastChatboardMembershipModifyNotification = {
    spaceName: string;
    chatboardUniqueName: string;
    toUserUniqueName: string;
    toUserDisplayName: string | undefined;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    spacePermission: SpacePermissionType;
};
export type RdbLastChatboardMembershipRemoveNotification = {
    spaceName: string;
    chatboardUniqueName: string;
    toUserUniqueName: string;
    toUserDisplayName: string | undefined;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    spacePermission: SpacePermissionType;
};

// TYPE
export type RdbLastChatboardMembership = {
    action: 'add' | 'modify' | 'remove';
    id: string;
    createdAt: number;
    updatedAt: number | undefined;
    removedAt: number | undefined;
    userId: string;
    chatboardId: string;
    permission: ChatboardPermissionType;
    // for notification
    addNotification: RdbLastChatboardMembershipAddNotification | undefined;
    modifyNotification: RdbLastChatboardMembershipModifyNotification | undefined;
    removeNotification: RdbLastChatboardMembershipRemoveNotification | undefined;
};

// INPUT
export type RdbLastChatboardMembershipInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    removedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    chatboardId: string;
    permission: ChatboardPermissionType;
    // for notification
    addNotification: RdbLastChatboardMembershipAddNotification | undefined;
    modifyNotification: RdbLastChatboardMembershipModifyNotification | undefined;
    removeNotification: RdbLastChatboardMembershipRemoveNotification | undefined;
};
export type AddRdbLastChatboardMembershipInput = {
    id: string;
    userId: string;
    chatboardId: string;
    permission: ChatboardPermissionType;
    // for notification
    addNotification: RdbLastChatboardMembershipAddNotification;
};
export type ModifyRdbLastChatboardMembershipInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    chatboardId: string;
    permission: ChatboardPermissionType;
    // for notification
    modifyNotification: RdbLastChatboardMembershipModifyNotification;
};
export type RemoveRdbLastChatboardMembershipInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    updatedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    chatboardId: string;
    permission: ChatboardPermissionType;
    // for notification
    removeNotification: RdbLastChatboardMembershipRemoveNotification;
};

export const getRdbLastChatboardMembershipInput = ({
    id,
    createdAt,
    updatedAt,
    removedAt,
    userId,
    chatboardId,
    permission,
    addNotification,
    modifyNotification,
    removeNotification,
}: RdbLastChatboardMembershipInput): {
    id: string;
    createdAt: number | ReturnType<typeof serverTimestamp>;
    updatedAt: number | ReturnType<typeof serverTimestamp> | null;
    removedAt: number | ReturnType<typeof serverTimestamp> | null;
    userId: string;
    chatboardId: string;
    permission: ChatboardPermissionType;
    // for notification
    addNotification: {
        spaceName: string;
        chatboardUniqueName: string;
        toUserUniqueName: string;
        toUserDisplayName: string | null;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        spacePermission: SpacePermissionType;
    } | null;
    modifyNotification: {
        spaceName: string;
        chatboardUniqueName: string;
        toUserUniqueName: string;
        toUserDisplayName: string | null;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        spacePermission: SpacePermissionType;
    } | null;
    removeNotification: {
        spaceName: string;
        chatboardUniqueName: string;
        toUserUniqueName: string;
        toUserDisplayName: string | null;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        spacePermission: SpacePermissionType;
    } | null;
} => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        updatedAt: updatedAt === 'serverTimestamp' ? serverTimestamp() : updatedAt ? updatedAt.getTime() : null,
        removedAt: removedAt === 'serverTimestamp' ? serverTimestamp() : removedAt ? removedAt.getTime() : null,
        userId,
        chatboardId,
        permission: new ChatboardPermission(permission),
        addNotification: (() => {
            if (addNotification) {
                const {
                    spaceName,
                    chatboardUniqueName,
                    fromUserUniqueName,
                    fromUserDisplayName,
                    toUserUniqueName,
                    toUserDisplayName,
                    spacePermission,
                } = addNotification;
                return {
                    spaceName,
                    chatboardUniqueName,
                    fromUserUniqueName,
                    fromUserDisplayName: fromUserDisplayName || null,
                    toUserUniqueName,
                    toUserDisplayName: toUserDisplayName || null,
                    spacePermission: new SpacePermission(spacePermission),
                };
            }
            return null;
        })(),
        modifyNotification: (() => {
            if (modifyNotification) {
                const {
                    spaceName,
                    chatboardUniqueName,
                    fromUserUniqueName,
                    fromUserDisplayName,
                    toUserUniqueName,
                    toUserDisplayName,
                    spacePermission,
                } = modifyNotification;
                return {
                    spaceName,
                    chatboardUniqueName,
                    fromUserUniqueName,
                    fromUserDisplayName: fromUserDisplayName || null,
                    toUserUniqueName,
                    toUserDisplayName: toUserDisplayName || null,
                    spacePermission: new SpacePermission(spacePermission),
                };
            }
            return null;
        })(),
        removeNotification: (() => {
            if (removeNotification) {
                const {
                    spaceName,
                    chatboardUniqueName,
                    fromUserUniqueName,
                    fromUserDisplayName,
                    toUserUniqueName,
                    toUserDisplayName,
                    spacePermission,
                } = removeNotification;
                return {
                    spaceName,
                    chatboardUniqueName,
                    fromUserUniqueName,
                    fromUserDisplayName: fromUserDisplayName || null,
                    toUserUniqueName,
                    toUserDisplayName: toUserDisplayName || null,
                    spacePermission: new SpacePermission(spacePermission),
                };
            }
            return null;
        })(),
    };
};
