import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import {
    postRdbReaction,
    modifyRdbMessage,
    postRdbUnconfirmedMark,
    postRdbReplyObserver,
} from '@/utils/PowerchatClient/RealtimeDatabase';
import { User } from '@/utils/PowerchatClient/models/User';
import { ReactionApi } from '@/utils/PowerchatClient/models/Reaction';
import { UnconfirmedMarkApi } from '@/utils/PowerchatClient/models/UnconfirmedMark';
import { ReplyObserverApi } from '@/utils/PowerchatClient/models/ReplyObserver';
import { Message, MessageType } from '@/utils/PowerchatClient/models/Message/data/Message';

export type MessageApiType = {
    // UPDATE SELF
    cancel: () => Promise<void>;

    // CREATE CHILDREN
    createReaction: (input: { letter: string }) => Promise<{
        reactionApi: ReactionApi;
    }>;
    createUnconfirmedMark: () => Promise<{
        unconfirmedMarkApi: UnconfirmedMarkApi;
    }>;
    createReplyObservers: () => Promise<ReplyObserverApi[]>;
};

type ConstructorInput = MessageType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    clientUser: User;
    clientUniqueName: string;
    clientDisplayName: string | undefined;
    spaceName: string;
    chatboardUniqueName: string | undefined;
};

export class MessageApi extends Message implements MessageApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    protected _clientUser: User;

    protected _clientUniqueName: string;

    protected _clientDisplayName: string | undefined;

    protected _spaceName: string;

    protected _chatboardUniqueName: string | undefined;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
        this._clientUser = input.clientUser;
        this._clientUniqueName = input.clientUniqueName;
        this._clientDisplayName = input.clientDisplayName;
        this._spaceName = input.spaceName;
        this._chatboardUniqueName = input.chatboardUniqueName;
    }

    // UPDATE SELF
    async cancel() {
        const now = new Date();
        this._updateCanceledAt({ canceledAt: now, updatedAt: now });
        modifyRdbMessage({
            spaceId: this.spaceId,
            message: {
                ...this,
                chatboardId: undefined,
                replyToMessageId: undefined,
                modifyNotification: {
                    chatboardUniqueName: undefined,
                },
            },
        });
        if (this.chatboardId) {
            await this._graphqlSdk.cancelChatboardMessage({
                input: {
                    spaceId: this.spaceId,
                    chatboardId: this.chatboardId,
                    messageId: this.id,
                },
            });
        } else {
            await this._graphqlSdk.cancelLobbyMessage({
                input: {
                    spaceId: this.spaceId,
                    messageId: this.id,
                },
            });
        }
    }

    // CREATE CHILDREN
    async createReaction({ letter }: { letter: string }) {
        if (!this.chatboardId || !this._chatboardUniqueName) {
            throw new Error('MessageApi.createReaction: !this.chatboardId || !this._chatboardUniqueName.');
        }
        const { newReaction } = postRdbReaction({
            spaceId: this.spaceId,
            newReaction: {
                chatboardId: this.chatboardId,
                userId: this._clientUser.id,
                messageId: this.id,
                letter,
                addNotification: {
                    messageUserId: this.userId,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spaceName: this._spaceName,
                    chatboardUniqueName: this._chatboardUniqueName,
                },
            },
        });
        this._graphqlSdk.createReaction({
            input: {
                preparedId: newReaction.id,
                spaceId: this.spaceId,
                chatboardId: this.chatboardId,
                messageId: this.id,
                letter,
            },
        });
        return {
            reactionApi: new ReactionApi({
                graphqlSdk: this._graphqlSdk,
                ...newReaction,
            }),
        };
    }

    async createUnconfirmedMark() {
        const { newUnconfirmedMark } = postRdbUnconfirmedMark({
            spaceId: this.spaceId,
            newUnconfirmedMark: {
                spaceId: this.spaceId,
                chatboardId: this.chatboardId,
                messageId: this.id,
                fromUserId: this._clientUser.id,
                toUserId: this._clientUser.id,
                addNotification: {
                    messageUserId: this.userId,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spaceName: this._spaceName,
                    chatboardUniqueName: this._chatboardUniqueName,
                },
            },
        });
        if (this.chatboardId) {
            this._graphqlSdk.createChatboardUnconfirmedMark({
                input: {
                    preparedId: newUnconfirmedMark.id,
                    spaceId: this.spaceId,
                    chatboardId: this.chatboardId,
                    messageId: this.id,
                    toUserId: this._clientUser.id,
                },
            });
        } else {
            this._graphqlSdk.createLobbyUnconfirmedMark({
                input: {
                    preparedId: newUnconfirmedMark.id,
                    spaceId: this.spaceId,
                    messageId: this.id,
                    toUserId: this._clientUser.id,
                },
            });
        }
        return {
            unconfirmedMarkApi: new UnconfirmedMarkApi({
                graphqlSdk: this._graphqlSdk,
                ...newUnconfirmedMark,
            }),
        };
    }

    async createReplyObservers() {
        if (this.mentionToUserIds.length < 1) {
            throw new Error('MessageApi.createReplyObservers: empty mentionToUserIds.');
        }
        return this.mentionToUserIds.map((toUserId) => {
            const { newReplyObserver } = postRdbReplyObserver({
                spaceId: this.spaceId,
                newReplyObserver: {
                    spaceId: this.spaceId,
                    chatboardId: this.chatboardId,
                    messageId: this.id,
                    fromUserId: this._clientUser.id,
                    toUserId,
                },
            });
            if (this.chatboardId) {
                this._graphqlSdk.createChatboardReplyObserver({
                    input: {
                        preparedId: newReplyObserver.id,
                        spaceId: this.spaceId,
                        chatboardId: this.chatboardId,
                        messageId: this.id,
                        toUserId,
                    },
                });
            } else {
                this._graphqlSdk.createLobbyReplyObserver({
                    input: {
                        preparedId: newReplyObserver.id,
                        spaceId: this.spaceId,
                        messageId: this.id,
                        toUserId,
                    },
                });
            }
            return new ReplyObserverApi({
                graphqlSdk: this._graphqlSdk,
                ...newReplyObserver,
            });
        });
    }
}
