import { GqlUser } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';

export type UserType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    uniqueName: string;
    uniqueCode: string;
    displayName: string | undefined;
    canceledAt: Date | undefined;
};

export class User implements UserType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    readonly uniqueName: string;

    readonly uniqueCode: string;

    displayName: string | undefined;

    canceledAt: Date | undefined;

    constructor({ id, createdAt, updatedAt, uniqueName, uniqueCode, displayName, canceledAt }: UserType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.uniqueName = uniqueName;
        this.uniqueCode = uniqueCode;
        this.displayName = displayName;
        this.canceledAt = canceledAt;
    }

    async _updateDisplayName({
        displayName,
        updatedAt,
    }: {
        displayName: string | undefined;
        updatedAt: Date | undefined;
    }) {
        this.displayName = displayName;
        this.updatedAt = updatedAt;
    }

    async _updateCanceledAt({ canceledAt, updatedAt }: { canceledAt: Date | undefined; updatedAt: Date | undefined }) {
        this.canceledAt = canceledAt;
        this.updatedAt = updatedAt;
    }
}

export const getUserFromGql = ({
    id,
    createdAt,
    updatedAt,
    uniqueName,
    uniqueCode,
    displayName,
    canceledAt,
}: GqlUser) => {
    return new User({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        uniqueName,
        uniqueCode,
        displayName: displayName || undefined,
        canceledAt: isoToDateOptionally(canceledAt),
    });
};
