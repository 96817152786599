import { FC, useState, useEffect, useRef, useReducer, ReactNode } from 'react';

import { WindowsBoxCtx } from '@/utils/ctxs';

import { WindowsBox } from './WindowsBox';

export const WindowsProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // REF
    const windowsBoxRef = useRef<HTMLDivElement>(null);

    // HOOKS
    const [highestPriority, setHighestPriority] = useState(0);
    const [windowAmount, dispatchWindowAmount] = useReducer<
        (state: number, input: { increase?: boolean; decrease?: boolean; reset?: boolean }) => number
    >((state, { increase, decrease, reset }) => {
        if (increase) {
            return state + 1;
        }
        if (decrease) {
            return state === 0 ? 0 : state - 1;
        }
        if (reset) {
            return 0;
        }
        throw new Error('never');
    }, 0);

    useEffect(() => {
        if (!windowAmount) {
            setHighestPriority(0);
        }
    }, [windowAmount]);

    return (
        <>
            <WindowsBox ref={windowsBoxRef} rootZIndex={10} />
            <WindowsBoxCtx.Provider
                value={{
                    elm: windowsBoxRef.current || undefined,
                    rootZIndex: 0,
                    highestPriority,
                    setHighestPriority,
                    addWindow: () => dispatchWindowAmount({ increase: true }),
                    removeWindow: () => dispatchWindowAmount({ decrease: true }),
                }}
            >
                {children}
            </WindowsBoxCtx.Provider>
        </>
    );
};
WindowsProvider.displayName = 'WindowsProvider';
