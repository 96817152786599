import { useCallback, useEffect } from 'react';

import { RdbUser, SpaceApi, SpaceMembershipApi, getRdbUser } from '@/utils/PowerchatClient';
import { useSpacesCtx, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';

export type SpaceMemberItem = {
    id: string;
    // rdbUser: RdbUser | undefined;
    spaceMembershipApi: SpaceMembershipApi;
};

export const useSpaceMembers = ({ spaceApi }: { spaceApi: SpaceApi | undefined }) => {
    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const spacesCtx = useSpacesCtx();
    const { ids, getItem, getItemAbsolutely, initialize, addItem, updateItem, removeItem } =
        useLazyItems<SpaceMemberItem>(undefined);

    // DATA
    // const spaceApi = current?.spaceApi;
    const updateSpaceMembershipApiForSpacesCtx = spacesCtx?.updateSpaceMembershipApi;
    const removeSpace = spacesCtx?.removeSpace;

    // CALLBACKS
    const fetchSpaceMembers = useCallback(async () => {
        if (spaceApi) {
            const fetchedItems = await spaceApi.getSpaceMembershipWithUsers();
            initialize(
                await Promise.all(
                    fetchedItems.map(async ({ spaceMembershipApi }) => {
                        const rdbUser = await getRdbUser({ userId: spaceMembershipApi.userId });
                        return {
                            id: spaceMembershipApi.userId,
                            rdbUser: rdbUser?.currentSpace?.id === spaceApi.id ? rdbUser : undefined,
                            spaceMembershipApi,
                        };
                    })
                )
            );
        } else {
            initialize(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaceApi?.id]);

    // USEEFFECT
    useEffect(() => {
        fetchSpaceMembers();
    }, [fetchSpaceMembers]);
    useEffect(() => {
        return () => {
            initialize(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaceApi?.id]);

    // CALLBACKS
    const addSpaceMember = useCallback(
        async (spaceMembershipApi: SpaceMembershipApi) => {
            // const rdbUser = await getRdbUser({ userId: spaceMembershipApi.userId });
            addItem({
                newItem: {
                    id: spaceMembershipApi.userId,
                    // rdbUser: rdbUser?.currentSpace?.id === spaceMembershipApi.userId ? rdbUser : undefined,
                    spaceMembershipApi,
                },
                isIgnoreDuplicationError: true,
            });
        },
        [addItem]
    );
    const updateSpaceMembershipApi = useCallback(
        (updatedSpaceMembershipApi: SpaceMembershipApi) => {
            updateItem({
                id: updatedSpaceMembershipApi.userId,
                updator: (prevItem) => ({
                    ...prevItem,
                    spaceMembershipApi: updatedSpaceMembershipApi,
                }),
                isIgnoreMissingError: true,
            });
            updateSpaceMembershipApiForSpacesCtx && updateSpaceMembershipApiForSpacesCtx(updatedSpaceMembershipApi);
        },
        [updateItem, updateSpaceMembershipApiForSpacesCtx]
    );
    // const updateSpaceMemberRdbUser = useCallback(
    //     (rdbUser: RdbUser) => {
    //         updateItem({
    //             id: rdbUser.id,
    //             updator: (prevItem) => ({
    //                 ...prevItem,
    //                 rdbUser,
    //             }),
    //             isIgnoreMissingError: true,
    //         });
    //     },
    //     [updateItem]
    // );
    const removeSpaceMember = useCallback(
        (userId: string) => {
            if (userId === userClient.id && spaceApi?.id && removeSpace) {
                removeSpace(spaceApi.id);
            } else {
                removeItem({
                    id: userId,
                    isIgnoreMissingError: true,
                });
            }
        },
        [removeItem, removeSpace, spaceApi?.id, userClient.id]
    );

    return {
        userIds: ids,
        getSpaceMember: getItem,
        getSpaceMemberAbsolutely: getItemAbsolutely,
        addSpaceMember,
        updateSpaceMembershipApi,
        // updateSpaceMemberRdbUser,
        removeSpaceMember,
    };
};
