import { createContext, useContext } from 'react';
import { ReplyObserverApi } from '@/utils/PowerchatClient';

export type LastReplyObserverLog = {
    action: 'add' | 'remove';
    replyObserverApi: ReplyObserverApi;
};

type LastReplyObserverCtxType = {
    lastReplyObserverLog: LastReplyObserverLog | undefined;
};
export const LastReplyObserverCtx = createContext<LastReplyObserverCtxType>({
    lastReplyObserverLog: undefined,
});
export const useLastReplyObserverCtx = () => {
    return useContext(LastReplyObserverCtx);
};
