import { useSpaceCtx, useChatboardsCtx, useSpaceMembersCtx, useHotMembersCtx } from '@/utils/ctxs';

export const useIsConnecting = ({ spaceId }: { spaceId?: string }) => {
    // CTX
    const { current } = useSpaceCtx();
    const { chatboardIds } = useChatboardsCtx();
    const { userIds } = useSpaceMembersCtx();
    const { hotUserIds } = useHotMembersCtx();

    // DATA
    const isConnecting = (() => {
        if ((spaceId && spaceId === current?.id) || (!spaceId && current)) {
            return !chatboardIds || !userIds || !hotUserIds;
        }
        return false;
    })();

    return {
        isConnecting,
    };
};
