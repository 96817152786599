import { GqlSpace } from '@/utils/PowerchatClient/graphql/generated/graphqlClient';
import { isoToDate, isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';

export type SpaceType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    name: string;
    closedAt: Date | undefined;
    isEnabledLobbyArchive: boolean;
    isEnabledActivities: boolean;
};

export class Space implements SpaceType {
    readonly id: string;

    readonly createdAt: Date;

    updatedAt: Date | undefined;

    name: string;

    closedAt: Date | undefined;

    isEnabledLobbyArchive: boolean;

    isEnabledActivities: boolean;

    constructor({ id, createdAt, updatedAt, name, closedAt, isEnabledLobbyArchive, isEnabledActivities }: SpaceType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.name = name;
        this.closedAt = closedAt;
        this.isEnabledLobbyArchive = isEnabledLobbyArchive;
        this.isEnabledActivities = isEnabledActivities;
    }

    async _updateClosedAt({ closedAt, updatedAt }: { closedAt: Date; updatedAt: Date }) {
        this.closedAt = closedAt;
        this.updatedAt = updatedAt;
    }

    async _updateName({ name, updatedAt }: { name: string; updatedAt: Date }) {
        this.name = name;
        this.updatedAt = updatedAt;
    }

    async _updateIsEnabledLobbyArchive({
        isEnabledLobbyArchive,
        updatedAt,
    }: {
        isEnabledLobbyArchive: boolean;
        updatedAt: Date;
    }) {
        this.isEnabledLobbyArchive = isEnabledLobbyArchive;
        this.updatedAt = updatedAt;
    }

    async _updateIsEnabledActivities({
        isEnabledActivities,
        updatedAt,
    }: {
        isEnabledActivities: boolean;
        updatedAt: Date;
    }) {
        this.isEnabledActivities = isEnabledActivities;
        this.updatedAt = updatedAt;
    }
}

export const getSpaceFromGql = ({
    id,
    createdAt,
    updatedAt,
    name,
    closedAt,
    isEnabledLobbyArchive,
    isEnabledActivities,
}: GqlSpace) => {
    return new Space({
        id,
        createdAt: isoToDate(createdAt),
        updatedAt: isoToDateOptionally(updatedAt),
        name,
        closedAt: isoToDateOptionally(closedAt),
        isEnabledLobbyArchive,
        isEnabledActivities,
    });
};
