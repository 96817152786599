import { serverTimestamp } from 'firebase/database';

// NOTIFICATION
export type RdbLastReactionAddNotification = {
    messageUserId: string;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    spaceName: string;
    chatboardUniqueName: string;
};

// TYPE
export type RdbLastReaction = {
    action: 'add' | 'remove';
    id: string;
    createdAt: number;
    removedAt: number | undefined;
    userId: string;
    chatboardId: string;
    messageId: string;
    letter: string;
    // for notification
    addNotification: RdbLastReactionAddNotification | undefined;
};

// INPUT
export type RdbLastReactionInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    removedAt: Date | 'serverTimestamp' | undefined;
    userId: string;
    chatboardId: string;
    messageId: string;
    letter: string;
    // for notification
    addNotification: RdbLastReactionAddNotification | undefined;
};
export type AddRdbLastReactionInput = {
    userId: string;
    chatboardId: string;
    messageId: string;
    letter: string;
    // for notification
    addNotification: RdbLastReactionAddNotification;
};
export type RemoveRdbLastReactionInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    userId: string;
    chatboardId: string;
    messageId: string;
    letter: string;
};

export const getRdbLastReactionInput = ({
    id,
    createdAt,
    removedAt,
    userId,
    chatboardId,
    messageId,
    letter,
    addNotification,
}: RdbLastReactionInput): {
    id: string;
    createdAt: number | ReturnType<typeof serverTimestamp>;
    removedAt: number | ReturnType<typeof serverTimestamp> | null;
    userId: string;
    chatboardId: string;
    messageId: string;
    letter: string;
    // for notification
    addNotification: {
        messageUserId: string;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        spaceName: string;
        chatboardUniqueName: string;
    } | null;
} => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        removedAt: removedAt === 'serverTimestamp' ? serverTimestamp() : removedAt ? removedAt.getTime() : null,
        userId,
        chatboardId,
        messageId,
        letter,
        addNotification: (() => {
            if (addNotification) {
                return {
                    messageUserId: addNotification.messageUserId,
                    fromUserUniqueName: addNotification.fromUserUniqueName,
                    fromUserDisplayName: addNotification.fromUserDisplayName || null,
                    spaceName: addNotification.spaceName,
                    chatboardUniqueName: addNotification.chatboardUniqueName,
                };
            }
            return null;
        })(),
    };
};
