import { serverTimestamp } from 'firebase/database';

// NOTIFICATION
export type RdbLastUnconfirmedMarkAddNotification = {
    messageUserId: string;
    fromUserUniqueName: string;
    fromUserDisplayName: string | undefined;
    spaceName: string;
    chatboardUniqueName: string | undefined;
};

// TYPE
export type RdbLastUnconfirmedMark = {
    action: 'add' | 'remove';
    id: string;
    createdAt: number;
    removedAt: number | undefined;
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
    // for notification
    addNotification: RdbLastUnconfirmedMarkAddNotification | undefined;
};

// INPUT
export type RdbLastUnconfirmedMarkInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    removedAt: Date | 'serverTimestamp' | undefined;
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
    // for notification
    addNotification: RdbLastUnconfirmedMarkAddNotification | undefined;
};
export type AddRdbLastUnconfirmedMarkInput = {
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
    // for notification
    addNotification: RdbLastUnconfirmedMarkAddNotification;
};
export type RemoveRdbLastUnconfirmedMarkInput = {
    id: string;
    createdAt: Date | 'serverTimestamp';
    spaceId: string;
    chatboardId: string | undefined;
    messageId: string;
    fromUserId: string;
    toUserId: string;
};

export const getRdbLastUnconfirmedMarkInput = ({
    id,
    createdAt,
    removedAt,
    spaceId,
    chatboardId,
    messageId,
    fromUserId,
    toUserId,
    addNotification,
}: RdbLastUnconfirmedMarkInput): {
    id: string;
    createdAt: number | ReturnType<typeof serverTimestamp>;
    removedAt: number | ReturnType<typeof serverTimestamp> | null;
    spaceId: string;
    chatboardId: string | null;
    messageId: string;
    fromUserId: string;
    toUserId: string;
    // for notification
    addNotification: {
        messageUserId: string;
        fromUserUniqueName: string;
        fromUserDisplayName: string | null;
        spaceName: string;
        chatboardUniqueName: string | null;
    } | null;
} => {
    return {
        id,
        createdAt: createdAt === 'serverTimestamp' ? serverTimestamp() : createdAt.getTime(),
        removedAt: removedAt === 'serverTimestamp' ? serverTimestamp() : removedAt ? removedAt.getTime() : null,
        spaceId,
        chatboardId: chatboardId || null,
        messageId,
        fromUserId,
        toUserId,
        addNotification: (() => {
            if (addNotification) {
                const { messageUserId, fromUserUniqueName, fromUserDisplayName, spaceName, chatboardUniqueName } =
                    addNotification;
                return {
                    messageUserId,
                    fromUserUniqueName,
                    fromUserDisplayName: fromUserDisplayName || null,
                    spaceName,
                    chatboardUniqueName: chatboardUniqueName || null,
                };
            }
            return null;
        })(),
    };
};
