import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import { AddChatboardModal } from '@/components/3_template/ConsoleTemplate/AddChatboardModal';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'fixed',
        top: 0,
        right: 0,
    },
}));

export const TemporaryAddChatboard: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <MuiButton className={c.button} onClick={() => setIsOpen(true)}>
                {'Add Chatboard'}
            </MuiButton>
            <AddChatboardModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </>
    );
};
TemporaryAddChatboard.displayName = 'TemporaryAddChatboard';
