import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.97327 1.23369C2.86174 0.472294 4.10096 0 5.47659 0C8.19433 0 10.3797 1.89708 10.3797 4.15326C10.3797 5.17318 9.93308 6.10879 9.19262 6.8279L10.8007 8.07397C11.0212 8.24487 11.0655 8.56755 10.8996 8.79471C10.7337 9.02186 10.4204 9.06746 10.1998 8.89657L0.199321 1.14728C-0.0212237 0.976384 -0.0655016 0.653699 0.100423 0.426544C0.266348 0.199389 0.579653 0.153784 0.800198 0.324682L1.97327 1.23369ZM2.73942 1.82736L8.43946 6.24428C9.06374 5.68391 9.44096 4.94472 9.44096 4.13544C9.44096 2.38842 7.64886 0.968039 5.46045 0.968039C4.41098 0.968039 3.45267 1.29469 2.73942 1.82736Z"
                fill="currentColor"
            />
            <path
                d="M6.29736 7.23562L7.25291 7.97606C6.70271 8.15487 6.10382 8.25307 5.47659 8.25307C4.84732 8.25307 4.24592 8.14914 3.69103 7.97098C3.11442 8.3768 2.19935 8.88655 1.0175 8.88655C0.832595 8.88655 0.668031 8.77174 0.610332 8.58268C0.538275 8.40946 0.571892 8.20655 0.699207 8.06798C0.708817 8.06065 1.30464 7.39985 1.59044 6.6179C0.956175 5.92404 0.573506 5.06033 0.573506 4.11763C0.573506 3.71336 0.64256 3.3227 0.771387 2.9536L1.54716 3.55474C1.50301 3.74303 1.47993 3.93713 1.47993 4.13544C1.47993 4.97085 1.89797 5.60235 2.24335 5.98402L2.63967 6.41697L2.43555 6.97126C2.32984 7.25831 2.19539 7.52813 2.05366 7.76806C2.50763 7.61722 2.88473 7.39431 3.15862 7.19397L3.53323 6.92187L3.97049 7.06292C4.45561 7.22119 4.96245 7.30285 5.47659 7.30285C5.7584 7.30285 6.03291 7.27968 6.29736 7.23562Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/message-slash';
export default Svg;
