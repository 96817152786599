import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    RemoveRdbLastChatboardMembershipInput,
    getRdbLastChatboardMembershipInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const removeRdbChatboardMembership = ({
    spaceId,
    chatboardMembership,
}: {
    spaceId: string;
    chatboardMembership: RemoveRdbLastChatboardMembershipInput;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastChatboardMembershipRef = ref(db, `lastChatboardMemberships/${spaceId}`);
    set(lastChatboardMembershipRef, {
        action: 'remove',
        ...getRdbLastChatboardMembershipInput({
            ...chatboardMembership,
            removedAt: 'serverTimestamp',
            addNotification: undefined,
            modifyNotification: undefined,
        }),
    });
};
