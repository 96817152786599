import { forwardRef } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        // top: '50%',
        // left: '50%',
        // transform: 'translateY(-50%) translateX(-50%)',
    },
}));

export const WindowsBox = forwardRef<
    HTMLDivElement,
    {
        className?: string;
        rootZIndex: number;
    }
>(({ className, rootZIndex }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    return <div ref={ref} className={clsx(c.root, className)} style={{ zIndex: rootZIndex }} />;
});
WindowsBox.displayName = 'WindowsBox';
