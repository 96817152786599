import { FC, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { User } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { MutationButton } from '@/components/0_atom/MutationButton';
import { InlineInputAndButton } from '@/components/1_mol/InlineInputAndButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    button: {
        paddingLeft: 24,
        paddingRight: 24,
    },
}));

export const FindUserInput: FC<{
    className?: string;
    title?: string;
    setFetchedUser: (fetchedUser: User | undefined) => void;
    buttonText: string;
    fetchUser?: (userCode: string) => Promise<User>;
    onFetched?: (input: { fetchedUser: User | undefined; setUserCode: (userCode: string | undefined) => void }) => void;
    excludingUsers?: {
        userCodes: string[];
        errorMessage: string;
    }[];
}> = ({ className, title, setFetchedUser, buttonText, fetchUser: givenFetchUser, onFetched, excludingUsers }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [userCode, setUserCode] = useState<string | undefined>(undefined);
    // const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isMissing, setIsMissing] = useState<boolean>(false);
    const { userClient } = useUserCtxAbsolutely();

    // DATA
    const excludingUserErrorMessage = (() => {
        const item = excludingUsers?.find(({ userCodes }) => !!userCode && userCodes.includes(userCode));
        console.log({ excludingUsers, item });
        return item?.errorMessage;
    })();

    // CALLBACK
    const runFetchUser = useCallback(async () => {
        setIsMissing(false);
        setFetchedUser(undefined);
        if (userCode) {
            // setIsFetching(true);
            const fetchedUser = await (async () => {
                if (givenFetchUser) {
                    return givenFetchUser(userCode).catch(() => undefined);
                }
                const { user } = await userClient.getUserByCode({ userCode }).catch(() => ({ user: undefined }));
                return user;
            })();
            if (fetchedUser) {
                setFetchedUser(fetchedUser);
            } else {
                setIsMissing(true);
            }
            onFetched && onFetched({ fetchedUser, setUserCode });
            // setIsFetching(false);
            return 'success' as const;
        }
        throw new Error('never');
    }, [givenFetchUser, userClient, userCode, setFetchedUser, onFetched]);

    // USEEFFECT
    useEffect(() => {
        if (isMissing) {
            setIsMissing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCode]);

    return (
        <div className={clsx(c.root, className)}>
            <InlineInputAndButton
                title={title}
                input={
                    <TextInput
                        value={userCode}
                        setValue={setUserCode}
                        placeholder={txt({
                            en: 'User code',
                            ja: 'ユーザーコード',
                        })}
                        messages={[
                            {
                                message: txt({
                                    en: "It's your own code!",
                                    ja: 'それは自分のコードです！',
                                }),
                                isVisible: userCode === userClient.uniqueCode,
                                isKeepSpace: false,
                                variant: 'error',
                            },
                            {
                                message: txt({
                                    en: 'User not found',
                                    ja: 'ユーザーが見つかりません',
                                }),
                                isVisible: isMissing,
                                isKeepSpace: false,
                                variant: 'error',
                            },
                            {
                                message: excludingUserErrorMessage,
                                isVisible: !!excludingUserErrorMessage,
                                isKeepSpace: false,
                                variant: 'error',
                            },
                        ]}
                    />
                }
                button={
                    <MutationButton
                        disabled={!userCode || userCode === userClient.uniqueCode || !!excludingUserErrorMessage}
                        className={c.button}
                        role={userCode ? 'submit' : undefined}
                        mutation={{
                            run: runFetchUser,
                            description: txt({
                                en: 'Looking for a user',
                                ja: 'ユーザーを探しています',
                            }),
                        }}
                    >
                        {buttonText}
                    </MutationButton>
                }
            />
        </div>
    );
};
FindUserInput.displayName = 'FindUserInput';
