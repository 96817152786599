import { FC, useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import MuiCollapse from '@mui/material/Collapse';

import { SpaceApi, SpaceMembershipApi, RdbUser } from '@/utils/PowerchatClient';
import { useSpacesCtxAbsolutely, useSpaceCtx, useUserCtxAbsolutely, useHotMembersCtx } from '@/utils/ctxs';
import { useLanguage, useIsConnecting, HotUsers } from '@/utils/customHooks';
import { FetchingText } from '@/components/0_atom/FetchingText';
import { SpaceIcon } from '@/components/2_org/SpaceIcon';
import { UserIcon } from '@/components/2_org/UserIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 6,
        borderRadius: '12px 0 0 12px',
        display: 'flex',
        alignItems: 'flex-start',
        '&:hover': {
            background: 'rgba(0,0,0,.18)',
        },
        '&.Mui-disabled': {
            opacity: 1,
        },
        transitionProperty: 'border-radius',
        transitionDuration: '.6s',
    },
    current: {
        background: 'rgba(0,0,0,.18)',
    },
    spaceIcon: {
        // marginRight: 4,
    },
    collapseContent: {
        whiteSpace: 'nowrap',
    },
    spaceName: {
        lineHeight: '1',
        marginLeft: 5,
        paddingTop: 4,
        paddingLeft: 4,
    },
    members: {
        transitionProperty: 'border-radius, bottom',
        transitionDuration: '.4s',
        padding: 1,
        position: 'absolute',
        right: 1,
        display: 'inline-flex',
    },
    membersCollapsed: {
        borderRadius: 5,
        bottom: 6,
    },
    membersExpanded: {
        borderRadius: 10,
        bottom: 2,
    },
    membersNotCurrent: {
        background: '#3C3C3E',
    },
    membersCurrent: {
        background: '#313133',
    },
    member: {
        lineHeight: '1',
        transitionProperty: 'width, height, font-size',
        transitionDuration: '.4s',
    },
    memberCollapsed: {
        width: 8,
        height: 8,
        fontSize: 5,
    },
    memberExpanded: {
        width: 18,
        height: 18,
        fontSize: 10,
    },
    connecting: {
        fontSize: 9,
        color: theme.palette.text.disabled,
        position: 'absolute',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        height: 36,
        left: 57,
        lineHeight: '1',
    },
}));

export const SpaceItem: FC<{
    className?: string;
    spaceApi: SpaceApi;
    spaceMembershipApi: SpaceMembershipApi;
    isExpanded: boolean;
    startCollapse: () => void;
    setIsExpanded: (isExpanded: boolean) => void;
    hotUserIds: string[] | undefined;
    getHotUser: (userId: string) => RdbUser | undefined;
}> = ({
    className,
    spaceApi,
    spaceMembershipApi,
    isExpanded,
    startCollapse,
    setIsExpanded,
    hotUserIds,
    getHotUser,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // CTX
    const { userClient } = useUserCtxAbsolutely();
    const { current } = useSpaceCtx();
    const { setSpaceId } = useSpacesCtxAbsolutely();

    // HOOKS
    const { txt } = useLanguage();
    const { isConnecting } = useIsConnecting({ spaceId: spaceApi.id });
    const [isAwaitingConnected, setIsAwaitingConnected] = useState(false);

    useEffect(() => {
        if (isAwaitingConnected && !isConnecting) {
            setIsAwaitingConnected(false);
            startCollapse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnecting, isAwaitingConnected]);

    return useMemo(
        () => (
            <MuiListItem
                className={clsx(c.root, className, {
                    [c.current]: current?.id === spaceApi.id,
                })}
                onClick={() => {
                    setIsExpanded(false);
                    if (!current) {
                        setIsAwaitingConnected(true);
                    }
                    setSpaceId(spaceApi.id);
                }}
                button
                disableGutters
                disabled={current?.id === spaceApi.id}
            >
                <SpaceIcon
                    className={c.spaceIcon}
                    spaceName={spaceApi.name}
                    spaceId={spaceApi.id}
                    isHot={!!hotUserIds && hotUserIds.length > 1}
                />
                <MuiCollapse in={isExpanded} orientation={'horizontal'}>
                    <div className={c.collapseContent}>
                        <div className={c.spaceName}>{spaceApi.name}</div>
                    </div>
                </MuiCollapse>
                {hotUserIds && hotUserIds.length > 0 && (
                    <div
                        className={clsx(c.members, {
                            [c.membersCollapsed]: !isExpanded,
                            [c.membersExpanded]: isExpanded,
                            [c.membersCurrent]: current?.id === spaceApi.id,
                            [c.membersNotCurrent]: current?.id !== spaceApi.id,
                        })}
                    >
                        {hotUserIds.map((hotUserId) => {
                            if (isConnecting && hotUserId === userClient.id) {
                                return <></>;
                            }
                            const member = getHotUser(hotUserId);
                            if (member) {
                                return (
                                    <UserIcon
                                        key={member.id}
                                        className={clsx(c.member, {
                                            [c.memberCollapsed]: !isExpanded,
                                            [c.memberExpanded]: isExpanded,
                                        })}
                                        userName={member.uniqueName}
                                    />
                                );
                            }
                            return <></>;
                        })}
                    </div>
                )}
                {isConnecting && (
                    <FetchingText
                        text={txt({
                            en: 'Connecting',
                            ja: '接続中',
                        })}
                        size={'minimum'}
                        className={c.connecting}
                    />
                )}
            </MuiListItem>
        ),
        [
            c,
            className,
            current,
            getHotUser,
            hotUserIds,
            isConnecting,
            isExpanded,
            setSpaceId,
            spaceApi.id,
            spaceApi.name,
            txt,
            userClient,
            setIsExpanded,
        ]
    );
};
SpaceItem.displayName = 'SpacesTemplate/SpaceItem';
