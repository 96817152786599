import { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTypography from '@material-ui/core/Typography';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiListItemText from '@material-ui/core/ListItemText';

import SvgPlus from '@/svgs/fa-plus-solid';

import { SpaceApi, SpaceMembershipApi, SpacePermissionType } from '@/utils/PowerchatClient';
import { useSpaceCtx, useSpacesCtx, useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useItems } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { PagingView } from '@/components/0_atom/PagingView';
import { ConfirmationModal } from '@/components/1_mol/ConfirmationModal';
import { AddSpaceModalStep1 } from '@/components/3_template/ConsoleTemplate/AddSpaceModal/Step1';
import { AddSpaceModalStep2 } from '@/components/3_template/ConsoleTemplate/AddSpaceModal/Step2';

const useStyles = makeStyles((theme) => ({
    root: {
        '& svg': {
            width: 14,
            height: 14,
        },
    },
    current: {},
    paper: {
        background: '#2a2a2b',
    },
}));

export const AddSpaceModal: FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { txt } = useLanguage();
    const spacesCtx = useSpacesCtx();
    const [currentIndex, setCurrentIndex] = useState<'0' | '1'>('0');
    const [spaceName, setSpaceName] = useState<string | undefined>(undefined);
    const {
        ids: userIds,
        getItemAbsolutely: getMemberAbsolutely,
        addItem: addMember,
        updateItem: updateMember,
        removeItem: removeMember,
        initialize: initializeMembers,
    } = useItems<{
        id: string;
        userUniqueName: string;
        userUniqueCode: string;
        membershipUniqueName: string | undefined;
        membershipDisplayName: string | undefined;
        permission: SpacePermissionType;
    }>([]);

    // DATA
    const addSpace = spacesCtx?.addSpace;

    // CALLBACK
    const runCreateSpace = useCallback(async () => {
        if (spaceName && addSpace) {
            const { spaceApi, ownerSpaceMembershipApi } = await userClient.createSpace({
                spaceName,
                members: userIds.map((userId) => getMemberAbsolutely(userId)),
                ownerUniqueName: userClient.uniqueName,
                ownerDisplayName: undefined,
            });
            addSpace({ spaceApi, spaceMembershipApi: ownerSpaceMembershipApi });
            onClose();
            return 'success' as const;
        }
        throw new Error('never');
    }, [spaceName, userClient, addSpace, userIds, getMemberAbsolutely, onClose]);

    // DATA
    // const currentIndex = spaceName ? 1 : 0;

    // USEEFFECT
    useEffect(() => {
        return () => {
            setCurrentIndex('0');
            setSpaceName(undefined);
            initializeMembers([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            title={txt({
                en: 'New space',
                ja: '新しいスペース',
            })}
            mutation={{
                run: runCreateSpace,
                description: txt({
                    en: 'Create new space',
                    ja: '新しいスペースを作成',
                }),
            }}
            submitText={txt({
                en: 'Create',
                ja: '作成',
            })}
            isHideSubmits={currentIndex === '0'}
        >
            {!addSpace ? (
                <LoadingIndicator />
            ) : (
                <PagingView
                    contents={{
                        '0': {
                            title: undefined,
                            children: (
                                <AddSpaceModalStep1
                                    spaceName={spaceName}
                                    setSpaceName={setSpaceName}
                                    handleNext={() => setCurrentIndex('1')}
                                />
                            ),
                            isShowTitle: false,
                            level: 0,
                            prevIndex: undefined,
                        },
                        '1': {
                            title: txt({
                                en: 'Add members',
                                ja: 'メンバーを追加',
                            }),
                            children: (
                                <AddSpaceModalStep2
                                    userIds={userIds}
                                    getMemberAbsolutely={getMemberAbsolutely}
                                    addMember={addMember}
                                    updateMember={updateMember}
                                    removeMember={removeMember}
                                />
                            ),
                            isShowTitle: true,
                            level: 1,
                            prevIndex: '0',
                        },
                    }}
                    currentIndex={currentIndex}
                    setCurrentIndex={(index) => setCurrentIndex(index)}
                    height={420}
                    drawerPaperClassName={c.paper}
                />
            )}
        </ConfirmationModal>
    );
};
AddSpaceModal.displayName = 'SpacesTemplate/AddSpaceModal';
