import { FC, ReactNode } from 'react';

import { RdbUser } from '@/utils/PowerchatClient';
import {
    ChatboardsCtx,
    SpaceMembersCtx,
    LastCheckinCtx,
    LastMessageCtx,
    LastReactionCtx,
    LastReplyObserverCtx,
    LastUnconfirmedMarkCtx,
    LastChatboardMembershipCtx,
    HotMembersCtx,
} from '@/utils/ctxs';
import { useSpace, HotUsers } from '@/utils/customHooks';

export const SpaceProvider: FC<{
    children: ReactNode;
    hotUserIds: string[] | undefined;
    hotUsers: HotUsers | undefined;
    getHotRdbUser: (userId: string) => RdbUser | undefined;
}> = ({ children, hotUserIds, hotUsers, getHotRdbUser }) => {
    // HOOKS
    const {
        chatboardIds,
        getChatboardWithMembershipAbsolutely,
        lastChatboardMembershipLog,
        lastMessageLog,
        lastReactionLog,
        lastReplyObserverLog,
        lastUnconfirmedMarkLog,
        lastCheckinLog,
        userIds,
        getSpaceMember,
        getSpaceMemberAbsolutely,
    } = useSpace();

    return (
        <HotMembersCtx.Provider value={{ hotUserIds, hotUsers, getHotRdbUser }}>
            <SpaceMembersCtx.Provider value={{ userIds, getSpaceMember, getSpaceMemberAbsolutely }}>
                <LastCheckinCtx.Provider value={{ lastCheckinLog }}>
                    <LastMessageCtx.Provider value={{ lastMessageLog }}>
                        <LastReactionCtx.Provider value={{ lastReactionLog }}>
                            <LastReplyObserverCtx.Provider value={{ lastReplyObserverLog }}>
                                <LastUnconfirmedMarkCtx.Provider value={{ lastUnconfirmedMarkLog }}>
                                    <LastChatboardMembershipCtx.Provider value={{ lastChatboardMembershipLog }}>
                                        <ChatboardsCtx.Provider
                                            value={{ chatboardIds, getChatboardWithMembershipAbsolutely }}
                                        >
                                            {children}
                                        </ChatboardsCtx.Provider>
                                    </LastChatboardMembershipCtx.Provider>
                                </LastUnconfirmedMarkCtx.Provider>
                            </LastReplyObserverCtx.Provider>
                        </LastReactionCtx.Provider>
                    </LastMessageCtx.Provider>
                </LastCheckinCtx.Provider>
            </SpaceMembersCtx.Provider>
        </HotMembersCtx.Provider>
    );
};
SpaceProvider.displayName = 'SpaceProvider';
