import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { Thread } from '@/utils/PowerchatClient';
import { ChatBoxState } from '@/components/0_atom/ChatBox';
import {
    ThreadMasterMessageCard,
    ThreadReplyMessageCard,
} from '@/components/3_template/ConsoleTemplate/SpaceTemplate/ChatboardTemplate/MessageCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {},
}));

export const ThreadViewContent: FC<
    ChatBoxState & {
        className?: string;
        thread: Thread;
    }
> = ({
    unreadCount,
    increaseUnreadCount,
    onAddDownstream,
    onAddUpstream,
    isSeeingOldest,
    isSeeingRecent,
    className,
    thread,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const { master, replies } = thread;

    return (
        <div className={c.root}>
            <ThreadMasterMessageCard
                messageApi={master.messageApi}
                replyObserverApis={master.replyObserverApis}
                unconfirmedMarkApi={master.unconfirmedMarkApi}
                reactionApis={master.reactionApis}
            />
            {replies.map((reply, i) => {
                return (
                    <ThreadReplyMessageCard
                        key={`${reply.messageApi.id}-reply`}
                        messageApi={reply.messageApi}
                        unconfirmedMarkApi={reply.unconfirmedMarkApi}
                        reactionApis={reply.reactionApis}
                        isOmitUser={[master, ...replies][i]?.messageApi.userId === reply.messageApi.userId}
                    />
                );
            })}
        </div>
    );
};
ThreadViewContent.displayName = 'ThreadViewContent';
