import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import { ReactionApi } from '@/utils/PowerchatClient';
import { Popover } from '@/components/0_atom/Popover';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 200,
        display: 'inline-block',
        fontSize: 15,
    },
    button: {
        padding: 6,
    },
    selected: {},
}));

const Emojis = ['😇', '🤓', '👍', '👏', '😭', '✔️'];

export const SelectReactionMenu: FC<{
    className?: string;
    anchorEl: Element;
    isOpen: boolean;
    onClose: () => void;
    onSelected: (input: { letter: string; userReactionApi: ReactionApi | undefined }) => void;
    userReactionApis: ReactionApi[];
}> = ({ className, anchorEl, isOpen, onClose, onSelected, userReactionApis }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Popover
            anchorEl={anchorEl}
            isOpen={isOpen}
            onClose={onClose}
            className={clsx(c.root, className)}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <div>
                {Emojis.map((letter) => {
                    const userReactionApi = userReactionApis.find((reactionApi) => reactionApi.letter === letter);
                    return (
                        <MuiIconButton
                            key={letter}
                            className={clsx(c.button, {
                                [c.selected]: !!userReactionApi,
                            })}
                            onClick={() => {
                                onSelected({ letter, userReactionApi });
                                onClose();
                            }}
                        >
                            {letter}
                        </MuiIconButton>
                    );
                })}
            </div>
        </Popover>
    );
};
SelectReactionMenu.displayName = 'SelectReactionMenu';
