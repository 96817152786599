export const unNull = <T>(input: T | null) => {
    if (input === null) {
        return undefined;
    }
    return input;
};

export const unUndefined = <T>(input: T | undefined) => {
    if (input === undefined) {
        return null;
    }
    return input;
};
