import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { modifyRdbSpaceMembership, removeRdbSpaceMembership } from '@/utils/PowerchatClient/RealtimeDatabase';
import {
    SpaceMembership,
    SpaceMembershipType,
} from '@/utils/PowerchatClient/models/SpaceMembership/data/SpaceMembership';
import { User } from '@/utils/PowerchatClient/models/User';
import { SpacePermissionType } from '@/utils/PowerchatClient/models/_interfaces';

type SpaceMembershipApiType = {
    // UTILS
    getDisplayName: () => string | undefined;

    // UPDATE SELF
    updatePermission: (input: { permission: SpacePermissionType }) => Promise<void>;
    updateDisplayName: (input: { displayName: string | undefined }) => Promise<void>;
    remove: () => Promise<void>;
};

type ConstructorInput = SpaceMembershipType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    clientUser: User;
    clientUniqueName: string;
    clientDisplayName: string | undefined;
    spaceName: string;
    memberUserDisplayName: string | undefined;
};

export class SpaceMembershipApi extends SpaceMembership implements SpaceMembershipApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    protected _clientUser: User;

    protected _clientUniqueName: string;

    protected _clientDisplayName: string | undefined;

    protected _spaceName: string;

    protected _memberUserDisplayName: string | undefined;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
        this._clientUser = input.clientUser;
        this._clientUniqueName = input.clientUniqueName;
        this._clientDisplayName = input.clientDisplayName;
        this._spaceName = input.spaceName;
        this._memberUserDisplayName = input.memberUserDisplayName;
    }

    // UTILS
    getDisplayName() {
        return this.displayName || this._memberUserDisplayName;
    }

    // UPDATE SELF
    async updateDisplayName({ displayName }: { displayName: string | undefined }) {
        this._updateDisplayName({
            displayName,
            updatedAt: new Date(),
        });
        modifyRdbSpaceMembership({
            spaceId: this.spaceId,
            spaceMembership: {
                ...this,
                displayName: this.getDisplayName(),
                modifyNotification: {
                    spaceName: this._spaceName,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    toUserDisplayName: this._memberUserDisplayName,
                },
            },
        });
        await this._graphqlSdk.modifySpaceMembershipDisplayName({
            input: {
                spaceId: this.spaceId,
                spaceMembershipId: this.id,
                displayName,
            },
        });
    }

    async updatePermission({ permission }: { permission: SpacePermissionType }) {
        this._updatePermission({ permission, updatedAt: new Date() });
        modifyRdbSpaceMembership({
            spaceId: this.spaceId,
            spaceMembership: {
                ...this,
                displayName: this.getDisplayName(),
                modifyNotification: {
                    spaceName: this._spaceName,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    toUserDisplayName: this._memberUserDisplayName,
                },
            },
        });
        await this._graphqlSdk.modifySpaceMembershipPermission({
            input: {
                spaceId: this.spaceId,
                spaceMembershipId: this.id,
                permission,
            },
        });
    }

    async remove() {
        removeRdbSpaceMembership({
            spaceId: this.spaceId,
            spaceMembership: {
                ...this,
                displayName: this.getDisplayName(),
                removeNotification: {
                    spaceName: this._spaceName,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    toUserDisplayName: this._memberUserDisplayName,
                },
            },
        });
        await this._graphqlSdk.removeSpaceMembership({
            input: {
                spaceId: this.spaceId,
                spaceMembershipId: this.id,
            },
        });
    }
}
