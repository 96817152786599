import { ref, get } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { RdbUser } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const getRdbUser = async ({ userId }: { userId: string }): Promise<RdbUser | undefined> => {
    const db = getFirebaseRealtimeDatabase();
    const data = await get(ref(db, `users/${userId}`));
    if (!data) {
        return undefined;
    }
    if (!data.key) {
        throw new Error('getRdbUser: unexectedly !data.key.');
    }
    const value: Omit<RdbUser, 'id'> = data.val();
    return {
        id: data.key,
        ...value,
    };
};
