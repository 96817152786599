import { createContext, useContext } from 'react';

export type WindowsBoxCtxType = {
    elm: HTMLDivElement | undefined;
    rootZIndex: number;
    highestPriority: number;
    setHighestPriority: (highestPriority: number) => void;
    addWindow: () => void;
    removeWindow: () => void;
};

export const WindowsBoxCtx = createContext<WindowsBoxCtxType>({
    elm: undefined,
    rootZIndex: 0,
    highestPriority: 0,
    setHighestPriority: () => {
        throw new Error('WindowsBoxCtx.setHighestPriority: not initialized.');
    },
    addWindow: () => {
        throw new Error('WindowsBoxCtx.addWindow: not initialized.');
    },
    removeWindow: () => {
        throw new Error('WindowsBoxCtx.removeWindow: not initialized.');
    },
});
export const useWindowsBoxCtx = () => {
    return useContext(WindowsBoxCtx);
};
export const useWindowsBoxCtxAbsolutely = () => {
    const { elm, ...otherProps } = useWindowsBoxCtx();
    if (!elm) {
        throw new Error('useWindowsBoxCtxAbsolutely: !elm.');
    }
    return {
        elm,
        ...otherProps,
    };
};
