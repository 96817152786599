import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgEllipsis from '@/svgs/ellipsis';

import { generateRgbColorForText } from '@/utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    indicator: {
        width: 4,
        height: 4,
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    hot: {
        color: theme.palette.primary.main,
    },
    cold: {
        color: theme.palette.text.disabled,
    },
}));

export const UserIcon: FC<{
    className?: string;
    onClick?: () => void;
    userName: string;
    isHot?: boolean;
    backgroundOpacity?: number;
    borderOpacity?: number;
    color?: string;
}> = ({ className, onClick, userName, isHot, backgroundOpacity, borderOpacity, color }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const { rgbColor } = generateRgbColorForText({ text: userName });

    return onClick ? (
        <MuiIconButton
            className={clsx(c.root, className)}
            onClick={onClick}
            style={{
                background: rgbColor.getCssValue({
                    opacity: backgroundOpacity === undefined ? 0.1 : backgroundOpacity,
                }),
                color: color || rgbColor.getCssValue({ opacity: 1 }),
                border: borderOpacity ? `solid 1px ${rgbColor.getCssValue({ opacity: borderOpacity })}` : undefined,
            }}
        >
            {userName[0]?.toUpperCase()}
            {isHot !== undefined && (
                <SvgEllipsis
                    className={clsx(c.indicator, {
                        [c.hot]: isHot,
                        [c.cold]: !isHot,
                    })}
                />
            )}
        </MuiIconButton>
    ) : (
        <span
            className={clsx(c.root, className)}
            style={{
                background: rgbColor.getCssValue({
                    opacity: backgroundOpacity === undefined ? 0.1 : backgroundOpacity,
                }),
                color: color || rgbColor.getCssValue({ opacity: 1 }),
                border: borderOpacity ? `solid 1px ${rgbColor.getCssValue({ opacity: borderOpacity })}` : undefined,
            }}
        >
            {userName[0]?.toUpperCase()}
            {isHot !== undefined && (
                <SvgEllipsis
                    className={clsx(c.indicator, {
                        [c.hot]: isHot,
                        [c.cold]: !isHot,
                    })}
                />
            )}
        </span>
    );
};
UserIcon.displayName = 'UserIcon';
