import { useEffect, useState } from 'react';

import { useSpaceCtx, LastReactionLog } from '@/utils/ctxs';

export const useLastReactionLog = () => {
    // HOOKS
    const { current: currentSpaceCtx } = useSpaceCtx();
    const [lastReactionLog, setLastReactionLog] = useState<LastReactionLog | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        if (currentSpaceCtx) {
            const { spaceApi } = currentSpaceCtx;
            const { unsubscribeListenToReactions } = spaceApi.listenToReactions({
                onAdded: ({ newReactionApi }) => {
                    setLastReactionLog({
                        action: 'add',
                        reactionApi: newReactionApi,
                    });
                },
                onRemoved: ({ removedReactionApi }) => {
                    setLastReactionLog({
                        action: 'remove',
                        reactionApi: removedReactionApi,
                    });
                },
            });
            return unsubscribeListenToReactions;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSpaceCtx?.spaceApi.id]);

    return {
        lastReactionLog,
    };
};
