import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { ChatboardMembership } from '@/utils/PowerchatClient/models';
import {
    getRdbLastChatboardMembershipInput,
    AddRdbLastChatboardMembershipInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbChatboardMembership = ({
    spaceId,
    newChatboardMembership,
}: {
    spaceId: string;
    newChatboardMembership: AddRdbLastChatboardMembershipInput;
}) => {
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastChatboardMembershipRef = ref(realtimeDb, `lastChatboardMemberships/${spaceId}`);
    set(lastChatboardMembershipRef, {
        action: 'add',
        ...getRdbLastChatboardMembershipInput({
            ...newChatboardMembership,
            createdAt: 'serverTimestamp',
            updatedAt: undefined,
            removedAt: undefined,
            modifyNotification: undefined,
            removeNotification: undefined,
        }),
    });
    return {
        newChatboardMembership: new ChatboardMembership({
            id: newChatboardMembership.id,
            createdAt: new Date(),
            updatedAt: undefined,
            userId: newChatboardMembership.userId,
            spaceId,
            chatboardId: newChatboardMembership.chatboardId,
            permission: newChatboardMembership.permission,
        }),
    };
};
