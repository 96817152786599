import { ref, set } from 'firebase/database';
import { collection, doc } from 'firebase/firestore';
import { getFirebaseRealtimeDatabase, getFirebaseFirestore } from '@/utils/GoogleCloud/firebase';
import { UnconfirmedMark } from '@/utils/PowerchatClient/models';
import {
    getRdbLastUnconfirmedMarkInput,
    AddRdbLastUnconfirmedMarkInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const postRdbUnconfirmedMark = ({
    spaceId,
    newUnconfirmedMark,
}: {
    spaceId: string;
    newUnconfirmedMark: AddRdbLastUnconfirmedMarkInput;
}) => {
    const firestoreDb = getFirebaseFirestore();
    const { id } = doc(collection(firestoreDb, `Space/${spaceId}/UnconfirmedMark`));
    const realtimeDb = getFirebaseRealtimeDatabase();
    const lastUnconfirmedMarkRef = ref(realtimeDb, `lastUnconfirmedMarks/${spaceId}`);
    set(lastUnconfirmedMarkRef, {
        action: 'add',
        ...getRdbLastUnconfirmedMarkInput({
            ...newUnconfirmedMark,
            id,
            createdAt: 'serverTimestamp',
            removedAt: undefined,
        }),
    });
    return {
        newUnconfirmedMark: new UnconfirmedMark({
            id,
            createdAt: new Date(),
            spaceId,
            chatboardId: newUnconfirmedMark.chatboardId,
            messageId: newUnconfirmedMark.messageId,
            fromUserId: newUnconfirmedMark.fromUserId,
            toUserId: newUnconfirmedMark.toUserId,
        }),
    };
};
