import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { isoToDateOptionally } from '@/utils/PowerchatClient/graphql/customScalars';
import { User, UserType } from '@/utils/PowerchatClient/models/User/data/User';
import { modifyRdbUser, RdbUser } from '@/utils/PowerchatClient/RealtimeDatabase';

type UserApiType = {
    // UPDATE SELF
    updateDisplayName: (input: { displayName: string | undefined; currentRdbUser: RdbUser }) => Promise<void>;
    cancel: () => Promise<void>;
};

type ConstructorInput = UserType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
};

export class UserApi extends User implements UserApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
    }

    // UPDATE SELF
    async updateDisplayName({
        displayName,
        currentRdbUser,
    }: {
        displayName: string | undefined;
        currentRdbUser: RdbUser;
    }) {
        const {
            modifyUserDisplayName: { user },
        } = await this._graphqlSdk.modifyUserDisplayName({
            input: {
                displayName,
            },
        });
        this._updateDisplayName({
            displayName: user.displayName || undefined,
            updatedAt: isoToDateOptionally(user.updatedAt),
        });
        modifyRdbUser({
            ...currentRdbUser,
            userId: this.id,
            currentFcmToken: this._currentFcmToken,
            displayName,
        });
    }

    async cancel() {
        const {
            cancelUser: { canceledUser },
        } = await this._graphqlSdk.cancelUser();
        this._updateCanceledAt({
            canceledAt: isoToDateOptionally(canceledUser.canceledAt),
            updatedAt: isoToDateOptionally(canceledUser.updatedAt),
        });
    }
}
