import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    RemoveRdbLastSpaceMembershipInput,
    getRdbLastSpaceMembershipInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const removeRdbSpaceMembership = ({
    spaceId,
    spaceMembership,
}: {
    spaceId: string;
    spaceMembership: RemoveRdbLastSpaceMembershipInput;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastSpaceMembershipRef = ref(db, `lastSpaceMemberships/${spaceId}`);
    set(lastSpaceMembershipRef, {
        action: 'remove',
        ...getRdbLastSpaceMembershipInput({
            ...spaceMembership,
            removedAt: 'serverTimestamp',
            addNotification: undefined,
            modifyNotification: undefined,
        }),
    });
};
