import { GraphQLSdk } from '@/utils/PowerchatClient/graphql/graphqlSdk';
import { modifyRdbChatboardMembership, removeRdbChatboardMembership } from '@/utils/PowerchatClient/RealtimeDatabase';
import { User } from '@/utils/PowerchatClient/models/User';
import { ChatboardPermissionType, SpacePermission } from '@/utils/PowerchatClient/models/_interfaces';
import {
    ChatboardMembership,
    ChatboardMembershipType,
} from '@/utils/PowerchatClient/models/ChatboardMembership/data/ChatboardMembership';

type ChatboardMembershipApiType = {
    // UPDATE SELF
    updatePermission: (input: { permission: ChatboardPermissionType }) => Promise<void>;
    remove: () => Promise<void>;
};

type ConstructorInput = ChatboardMembershipType & {
    graphqlSdk: GraphQLSdk;
    currentFcmToken: string;
    clientUser: User;
    clientUniqueName: string;
    clientDisplayName: string | undefined;
    spaceName: string;
    spaceMembershipPermission: SpacePermission;
    spaceMembershipUniqueName: string;
    spaceMembershipDisplayName: string | undefined;
    chatboardUniqueName: string;
};

export class ChatboardMembershipApi extends ChatboardMembership implements ChatboardMembershipApiType {
    protected _graphqlSdk: GraphQLSdk;

    protected _currentFcmToken: string;

    protected _clientUser: User;

    protected _clientUniqueName: string;

    protected _clientDisplayName: string | undefined;

    protected _spaceName: string;

    protected _spaceMembershipPermission: SpacePermission;

    protected _spaceMembershipUniqueName: string;

    protected _spaceMembershipDisplayName: string | undefined;

    protected _chatboardUniqueName: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._currentFcmToken = input.currentFcmToken;
        this._clientUser = input.clientUser;
        this._clientUniqueName = input.clientUniqueName;
        this._clientDisplayName = input.clientDisplayName;
        this._spaceName = input.spaceName;
        this._spaceMembershipPermission = input.spaceMembershipPermission;
        this._spaceMembershipUniqueName = input.spaceMembershipUniqueName;
        this._spaceMembershipDisplayName = input.spaceMembershipDisplayName;
        this._chatboardUniqueName = input.chatboardUniqueName;
    }

    // UPDATE SELF
    async updatePermission({ permission }: { permission: ChatboardPermissionType }) {
        this._updatePermission({ permission, updatedAt: new Date() });
        modifyRdbChatboardMembership({
            spaceId: this.spaceId,
            chatboardMembership: {
                ...this,
                modifyNotification: {
                    spaceName: this._spaceName,
                    chatboardUniqueName: this._chatboardUniqueName,
                    toUserUniqueName: this._spaceMembershipUniqueName,
                    toUserDisplayName: this._spaceMembershipDisplayName,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spacePermission: this._spaceMembershipPermission,
                },
            },
        });
        await this._graphqlSdk.modifyChatboardMembershipPermission({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.chatboardId,
                chatboardMembershipId: this.id,
                permission,
            },
        });
    }

    async remove() {
        removeRdbChatboardMembership({
            spaceId: this.spaceId,
            chatboardMembership: {
                ...this,
                removeNotification: {
                    spaceName: this._spaceName,
                    chatboardUniqueName: this._chatboardUniqueName,
                    toUserUniqueName: this._spaceMembershipUniqueName,
                    toUserDisplayName: this._spaceMembershipDisplayName,
                    fromUserUniqueName: this._clientUniqueName,
                    fromUserDisplayName: this._clientDisplayName,
                    spacePermission: this._spaceMembershipPermission,
                },
            },
        });
        await this._graphqlSdk.removeChatboardMembership({
            input: {
                spaceId: this.spaceId,
                chatboardId: this.chatboardId,
                chatboardMembershipId: this.id,
            },
        });
    }
}
