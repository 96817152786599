import { useEffect, useState } from 'react';

import { useSpaceCtx, useUserCtxAbsolutely, LastChatboardMembershipLog } from '@/utils/ctxs';
import { useChatboards } from '@/utils/customHooks';

export const useChatboardsAndLastChatboardMembershipLog = () => {
    // HOOKS
    const { userClient } = useUserCtxAbsolutely();
    const { current: currentSpaceCtx } = useSpaceCtx();
    const [lastChatboardMembershipLog, setLastChatboardMembershipLog] = useState<
        LastChatboardMembershipLog | undefined
    >(undefined);
    const {
        chatboardIds,
        getChatboardWithMembershipAbsolutely,
        addChatboard,
        updateChatboardApi,
        updateChatboardMembershipApi,
        removeChatboard,
    } = useChatboards({ currentSpace: currentSpaceCtx });
    console.log({ currentSpaceCtx });
    // USEEFFECT
    // useEffect(() => {
    //     if(currentChatboard&& currentChatboard.chatboardApi.spaceId!==currentSpaceCtx?.id){
    //         setChatboardId(un)
    //     }
    // },[currentSpaceCtx?.id])
    useEffect(() => {
        if (chatboardIds && currentSpaceCtx) {
            const { spaceApi } = currentSpaceCtx;
            const { unsubscribeListenToChatboards } = spaceApi.listenToChatboards({
                onAdded: ({ newChatboardApi, initialChatboardMemberships }) => {
                    const clientChatboardMembershipApi = initialChatboardMemberships.find(
                        ({ userId }) => userId === userClient.id
                    );
                    if (clientChatboardMembershipApi) {
                        addChatboard({
                            chatboardApi: newChatboardApi,
                            chatboardMembershipApi: clientChatboardMembershipApi,
                        });
                    }
                },
                onModified: ({ modifiedChatboardApi }) => {
                    if (modifiedChatboardApi.closedAt) {
                        removeChatboard(modifiedChatboardApi.id);
                    } else {
                        updateChatboardApi(modifiedChatboardApi);
                    }
                },
            });
            return unsubscribeListenToChatboards;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatboardIds, currentSpaceCtx?.spaceApi.id]);

    useEffect(() => {
        if (currentSpaceCtx) {
            const { spaceApi } = currentSpaceCtx;
            const { unsubscribeListenToChatboardMemberships } = spaceApi.listenToChatboardMemberships({
                onAdded: ({ newChatboardMembershipApi }) => {
                    setLastChatboardMembershipLog({
                        action: 'add',
                        chatboardMembershipApi: newChatboardMembershipApi,
                    });
                },
                onModified: ({ modifiedChatboardMembershipApi }) => {
                    setLastChatboardMembershipLog({
                        action: 'modify',
                        chatboardMembershipApi: modifiedChatboardMembershipApi,
                    });
                    updateChatboardMembershipApi(modifiedChatboardMembershipApi);
                },
                onRemoved: ({ removedChatboardMembershipApi }) => {
                    setLastChatboardMembershipLog({
                        action: 'remove',
                        chatboardMembershipApi: removedChatboardMembershipApi,
                    });
                    if (removedChatboardMembershipApi.userId === userClient.id) {
                        removeChatboard(removedChatboardMembershipApi.chatboardId);
                    }
                },
            });
            return unsubscribeListenToChatboardMemberships;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSpaceCtx?.spaceApi.id]);

    return {
        chatboardIds,
        getChatboardWithMembershipAbsolutely,
        lastChatboardMembershipLog,
    };
};
