import { ref, onValue } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import { Reaction } from '@/utils/PowerchatClient';
import { RdbLastReaction, RdbLastReactionAddNotification } from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const listenToReactions = ({
    spaceId,
    onAdded,
    onRemoved,
}: {
    spaceId: string;
    onAdded: (input: { newReaction: Reaction; addNotification: RdbLastReactionAddNotification }) => void;
    onRemoved: (input: { removedReaction: Reaction }) => void;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastReactionRef = ref(db, `lastReactions/${spaceId}`);
    const firedAt = new Date();
    const unsubscribeListenToReactions = onValue(lastReactionRef, (data) => {
        if (data.exists()) {
            const { action, removedAt, addNotification, ...reactionData }: RdbLastReaction = data.val();
            const reaction = new Reaction({
                id: reactionData.id,
                createdAt: new Date(reactionData.createdAt),
                userId: reactionData.userId,
                spaceId,
                chatboardId: reactionData.chatboardId,
                messageId: reactionData.messageId,
                letter: reactionData.letter,
            });
            if (firedAt <= reaction.createdAt && action === 'add' && addNotification) {
                onAdded({
                    addNotification,
                    newReaction: reaction,
                });
            } else if (removedAt && firedAt <= new Date(removedAt) && action === 'remove') {
                onRemoved({
                    removedReaction: reaction,
                });
            }
        }
    });
    return {
        unsubscribeListenToReactions,
    };
};
