import { useEffect, useState } from 'react';

import { useSpaceCtx, LastReplyObserverLog } from '@/utils/ctxs';

export const useLastReplyObserverLog = () => {
    // HOOKS
    const { current: currentSpaceCtx } = useSpaceCtx();
    const [lastReplyObserverLog, setLastReplyObserverLog] = useState<LastReplyObserverLog | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        if (currentSpaceCtx) {
            const { spaceApi } = currentSpaceCtx;
            const { unsubscribeListenToReplyObservers } = spaceApi.listenToReplyObservers({
                onAdded: ({ newReplyObserverApi }) => {
                    setLastReplyObserverLog({
                        action: 'add',
                        replyObserverApi: newReplyObserverApi,
                    });
                },
                onRemoved: ({ removedReplyObserverApi }) => {
                    setLastReplyObserverLog({
                        action: 'remove',
                        replyObserverApi: removedReplyObserverApi,
                    });
                },
            });
            return unsubscribeListenToReplyObservers;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSpaceCtx?.spaceApi.id]);

    return {
        lastReplyObserverLog,
    };
};
