import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.50029 0C2.45184 0 0.000576124 2.0744 0.000576124 4.63355C0.000576124 5.69436 0.429817 6.6663 1.14127 7.44709C0.820682 8.32702 0.152352 9.07062 0.141572 9.07886C-0.00123483 9.2348 -0.0389416 9.46313 0.0418833 9.65805C0.106604 9.8708 0.291192 10 0.49859 10C1.82426 10 2.85068 9.42638 3.49745 8.9697C4.11986 9.17019 4.79444 9.28715 5.50029 9.28715C8.54874 9.28715 11 7.21252 11 4.67365C11 2.13477 8.54874 0 5.50029 0ZM5.50029 8.21787C4.92358 8.21787 4.35507 8.12597 3.81092 7.94787L3.32045 7.78915L2.90027 8.09534C2.59305 8.32078 2.17006 8.57162 1.66084 8.74137C1.81981 8.47137 1.97064 8.16774 2.08922 7.84473L2.31817 7.22098L1.87363 6.73379C1.48621 6.3043 1.0173 5.59367 1.0173 4.6536C1.0173 2.68768 3.02747 1.08933 5.48218 1.08933C7.93689 1.08933 9.94706 2.68768 9.94706 4.6536C9.94706 6.61951 7.97527 8.21787 5.50029 8.21787Z"
                fill="currentColor"
            />
            <path
                d="M2.60864 4.03208C2.60864 3.7122 2.8596 3.45288 3.16918 3.45288H7.80437C8.11395 3.45288 8.36491 3.7122 8.36491 4.03208C8.36491 4.35196 8.11395 4.61127 7.80437 4.61127H3.16918C2.8596 4.61127 2.60864 4.35196 2.60864 4.03208Z"
                fill="currentColor"
            />
            <path
                d="M2.60864 5.94787C2.60864 5.62799 2.8596 5.36868 3.16918 5.36868H5.8425C6.15208 5.36868 6.40304 5.62799 6.40304 5.94787C6.40304 6.26775 6.15208 6.52707 5.8425 6.52707H3.16918C2.8596 6.52707 2.60864 6.26775 2.60864 5.94787Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/message';
export default Svg;
