import { useEffect, useState, RefObject } from 'react';

export const useScroll = ({ boxRef }: { boxRef: RefObject<HTMLElement> }) => {
    const [scrollTop, setScrollTop] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);

    const handleScroll = (boxElm: HTMLElement) => {
        setScrollTop(boxElm.scrollTop);
    };

    useEffect(() => {
        const boxElm = boxRef.current;
        if (boxElm) {
            boxElm.removeEventListener('scroll', () => handleScroll(boxElm));
            boxElm.addEventListener('scroll', () => handleScroll(boxElm), {
                passive: true,
            });
            setClientHeight(boxElm.clientHeight);
            handleScroll(boxElm);
        }
        return () => {
            boxElm?.removeEventListener('scroll', () => handleScroll(boxElm));
        };
    }, [boxRef]);

    // DATA
    const scrollHeight = boxRef.current?.scrollHeight || 0;
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);

    return {
        clientHeight,
        scrollHeight,
        scrollTop,
        scrollBottom,
    };
};
