import { useEffect, useState } from 'react';

import { UnconfirmedMarkApi } from '@/utils/PowerchatClient';
import { useLastUnconfirmedMarkCtx } from '@/utils/ctxs';

export const useUnconfirmedMark = ({
    messageId,
    initialUnconfirmedMarkApi,
}: {
    messageId: string;
    initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
}) => {
    // HOOKS
    const { lastUnconfirmedMarkLog } = useLastUnconfirmedMarkCtx();
    const [unconfirmedMarkApi, setUnconfirmedMarkApi] = useState(initialUnconfirmedMarkApi);
    // const { ids, getItemAbsolutely, addItem, removeItem } = useItems<UnconfirmedMarkApi>(initialUnconfirmedMarkApis);

    // USEEFFECT
    useEffect(() => {
        if (lastUnconfirmedMarkLog?.unconfirmedMarkApi.messageId === messageId) {
            if (lastUnconfirmedMarkLog.action === 'add') {
                setUnconfirmedMarkApi(lastUnconfirmedMarkLog.unconfirmedMarkApi);
            } else if (lastUnconfirmedMarkLog.action === 'remove') {
                console.log('remove unconfirmedMark: ', lastUnconfirmedMarkLog.unconfirmedMarkApi.id);
                setUnconfirmedMarkApi(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUnconfirmedMarkLog, messageId]);

    return {
        unconfirmedMarkApi,
    };
};
