import { FC, useRef, MouseEvent } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

import SvgEllipsis from '@/svgs/ellipsis';

import { MessageApi, ReplyObserverApi, UnconfirmedMarkApi } from '@/utils/PowerchatClient';
import { useUserCtxAbsolutely, useSpaceMembersCtx, useHotMembersCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useReplyObservers, useUnconfirmedMark, useLobbyReplyMessages } from '@/utils/customHooks';
import { generateRgbColorForText } from '@/utils/utilFunctions';
import { UserIcon } from '@/components/2_org/UserIcon';
import { UserName } from '@/components/2_org/UserName';
import { MessageBodyViewer } from '@/components/2_org/MessageBodyViewer';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 5,
        display: 'flex',
        alignItems: 'flex-start',
        '&:hover .MessageCard-actions': {
            visibility: 'visible',
        },
        '&:not(:hover) .MessageCard-actions': {
            visibility: 'hidden',
        },
    },
    userIcon: {
        width: 22,
        height: 22,
        flexShrink: 0,
        flexGrow: 0,
        fontSize: 12,
        marginTop: 3,
        marginRight: 3,
    },
    card: {
        flexShrink: 1,
        flexGrow: 0,
        borderRadius: 14,
        padding: '5px 13px',
        lineHeight: '1.5',
        position: 'relative',
    },
    userName: {
        display: 'inline-block',
        float: 'left',
        fontSize: 10,
        lineHeight: '18px',
        marginRight: 4,
    },
    body: {
        fontSize: 12,
        display: 'inline',
    },
    bodyCanceled: {
        textDecoration: 'line-through',
        opacity: 0.5,
    },
    marks: {
        position: 'absolute',
        top: 1,
        left: 0,
    },
    unconfirmedMark: {
        display: 'block',
        width: 6,
        height: 6,
        color: '#fff',
        marginBottom: 3,
    },
    replyOberver: {
        display: 'block',
        width: 6,
        height: 6,
        color: '#FF9901',
    },
}));

export type OpenMessageMenuInput = {
    mouseEvent: MouseEvent<HTMLDivElement, globalThis.MouseEvent>;
    elm: HTMLElement;
    messageApi: MessageApi;
    unconfirmedMarkApi: UnconfirmedMarkApi | undefined;
    replyObserverIds: string[];
    getReplyObserverItemAbsolutely: (id: string) => ReplyObserverApi;
    replyMessageIds: string[];
    getReplyMessageAbsolutely: (id: string) => {
        id: string;
        messageApi: MessageApi;
        initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
        initialReplyObserverApis: ReplyObserverApi[];
    };
};
export type OpenMessageMenu = (input: OpenMessageMenuInput) => void;

export const MessageCard: FC<{
    className?: string;
    messageApi: MessageApi;
    initialReplyObserverApis: ReplyObserverApi[];
    initialUnconfirmedMarkApi: UnconfirmedMarkApi | undefined;
    openMessageMenu?: OpenMessageMenu;
}> = ({ className, messageApi, initialUnconfirmedMarkApi, initialReplyObserverApis, openMessageMenu }) => {
    // STYLE
    const c = useStyles(useTheme());

    // REF
    const ref = useRef<HTMLDivElement>(null);

    // CTX
    const { userClient } = useUserCtxAbsolutely();
    const { getSpaceMember } = useSpaceMembersCtx();
    const { getHotRdbUser } = useHotMembersCtxAbsolutely();

    // HOOKS
    const { unconfirmedMarkApi } = useUnconfirmedMark({
        messageId: messageApi.id,
        initialUnconfirmedMarkApi,
    });
    const { replyObserverIds, getReplyObserverItemAbsolutely } = useReplyObservers({
        messageId: messageApi.id,
        initialReplyObserverApis: initialReplyObserverApis || [],
        fromUserId: userClient.id,
    });
    const { messageIds: replyMessageIds, getMessageItemAbsolutely: getReplyMessageAbsolutely } = useLobbyReplyMessages({
        masterMessageId: messageApi.id,
    });
    const { txt, getDateTimeStr } = useLanguage();

    // DATA
    const member = getSpaceMember(messageApi.userId);
    const uniqueName =
        member?.spaceMembershipApi.uniqueName ||
        txt({
            en: 'Missing member',
            ja: '存在しないメンバー',
        });
    const { rgbColor } = generateRgbColorForText({ text: uniqueName });

    return (
        <div ref={ref} className={clsx(c.root, className)}>
            <UserIcon
                className={c.userIcon}
                userName={uniqueName}
                isHot={(member && getHotRdbUser(member.id)?.currentSpace?.id === messageApi.spaceId) || false}
            />
            <div
                className={c.card}
                style={{
                    background: rgbColor.getCssValue({ opacity: 0.1 }),
                }}
                onContextMenu={(mouseEvent) => {
                    if (openMessageMenu) {
                        if (ref.current) {
                            mouseEvent.preventDefault();
                            openMessageMenu({
                                mouseEvent,
                                elm: ref.current,
                                messageApi,
                                unconfirmedMarkApi,
                                replyObserverIds,
                                getReplyObserverItemAbsolutely,
                                replyMessageIds,
                                getReplyMessageAbsolutely,
                            });
                        }
                    }
                }}
            >
                <UserName userName={uniqueName} className={c.userName} />
                <MessageBodyViewer
                    text={messageApi.body}
                    className={clsx(c.body, {
                        [c.bodyCanceled]: !!messageApi.canceledAt,
                    })}
                />
                {(!!unconfirmedMarkApi || replyObserverIds.length > 0) && (
                    <div className={c.marks}>
                        {!!unconfirmedMarkApi && <SvgEllipsis className={c.unconfirmedMark} />}
                        {replyObserverIds.length > 0 && <SvgEllipsis className={c.replyOberver} />}
                    </div>
                )}
            </div>
        </div>
    );
};
MessageCard.displayName = 'RoomTemplate/MessageCard';
