import { useEffect, useState } from 'react';

import { useSpaceCtx, LastUnconfirmedMarkLog } from '@/utils/ctxs';

export const useLastUnconfirmedMarkLog = () => {
    // HOOKS
    const { current: currentSpaceCtx } = useSpaceCtx();
    const [lastUnconfirmedMarkLog, setLastUnconfirmedMarkLog] = useState<LastUnconfirmedMarkLog | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        if (currentSpaceCtx) {
            const { spaceApi } = currentSpaceCtx;
            const { unsubscribeListenToUnconfirmedMarks } = spaceApi.listenToUnconfirmedMarks({
                onAdded: ({ newUnconfirmedMarkApi }) => {
                    setLastUnconfirmedMarkLog({
                        action: 'add',
                        unconfirmedMarkApi: newUnconfirmedMarkApi,
                    });
                },
                onRemoved: ({ removedUnconfirmedMarkApi }) => {
                    setLastUnconfirmedMarkLog({
                        action: 'remove',
                        unconfirmedMarkApi: removedUnconfirmedMarkApi,
                    });
                },
            });
            return unsubscribeListenToUnconfirmedMarks;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSpaceCtx?.spaceApi.id]);

    return {
        lastUnconfirmedMarkLog,
    };
};
