import { createContext, useContext } from 'react';

import { RdbUser } from '@/utils/PowerchatClient';
import { HotUsers } from '@/utils/customHooks';

export type HotMembersCtxType = {
    hotUserIds: string[] | undefined;
    hotUsers: HotUsers | undefined;
    getHotRdbUser: (userId: string) => RdbUser | undefined;
};

export const HotMembersCtx = createContext<HotMembersCtxType>({
    hotUserIds: undefined,
    hotUsers: undefined,
    getHotRdbUser: () => {
        throw new Error('HotMembersCtx.getHotRdbUser: not initialized.');
    },
});
export const useHotMembersCtx = () => {
    return useContext(HotMembersCtx);
};
export const useHotMembersCtxAbsolutely = () => {
    const ctx = useHotMembersCtx();
    if (!ctx?.hotUserIds || !ctx?.hotUsers) {
        throw new Error('useHotMembersCtxAbsolutely: !ctx?.hotUserIds || !ctx?.hotUsers.');
    }
    return {
        ...ctx,
        hotUserIds: ctx.hotUserIds,
        hotUsers: ctx.hotUsers,
    };
};
