import { forwardRef, MouseEvent, ComponentProps } from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiPopover from '@material-ui/core/Popover';

export type { PopoverOrigin, PopoverPosition } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        backdropFilter: 'blur(40px)',
        background: 'rgba(0,0,0,.23)',
        boxShadow: '0 64px 64px -64px rgb(0 0 0 / 48%)',
    },
}));

export const Popover = forwardRef<
    HTMLElement,
    Omit<ComponentProps<typeof MuiPopover>, 'onClose' | 'classes' | 'className' | 'open' | 'transitionDuration'> & {
        className?: string;
        isOpen: boolean;
        onClose?: (mouseEvent: MouseEvent | undefined, reason: 'backdropClick' | 'escapeKeyDown') => void;
    }
>(({ className, isOpen, onClose, ...otherProps }, ref) => {
    const c = useStyles(useTheme());

    return (
        <MuiPopover
            ref={ref}
            open={isOpen}
            onClose={(e, reason) => {
                if (onClose) {
                    const mouseEvent = e as MouseEvent; // later
                    onClose(mouseEvent, reason);
                }
            }}
            classes={{ paper: clsx(c.paper, className) }}
            transitionDuration={{ enter: 500, exit: 400 }}
            {...otherProps}
        />
    );
});
Popover.displayName = 'Popover';
