import { ref, set } from 'firebase/database';
import { getFirebaseRealtimeDatabase } from '@/utils/GoogleCloud/firebase';
import {
    getRdbLastSpaceMembershipInput,
    ModifyRdbLastSpaceMembershipInput,
} from '@/utils/PowerchatClient/RealtimeDatabase/RdbModels';

export const modifyRdbSpaceMembership = ({
    spaceId,
    spaceMembership,
}: {
    spaceId: string;
    spaceMembership: ModifyRdbLastSpaceMembershipInput;
}) => {
    const db = getFirebaseRealtimeDatabase();
    const lastSpaceMembershipRef = ref(db, `lastSpaceMemberships/${spaceId}`);
    set(lastSpaceMembershipRef, {
        action: 'modify',
        ...getRdbLastSpaceMembershipInput({
            ...spaceMembership,
            updatedAt: 'serverTimestamp',
            removedAt: undefined,
            addNotification: undefined,
            removeNotification: undefined,
        }),
    });
};
