import { useEffect } from 'react';

import { ReplyObserverApi } from '@/utils/PowerchatClient';
import { useLastReplyObserverCtx } from '@/utils/ctxs';
import { useItems } from '@/utils/customHooks';

export const useReplyObservers = ({
    messageId,
    initialReplyObserverApis,
    fromUserId,
}: {
    messageId: string;
    initialReplyObserverApis: ReplyObserverApi[];
    fromUserId?: string;
}) => {
    // HOOKS
    const { lastReplyObserverLog } = useLastReplyObserverCtx();
    const { ids, itemDatas, getItemAbsolutely, addItem, removeItem } =
        useItems<ReplyObserverApi>(initialReplyObserverApis);

    // USEEFFECT
    useEffect(() => {
        if (lastReplyObserverLog?.replyObserverApi.messageId === messageId) {
            if (fromUserId && fromUserId !== lastReplyObserverLog.replyObserverApi.fromUserId) {
                return;
            }
            const { replyObserverApi } = lastReplyObserverLog;
            if (lastReplyObserverLog.action === 'add') {
                addItem({
                    newItem: replyObserverApi,
                    isIgnoreDuplicationError: true,
                });
            } else if (lastReplyObserverLog.action === 'remove') {
                console.log('remove replyObserver: ', replyObserverApi.id);
                removeItem({ id: replyObserverApi.id });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastReplyObserverLog, messageId]);

    return {
        replyObserverIds: ids,
        replyObserverApis: itemDatas,
        getReplyObserverItemAbsolutely: getItemAbsolutely,
    };
};
